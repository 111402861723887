<div [formGroup]="parentForm">
  <div formArrayName="medications">
    <h2 class="medications-title">
      <i class="healent-form-input-icon-12"></i> Current medications
    </h2>
    <div
      *ngFor="let medication of medications.controls; let i = index"
      [formGroupName]="i"
      class="medication-usage p-t-l p-b-s"
    >
      <i
        class="healent-close-circle close-icon"
        (click)="removeMedicationSet(i)"
      ></i>
      <app-custom-input
        [formGroup]="getMedicationFormGroup(medication)"
        controlName="name"
        placeholder="Name"
        [type]="'text'"
        [readonly]="false"
        [iconClass]="'healent-form-input-icon-12'"
      ></app-custom-input>
      <div class="dose-freq">
        <div>
          <app-custom-input
            [formGroup]="getMedicationFormGroup(medication)"
            controlName="dose"
            placeholder="Dose"
            [type]="'text'"
            [readonly]="false"
            [iconClass]="'healent-form-input-icon-13'"
          ></app-custom-input>
          <p class="hint-text p-l-s p-l-xs" style="margin-top: 0">
            e.g (2.5mL, 2.5mg)
          </p>
        </div>
        <div>
          <app-custom-input
            [formGroup]="getMedicationFormGroup(medication)"
            controlName="freq"
            placeholder="FREQ"
            [type]="'number'"
            [readonly]="false"
            [iconClass]="'healent-form-input-icon-14'"
          ></app-custom-input>
          <p class="hint-text p-l-s" style="margin-top: 0">Times per day</p>
        </div>
      </div>
    </div>
  </div>
  <button
    [ngClass]="{ 'medications-btn-m-t-m': medications.length === 0 }"
    class="medications-btn"
    (click)="addMedicationSet()"
  >
    <i class="healent-plus"></i>Add a medicine
  </button>
</div>
