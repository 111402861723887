<div class="error-main">
  <div class="w-700 m-auto h-body">
    <div class="flex-1">
      <i class="error-icon healent-form-input-icon-32"></i>
      <p class="error-title">Can't Load Your Form!</p>
      <p class="error-body">
        It looks like your form was not loaded; this can be temporary, so please
        try again later. If this issue continues, please contact us using the
        button below.
      </p>
      <a class="btn a-btn" [href]="'tel:' + supportPhoneNumber">
        Reach support
      </a>
    </div>
  </div>
</div>
