.title {
  background: var(--accent);
  border-radius: 2px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 4px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.subTitle {
  background: var(--accent);
  border-radius: 2px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 4px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.headLine {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 700;
}

.space-20 {
  width: 20px;
}

.m-v-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.m-l-16 {
  margin-left: 16px;
}

.fs-12 {
  font-size: 12px;
}
