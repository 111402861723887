In keeping with our philosophy of open communication and education, it is
important that you understand the financial policies of the practice. It is
equally important that you understand the terms of YOUR OWN medical coverage.
Your insurance policy is a contract between you and your insurance company.
Although our staff is very knowledgeable of most insurance plans, it is
important that you know the details and terms of your personal plan. Typically,
you will find the insurance company’s phone number on the back of your insurance
card and we encourage you to contact them with questions specific to your
coverage.
<br />
If your insurance plan requires a referral, you must contact your PCP prior to
receiving care from us. Regretfully, many insurers will not cover specialty
services that are rendered without a referral and you may be held responsible
for costs. Note: All ongoing referral renewals are the responsibility of the
patient.
<br />

At each office visit, you will be asked to:
<p style="padding-left: 20px">
  (1) Provide your most accurate and up-to-date insurance card (as well as any
  secondary or tertiary insurance info)<br />
  (2) Provide us with a copy of your current photo identifications<br />
  (3) Verify your correct address and phone number<br />
  (4) Make payment of your copay by cash, check, debit or credit card (returned
  checks will be charged a $40 fee)<br />
</p>
<br />

Your insurance company requires us to collect co-payments at the time services
are rendered. Failure to collect your co-payment may constitute fraud under
state and federal law. Please be prepared to pay your co-payment on the date
services are rendered.
<br />

Our office requires a 24-hour notice to cancel an appointment. A $50 fee may be
assessed to patients that do not provide this required notification.
<br />

If you are having a medical procedure, our staff will obtain a pre-certification
prior to your visit. We encourage you to contact your insurance company prior to
your procedure date to obtain an accurate amount of the co-insurance or other
monies that may be due relative to the portion of the charge that is your
financial responsibility.
<br />

CP&S participates in most major health plans and will submit claims for
services. It is the patient’s responsibility to provide all necessary
information to file the claims prior to leaving our office. We will file your
primary and secondary insurance claims and work diligently with the carrier to
resolve any conflicts that may arise. However, your insurance company may need
you to supply certain information directly. It is your responsibility to comply
with this request.
<br />

You may have coinsurance and/or deductible amounts required by your insurance
carrier. Any outstanding balance on your account following insurance processing
will be billed to you. If genuine financial difficulties exist, please call our
office. We are happy to work with you in resolving your balance and may be able
to set up payment arrangements.
<br />

Any patient balances that remain delinquent after 90 days may be referred to a
collection agency. You will be responsible for any and all costs associated with
the collection agency up to and including all legal costs. Patients with account
balances in excess of 120 days with no payment arrangements or hardship request
may be discharged from the practice. If this occurs, you will have 30 days to
seek alternative medical care and our physician will only be able to treat you
on an emergency basis.
<br />
I understand the above financial policy. I give consent to Commonwealth Pain &
Spine to bill insurance carrier and agree that I am financially responsible for
any and all charges whether or not they are covered by insurance. Please see the
front desk if you would like paper copy of this consent.
