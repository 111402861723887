app-header {
  width: 100%;
  background: var(--topbar-color);
  border-bottom: 2px var(--gray-2) solid !important;
  position: absolute;
  z-index: 999;
}
.h-main app-header .mat-toolbar {
  max-width: 720px;
  margin: auto;
  justify-content: space-between;
}
.header-logo-bg img {
  height: 32px;
  width: auto;
}
.provider-cps .header-logo-bg img {
  height: 48px;
}
.header-logo-bg span {
  color: var(--white);
  font-size: var(--font-size-s);
  font-weight: 500;
}
.header-logo-bg span {
  color: var(--white);
  font-size: var(--font-size-s);
  font-weight: 500;
}
body.provider-akps .header-logo-bg span {
  color: var(--primary-color);
}
