<h3 class="upload-title">{{ redTitle | translate }} {{ title | translate }}</h3>
<mat-card class="upload-card-1" (click)="openActionSheet()">
  <img
    mat-card-image
    *ngIf="formGroup.get(formName)?.value"
    [src]="formGroup.get(formName)?.value"
  />
  <mat-card-content
    *ngIf="!formGroup.get(formName)?.value"
    style="text-align: center; padding-top: 20px"
  >
    <div class="upload-title">
      <span class="prefix-title" *ngIf="prefix">{{ prefix | translate }} </span>
      <span>{{ title | translate }}</span>
    </div>
    <mat-icon class="upload-icon">
      <i
        class="input-icon {{ icon ? icon : 'healent-form-input-icon-34' }}"
      ></i>
    </mat-icon>
  </mat-card-content>
  <mat-card-actions>
    <button class="upload-btn">
      <mat-icon style="margin-right: 20px" matSuffix>
        <i class="input-icon healent-form-input-icon-9"></i>
      </mat-icon>
      {{
        formGroup.get(formName)?.value
          ? 'Replace image/file'
          : (btn | translate)
      }}
    </button>
  </mat-card-actions>
</mat-card>
<!-- Display required/optional hint -->
<div class="hintContainerPlus">
  <div *ngIf="sizeError">
    <span class="hintError">#Error</span>
    <span class="hintText"> - {{ errorMessage | translate }}</span>
  </div>
  <div *ngIf="!sizeError">
    <span *ngIf="required; else optionalField" class="hintRequired">
      *Required
    </span>
    <ng-template #optionalField>
      <span class="hintOptional">+Optional</span>
    </ng-template>
    <span class="hintText"> - {{ desc | translate }}</span>
  </div>
</div>
