import { Component, Input, AfterViewInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { BottomSheetRadioFormComponent } from '../bottom-sheet-radio-form/bottom-sheet-radio-form.component';
import { startWith } from 'rxjs/operators';

interface Options {
  [key: string]: string;
}

@Component({
  selector: 'app-custom-select',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatIconModule
  ],
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.css']
})
export class CustomSelectComponent implements AfterViewInit {
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() optionsTitle!: string;
  @Input() options!: Options;
  @Input() iconClass?: string;
  @Input() errorMessages!: { [key: string]: string };
  @Input() separateTitle?: boolean = false;
  @Input() titleNumber!: string;
  @Input() subTitle!: string;
  @Input() hint!: string;

  displayValue: string = '';
  @ViewChild('selectButton') selectButton?: ElementRef;

  constructor(private bottomSheet: MatBottomSheet, private cdr: ChangeDetectorRef) { }

  get control(): FormControl {
    const control = this.formGroup.get(this.controlName);
    if (!control) {
      console.warn(`Control with name '${this.controlName}' not found in form group`);
      return new FormControl();  // Return a default control to avoid errors
    }
    return control as FormControl;
  }

  ngAfterViewInit(): void {
    const control = this.control;

    control.valueChanges.pipe(startWith(control.value)).subscribe(value => {
      if (typeof value === 'string' && value) {
        this.displayValue = this.capitalizeFirstLetter(value);
      } else {
        this.displayValue = ''; // Reset if value is invalid
      }

      this.cdr.detectChanges();
    });
  }

  capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  get capitalizedValue(): string {
    return this.displayValue;
  }

  openOptions(): void {
    const currentValue = this.control.value;

    const itemsArray = Object.entries(this.options).map(([key, value]) => {
      return { key, value };
    });

    const bottomSheetRef = this.bottomSheet.open(BottomSheetRadioFormComponent, {
      data: {
        value: currentValue ? Object.keys(this.options).find(key => this.options[key].toLowerCase() === currentValue.toLowerCase()) : null,
        items: itemsArray,
        optionsTitle: this.optionsTitle || "Select the correct option"
      }
    });

    bottomSheetRef.afterDismissed().subscribe(selectedKey => {
      if (selectedKey) {
        const selectedValue = this.options[selectedKey];
        this.control.setValue(selectedValue);

        this.cdr.detectChanges();
      }

      // Check if `selectButton` exists before accessing `nativeElement`
      if (this.selectButton) {
        this.selectButton.nativeElement.focus();
      }
    });
  }

  getOptionValue(key: string): string {
    return this.options[key] || key;
  }

  getErrorMessage(): string {
    const controlErrors = this.control.errors;

    if (controlErrors) {
      for (const key in controlErrors) {
        if (controlErrors.hasOwnProperty(key) && this.errorMessages && this.errorMessages[key]) {
          return this.errorMessages[key];
        }
      }
    }

    return `Invalid selection`;
  }

  isFieldRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }
}
