<div class="done-main">
  <div class="w-700 m-auto h-body">
    <div class="flex-1">
      <i class="done-icon healent-ios-checkmark-circle"></i>
      <p class="done-title">
        {{ formsInfo.DoneTitle }}
      </p>
      <p class="done-body">
        {{ formsInfo.DoneBody }}
      </p>
      <div class="hide-me-in-app m-t-l d-flex gap-m">
        <a [href]="androidLinke">
          <img
            src="https://res.cloudinary.com/kitchry/image/upload/v1726847930/Images%20for%20app/p-s.png"
            class="download-img"
          />
        </a>
        <a [href]="iosLinke">
          <img
            src="https://res.cloudinary.com/kitchry/image/upload/v1726847930/Images%20for%20app/a-s.png"
            class="download-img"
          />
        </a>
      </div>
    </div>
  </div>
</div>
