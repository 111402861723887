<p>
  This Notice is provided to you pursuant to the privacy regulations enacted as
  a result of the
  <strong
    >Health Insurance Portability and Accountability Act of 1996 (HIPAA)</strong
  >. This joint notice of privacy practices describes how your medical
  information may be used and disclosed and how you can get access to your
  information. This Notice applies to all your medical information created or
  maintained by AK Pain and Spine Center (hereafter also referred to as “AKPS”
  or “the practice.” Please review this notice carefully.
</p>

<h3>A. OUR COMMITMENT TO YOUR PRIVACY</h3>
<p>
  AK Pain and Spine is committed to maintaining the privacy of your health
  information. We are required by law to (i) maintain the privacy of your health
  information; (ii) provide you with this notice of our legal duties and privacy
  practices with respect to your health information; (iii) follow the terms of
  the notice of privacy practices currently in effect; and (iv) notify you if
  there is a breach of your health information. We must also provide you with
  the following important information: (a) how we may use and disclose your
  health information; (b) your privacy rights; and (c) our obligations
  concerning the use and disclosure of your health information.
</p>
<br />

<p>
  This Notice of Privacy Practices is NOT an authorization. Rather it describes
  how we, our Business Associates, and their subcontractors may use and disclose
  your Protected Health Information to carry out treatment, payment, or
  healthcare operations, and for other purposes as permitted or required by law.
  It also describes your rights to access and control your Protected Health
  Information.
</p>
<br />

<p>
  “Protected Health Information” (“PHI”) means information that identifies you
  individually; including demographic information, and information that relates
  to your past, present, or future physical or mental health condition and/or
  related health care services.
</p>
<br />

<p>
  The terms of this notice apply to all your PHI created or maintained by AK
  Pain and Spine. We reserve the right to revise or amend this Notice at any
  time. Any revision or amendment to this notice will be effective for all of
  your records that we created or maintained in the past and for any of your
  records that we may create or maintain in the future. We will post a copy of
  our current Notice online at
  <a target="_blank" href="http://AKPainandSpine.com">AKPainandSpine.com</a>.
  You may request a copy of our most current Notice at any time.
</p>

<h3>
  B. USE AND DISCLOSURE OF YOUR INDIVIDUALLY IDENTIFIABLE HEALTH INFORMATION
  (PHI)
</h3>
<p>
  1. Treatment. AKPS may use or share your PHI to provide medical treatment or
  services for you and manage and coordinate your medical care. AKPS may
  disclose your PHI to physicians and healthcare providers (including
  pharmacists), durable medical equipment (DME) vendors, surgery centers,
  hospitals, rehabilitation therapists, home health providers, laboratories,
  nurse case managers, worker’s compensation adjusters, etc. to ensure that your
  medical providers have the necessary information to diagnose and provide
  treatment to you. For example, a doctor treating you for a broken leg may need
  to know if you have diabetes because diabetes may affect the healing process.
  AKPS may also disclose your PHI to individuals who are directly involved in
  your care, including family members, friends or other care providers. If you
  participate in a virtual visit (telehealth), your information will be shared
  electronically via a secure transmission to facilitate the virtual visit.
</p>
<br />

<p>
  2. Payment. AKPS may use and disclose your PHI in order to bill for services
  provided and collect payment from health plans or other entities. For example,
  we may disclose PHI to your health insurance plan so it will pay for your
  services, determine your eligibility for coverage, or to obtain prior approval
  from the insurer to cover payment for treatment. AKPS also may use and
  disclose your PHI to obtain payment from third parties that may be responsible
  for such costs, including family members. AKPS may also disclose your
  information to a collection agency to obtain overdue payment or to a
  regulatory agency or insurance company to determine whether the services we
  provided were medically necessary or appropriately billed.
</p>
<br />

<p>
  3. Health Care Operations. AKPS and its providers may use and disclose your
  PHI to run our businesses, improve your care, and contact you when necessary.
  For example: We may use or disclose your PHI:(1) to conduct quality or patient
  safety activities, population-based activities relating to improving health or
  reducing health care costs, case management and care coordination, and
  contacting your health care providers and you with information about treatment
  alternatives; (2) when conducting training programs or performing
  accreditation, licensing, or credentialing activities; (3) when conducting or
  arranging for medical review, legal services, and auditing functions; and (4)
  for our proper management and administration, including customer service,
  resolving complaints, strategic planning, etc. In addition, we may use or
  disclose de-identified information or a limited data set for certain
  healthcare operations purposes. We may also record your visit in order to
  facilitate the documentation of your care by your provider via a scribe or
  virtual scribe service.
</p>
<br />

<p>
  4. Appointment Reminders, Check-In and Results. AKPS may use and disclose your
  PHI to contact you and remind you of an appointment. AKPS may use a sign-in
  sheet at the registration desk and call you by name in the waiting room when
  your provider is ready to see you. AKPS may also use your PHI to contact you
  about test results. AKPS may leave a message reminding you of an appointment
  or the results of certain tests but will leave the minimum amount of
  information necessary to communicate this information.
</p>
<br />

<p>
  5.
  <strong>Treatment Options and Health-Related Benefits and Services</strong>.
  AKPS may use and disclose your PHI to inform you of treatment options or
  alternatives as well as certain health-related benefits or services that may
  be of interest to you. AKPS may also use and disclose your PHI to describe
  health-related products or services (or payment for such products or services)
  provided through your benefit plan or to offer information on other providers
  participating in a healthcare network that we participate in.
</p>
<br />

<p>
  6. <strong>Disclosures to Family or Friends</strong>. AKPS may disclose your
  PHI to individuals involved in your care or treatment or responsible for
  payment of your care or treatment. If you are incapacitated, we may disclose
  your PHI to the person named in your Durable Power of Attorney for Health Care
  or your personal representative (the individual authorized by law to make
  health-related decisions for you). In the event of a disaster, your PHI may be
  disclosed to disaster relief organizations to coordinate your care and/or to
  notify family members or friends of your location and condition.
</p>
<br />

<p>
  7. <strong>Disclosures Required by Law</strong>. AKPS will use and disclose
  your PHI when we are required to do so by federal, state or local law. For
  example, AKPS may disclose PHI to comply with child and elder abuse reporting
  laws or to report certain diseases, injuries or deaths to state or federal
  agencies.
</p>
<h3>C. USE AND DISCLOSURE OF YOUR PHI IN CERTAIN SPECIAL CIRCUMSTANCES</h3>
<p>
  1. <strong>Public Health Reporting</strong>. AKPS may disclose and may be
  required by law to disclose your PHI for certain public health purposes. For
  example, AKPS may disclose your PHI to the Food and Drug Administration (FDA)
  regarding the quality and safety of an FDA-regulated product or activity; to
  prevent or control disease; report births and deaths; to report child abuse
  and/or neglect; to report reactions to medications or problems with health
  products; to provide notification of recalls of products; or report a person
  who may have been exposed to a disease or may be at risk of contracting and/or
  spreading a disease or condition. In addition, AKPS may provide proof of
  immunizations to a school that requires a patient’s immunization record prior
  to enrollment or admittance of a student if you have informally agreed to the
  disclosure for yourself or on behalf of your legal dependent.
</p>
<br />

<p>
  2. Health Oversight Activities. AKPS may disclose your PHI to a health
  oversight agency for investigations, inspections, audits, surveys, licensure
  and disciplinary actions, and in certain civil, administrative, and criminal
  procedures or actions, or other health oversight activities as authorized by
  law.
</p>
<br />

<p>
  3. <strong>Lawsuits and Disputes</strong>. AKPS may disclose your PHI in
  response to a court or administrative order, subpoena, request for discovery,
  or other legal processes. However, absent a court order, AKPS will generally
  disclose your PHI if you have authorized the disclosure or efforts have been
  made to inform you of the request or obtain an order protecting the
  information requested. Your information may also be disclosed if required for
  our legal defense in the event of a lawsuit.
</p>
<br />

<p>
  4. <strong>Law Enforcement</strong>. AKPS may disclose your PHI if requested
  by a law enforcement official: (a) regarding a crime victim in certain
  situations, if we are unable to obtain the person’s agreement; (b) about a
  death we believe resulted from criminal conduct; (c) regarding criminal
  conduct on our premises; (d) in response to a warrant, summons, court order,
  subpoena or similar legal process; (e) to identify/locate a suspect, material
  witness, fugitive or missing person; or (f) in an emergency, to report a crime
  (including the location or victim(s) of the crime, or the description,
  identity or location of the perpetrator).
</p>
<br />

<p>
  5. <strong>Deceased Patients </strong>. AKPS may disclose your PHI to a
  medical examiner or coroner to identify a deceased individual or to identify
  the cause of death. In addition, we may disclose PHI necessary for funeral
  directors to fulfill their responsibilities.
</p>
<br />

<p>
  6. <strong>Organ and Tissue Donation</strong>. AKPS may disclose your PHI to
  organizations that handle organ, eye or tissue procurement or transplantation,
  including organ donation or blood banks, as necessary to facilitate donation
  and transplantation if you are a donor.
</p>
<br />

<p>
  7. <strong>Research</strong>. AKPS may use and disclose your PHI to
  researchers for the purpose of conducting research with your written
  authorization or when the research has been approved by an Institutional
  Review Board and is in compliance with law governing research. In certain
  situations, the need for your individual consent may be waived by a Privacy
  Board.
</p>
<br />

<p>
  8. <strong>Serious Threats to Health or Safety</strong>. AKPS may use and
  disclose your PHI when necessary to reduce or prevent a serious threat to your
  health and safety or the health and safety of another individual or the
  public. Under these circumstances, we will only make disclosures to a person
  or organization able to help prevent the threat.
</p>
<br />

<p>
  9.
  <strong>
    Military, National Security, and other Specialized Government
    Functions:</strong
  >
  If you are in the military or involved in national security or intelligence,
  AKPS may disclose your PHI to authorized officials. AKPS also may disclose
  your PHI to authorized federal officials in order to protect the President,
  other officials or foreign heads of state, or to conduct certain
  investigations.
</p>
<br />

<p>
  10. <strong>Workers’ Compensation</strong>. AKPS will disclose only the PHI
  necessary for worker’s compensation in compliance with worker’s compensation
  laws. This information may be reported to your employer and/or your employer’s
  representative in the case of an occupational injury or illness.
</p>
<br />

<p>
  11. <strong>Inmates</strong>. If you are an inmate or in the custody of a law
  enforcement official, AKPS may disclose your PHI to correctional institutions
  or law enforcement officials as necessary: (a) for the institution to provide
  health care services to you; (b) for the safety and security of the law
  enforcement officer or the correctional institution; and/or (c) to protect
  your health and safety or the health and safety of other individuals.
</p>
<br />

<p>
  12. <strong>Minors</strong>. If you are a minor (generally an individual under
  18 years old), we may disclose your PHI to your parent or guardian unless
  otherwise prohibited by law.
</p>
<h3>D.YOUR PRIVACY RIGHTS REGARDING YOUR PHI</h3>
<p>
  1. <strong>Inspection and Copies</strong>. You may request a copy of, or
  request to inspect, the PHI that is used to make decisions about you,
  including medical and billing records and laboratory and imaging reports. You
  have the right to obtain an electronic copy if it is readily producible by us
  in the form and format requested, or you may request that we provide a paper
  copy of your record. You may also request a summary of your record. We will
  provide your health information, to you or whomever you designate to receive
  it, usually within thirty (30) days of your request, or fifteen (15) days if
  your provider is in Texas. AKPS may charge a reasonable cost-based fee to
  cover the costs of copying, mailing, labor and supplies associated with your
  request. AKPS may deny your request to inspect and/or copy in certain limited
  circumstances; however, you may request a review of our denial. There may be
  times that your provider, in his or her professional judgment, may not think
  it is in your best interest to have access to your medical record. Depending
  on the reason for the decision to deny a request, we may ask another licensed
  provider chosen by us to conduct a review of your request and its denial.
</p>
<br />

<p>
  2. <strong>Confidential Communications</strong>. You may request in writing
  that we communicate with you in a specific way or send mail to a different
  address. For example, you may request that we contact you at home, rather than
  work or by mail. AKPS will accommodate all reasonable requests. You do not
  need to give a reason for your request. We will comply with your request if we
  are reasonably able to do so.
</p>
<br />

<p>
  3. <strong>Amendment</strong>. You may request a correction or amendment of
  your PHI if you believe it is incorrect or incomplete. You may make a written
  request for a correction or amendment for as long as your PHI is maintained by
  or for AKPS. Requests must provide a reason or explanation that supports the
  request. AKPS will deny your request if it is not in writing or if, in the
  provider’s opinion, the information is: (a) accurate and complete; (b) not
  part of the PHI maintained by or for AKPS; (c) not part of the PHI that you
  have the right to inspect and copy; or (d)not created by AKPS, unless the
  individual or entity that created the information is not available to amend
  the information. AKPS will notify you in writing within sixty (60) days if we
  cannot fulfill your request.
</p>
<br />

<p>
  4. <strong>Accounting of Disclosures</strong>. You may request an accounting
  of certain disclosures that AKPS has made of your PHI. This accounting will
  list the disclosures that we have made of your PHI but will not include
  disclosures made for the purposes of treatment, payment, health care
  operations, disclosures required by law, and certain other disclosures (such
  as any you asked us to make). Your request must be in writing and state the
  time period for which you want the accounting (not to exceed six (6) years
  prior to the date you make the request). AKPS will provide one accounting a
  year for free but will charge a reasonable, cost-based fee if you ask for
  another one within twelve (12) months. AKPS will notify you of the costs
  involved with any additional request and you may withdraw your request before
  you incur any costs.
</p>
<br />

<p>
  5. <strong>Requests for Restrictions</strong>. You have the right to request
  that AKPS not use or share your PHI for treatment, payment, or health care
  operations. We are not required to agree to your request, and we may say “no”
  if we believe it might affect your care. If you pay for a service or health
  care item out-of-pocket in full, you may ask us not to share that information
  for the purpose of payment or our operations with your health insurer. In that
  case, we will approve your request unless a law specifically requires us to
  share that information.
</p>
<br />

<p>
  6.
  <strong
    >Right to Receive a Notice of a Breach of Unsecured Medical Information </strong
  >. You have the right to receive prompt notice in writing of a breach of your
  PHI that may have compromised the privacy or security of your information.
</p>
<br />

<p>
  7. <strong>Right to a Paper Copy of This Notice</strong>. You have the right
  to receive a paper copy of this notice at any time even if you have agreed to
  receive the notice electronically. You may also obtain a copy of this notice
  at our website –
  <a target="_blank" href="http://AKPainandSpine.com">AKPainandSpine.com</a>.
</p>
<br />

<p>
  8. <strong>Right to File a Complaint</strong>. If you believe your rights have
  been violated, you may file a complaint with us or with the Secretary of the
  Department of Health and Human Services (“HHS”), Office for Civil Rights, 200
  Independence Avenue, S.W., Washington, D.C. 20201, calling
  <a target="_blank" href="tel:+1-877-696-6775">1-877-696-6775</a> , or visiting
  <a target="_blank" href="http://www.hhs.gov/ocr/privacy/hipaa/complaints/"
    >www.hhs.gov/ocr/privacy/hipaa/complaints/</a
  >. All complaints must be submitted in writing. You will not be penalized for
  filing a complaint.
</p>
<h3>E. ADDITIONAL INFORMATION</h3>
<p>
  1.
  <strong>Patient Portal and Other Patient Electronic Correspondence</strong>.
  AKPS may use and disclose your PHI through various secure patient portals that
  allow you to view, download and transmit certain medical and billing
  information and communicate with certain health care providers in a secure
  manner through the portal. For more information on the AKPS patient portal,
  please visit our website at
  <a
    href="http://www.AKPShealth.com/signin.html"
    target="_blank"
    rel="noopener noreferrer"
    >AKPShealth.com</a
  >.
</p>
<br />

<p>
  2.
  <strong
    >Your Contact Information: Home and Email Addresses/Phone Numbers</strong
  >. If you provide us with a home or email address, home/work/cell telephone
  number, or other contact information during any registration or administrative
  process we will assume that the information you provided us is accurate and
  that you consent to our use of this information to communicate with you about
  your treatment, payment for service and health care operations. You are
  responsible to notify us of any change of this information. AKPS reserves the
  right to utilize third parties to update this information for our records as
  needed.
</p>
<br />

<p>
  3. <strong>Email or Downloading PHI</strong>. If you email us medical or
  billing information from a private email address (such as a Yahoo or Gmail),
  your information may not be secure in transmission. We therefore recommend you
  use your AKPS patient portal to communicate with us regarding your care and/or
  billing issues. If you request that AKPS email your PHI to a private email
  address, we will send it in an encrypted manner unless you request otherwise.
  AKPS is not responsible for the privacy or security of your PHI if you request
  that we send it to you in an unsecured manner or download or post it on a
  Dropbox, unencrypted USB drive, CD or other unsecure medium. In addition, AKPS
  is not responsible if your PHI is redisclosed, damaged, altered or otherwise
  misused by an authorized recipient. In addition, if you share an email account
  with another person (for example, your spouse/partner/roommate) or you choose
  to store, print, email, or post your PHI, it may not be private or secure.
</p>
<br />

<p>
  4. <strong>Sensitive Health Information</strong>. Federal and state laws
  provide special protection for certain types of health information, including
  psychotherapy notes, information about substance use disorders and treatment,
  mental health and AIDS/HIV or other communicable diseases, and may limit
  whether and how we may disclose information about you to others.
</p>
<br />

<p>
  5. <strong>Substance Use Disorder Records and Information</strong>. The
  confidentiality of patient records maintained by federally assisted substance
  use disorder rehabilitation programs is protected by Federal law and
  regulations. Generally, such programs may not disclose any information that
  would identify an individual as having or being treated for a substance use
  disorder unless:
</p>
<ul>
  <li>a. the individual consents in writing;</li>
  <li>b. the disclosure is allowed by a court order;</li>
  <li>
    c. the disclosure is made to medical personnel in a medical emergency or to
    qualified personnel for research, audit, or program evaluation; or as
    otherwise permitted by law
  </li>
</ul>
<p>
  (Notwithstanding the preceding, we may disclose certain information that could
  identify you as having a substance use disorder pursuant to paragraph 6,
  below.) Violation of these laws and regulations is a crime. Suspected
  violations may be reported to appropriate authorities in accordance with
  Federal regulations. Federal law and regulations do not protect any
  information about a crime committed by a patient either at the program or
  against any person who works for the program or about any threat to commit
  such a crime. Federal laws and regulations do not prevent any information
  about suspected child abuse or neglect from being reported under state law to
  appropriate state or local authorities.
</p>
<br />

<p>
  6.
  <strong
    >Consent to Disclose Sensitive Health and Substance Use Disorder
    Information</strong
  >. The AKPS Authorization & Consent to Treat form you sign as part of the
  registration process includes your consent to the release of federally
  assisted substance use disorder information, information regarding treatment
  of communicable diseases and mental health information for the purposes
  specified in this notice. If you do not wish for this information to be
  disclosed, you must notify us in writing and we will determine if it is
  feasible for us to accept your request.
</p>
<br />

<p>
  7. <strong>Incidental Disclosures</strong>. Despite our efforts to protect
  your privacy, your PHI may be overheard or seen by people not involved in your
  care. For example, other individuals at your provider’s office could overhear
  a conversation about you or see you getting treatment. Such incidental
  disclosures are not a violation of HIPAA.
</p>
<br />

<p>
  8. <strong>Business Associates</strong>. Your PHI may be disclosed to
  individuals or entities who provide services to or on behalf of AKPS. Pursuant
  to HIPAA, AKPS requires these companies sign business associate or
  confidentiality agreements before we disclose your PHI to them. However, AKPS
  generally does not control the business, privacy, or security operations of
  our business associates.
</p>
<br />

<p>
  9. <strong>Authorization for Other Uses and Disclosures</strong>. AKPS will
  obtain your written authorization for uses and disclosures that are not
  identified by this notice or otherwise required or permitted by applicable
  law. Any authorization you provide regarding the use and disclosure of your
  PHI may be revoked at any time in writing. After you revoke your
  authorization, we will no longer use or disclose your PHI for the reasons
  described in the authorization. However, your revocation will not affect
  actions we have already taken; in other words, we are unable to take back any
  disclosures of PHI we have already made.
</p>
<h3>F. CHANGES TO THIS NOTICE.</h3>
<p>
  AKPS reserves the right to change this Notice at any time. AKPS reserves the
  right to make the revised or changed Notice effective for medical information
  we already have about you, as well as for any information we receive in the
  future. AKPS will post the current Notice on our website at
  <a href="http://AKPainandSpine.com" target="_blank" rel="noopener noreferrer"
    >AKPainandSpine.com</a
  >.
</p>

<h3>G. NO WAIVER.</h3>
<p>
  Under no circumstances will AKPS require an individual to waive his or her
  rights under the HIPAA Privacy Rule or the HIPAA Breach Notification Rule as a
  condition for receiving treatment.
</p>
<h3>H. CONTACT/COMPLAINT INFORMATION.</h3>
<p>
  If you have any questions about this Notice or wish to file a privacy
  complaint, please contact:
</p>
<p>Privacy Officer</p>
<p>255 Ed English Dr., Ste C Shenandoah, TX 77385</p>
<p>ph: <a href="tel:281.896.0013">281.896.0013</a></p>
<p>fax: <a href="tel:713.527.2527">713.527.2527</a></p>
<p>
  Email:
  <a href="mailto:info@akpainandspine.com">info&#64;akpainandspine.com</a>
</p>
<br />
<p>You can file a complaint directly with the U.S.</p>
<p>
  Department of Health and Human Services Office for Civil Rights by sending a
  letter to: 200 Independence Avenue, S.W Washington, D.C. 20201 calling
  <a href="tel:1-877-696-6775">1-877-696-6775</a> or online at:
  <a
    href="http://www.hhs.gov/ocr/privacy/hipaa/complaints/"
    target="_blank"
    rel="noopener noreferrer"
    >www.hhs.gov/ocr/privacy/hipaa/complaints/</a
  >
</p>
<br />
<p>Texas Department of State Health Services Investigations</p>
<p>P.O. Box 141369 Austin, Texas 78714- 1369.</p>
<p>
  More information is at:
  <br />
  <a
    href="http://https://dshs.texas.gov/hipaa/privacycomplaints.shtm"
    target="_blank"
    rel="noopener noreferrer"
    >https://dshs.texas.gov/hipaa/privacycomplaints.shtm</a
  >
</p>
<p>We may not retaliate against you for filing a complaint.</p>
