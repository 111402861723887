import {
  Component, HostListener, OnInit, OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { AppType } from './customer/common/types';
import { HeaderComponent } from './reusable/header/header.component';
import {
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { StepProgressService } from './services/stepProgress/step-progress.service';
import { AppService } from "./repair/services/form.service";

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, TranslateModule, MatProgressBarModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'heal-angular-forms';
  app: AppType = AppType.AKPS;
  progress = 1;
  private stepChangeSubscription!: Subscription;

  constructor(
    private stepProgressService: StepProgressService,
    private appService: AppService // Inject AppService here
  ) { }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    // const confirmationMessage = 'You\'re about to leave the form; are you sure you want to do that?';
    // $event.returnValue = confirmationMessage;
    // return confirmationMessage;
  }

  ngOnInit() {
    // Fetch hospital_slug from localStorage
    const hospitalSlug = localStorage.getItem('hospital_slug');
    const patient_id = localStorage.getItem('patient_id');

    // Check if hospital_slug exists, then update the title and favicon
    if (hospitalSlug) {
      this.appService.setFavIcon(hospitalSlug);
    } else {
      console.error('No hospital_slug found in localStorage');
    }
    if (window.clarity && typeof window.clarity === "function") {
      window.clarity("identify", String(patient_id), String(patient_id), String(patient_id), String(patient_id));
    } else {
      console.warn("Clarity is not initialized yet.");
    }

    // Progress bar logic
    const totalSteps = this.stepProgressService.getTotalSteps(); // Get the total number of steps
    const initialStep = this.stepProgressService.getCurrentStep(); // Get the initial step
    this.updateProgress(initialStep, totalSteps); // Set initial progress

    this.stepChangeSubscription = this.stepProgressService.stepChange.subscribe((step: number) => {
      this.updateProgress(step, totalSteps); // Update progress on step change
    });
    setTimeout(() => {
      if (hospitalSlug) {
        this.appService.setFavIcon(hospitalSlug);
      }
      this.getBrowserVersion();
    }, 1000);
    setTimeout(() => {
      if (hospitalSlug) {
        this.appService.setFavIcon(hospitalSlug);
      }
      this.getBrowserVersion();
    }, 3000);
  }

  ngOnDestroy() {
    if (this.stepChangeSubscription) {
      this.stepChangeSubscription.unsubscribe();
    }
  }

  updateProgress(step: number, totalSteps: number) {
    this.progress = ((step - 1) / (totalSteps - 1)) * 100;
  }
  getBrowserVersion() {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf('Chrome') > -1) {
      const chromeVersionMatch = userAgent.match(/Chrome\/(\d+)/);
      if (chromeVersionMatch) {
        const chromeVersion = parseInt(chromeVersionMatch[1], 10);
        if (chromeVersion < 102) {
          alert('Your Chrome version is too old and not supported.');
        }
      }
    }

    if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) {
      const safariVersionMatch = userAgent.match(/Version\/(\d+)/);
      if (safariVersionMatch) {
        const safariVersion = parseInt(safariVersionMatch[1], 10);
        if (safariVersion < 15.4) {
          alert('Your Safari version is too old and not supported.');
        }
      }
    }
  }

}

export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient, private router: Router) { }

  public getTranslation(lang: string): Observable<any> {
    const timestamp = new Date().getTime();
    let path = `./assets/i18n/${lang}.json?t=${timestamp}`;
    return this.http.get(path);
  }
}

export function createTranslateLoader(http: HttpClient, router: Router) {
  return new CustomTranslateLoader(http, router);
}
