import { Component } from '@angular/core';

@Component({
  selector: 'app-cwps-authorization-to-treat',
  standalone: true,
  imports: [],
  templateUrl: './cwps-authorization-to-treat.component.html',
  styleUrl: './cwps-authorization-to-treat.component.css'
})
export class CwpsAuthorizationToTreatComponent {

}
