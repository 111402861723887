<h3>PATIENT DISCLOSURE:</h3>
<p>To All New Patients:</p>
<br />
<p>
  During your medical treatment with Dr. Ankur Khosla, we may refer you to a
  hospital, ambulatory surgery center, diagnostic facility, anesthesia provider,
  laboratory facility and/or implant a medical device in which they may have a
  pecuniary interest in the company that owns the aforementioned. (A list can be
  provided at your request.)
</p>
<br />
<p>
  As a patient of Dr. Khosla you have a right to be treated by physicians and at
  facilities of your choosing. If you elect to be treated at facilities other
  than those to which you have been referred this will in no way affect the
  quality of your healthcare. However, your treating physician may or may not be
  credentialed at the facilities of your choosing and thus require you to obtain
  a new treating physician.
</p>
<br />
<p>
  As a patient Dr. Khosla you have the right to request and you agree that you
  will request that we refer you to a different physician, hospital, ambulatory
  surgery center, diagnostic facility and/or laboratory if you are unhappy with
  the initial referral.
</p>
