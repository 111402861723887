import { provideRouter, Route } from '@angular/router';
import { BlueClientComponent } from "./repair/blue-client/blue-client.component";
import { DirectorComponent } from "./director/director.component";
import { VerificationComponent } from './repair/verification/verification.component';
import { DoneComponent } from './repair/reusable/done/done.component';

export const routes: Route[] = [
  { path: 'director/:ciphertext', component: DirectorComponent },
  { path: 'verification/:form_id', component: VerificationComponent, data: { key: 'Verification' } },
  { path: 'form/:form_id', component: BlueClientComponent, data: { key: 'BlueClient' } },
  { path: 'done', component: DoneComponent },
];
