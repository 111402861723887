<p>
  Under federal and state law, we must notify our patients that Commonwealth
  Pain and Spine physicians have financial interests in the medical facilities
  listed below.
</p>
<p>
  A “financial interest” means that the physician may have an ownership or
  investment interest in the medical facility and receive money or other
  compensation from the medical facility.
</p>
<ul>
  <li>Proscan Imaging; Dr. Jason Lewis and Dr. S. Kyle Young</li>
  <li>Premier Surgery Center; Dr. S. Kyle Young</li>
  <li>PMC; Dr. Jason Lewis</li>
  <li>Medosi, manufacturer of CDC products; Dr. S. Kyle Young</li>
</ul>
<p>
  Your Commonwealth medical provider may refer you to one or more of the
  facilities listed for further treatment or testing.
</p>
<p>
  Please let us know immediately if you would NOT like to be referred to any of
  the facilities listed above.
</p>
<p>
  You have the right to choose the medical facility you want to perform the
  health care services ordered by your Commonwealth medical provider.
</p>
<p>
  You are free to choose the medical facility you want to go to for the
  treatment or testing services ordered by your Commonwealth medical provider.
</p>
<p>
  You do not have to go to the medical facilities listed above. You will not be
  treated differently by your Commonwealth medical provider if you choose to
  obtain health care services at a medical facility of your choice.
</p>
<p>
  By signing this Disclosure, you acknowledge that you have read and understand
  the foregoing notice and hereby understand that Commonwealth physicians have
  ownership interests in the medical facilities listed above.
</p>

<p>(A copy of this Disclosure MUST be maintained in the patient’s record)</p>
