<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>RPM/RTM Consent Form</title>
  </head>
  <body>
    <div class="container">
      <h4>
        Remote Patient Monitoring (RPM)/Remote Therapeutic Monitoring (RTM)
        Consent Agreement
      </h4>
      <h4>Patient Consent Agreement</h4>
      <div class="section">
        <p>
          By signing this Patient Consent Form, you agree for Commonwealth Pain
          & Spine to provide remote patient monitoring services (referred to as
          “RPM” services) and remote therapeutic monitoring services (referred
          to as “RTM” services) as fully described below. RPM/RTM services
          include daily physiologic and/or therapeutic monitoring, and
          occasional assessments, along with review available by, or under the
          supervision of, your physician, for patients who meet the following
          criteria:
        </p>
        <ul>
          <li>You must have been diagnosed with a chronic condition.</li>
          <li>
            You must have an Android or iOS device with internet access which
            you can use daily.
          </li>
          <li>You must have an active cell phone number.</li>
        </ul>
      </div>

      <div class="section">
        <h3>Patient (Beneficiary) Acknowledgement and Authorization</h3>
        <p>By signing this Consent Agreement, you agree to the following:</p>
        <ul>
          <li>
            You authorize electronic communication of your medical information
            with other treating providers as part of coordination of your care.
          </li>
          <li>You will be provided a Pulse Oximeter (if applicable).</li>
          <li>
            You acknowledge that only one practitioner can furnish RPM/RTM
            Services to you during a calendar month.
          </li>
          <li>
            You understand cost-sharing applies to RPM/RTM Services, so you may
            be billed for a portion of RPM/RTM Services even though it does not
            involve a face-to-face meeting with a provider.
          </li>
          <li>
            You must return any provided monitoring equipment (if provided) by
            the end of the month upon discontinuation of the service.
          </li>
          <li>
            You understand that your doctor has prescribed Remote Patient
            Monitoring/Remote Therapeutic Monitoring (RPM or RTM) as applicable
            to you. The RPM/RTM service provider, Healent Health Inc., is not
            responsible for your healthcare management. Healent's role is to
            collect information for your doctor to better manage your chronic
            condition(s) or exacerbation(s). Should you experience an EMERGENT
            or URGENT health event while on RPM/RTM services, it is your
            responsibility to contact Emergency Services 911 for help.
          </li>
          <li>
            You will do your best to take your readings and complete all
            assignments as ordered daily.
          </li>
          <li>
            You are aware that your Commonwealth Pain & Spine Medical Provider
            and/or your Provider’s qualified clinical staff will review your
            readings multiple times each month, and that this program is only
            available during regular business hours.
          </li>
          <li>
            Any communication that takes place through the monitoring service
            will be addressed as quickly as possible; however, as stated above,
            this service is NOT available 24 hours a day, 7 days a week.
          </li>
          <li>
            You agree to receive voice calls, SMS text messages, and
            notifications from the Healent Health service.
          </li>
        </ul>
      </div>

      <div class="section">
        <h3>Patient (Beneficiary) Rights</h3>
        <ul>
          <li>
            You have the right to stop RPM/RTM Services at any time by revoking
            this Agreement effective at the end of the then-current month. You
            may revoke this agreement verbally (by calling 502-352-2273) or in
            writing.
          </li>
        </ul>
      </div>

      <div class="section">
        <h3>Authorization to Disclose PHI</h3>
        <ul>
          <li>
            I authorize Healent Health, Inc. and Commonwealth Pain & Spine to
            use and disclose my protected health information.
          </li>
          <li>
            This document shall remain in effect until my written or verbal
            revocation is obtained.
          </li>
          <li>
            This medical information may be used by the person/persons
            authorized to receive this information for my medical treatment or
            consultation, billing or claims payment, or other purposes as
            needed.
          </li>
          <li>
            I understand that I have the right to revoke this authorization in
            writing or verbally at any time. I understand that a revocation is
            not effective to the extent that any person or entity has already
            acted in reliance on my authorization or if my authorization was
            obtained as a condition of obtaining insurance coverage and the
            insurer has a legal right to contest a claim.
          </li>
          <li>
            I understand that my treatment, payment, enrollment, or eligibility
            for benefits will not be conditioned on whether I sign this
            authorization.
          </li>
          <li>
            I understand that information used or disclosed pursuant to this
            authorization may be disclosed by the recipient and may no longer be
            protected by Federal or State law.
          </li>
        </ul>
      </div>
    </div>
  </body>
</html>
