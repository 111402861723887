<div class="right-selector">
  <svg
    #svgElement
    width="300"
    height="600"
    viewBox="0 0 1.7460802665149737 11.303058618150343"
    version="1.1"
    id="R_svg197"
    (click)="onSvgClick($event)"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      height="20"
      d="M 0.35006197,1.8350267 C 0.58673574,1.8914594 0.8273027,1.9439472 1.0602301,2.0091882 1.1360778,2.0348872 1.0584136,1.8189848 1.0859678,1.7694433 1.07399,1.5423675 1.1095884,1.318668 1.1564525,1.0972591 0.92994017,1.0638707 0.70224513,1.0334307 0.47791192,0.99047883 0.47737218,1.1522075 0.48714577,1.3373827 0.4149194,1.4957644 0.38705123,1.6072113 0.37305293,1.7223907 0.35006197,1.8350267 Z"
      id="R_neck"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 0.31097398,0.74509227 C 0.40881237,1.1011904 0.79133437,1.2464016 1.0942001,1.3873515 1.2282316,1.4214991 1.368096,1.5101902 1.5075835,1.481585 1.6350752,1.3912517 1.6237091,1.2005774 1.6498835,1.0609948 1.6802295,0.70805202 1.6201674,0.25950852 1.2650599,0.08586911 0.94716751,-0.08772728 0.43703677,-0.00690787 0.32011822,0.37719979 0.28314243,0.49602176 0.28088364,0.62450293 0.31097398,0.74509227 Z"
      id="R_head"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      width="60"
      d="M 0.07758463,2.2877092 C 0.09410467,2.7293112 0.46146582,3.0424563 0.61298862,3.4367794 0.67203386,3.5460278 0.70380038,3.7615818 0.86681351,3.751365 0.99350316,3.7293146 1.0762466,3.6071825 1.2075754,3.5888745 1.4572128,3.4904767 1.6941012,3.2780726 1.7449811,3.006537 1.7379876,2.5746349 1.4555941,2.2165428 1.2085506,1.8862161 1.0431145,1.7197645 0.89995473,1.4898918 0.65830889,1.4315019 0.41415227,1.4067867 0.3038906,1.6744941 0.19801041,1.8462166 0.12647921,1.982659 0.07689167,2.1322655 0.07758463,2.2877092 Z"
      id="R_upperBody"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 0.60888113,4.6420573 C 0.58501013,4.9772745 0.92915592,5.1860617 1.220811,5.2336678 1.3715826,5.271385 1.5990136,5.2426417 1.6217226,5.0519297 1.7470284,4.6686042 1.6355157,4.2652494 1.683921,3.8727067 1.6877571,3.5723312 1.7170834,3.2471468 1.5799155,2.9698908 1.4070656,2.6856932 0.90284029,2.6851655 0.74200402,2.9809482 0.63641441,3.2862124 0.77387454,3.6110098 0.73262095,3.9248747 0.7294201,4.1703363 0.63363285,4.4002398 0.60888113,4.6420573 Z"
      id="R_lowerBody"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      height="50"
      d="m 0.48785245,5.8716976 c -0.009327,0.5827469 0.0665106,1.1649046 0.18616632,1.7341486 0.0541261,0.1612215 0.20491377,0.376453 0.40012283,0.3169379 C 1.306881,7.79108 1.3202435,7.4827316 1.3899734,7.2514759 1.546859,6.6976961 1.6522769,6.124119 1.6124665,5.5462636 1.5878585,5.2692572 1.6216725,4.9443683 1.4309966,4.7180386 1.2124499,4.5130906 0.85698609,4.6735297 0.72394536,4.9027459 0.53958151,5.1873397 0.50327592,5.5409752 0.48785245,5.8716976 Z"
      id="R_rightUpperLeg"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="m 0.23348136,8.5695217 c 0.0350688,0.4654987 0.12808419,0.9261617 0.13411335,1.3946492 0.0310073,0.2252371 -0.0454192,0.4846541 0.0796121,0.6879891 0.078779,0.08492 0.16765525,0.225087 0.30243468,0.193166 0.1439677,-0.112303 0.0744896,-0.33197 0.0841834,-0.489367 C 0.80632836,9.7996307 0.91162612,9.2458843 1.0277306,8.7042666 1.1108435,8.3297058 1.2514186,7.9385744 1.1590868,7.5533645 1.1195145,7.3648925 0.85971919,7.2627911 0.7273174,7.4217024 0.54564081,7.6483874 0.45268139,7.933957 0.32154496,8.1910255 0.26780508,8.3105488 0.23517707,8.4380731 0.23348136,8.5695217 Z"
      id="R_rightLowerLeg"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      width="50"
      d="M 0.36514635,5.4257763 C 0.35958752,5.6067601 0.46433756,5.8086331 0.65864758,5.8327889 0.90643582,5.8728266 1.1493992,5.7179583 1.3977871,5.8027417 1.5267227,5.8449314 1.7112992,5.8172201 1.7336194,5.6558574 1.7660851,5.359917 1.6480612,5.0724079 1.6520385,4.7759678 1.6393162,4.6147157 1.6818668,4.4070671 1.5132124,4.3092196 1.2819263,4.1491565 0.95722817,4.2363533 0.78439098,4.4428088 0.51675152,4.6799174 0.41036731,5.0448166 0.36665443,5.3876999 l -9.0526e-4,0.022856 z"
      id="R_hips"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="m 0.29828425,11.060158 c -0.0177414,0.140563 0.13254524,0.212788 0.25243753,0.180445 0.34296262,-0.0038 0.68842682,0.101035 1.03017882,0.04456 0.1209906,-0.01904 0.1295713,-0.189895 0.021471,-0.235304 C 1.3706546,10.905386 1.0302417,10.892003 0.90845486,10.608884 0.81578146,10.455159 0.78222639,10.255567 0.63763896,10.140157 0.4697853,10.100541 0.39572909,10.313448 0.39448312,10.44617 c -0.0091,0.201715 -0.0455702,0.398964 -0.0944882,0.593552 z"
      id="R_rightFoot"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 1.0194914,7.770751 C 1.0059338,7.869922 1.0320957,8.0746577 1.1725583,8.0417632 1.3219265,7.9768808 1.3535163,7.7767719 1.3478006,7.630381 1.3472244,7.519439 1.1991619,7.3980887 1.1154529,7.5098245 1.049784,7.5795257 1.0336279,7.679975 1.0194914,7.770751 Z"
      id="R_rightKnee"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      height="10"
      d="M 0.13281049,2.1459589 C 0.11202456,2.3764052 0.3121542,2.5532221 0.49248777,2.6601897 0.64408915,2.7474474 0.83981892,2.7025864 0.93448675,2.5537445 1.1898348,2.3498837 1.0304213,1.8961095 0.73125976,1.8302776 0.55178086,1.7767 0.28581704,1.7300112 0.19047627,1.9416317 0.15853883,2.006187 0.1421712,2.0748971 0.13281049,2.1459589 Z"
      id="R_rightShoulder"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 0.03066456,3.2157372 C 0.060629,3.4616605 -0.00367469,3.7255483 0.09163563,3.959199 0.18598198,4.122761 0.42568276,4.0598923 0.49904118,3.9097677 0.60714667,3.6900305 0.70532185,3.4615357 0.78085222,3.2282023 0.84454222,2.9394492 0.79935784,2.6178631 0.65177246,2.3615687 0.54532556,2.1844842 0.2362043,2.1564143 0.15470246,2.3712178 0.05091207,2.6379806 0.01991363,2.9311542 0.03066456,3.2157372 Z"
      id="R_rightUpperArm"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 2.5332914e-8,3.8564432 C -0.01482485,3.9938362 0.15528538,4.1639983 0.27968724,4.0504788 0.40010709,3.9192777 0.23122488,3.6387182 0.05588205,3.7377756 0.01270671,3.7641764 0.00474426,3.8103375 2.5332914e-8,3.8564432 Z"
      id="R_rightElbow"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 0.09591604,3.8568919 C 0.08231851,4.3367573 0.41785113,4.720482 0.55304355,5.1610353 0.57272623,5.3054382 0.74274155,5.3221629 0.84483424,5.2601674 0.90481169,5.1485119 0.82233032,5.0087354 0.82221277,4.8897606 0.74609273,4.5404018 0.79741045,4.1582744 0.60833368,3.8394057 0.56696175,3.6780474 0.40297193,3.4655203 0.21628403,3.548463 0.10755724,3.6065766 0.09565217,3.7476419 0.09591604,3.8568919 Z"
      id="R_rightLowerArm"
    />
    <path
      id="R_rightHand"
      fill-rule="evenodd"
      class="svgUnselected"
      d="m 0.43255026,6.0607603 c 0.0738299,0.028852 0.13036832,0.017846 0.11605737,-0.067962 0.0132137,-0.09944 0.0287179,-0.2041545 0.0419304,-0.3080276 C 0.44815901,5.6121789 0.46741803,5.743686 0.45241478,5.839611 0.44486717,5.913446 0.43385644,5.9864183 0.43255026,6.0607603 Z M 0.60360224,5.8807044 c 0.00274,0.1022459 6.666e-5,0.2054226 0.004764,0.3079534 0.12605721,0.043241 0.12310984,-0.043336 0.10851513,-0.1358254 0.0341225,-0.030047 0.002226,-0.1232976 0.0136861,-0.1759793 -0.009947,-0.060495 0.0474862,-0.1998633 -0.0718965,-0.1589998 -0.10458111,-0.040319 -0.0316842,0.1149228 -0.0550684,0.1628511 z m 0.14652592,-0.155437 c 0.0217802,0.1778941 0.0465523,0.3554996 0.0756755,0.533818 0.15777443,0.033503 0.0968297,-0.095805 0.0829575,-0.1876048 0.0359902,-0.031233 -0.0233435,-0.1298315 -0.0105559,-0.1803536 -0.0121725,-0.1029857 0.0118073,-0.251754 -0.1433218,-0.173696 -0.0172582,0.028441 0.0234896,-0.038711 -0.004755,0.00784 z m 0.14908838,-0.013095 c 0.0373775,0.151789 0.071447,0.3030004 0.11728986,0.4539172 0.1491396,0.013798 0.094978,-0.094724 0.064176,-0.1837284 0.030938,-0.039494 -0.023319,-0.1228174 -0.028246,-0.1831886 -0.00489,-0.1092611 -0.031573,-0.1839286 -0.150165,-0.1126809 0,0 -0.003047,0.025688 -0.003047,0.025688 z m 0.084817,-0.2222811 C 1.0375191,5.605749 1.0966767,5.6868084 1.1514762,5.8040098 1.3037756,5.7827529 1.2443078,5.6868225 1.1850162,5.6009813 1.155671,5.5691215 1.1391928,5.3835699 1.0634606,5.4487044 c -0.026476,0.013729 -0.052951,0.027458 -0.0794271,0.041187 z M 0.47807877,5.6439169 C 0.46539108,5.7968563 0.65065451,5.7953265 0.75500304,5.7788267 0.83836268,5.7567977 1.0251181,5.8109881 1.0184755,5.6721919 0.98087004,5.5575015 1.1638544,5.5244022 1.0971402,5.4105033 1.0215961,5.2652875 0.87180119,5.0707411 0.68508935,5.1136103 0.48221809,5.1889883 0.48856962,5.4679326 0.47807877,5.6439169 Z"
    />
  </svg>
</div>
