import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatIcon } from "@angular/material/icon";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { KeyValuePipe, NgForOf, NgIf } from "@angular/common";
import { MatCheckbox } from "@angular/material/checkbox";
import { TranslateService } from "@ngx-translate/core";
import { formObjectByType } from "../../functions/functions";
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CustomSelectComponent } from '../../reusable/custom-select/custom-select.component';
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';
import { CustomRadioGroupComponent } from '../../reusable/custom-radio-group/custom-radio-group.component';
import { CustomDatePickerComponent } from '../../reusable/custom-date-picker/custom-date-picker.component';
import { PhoneComponent } from '../../reusable/phone/phone.component';

@Component({
  selector: 'app-employer-information-step-form',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatIcon,
    MatError,
    MatRadioButton,
    MatRadioGroup,
    NgIf,
    MatHint,
    MatSuffix,
    MatCheckbox,
    KeyValuePipe,
    NgForOf,
    CustomCheckboxComponent,
    CustomSelectComponent,
    CustomInputComponent,
    CustomRadioGroupComponent,
    CustomDatePickerComponent,
    PhoneComponent
  ],
  templateUrl: './employer-information-step-form.component.html',
  styleUrl: './employer-information-step-form.component.css'
})
export class EmployerInformationStepFormComponent implements OnInit, OnDestroy {

  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';
  formValues: any = {};

  minDate!: Date;
  maxDate!: Date;
  form_loaded = false;
  myForm!: FormGroup;
  checkboxItems: any = {}
  radioItems: any = {}
  inputItems: any = {}

  destroySubject = new Subject<void>();

  constructor(
    private translateService: TranslateService
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit() {

    this.myForm = new FormGroup({});
    const currentLang = this.translateService.currentLang;
    const lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {

      const translationsObj = translations['healent'][this.step];
      const translationsObjSlug = translations[this.hospital_slug][this.step];

      let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step)
      this.myForm = formObject['myForm'];
      this.radioItems = formObject['radioItems'];
      this.inputItems = formObject['inputItems'];
      this.checkboxItems = formObject['checkboxItems'];

      if (this.patch_values) {
        this.myForm.patchValue(this.patch_values, { emitEvent: false });
      }
      this.control?.setValue(this.myForm.value, { emitEvent: true }); // set initial value
      this.formGroup = this.myForm;
      this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(val => {
        this.formValues = val;
        this.control?.setValue(this.myForm.value, { emitEvent: true });
        this.formGroup = this.myForm;
      });
      this.onValueChanges()
      this.form_loaded = true;
    })
  } // end ngOnInit

  onValueChanges() {
    this.myForm.get('isWorkVehicleRelated')?.valueChanges.subscribe(checked => {

      const date_injuryControl = this.myForm.get('injuryDate');
      const claim_numberControl = this.myForm.get('claim');
      const case_managerControl = this.myForm.get('caseManager');
      //  if (proxy_relationshipControl && proxy_full_nameControl) {} as alternative to proxy_relationshipControl!
      if (checked) {
        date_injuryControl!.setValidators([Validators.required]);
        claim_numberControl!.setValidators([Validators.required]);
        case_managerControl!.setValidators([Validators.required]);
      } else {
        date_injuryControl!.clearValidators();
        claim_numberControl!.clearValidators();
        case_managerControl!.clearValidators();
      }
      date_injuryControl!.updateValueAndValidity();
      claim_numberControl!.updateValueAndValidity();
      case_managerControl!.updateValueAndValidity();
    });
  }


  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  } // end ngOnDestroy


} // end EmployerInformationStepFormComponent
