<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>CCM Consent</title>
  </head>
  <body>
    <div class="container">
      <h4>Agreement to Receive Chronic Care Management Services</h4>

      <div class="section">
        <p>
          As a patient of Commonwealth Pain and Spine with two or more chronic
          conditions, you may benefit from a new program providing chronic care
          management services to Medicare patients. Chronic care management
          services include:
        </p>
        <ul>
          <li>
            Care management for chronic conditions, including systematic
            assessment of your health care needs, timely scheduling of
            preventive care services, and medication review and oversight;
          </li>
          <li>
            Access to your care team 24-hours-a-day, 7-days-a-week, including
            non-face-to-face access such as telephone, email, and secure
            messages;
          </li>
          <li>
            Creation of a comprehensive plan of care for your health issues;
          </li>
          <li>
            Management of care transitions among health care providers and
            settings, including referrals to other clinicians, follow-up after
            emergency department visits, and follow-up after discharge from
            hospitals, skilled nursing facilities, or other health care
            facilities;
          </li>
          <li>
            Coordination with home and community-based clinical service
            providers.
          </li>
        </ul>
      </div>

      <div class="section">
        <h4>Your Rights</h4>
        <ul>
          <li>
            As part of the chronic care management services, you will receive a
            copy of your comprehensive plan of care.
          </li>
          <li>
            You have the right to stop this service at any time, effective at
            the end of the calendar month. Please contact our practice to revoke
            your consent.
          </li>
        </ul>
      </div>

      <div class="section">
        <p>You agree and consent to the following by signing this agreement:</p>
        <ul>
          <li>
            You consent to Commonwealth Pain and Spine providing chronic care
            management services to you and billing for them.
          </li>
          <li>
            You acknowledge that only one provider can furnish and bill for
            chronic care management services for you during a calendar month.
            Please let us know if you have entered into a similar agreement with
            another practice.
          </li>
          <li>
            You consent to electronic communication of your health information
            with others involved in your care.
          </li>
          <li>
            You understand that standard coinsurance, copays, and deductibles
            apply to chronic care management services, so you may be billed for
            these services up to once a month, whether or not you had a
            face-to-face meeting with your provider.
          </li>
        </ul>
      </div>
    </div>
  </body>
</html>
