<div class="error-main">
  <div class="w-700 m-auto h-body">
    <div class="flex-1">
      <i class="error-icon healent-form-input-icon-32"></i>
      <p class="error-title">
        {{
          additionalErrorMessage
            ? additionalErrorTitle
            : "Can't Load Your Form!"
        }}
      </p>

      <!-- Show the additional error message only if available -->
      <p class="error-body">
        {{
          additionalErrorMessage
            ? additionalErrorMessage
            : 'It looks like your form was not loaded; this can be temporary, so please try again later. If this issue continues, please contact us using the button below.'
        }}
      </p>

      <a
        class="btn a-btn"
        *ngIf="showCloseButton === false"
        [href]="'tel:' + supportPhoneNumber"
      >
        Reach support
      </a>
      <span
        class="btn a-btn back-to-home"
        *ngIf="showCloseButton === true"
        (click)="closeReact()"
        >Back to home</span
      >
    </div>
  </div>
</div>
