import { Component, Input, OnChanges, SimpleChanges, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";

@Component({
  selector: 'app-ssn',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    provideNgxMask(),
  ],
  templateUrl: './ssn.component.html',
  styleUrls: ['./ssn.component.css']
})
export class SsnComponent implements OnInit, OnChanges {
  @Input() formGroup!: FormGroup;
  @Input() title!: string;
  @Input() placeholder!: string;
  @Input() errorMessages!: { [key: string]: string };

  mask = '000-00-0000';
  displayedValue = '';
  iconClass = 'healent-form-input-icon-23';

  constructor() { }

  ngOnInit(): void {
    this.initializeValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formGroup'] && this.formGroup) {
      this.initializeValue();
      this.formGroup.get('ssn')?.valueChanges.subscribe((value) => {
        this.updateDisplayedValue(value);
      });
    }
  }

  initializeValue() {
    const ssn = this.formGroup.get('ssn')?.value;
    if (ssn) {
      this.formatAndDisplayValue(ssn);
    }
  }

  updateDisplayedValue(value: string | null) {
    this.formatAndDisplayValue(value);
  }

  private formatAndDisplayValue(value: string | null) {
    if (value) {
      const formattedValue = value
        .replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
        .replace(/\d/g, '*');
      this.displayedValue = formattedValue;
      this.iconClass = 'healent-path-4029';
    } else {
      this.displayedValue = '';
      this.iconClass = 'healent-form-input-icon-23';
    }
  }

  @ViewChild('inputRef') inputRef!: ElementRef;
  @ViewChild('inputStars') inputStars!: ElementRef;

  toggleIcon() {
    if (this.iconClass === 'healent-path-4029') {
      this.iconClass = 'healent-path-4030';
      this.inputRef.nativeElement.classList.add('show-numbers');
      this.inputStars.nativeElement.classList.add('hide-stars');
    } else {
      this.iconClass = 'healent-path-4029';
      this.inputRef.nativeElement.classList.remove('show-numbers');
      this.inputStars.nativeElement.classList.remove('hide-stars');
    }
  }

  // Getter for the control to simplify access
  get control() {
    return this.formGroup.get('ssn');
  }

  // Method to check if the field is required
  isRequired(): boolean {
    return this.control?.hasValidator(Validators.required) ?? false;
  }
  getErrorMessage(): string {
    const controlErrors = this.control?.errors;
    if (controlErrors) {
      if (controlErrors['required']) {
        return `The ${this.placeholder} is required and cannot be empty`;
      }
      for (const key in controlErrors) {
        if (controlErrors.hasOwnProperty(key) && this.errorMessages && this.errorMessages[key]) {
          return this.errorMessages[key];
        }
      }
    }
    return `Invalid ${this.placeholder}`;
  }
}
