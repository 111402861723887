import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-custom-input',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    CommonModule,
  ],
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.css'],
})
export class CustomInputComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() placeholder!: string;
  @Input() hint!: string;
  @Input() type: string = 'text';
  @Input() readonly: boolean = false;
  @Input() required: boolean = false;
  @Input() min?: string;
  @Input() max?: string;
  @Input() maxlength?: number;
  @Input() errorMessages!: { [key: string]: string };
  @Input() iconClass?: string;

  ngOnInit() {
    this.initializeControl();
  }

  initializeControl() {
    const control = this.control;

    if (this.maxlength) {
      const existingValidators = control.validator ? [control.validator] : [];
      control.setValidators([...existingValidators, Validators.maxLength(this.maxlength)]);
      control.updateValueAndValidity();
    }

    const existingValue = this.formGroup.get(this.controlName)?.value;
    if (existingValue !== null && existingValue !== undefined) {
      control.setValue(existingValue);
    }
  }

  get control(): FormControl {
    const control = this.formGroup.get(this.controlName);
    if (!control) {
      throw new Error(`Control with name '${this.controlName}' not found in form group`);
    }
    return control as FormControl;
  }

  getErrorMessage(): string {
    const controlErrors = this.control.errors;
    if (controlErrors) {
      if (controlErrors['required']) {
        return `This field cannot be empty`;
      }
      for (const key in controlErrors) {
        if (controlErrors.hasOwnProperty(key) && this.errorMessages && this.errorMessages[key]) {
          return this.errorMessages[key];
        }
      }
    }
    return `Invalid ${this.placeholder}`;
  }

  isFieldRequired(): boolean {
    return this.control && this.control.hasValidator(Validators.required);
  }
}
