<p>
  Welcome to AK Pain & Spine Center! We are pleased that you have chosen us as
  your health care provider. Our mission is to provide you with the highest
  level of professional medical care with the highest degree of patient
  satisfaction. To avoid any misunderstandings and ensure timely payment for
  services, it is important that you understand your financial responsibilities
  with respect to your health care.
</p>
<br />
<p>
  We require that all patients sign our Authorization and Consent for Treatment
  Form before receiving medical services. This form confirms that you understand
  that the services provided are necessary, appropriate and advises you of your
  financial responsibility with respect to services received.
</p>

<h2>PATIENT RESPONSIBILITY</h2>
<p>
  Patients or their legal representatives are ultimately responsible for all
  charges for services provided. We expect your payment at the time of your
  visit for all charges owed for that visit as well as any prior balance. Some
  insurance plans tell us exactly what you will owe at the time of your visit;
  in that case, we may request full payment for your share when you check in or
  out. Other insurance plans do not provide immediate information regarding
  patient responsibility. You may be asked to save a credit card on file to
  settle your account or pay a deposit when you check in or out.
</p>
<br />
<p>
  If you save a credit card on file, we will charge your card for the balance
  due when your insurance company notifies us of your patient responsibility.
  When you make a deposit, you will pay an estimate of the expected patient
  responsibility; when your insurance company notifies us of your patient
  responsibility, we will either send you a statement for the balance due or
  issue a refund.
</p>
<br />
<p>
  All services for patients who are minors will be billed to the custodial
  parent or legal guardian. If you demonstrate financial need and complete the
  required paperwork, financial assistance may be available. If you have a large
  balance, payment plans may be available.
</p>
<h2>TYPES OF PAYMENTS</h2>

<ol>
  <li>
    <strong>Co-payments.</strong> Insurance carriers require that we collect
    your co-payment at the time of your visit. If you are not prepared to make
    your co-payment, you may be asked to reschedule your appointment.
  </li>
  <br />
  <li>
    <strong>Deductibles.</strong> Most insurance plans require you to pay a
    predetermined amount (the "deductible") before insurance covers certain
    charges. Our technology allows us to view your remaining deductible and
    helps you understand what you will owe for your visit so we can collect the
    amount due at the time of your visit. For new patients who have not yet met
    their deductible, we may collect up to $150.00; for established patients, we
    may collect up to $100.00. This payment will be applied to your visit. When
    your insurance completes processing of your health insurance claim, you may
    be responsible for an additional amount depending on our contract with your
    insurer.
    <br />

    <p>
      NOTE: If you take advantage of our Card on File process, you will not be
      required to pay a deposit at the time of your visit.
    </p>
    <br />
  </li>

  <li>
    <strong>Co-insurance.</strong> Some insurance plans require that you pay a
    certain percentage (for example, 20%) of the allowable charge amount. Our
    technology allows us to view the details of your insurance plan, including
    your coinsurance amount, and calculate the expected out-of-pocket cost for
    you. If we can determine the amount, we will ask that you pay your
    co­insurance at the time of your visit.
  </li>
  <br />

  <li>
    <strong>Uninsured Patients / Self-Pay.</strong> If you do not have Insurance
    or if the services provided are not covered by your insurance, payment for
    all services is due at the time of your visit.
  </li>
  <br />

  <li>
    <strong>Out-of-Network.</strong> We participate with most major insurance
    plans. You can contact your insurance company to confirm if your provider is
    in-network prior to making your appointment. If we do not participate with
    your insurance plan, you will be required to pay for your visit at the time
    of service. We may send a courtesy bill to your insurance company.
  </li>
  <br />

  <li>
    <strong>Non-Covered Services.</strong> It is your responsibility to contact
    your insurance plan to determine whether a particular service is covered. If
    we provide you with non-covered services, you are expected to pay for the
    services at the time of your visit. Our billing staff will assist you in
    attempting to resolve any appeals.
  </li>
  <br />
</ol>
<p>
  If you are a Medicare patient, we will inform you of any non-covered services
  prior to your treatment. Your provider will review options with you and
  document your decision and acceptance of financial responsibility using the
  Centers for Medicare and Medicaid Services (CMS) form CMS R-131/Advance
  Beneficiary Notice (ABN).
</p>
<h2>INSURANCE</h2>
<p>
  We ask all patients to provide their insurance card (if applicable) and proof
  of Identification (such as a photo ID or driver's license) at every visit. If
  you do not provide current proof of insurance, you may be billed as an
  uninsured patient (self-pay). If you provide your insurance card(s) at a later
  time, we may be able to retroactively bill the services to your insurer
  depending on the insurance plan's requirements. We accept assignment of
  benefits for many third-party carriers so in most cases, we will submit
  charges for services rendered to your insurance carrier. You are expected to
  pay the entire amount determined by your insurance to be the patient's
  responsibility. Our fees are for physician services <strong>only</strong>, you
  may receive additional bills from the hospital, ambulatory surgical center,
  laboratory, radiology, or other diagnostic related providers.
</p>
<br />

<p>You are responsible to:</p>
<ul>
  <li>
    Know if a referral or authorization is necessary for office visits (If it is
    required and you do not have the appropriate referral or authorization, you
    may be billed as an uninsured patient).
  </li>
  <br />
  <li>
    Check with your insurance plan to review the schedule of benefits and
    whether a co-payment or deductible applies.
  </li>
  <br />
  <li>File any appeals with your insurance plan, if needed.</li>
  <br />
  <li>
    Coordinate benefits if you have more than one insurance plan. You may be
    required to contact your insurance company to clarify which plan is primary
    or to correct any demographic information or other issues.
  </li>
  <br />
  <li>Arrive for appointments with all required documentation.</li>
</ul>
<p>
  <strong>Insurance Verification.</strong> We will attempt to verify your
  insurance eligibility two (2) business days prior to your visit. If we are
  unable to confirm active insurance coverage, we will contact you about your
  insurance eligibility. If you are unable to present an alternative form of
  active insurance coverage prior to the visit, you will be required to either
  pay at the time of your visit or reschedule your appointment. For same day
  appointments, we will check eligibility when the appointment is made.
</p>
<br />

<p>
  <strong>Outstanding Balances.</strong> After your visit we will send you a
  statement for any outstanding balances. All outstanding balances are due upon
  receipt. If you come for another visit and have an outstanding balance, we
  will request payment for both the new visit and your outstanding balance. Your
  outstanding balances can also be paid conveniently via our patient portal.
</p>
<br />

<p>
  We generally send statements every twenty-eight (28) days, beginning when the
  balance becomes patient responsibility. If you have an outstanding balance for
  more than ninety (90) days, you may be referred to an outside collection
  agency and charged a collection fee of $25 or whatever amount is permitted by
  applicable state law in addition to the balance owed. In addition, if you have
  unpaid delinquent accounts, we may discharge you as a patient and you may not
  be allowed to schedule any additional services unless special arrangements
  have been made.
</p>

<h2>LATE ARRIVALS, CANCELLATIONS, AND NO SHOWS</h2>
<p>
  <strong>Late arrivals.</strong> If you arrive late for a scheduled
  appointment, you may be asked to reschedule your appointment or wait for an
  open appointment time on that day's schedule.
</p>
<br />
<p>
  <strong>Cancellations.</strong> If you are unable to keep a scheduled
  appointment, you must call at least one (1) business day in advance, or we may
  consider you a "no-show."
</p>
<br />
<p>
  <strong>No-shows.</strong> If you miss your appointment, you may be charged a
  $50.00 fee for a missed appointment or a $200 fee for a missed procedure or
  surgery. This fee will need to be paid prior to rescheduling. This fee cannot
  be billed to insurance. As permitted by state law, you may be discharged as a
  patient following three (3) no- shows in a one-year period (365 days).
</p>
<br />
<p>Thank you for helping us run a better practice!</p>
