import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { MatError, MatFormField, MatHint, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatIcon } from "@angular/material/icon";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { KeyValuePipe, NgForOf, NgIf, CommonModule } from "@angular/common";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { TranslateService } from "@ngx-translate/core";
import { formObjectByType } from "../../functions/functions";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CustomSelectComponent } from '../../reusable/custom-select/custom-select.component';
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';
import { CustomRadioGroupComponent } from '../../reusable/custom-radio-group/custom-radio-group.component';
import { AddressComponent } from '../../reusable/address/address.component';
import { PhoneComponent } from '../../reusable/phone2/phone.component';
import { SharedFormService } from '../../services/SharedFormService.service';
@Component({
  selector: 'app-contact-information-step-form',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatHint,
    MatIcon,
    MatError,
    NgIf,
    MatRadioButton,
    MatRadioGroup,
    KeyValuePipe,
    NgForOf,
    CustomCheckboxComponent,
    CustomSelectComponent,
    CustomInputComponent,
    CustomRadioGroupComponent,
    CommonModule,
    AddressComponent,
    PhoneComponent
  ],
  templateUrl: './contact-information-step-form.component.html',
  styleUrls: ['./contact-information-step-form.component.css']
})
export class ContactInformationStepFormComponent implements OnInit, OnDestroy {

  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';

  formValues: any = {};
  form_loaded = false;
  myForm!: FormGroup;
  checkboxItems: any = {};
  radioItems: any = {};
  inputItems: any = {};
  destroySubject = new Subject<void>();

  constructor(
    private _bottomSheet: MatBottomSheet,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedFormService: SharedFormService
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit() {

    this.myForm = new FormGroup({});
    const currentLang = this.translateService.currentLang;
    const lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {
      const translationsObj = translations['healent'][this.step];
      const translationsObjSlug = translations[this.hospital_slug][this.step];
      let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step);
      this.myForm = formObject['myForm'];
      this.radioItems = formObject['radioItems'];
      this.checkboxItems = formObject['checkboxItems'];
      this.inputItems = formObject['inputItems'];
      if (this.patch_values) { // ORDER MATTERS - PATCH FIRST
        this.myForm.patchValue(this.patch_values, { emitEvent: false });
      }
      if (this.myForm.get('isDifferentAddress')?.value) {
        this.applyIsDifferentAddressValidators(true);
      }
      this.control?.setValue(this.myForm.getRawValue(), { emitEvent: true }); // set initial value
      this.formGroup = this.myForm;
      this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(val => {
        this.formValues = val;
        this.control?.setValue(this.myForm.getRawValue(), { emitEvent: true });
        this.formGroup = this.myForm;
        this.sharedFormService.updateFormValues(this.formValues);

      });

      this.form_loaded = true;

      // Set up valueChanges subscription for workPhone
      this.onInputChange();
      this.onCheckboxChange();
    }); // end translateService.getTranslation

  } // end ngOnInit

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onCheckboxChange() {
    /**
     * When we toggle a checkbox and if true we want to add validation to the form controls
     * when false we want to remove the validation.
     * place on ngOnInit
     */
    this.myForm.get('isDifferentAddress')?.valueChanges.subscribe(checked => {
      this.applyIsDifferentAddressValidators(checked);
    });
  }
  applyIsDifferentAddressValidators(isDifferentAddressChecked: boolean) {
    const streetAddress = this.myForm.get('streetAddress');

    if (isDifferentAddressChecked) {
      streetAddress?.setValidators([Validators.required]);
    } else {
      streetAddress?.clearValidators();
      streetAddress?.reset();
    }

    streetAddress?.updateValueAndValidity();
  }
  onInputChange() {
    if (this.myForm.get('homePhone')) {
      this.myForm.get('homePhone')!.valueChanges.subscribe(value => {
        const homePhonePreference = this.myForm.get('homePhonePreference');
        if (value && this.myForm.get('homePhone')?.valid) {
          homePhonePreference?.setValidators([Validators.required]);
        } else {
          homePhonePreference?.clearValidators();
        }
        homePhonePreference?.updateValueAndValidity();
      });
    }
    if (this.myForm.get('workPhone')) {
      this.myForm.get('workPhone')!.valueChanges.subscribe(value => {
        const workPhonePreference = this.myForm.get('workPhonePreference');
        if (value && this.myForm.get('workPhone')?.valid) {
          workPhonePreference?.setValidators([Validators.required]);
        } else {
          workPhonePreference?.clearValidators();
        }
        workPhonePreference?.updateValueAndValidity();
      });
    }
  }

} // end ContactInformationStepFormComponent
