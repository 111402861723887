<h3>Assignment of Benefits and Authorization to Release Medical Information</h3>
<p>
  I understand and agree that payment of authorized benefits under Medicare
  and/or any of my insurance carriers will be made to me or on my behalf to the
  provider or supplier of any services furnished to me by that provider or
  supplier. I authorize any holder of my medical information to release it to AK
  Pain & Spine Center, the Health Care Financing Administration (HCFA), the
  listed insurer and/or agents of the company and/or the listed responsible
  person(s), and any information necessary to determine my benefits or the
  benefit for the related services. If my insurance plan does not participate in
  the AK Pain & Spine Center network, or if I am a self-pay patient, assignment
  of benefits may not apply.
</p>

<h3>Guarantee of Payment & Pre-Certification</h3>
<p>
  In consideration of services provided to me by AK Pain & Spine Center, I agree
  to be financially responsible and to pay charges for all services ordered by
  my provider(s). I understand that any balance due as a result of being
  uninsured or under-insured is payable immediately. I further understand that
  if I fail to maintain consistent payments, my account will be referred to a
  collection agent and/or attorney and I agree to pay all collection related
  charges.
</p>
<br />
<p>
  I understand that if my insurance has a pre-certification or authorization
  requirement, it is my responsibility to notify the carrier of services
  rendered according to the plan's provisions. I understand that my failure to
  do so will result in reduction or denial of benefit payment and I will be
  responsible for all balances.
</p>

<h3>Consent to Treatment</h3>
<p>
  As an AK Pain & Spine Center patient I voluntarily consent to the rendering of
  such care and treatment as the AK Pain & Spine Center providers and personnel,
  in their professional judgment, deem necessary for my health and well-being.
</p>
<br />
<p>
  My consent shall include medical examination and diagnostic testing,
  including, but not limited to, minor surgical procedures. My consent shall
  also include the carrying out of the orders of my treating provider by care
  center staff. I acknowledge that neither my AK Pain & Spine Center provider
  nor any care center staff has made any guarantee or promise as to the results
  that may be obtained.
</p>

<h3>Consent to Call</h3>
<p>
  I understand and agree that AK Pain & Spine Center may contact me using
  automated calls. emails, and text messaging sent to my landline and mobile
  device. These communications may notify me of test results, treatment
  recommendations, outstanding balances, or any other communications from AK
  Pain & Spine Center.
</p>
<br />
<p>
  I understand that I may voluntarily "opt-in" to receive automated text message
  communications from AK Pain & Spine Center and its partners by informing my
  provider's staff or visiting my Athena Patient Portal, and agreeing to any
  additional Terms and Conditions established by my mobile carrier.
</p>
<br />
<p>
  I hereby acknowledge that I have received AK Pain & Spine Center’s Financial
  Policy and Notice of Privacy Practices. I agree to the terms of AK Pain &
  Spine Center’s Financial Policy, the sharing of my information via HIE," and
  consent to my treatment by AK Pain & Spine Center providers.
</p>
