<div>
  <app-upload
    [formGroup]="formGroup"
    *ngIf="uploadItems[frontFormName]?.is_include"
    [prefix]="uploadItems[frontFormName]?.redTitle"
    [redTitle]="uploadItems[frontFormName]?.redTitle"
    [title]="uploadItems[frontFormName]?.title"
    [icon]="uploadItems[frontFormName]?.icon"
    [formName]="frontFormName"
    [btn]="uploadItems[frontFormName]?.btn"
    [desc]="uploadItems[frontFormName]?.description"
    class="m-t-m d-block"
    (setImage)="onImageSet($event, 'front')"
  ></app-upload>
  <app-upload
    [formGroup]="formGroup"
    *ngIf="uploadItems[backFormName]?.is_include"
    [prefix]="uploadItems[backFormName]?.redTitle"
    [redTitle]="uploadItems[backFormName]?.redTitle"
    [title]="uploadItems[backFormName]?.title"
    [icon]="uploadItems[backFormName]?.icon"
    [formName]="backFormName"
    [btn]="uploadItems[backFormName]?.btn"
    [desc]="uploadItems[backFormName]?.description"
    class="m-t-m d-block"
    (setImage)="onImageSet($event, 'back')"
  ></app-upload>
</div>
