import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, Router, withHashLocation } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HttpClient, provideHttpClient, withFetch, withInterceptors } from "@angular/common/http";
import { authInterceptor } from "./services/http-interceptors/auth-intercepter.service";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { createTranslateLoader } from "./app.component";
import { provideLottieOptions } from 'ngx-lottie';

export const appConfig: ApplicationConfig = {
  providers: [provideLottieOptions({
    player: () => import('lottie-web'),
  }),
  provideRouter(routes, withHashLocation()),
  provideAnimationsAsync(),
  provideHttpClient(withInterceptors([authInterceptor])),
  //importProvidersFrom(HttpClientModule),
  importProvidersFrom
    (
      TranslateModule.forRoot(
        {
          loader:
          {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient, Router]
          },
          defaultLanguage: 'en'
        })
    )
  ],

};
