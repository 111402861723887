import { Component, EventEmitter, Output, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from "@angular/material/icon";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { Router, ActivatedRoute } from '@angular/router';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';
import { LanguageService } from '../../services/language.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-done',
  standalone: true,
  imports: [
    MatIconModule,
    LanguageSelectorComponent,
    TranslateModule,
    CommonModule
  ],
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DoneComponent implements OnInit, OnDestroy {
  iosLinke = '';
  androidLinke = '';
  currentPath!: string;
  addClass: boolean = false;
  ios: any = {
    akps: 'https://apps.apple.com/us/app/akpain-spine/id6471281069',
    cps: 'https://apps.apple.com/us/app/mypainsolution/id6474763802',
  }
  android: any = {
    akps: 'https://play.google.com/store/apps/details?id=com.akps.akpsapp',
    cps: 'https://play.google.com/store/apps/details?id=com.mypainsolution.mypainsolutionapp',
  }
  @Output() close = new EventEmitter<void>();
  formsInfo: any = {};
  private destroySubject = new Subject<void>();

  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    const hospital_slug = localStorage.getItem('hospital_slug') || '';
    const form_id = localStorage.getItem('form_id') || 1;

    this.iosLinke = this.ios[hospital_slug] || '';
    this.androidLinke = this.android[hospital_slug] || '';
    const currentLang = this.translateService.currentLang;
    let lang_form = `${currentLang}-${form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {
      const defaultFormsInfo = translations['healent']?.['formsInfo'];
      const hospitalFormsInfo = translations[hospital_slug]?.['formsInfo'];
      this.formsInfo = { ...defaultFormsInfo, ...hospitalFormsInfo };
    });

    this.addAndRemoveClass();
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  startForm() {
    this.close.emit();
  }

  addAndRemoveClass(): void {
    this.addClass = true;
    setTimeout(() => {
      this.addClass = false;
    }, 2000);
  }
}
