import {Component, ViewEncapsulation} from '@angular/core';
import {MatRadioModule} from "@angular/material/radio";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {LanguageService} from "../../services/language.service";
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {MatCheckboxModule} from "@angular/material/checkbox";

@Component({
  selector: 'app-language-selector',
  standalone: true,
  imports: [
    MatRadioModule,
    MatCheckboxModule,
    FormsModule,
    CommonModule,
  ],
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class LanguageSelectorComponent {
  constructor(
    private languageService: LanguageService,
    private bottomSheetRef: MatBottomSheetRef<LanguageSelectorComponent>,
  ) {}
  selectedLanguage = 'en';
  languages: any[] = [
    {title: 'English', value: 'en'},
    {title: 'Spanish' ,value: 'sp'}
  ];
  ngOnInit() {
    this.selectedLanguage = this.languageService.getCurrentLanguage()['value']
  }

  selectLanguage(lang: Event) {
    const selected = this.languages.filter(l => l.value === lang)[0]
    this.languageService.setCurrentLanguage(selected)
    this.bottomSheetRef.dismiss()
  }
}
