<div [formGroup]="formGroup" [class.error]="control.invalid && control.touched">
  <span class="radio-title" [innerHTML]="label"></span>

  <mat-radio-group [formControl]="control">
    <mat-radio-button
      *ngFor="let option of options | keyvalue"
      [value]="option.key"
    >
      {{ option.value }}
    </mat-radio-button>
  </mat-radio-group>

  <!-- Display hint based on whether the field is required or optional -->
  <div
    class="hintContainerPlus"
    [ngClass]="{ valid: control.valid && control.value }"
  >
    <span *ngIf="isRequired; else optionalField" class="hintRequired">
      *Required
    </span>
    <ng-template #optionalField>
      <span class="hintOptional">+Optional</span>
    </ng-template>
  </div>
</div>
