<div class="camera-main">
  <h2 mat-dialog-title class="sub-title">Take an image</h2>
  <webcam
    [trigger]="triggerObservable"
    (imageCapture)="handleImage($event)"
    [width]="webcamWidth"></webcam>

  <mat-dialog-actions align="end">
    <button class="camera-btn" mat-icon-button (click)="triggerSnapshot()">
    </button>
  </mat-dialog-actions>
</div>

