import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  logo = '';
  currentPath!: string;
  header: any = {
    healent: '../../../assets/akps/logo.svg',
    akps: '../../../assets/akps/logo.svg',
    cps: '../../../assets/cwps/logo.svg',
  }

  ngOnInit() {
    this.getCurrentPath();
  }

  getCurrentPath() {
    const providerName = localStorage.getItem('hospital_slug');
    if (providerName) {
      this.logo = this.header[providerName] || '';
    } else {
      this.logo = '';
    }
  }

}
