.camera-btn {
  margin: auto;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: var(--primary-color);
}

.camera-btn:active {
  margin: auto;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: var(--primary-color);
}

.camera-main {
  overflow-x: hidden;
}
