import { Component, EventEmitter, Output, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from "@angular/material/icon";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { Router, ActivatedRoute } from '@angular/router';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';
import { LanguageService } from '../../services/language.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [
    MatIconModule,
    LanguageSelectorComponent,
    TranslateModule,
  ],
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorComponent implements OnInit, OnDestroy {

  currentPath!: string;

  @Output() close = new EventEmitter<void>();
  formsInfo: any = {};
  hospital_slug: string = '';
  form_id: number = 0;
  private destroySubject = new Subject<void>();

  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {

    this.hospital_slug = localStorage.getItem('hospital_slug') || '';
    this.form_id = Number(localStorage.getItem('form_id')) || 0;

    const currentLang = this.translateService.currentLang;
    let lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {
      const defaultFormsInfo = translations['healent']?.['formsInfo'];
      const hospitalFormsInfo = translations[this.hospital_slug]?.['formsInfo'];
      this.formsInfo = { ...defaultFormsInfo, ...hospitalFormsInfo };
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  startForm() {
    this.close.emit();
  }

  callSupport() {
    let phoneNumber = '';
    if (this.hospital_slug.toLowerCase() === 'akps') {
      phoneNumber = '+14697725585';
    } else if (this.hospital_slug.toLowerCase() === 'cwps') {
      phoneNumber = '+15027858997';
    }
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    }
  }
}