import { Component, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamModule } from 'ngx-webcam';
import { MatDialogClose, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: 'app-camera',
  standalone: true,
  imports: [
    WebcamModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatDialogClose
  ],
  templateUrl: './camera.component.html',
  styleUrl: './camera.component.css',
  encapsulation: ViewEncapsulation.None
})
export class CameraComponent {
  private trigger: Subject<any> = new Subject<any>();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject<any>();
  webcamWidth = window.innerWidth; // Example width
  captureImage = '';

  constructor(
    public dialogRef: MatDialogRef<CameraComponent>,
  ) {
  }

  public triggerSnapshot(): void {
    this.trigger.next(null);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.captureImage = webcamImage!.imageAsDataUrl;
    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      .then(mediaStream => {
        mediaStream.getTracks().forEach(function (track) {
          track.enabled = false
          track.stop();
        });
      })
    this.dialogRef.close({ data: this.captureImage });
  }

  public get triggerObservable(): Observable<any> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

  ngOnDestroy(): void {
  }
}
