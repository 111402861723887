<div>
  <p class="fs-12">
    <b>Commonwealth Pain Associates, PLLC</b> <br />
    dba Commonwealth Pain & Spine <br />
    120 Executive Park, Louisville, KY 40206 <br />
    Phone: 502.855.7200 <br />
    Fax: 502.855.7201 <br />
    www.mypainsoluition.com <br />
    <br />
    Herein referred to as “CP&S”
  </p>

  <p class="title">
    THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND
    DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT
    CAREFULLY.
  </p>

  <p class="fs-12">
    This Privacy Notice is being provided to you as a requirement of a federal
    law, the Health Insurance Portability and Accountability Act (HIPAA). This
    Privacy Notice describes how CP&amp;S may use and disclose your protected
    health information to carry out treatment, payment or health care operations
    and for other purposes that are permitted or required by law. It also
    describes your rights to access and control your protected health
    information in some cases. Your "protected health information" means any
    written and oral health information about you, including demographic data
    that can be used to identify you. This is health information that is created
    or received by your healthcare provider, and that relates to your past,
    present or future physical or mental health or condition.
  </p>

  <p class="subTitle">
    I. &nbsp;&nbsp;&nbsp; Uses and Disclosures of Protected Health Information
  </p>

  <p class="fs-12">
    We may use your protected health information for purposes of providing
    treatment, obtaining payment for treatment, and conducting health care
    operations. Your protected health information may be used or disclosed only
    for these purposes unless CP&amp;S has obtained your authorization or the
    use or disclosure is otherwise permitted by the HIPAA privacy regulations or
    state law. Disclosures of your protected health information for the purposes
    described in this Privacy Notice may be made in writing, orally, or by
    facsimile.
  </p>

  <p class="headLine">A. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Treatment.</p>

  <p class="fs-12">
    We will use and disclose your protected health information to provide,
    coordinate, or manage your health care and any related services. This
    includes the coordination or management of your health care with a third
    party for treatment purposes. For example, we may disclose your protected
    health information to a pharmacy to fill a prescription or to a laboratory
    to order a blood test. We may also disclose protected health information to
    physicians who may be treating you or consulting with CP&amp;S with respect
    to your care. In some cases, we may also disclose your protected health
    information to an outside treatment provider for purposes of the treatment
    activities of the other provider.
  </p>

  <p class="headLine">B. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Payment.</p>

  <p class="fs-12">
    Your protected health information will be used, as needed, to obtain payment
    for the services that we provide. This may include certain communications to
    your health insurance company to get approval for the procedure that we have
    scheduled. For example, we may need to disclose information to your health
    insurance company to get prior approval for your surgery and care. We may
    also disclose protected health information to your health insurance company
    to determine whether you are eligible for benefits or whether a particular
    service is covered under your health plan. In order to get payment for the
    services we provide to you, we may also need to disclose your protected
    health information to your health insurance company to demonstrate the
    medical necessity of the services or, as required by your insurance company,
    for utilization review. We may also disclose patient information to another
    provider involved in your care for the other provider's payment activities.
    This may include disclosure of demographic information to anesthesia care
    providers for payment of their services.
  </p>

  <p class="headLine">C. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Operations.</p>

  <p class="fs-12">
    We may use or disclose your protected health information, as necessary, for
    our own health care operations to facilitate the function of CP&amp;S and to
    provide quality care to all patients. Health care operations include such
    activities as: quality assessment and improvement activities, employee
    review activities, training programs including those in which students,
    trainees, or practitioners in health care learn under supervision,
    accreditation, certification, licensing or credentialing activities, review
    and auditing, including compliance reviews, medical reviews, legal services
    and maintaining compliance programs, and business management and general
    administrative activities. In certain situations, we may also disclose
    patient information to another provider or health plan for their health care
    operations.
  </p>

  <p class="headLine">
    D. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Other Uses and Disclosures.
  </p>

  <p class="fs-12">
    As part of treatment, payment and health care operations, we may also use or
    disclose your protected health information for the following purposes: to
    remind you of your appointments or surgery date, to inform you of potential
    treatment alternatives or options, to inform you of health-related benefits
    or services that may be of interest to you, or to contact you to raise funds
    for CP&amp;S or an institutional foundation related to CP&amp;S. If you do
    not wish to be contacted regarding fundraising, please contact our Privacy
    Officer.
  </p>

  <p class="subTitle">
    II. &nbsp;&nbsp;&nbsp; Uses and Disclosures Beyond Treatment, Payment, and
    Health Care Operations Permitted Without Authorization or Opportunity to
    Object
  </p>

  <p class="fs-12">
    Federal privacy rules allow us to use or disclose your protected health
    information without your permission or authorization for a number of reasons
    including the following:
  </p>

  <p class="headLine">
    A. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When Legally Required.
  </p>

  <p class="fs-12">
    We will disclose your protected health information when we are required to
    do so by any federal, state or local law.
  </p>

  <p class="headLine">
    B. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When There Are Risks to Public
    Health.
  </p>

  <p class="fs-12">
    We may disclose your protected health information for the following public
    activities and purposes:
  </p>

  <p class="m-l-16 fs-12">
    - &nbsp; To prevent, control, or report disease, injury or disability as
    permitted by law.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; To report vital events such as birth or death as permitted or
    required by law.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; To conduct public health surveillance, investigations and
    interventions as permitted or required
  </p>
  <p class="m-l-16 fs-12">- &nbsp; by law.</p>
  <p class="m-l-16 fs-12">
    - &nbsp; To collect or report adverse events and product defects, track FDA
    regulated products, enable
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; product recalls, repairs or replacements to the FDA and to conduct
    post marketing surveillance.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; To notify a person who has been exposed to a communicable disease
    or who may be at risk of contracting or spreading a disease as authorized by
    law.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; To report to an employer information about an individual who is a
    member of the workforce as legally permitted or required.
  </p>

  <p class="headLine">
    C. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To Report Suspected Abuse, Neglect
    Or Domestic Violence.
  </p>

  <p class="fs-12">
    We may notify government authorities if we believe that a patient is the
    victim of abuse, neglect or domestic violence. We will make this disclosure
    only when specifically required or authorized by law or when the patient
    agrees to the disclosure.
  </p>

  <p class="headLine">
    D. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To Conduct Health Oversight
    Activities.
  </p>

  <p class="fs-12">
    We may disclose your protected health information to a health oversight
    agency for activities including audits; civil, administrative, or criminal
    investigations, proceedings, or actions; inspections; licensure or
    disciplinary actions; or other activities necessary for appropriate
    oversight as authorized by law. We will not disclose your health information
    under this authority if you are the subject of an investigation and your
    health information is not directly related to your receipt of health care or
    public benefits.
  </p>

  <p class="headLine">
    E. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In Connection With Judicial and
    Administrative Proceedings.
  </p>

  <p class="fs-12">
    We may disclose your protected health information in the course of any
    judicial or administrative proceeding in response to an order of a court or
    administrative tribunal as expressly authorized by such order. In certain
    circumstances, we may disclose your protected health information in response
    to a subpoena to the extent authorized by state law if we receive
    satisfactory assurances that you have been notified of the request or that
    an effort was made to secure a protective order.
  </p>

  <p class="headLine">
    F. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; For Law Enforcement Purposes.
  </p>

  <p class="fs-12">
    We may disclose your protected health information to a law enforcement
    official for law enforcement purposes as follows: As required by law for
    reporting of certain types of wounds or other physical injuries. Pursuant to
    court order, court-ordered warrant, subpoena, summons or similar process.
    For the purpose of identifying or locating a suspect, fugitive, material
    witness or missing person. Under certain limited circumstances, when you are
    the victim of a crime. To a law enforcement official if CP&amp;S has a
    suspicion that your health condition was the result of criminal conduct. In
    an emergency to report a crime.
  </p>

  <p class="headLine">
    G. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To Coroners, Funeral Directors, and
    for Organ Donation.
  </p>

  <p class="fs-12">
    We may disclose protected health information to a coroner or medical
    examiner for identification purposes, to determine cause of death or for the
    coroner or medical examiner to perform other duties authorized by law. We
    may also disclose protected health information to a funeral director, as
    authorized by law, in order to permit the funeral director to carry out
    their duties. We may disclose such information in reasonable anticipation of
    death. Protected health information may be used and disclosed for cadaver,
    organ, eye or tissue donation purposes.
  </p>

  <p class="headLine">
    H. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; For Research Purposes.
  </p>

  <p class="fs-12">
    We may use or disclose your protected health information for research when
    the use or disclosure for research has been approved by an institutional
    review board that has reviewed the research proposal and research protocols
    to address the privacy of your protected health information.
  </p>

  <p class="headLine">
    I. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In the Event of a Serious Threat to
    Health or Safety.
  </p>

  <p class="fs-12">
    We may, consistent with applicable law and ethical standards of conduct, use
    or disclose your protected health information if we believe, in good faith,
    that such use or disclosure is necessary to prevent or lessen a serious and
    imminent threat to your health or safety or to the health and safety of the
    public.
  </p>

  <p class="headLine">
    J. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; For Specified Government Functions.
  </p>

  <p class="fs-12">
    In certain circumstances, federal regulations authorize CP&amp;S to use or
    disclose your protected health information to facilitate specified
    government functions relating to military and veterans activities, national
    security and intelligence activities, protective services for the President
    and others, medical suitability determinations, correctional institutions,
    and law enforcement custodial situations.
  </p>

  <p class="headLine">
    K. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; For Worker's Compensation.
  </p>

  <p class="fs-12">
    CP&amp;S may release your health information to comply with worker's
    compensation laws or similar programs.
  </p>

  <p class="subTitle">
    III. &nbsp;&nbsp;&nbsp; Uses and DisclosuresPermitted without Authorization
    but with Opportunity to Object
  </p>

  <p class="fs-12">
    We may disclose your protected health information to your family member or a
    close personal friend if it is directly relevant to the person's involvement
    in your surgery or treatment or payment related to your care. We can also
    disclose your information in connection with trying to locate or notify
    family members or others involved in your care concerning your location,
    condition or death.
  </p>
  <br />
  <p class="fs-12">
    You may object to these disclosures. If you do not object to these
    disclosures or we can infer from the circumstances that you do not object or
    we determine, in the exercise of our professional judgment, that it is in
    your best interests for us to make disclosure of information that is
    directly relevant to the person's involvement with your care, we may
    disclose your protected health information as described.
  </p>

  <p class="subTitle">
    IV. &nbsp;&nbsp;&nbsp; Uses and Disclosures which you Authorize
  </p>

  <p class="fs-12">
    Other than as stated above, we will not disclose your health information
    other than with your written authorization. You may revoke your
    authorization in writing at any time except to the extent that we have taken
    action in reliance upon the authorization.
  </p>

  <p class="subTitle">V. &nbsp;&nbsp;&nbsp; Your Rights</p>

  <p class="fs-12">
    You have the following rights regarding your health information:
  </p>
  <br />

  <p class="fs-12">
    <b>L.</b> The right to inspect and copy your protected health information.
    You may inspect and obtain a copy of your protected health information that
    is contained in a designated record set for as long as we maintain the
    protected health information. A "designated record set" contains medical and
    billing records and any other records that your surgeon and CP&amp;S uses
    for making decisions about you.
  </p>
  <br />
  <p class="fs-12">
    Under federal law, however, you may not inspect or copy the following
    records: psychotherapy notes; information compiled in reasonable
    anticipation of, or for use in, a civil, criminal, or administrative action
    or proceeding; and protected health information that is subject to a law
    that prohibits access to protected health information. Depending on the
    circumstances, you may have the right to have a decision to deny access
    reviewed.
  </p>
  <br />
  <p class="fs-12">
    We may deny your request to inspect or copy your protected health
    information if, in our professional judgment, we determine that the access
    requested is likely to endanger your life or safety or that of another
    person, or that it is likely to cause substantial harm to another person
    referenced within the information. You have the right to request a review of
    this decision.
  </p>
  <br />
  <p class="fs-12">
    To inspect and copy your medical information, you must submit a written
    request to the Privacy Officer whose contact information is listed on the
    last page of this Privacy Notice. If you request a copy of your information,
    we may charge you a fee for the costs of copying, mailing or other costs
    incurred by us in complying with your request.
  </p>
  <br />
  <p class="fs-12">
    Please contact our Privacy Officer if you have questions about access to
    your medical record.
  </p>
  <br />
  <p class="fs-12">
    <b>M.</b> The right to request a restriction on uses and disclosures of your
    protected health information. You may ask us not to use or disclose certain
    parts of your protected health information for the purposes of treatment,
    payment or health care operations. You may also request that we not disclose
    your health information to family members or friends who may be involved in
    your care or for notification purposes as described in this Privacy Notice.
    Your request must state the specific restriction requested and to whom you
    want the restriction to apply.
  </p>
  <br />
  <p class="fs-12">
    CP&amp;S is not required to agree to a restriction that you may request. We
    will notify you if we deny your request to a restriction. If the CP&amp;S
    does agree to the requested restriction, we may not use or disclose your
    protected health information in violation of that restriction unless it is
    needed to provide emergency treatment. Under certain circumstances, we may
    terminate our agreement to a restriction. You may request a restriction by
    contacting the Privacy Officer.
  </p>
  <br />
  <p class="fs-12">
    <b>N.</b> The right to request to receive confidential communications from
    CP&amp;S by alternative means or at an alternative location. You have the
    right to request that we communicate with you in certain ways. We will
    accommodate reasonable requests. We may condition this accommodation by
    asking you for information as to how payment will be handled or
    specification of an alternative address or other method of contact. We will
    not require you to provide an explanation for your request. Requests must be
    made in writing to our Privacy Officer.
  </p>
  <br />
  <p class="fs-12">
    <b>O.</b> The right to request amendments to your protected health
    information. You may request an amendment of protected health information
    about you in a designated record set for as long as we maintain this
    information. In certain cases, we may deny your request for an amendment. If
    we deny your request for amendment, you have the right to file a statement
    of disagreement with us and we may prepare a rebuttal to your statement and
    will provide you with a copy of any such rebuttal. Requests for amendment
    must be in writing and must be directed to our Privacy Officer. In this
    written request, you must also provide a reason to support the requested
    amendments.
  </p>
  <br />
  <p class="fs-12">
    <b>P.</b> The right to receive an accounting. You have the right to request
    an accounting of certain disclosures of your protected health information
    made by CP&amp;S. This right applies to disclosures for purposes other than
    treatment, payment or health care operations as described in this Privacy
    Notice. We are also not required to account for disclosures that you
    requested, disclosures that you agreed to by signing an authorization form,
    disclosures for a CP&amp;S directory, to friends or family members involved
    in your care, or certain other disclosures we are permitted to make without
    your authorization. The request for an accounting must be made in writing to
    our Privacy Officer. The request should specify the time period sought for
    the accounting. We are not required to provide an accounting for disclosures
    that take place prior to April 14, 2003. Accounting requests may not be made
    for periods of time in excess of six years. We will provide the first
    accounting you request during any 12-month period without charge. Subsequent
    accounting requests may be subject to a reasonable cost-based fee.
  </p>
  <br />
  <p class="fs-12">
    <b>Q.</b> The right to obtain a paper copy of this notice. Upon request, we
    will provide a separate paper copy of this notice even if you have already
    received a copy of the notice or have agreed to accept this notice
    electronically.
  </p>

  <p class="subTitle">
    SUMMARY OF THE PATIENT'S BILL OF RIGHTS AND RESPONSIBILITIES
  </p>

  <p class="fs-12">
    Federal law requires that your healthcare provider or health care facility
    recognize your rights while you are receiving medical care and that you
    respect the health care provider's or health care facility's right to expect
    certain behavior on the part of patients. You may request a copy of the full
    text of this law from your healthcare provider or health care facility. A
    summary of your rights and responsibilities follows:
  </p>
  <br />

  <p class="m-l-16 fs-12">
    - &nbsp; A patient has the right to be treated with courtesy and respect,
    with appreciation of his or her individual dignity, and with protection of
    his or her need for privacy.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient has the right to a prompt and reasonable response to
    questions and requests.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient has the right to know who is providing medical services
    and who is responsible for his or her care.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient has the right to know what patient support services are
    available, including whether an interpreter is available if he or she does
    not speak English.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient has the right to know what rules and regulations apply to
    his or her conduct.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient has the right to be given, by the health care provider,
    information concerning diagnosis, planned course of treatment, alternatives,
    risks, and prognosis.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient has the right to refuse any treatment, except as
    otherwise provided by law.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient has the right to be given, upon request, full information
    and necessary counseling on the availability of known financial resources
    for his or her care.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient who is eligible for Medicare has the right to know, upon
    request and in advance of treatment, whether the health care provider or
    health care facility accepts the Medicare assignment rate.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient has the right to receive, upon request, prior to
    treatment, a reasonable estimate of charges for medical care.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient has the right to receive a copy of a reasonably clear and
    understandable, itemized bill and, upon request, to have the charges
    explained.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient has the right to impartial access to medical treatment or
    accommodations, regardless of race, national origin, religion, handicap, or
    source of payment.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient has the right to treatment for any emergency medical
    condition that will deteriorate from failure to provide treatment.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient has the right to know if medical treatment is for
    purposes of experimental research and to give his or her consent or refusal
    to participate in such experimental research.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient has the right to express grievances regarding any
    violation of his or her rights, as stated in law, through the grievance
    procedure of the health care provider or health care facility which served
    him or her and to the appropriate state licensing agency.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient is responsible for providing to the health care provider,
    to the best of his or her knowledge, accurate and complete information about
    present complaints, past illnesses, hospitalizations, medications, and other
    matters relating to his or her health.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient is responsible for reporting unexpected changes in his or
    her condition to the health care provider.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient is responsible for reporting to the health care provider
    whether he or she comprehends a contemplated course of action and what is
    expected of him or her.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient is responsible for following the treatment plan
    recommended by the health care provider.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient is responsible for keeping appointments and, when he or
    she is unable to do so for any reason, for notifying the health care
    provider or health care facility.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient is responsible for his or her actions if he or she
    refuses treatment or does not follow the health care provider's
    instructions.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient is responsible for assuring that the financial
    obligations of his or her health care are fulfilled as promptly as possible.
  </p>
  <p class="m-l-16 fs-12">
    - &nbsp; A patient is responsible for following health care facility rules
    and regulations affecting patient care and conduct.
  </p>

  <p class="subTitle">VI. &nbsp;&nbsp;&nbsp; Our Duties</p>

  <p class="fs-12">
    CP&amp;S is required by law to maintain the privacy of your health
    information and to provide you with this Privacy Notice of our duties and
    privacy practices. We are required to abide by terms of this Notice as may
    be amended from time to time. We reserve the right to change the terms of
    this Notice and to make the new Notice provisions effective for all future
    protected health information that we maintain. If CP&amp;S changes its
    Notice, we will post a copy of the most current notice in CP&amp;S and will
    provide you a copy upon request. Each time you visit CP&amp;S you may obtain
    a copy of the most current notice if you so request.
  </p>

  <p class="subTitle">VII. &nbsp;&nbsp;&nbsp; Complaints</p>

  <p class="fs-12">
    You have the right to express complaints to the Privacy Officer at CP&amp;S
    and to the Secretary of Health and Human Services if you believe that your
    privacy rights have been violated. You may complain to CP&amp;S by
    contacting CP&amp;S's Privacy Officer, verbally or in writing, using the
    contact information below. We encourage you to express any concerns you may
    have regarding the privacy of your information. You will not be retaliated
    against in any way for filing a complaint.
  </p>

  <p class="subTitle">VIII. &nbsp;&nbsp;&nbsp; Contact Person</p>

  <p class="fs-12">
    CP&amp;S's contact person for all issues regarding patient privacy and your
    rights under the federal privacy standards is the Privacy Officer.
    Information regarding matters covered by this Notice can be requested by
    contacting the Privacy Officer. If you feel that your privacy rights have
    been violated by CP&amp;S you may submit a complaint to our Privacy Officer
    by sending it to:
  </p>
  <br />

  <p class="fs-12">
    <b>Facility Name: </b> <br />
    CP&amp;S <br />
    <br />
    <b>Address:</b> <br />
    120 Executive Park <br />
    <br />
    Louisville, KY 40207 <br />
    <br />
    <b>ATTN: Privacy Officer</b> <br />
    The Privacy Office can be contacted by phone at (502) 855-7200 <br />
  </p>

  <p class="subTitle">IX. &nbsp;&nbsp;&nbsp; Effective Date</p>

  <p class="fs-12">This Notice is Effective1-1-2016</p>
</div>
