import { HttpInterceptorFn } from "@angular/common/http";
import {tap} from "rxjs";


export const authInterceptor: HttpInterceptorFn = (req, next) => {

  //Lets check if the request is going to amazonaws.com for a presigned url
  if (req.url.includes('amazonaws.com')) {
    const headers = req.headers.set('Content-Type', 'plain/text')
    req = req.clone({
      headers
    });
    return next(req).pipe(
      tap(resp => {})
    );
  } else {
    const headers = req.headers.set('Content-Type', 'application/json')
    req = req.clone({
      headers
    });
    return next(req).pipe(
      tap(resp => {})
    );
  }
}
