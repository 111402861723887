<form [formGroup]="myForm">
    <ng-container *ngIf="form_loaded">
        <app-custom-select [formGroup]="myForm"
            controlName="holdStatements"
            label="{{ radioItems['holdStatements']['title'] }}"
            [options]="radioItems['holdStatements']['options']">
        </app-custom-select>
        <app-custom-checkbox [formGroup]="myForm"
            controlName="statementsDeliveredOnlineOnly"
            label="{{ checkboxItems['statementsDeliveredOnlineOnly']['title'] }}">
        </app-custom-checkbox>
        <app-custom-textarea [formGroup]="myForm"
            controlName="statementNote"
            label="{{ inputItems['statementNote']['placeholder'] }}">
        </app-custom-textarea>
        <app-custom-date-picker [formGroup]="myForm"
            controlName="statementNoteEffectiveDate"
            label="{{inputItems['statementNoteEffectiveDate']['placeholder']}}"
            [minDate]="minDate"
            [maxDate]="maxDate">
        </app-custom-date-picker>
    </ng-container>
</form>