import { Component, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HeaderComponent } from '../reusable/header/header.component';
import { WelcomeComponent } from '../reusable/welcome-steps/welcome/welcome.component';
import { UserInfoComponent } from '../reusable/welcome-steps/user-info/user-info.component';
import { UserInfoVerificationComponent } from '../reusable/welcome-steps/user-info-verification/user-info-verification.component';
import { ErrorComponent } from '../reusable/error/error.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verification',
  standalone: true,
  imports: [CommonModule, NgIf, MatProgressBarModule, HeaderComponent, WelcomeComponent, UserInfoComponent, UserInfoVerificationComponent, ErrorComponent],
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit {
  currentStep: string = 'welcome';
  is_loading = false;
  transitioning = false;
  hospital_slug: string;
  hospital_id: number;
  patient_id: number;
  form_id: number;

  constructor(private router: Router) {
    this.hospital_slug = localStorage.getItem('hospital_slug') || '';
    this.hospital_id = Number(localStorage.getItem('hospital_id')) || 0;
    this.patient_id = Number(localStorage.getItem('patient_id')) || 0;
    this.form_id = Number(localStorage.getItem('form_id')) || 0;
  }

  ngOnInit() {
    window.addEventListener('popstate', (event) => {
      if (this.currentStep === 'userInfo' || this.currentStep === 'verification') {
        event.preventDefault();
        this.changeStepWithClass('welcome');
      }
    });
  }

  changeStepWithClass(nextStep: string) {
    this.transitioning = true;
    this.is_loading = true;
    setTimeout(() => {
      this.currentStep = nextStep;
      this.transitioning = false;
      this.is_loading = false;
    }, 0);
  }

  goToNextStep() {
    if (this.currentStep === 'welcome') {
      this.changeStepWithClass('userInfo');
    } else if (this.currentStep === 'userInfo') {
      this.changeStepWithClass('verification');
    }
  }


  onUserInfoVerified() {
    this.changeStepWithClass('verification');
  }

  onCodeVerified() {
    this.router.navigate(['form', this.form_id]);
  }
}
