import {Component, forwardRef, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator
} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";

interface MedicalEvaluationsFormInterface {
  firstName: FormControl<string | null>;
}

export interface MedicalEvaluationsInterface {
  firstName: string;
}

@Component({
  selector: 'app-medical-evaluations-step-form',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MedicalEvaluationsStepFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MedicalEvaluationsStepFormComponent),
      multi: true,
    },
  ],
  templateUrl: './medical-evaluations-step-form.component.html',
  styleUrl: './medical-evaluations-step-form.component.css'
})
export class MedicalEvaluationsStepFormComponent implements ControlValueAccessor, Validator, OnDestroy, OnChanges {

  myForm = new FormGroup<MedicalEvaluationsFormInterface>({
    firstName: new FormControl(''),
  });

  destroySubject = new Subject<void>();

  ngOnChanges(changes: SimpleChanges) {} // end ngOnChanges

  registerOnChange(fn: any): void {
    //console.log('registerOnChange', fn)
    this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    //console.log('registerOnTouched', fn)
    this. myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.myForm.disable() : this.myForm.enable();
  }

  writeValue(data: MedicalEvaluationsInterface): void {
    this.myForm.patchValue(data, {emitEvent: false});
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.myForm.valid ? null : {intro: true};
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }


}
