<div [formGroup]="parentForm">
  <div formArrayName="authorized">
    <h2 class="authorized-title">Add authorized individuals</h2>
    <h3 class="authorized-sub-title">
      HIPAA requires your written consent to share your medical information with
      anyone except those involved in your care.
    </h3>
    <div
      *ngFor="let item of authorizedArray.controls; let i = index"
      [formGroupName]="i"
      class="authorized-item p-t-l p-b-s"
    >
      <i class="healent-close-circle close-icon" (click)="removeItem(i)"></i>
      <app-custom-input
        [formGroup]="getItemFormGroup(item)"
        controlName="name"
        *ngIf="inputItems['name']?.is_include"
        placeholder="{{ inputItems['name']['placeholder'] }}"
      ></app-custom-input>
      <app-custom-select
        [formGroup]="getItemFormGroup(item)"
        controlName="RelationshipToThePatient"
        *ngIf="radioItems['RelationshipToThePatient']?.is_include"
        label="{{ radioItems['RelationshipToThePatient']['title'] }}"
        [options]="radioItems['RelationshipToThePatient']['options']"
        [iconClass]="radioItems['RelationshipToThePatient']?.icon"
      ></app-custom-select>
      <app-phone
        [formGroup]="getItemFormGroup(item)"
        [title]="inputItems['primaryPhone']['placeholder']"
        formName="primaryPhone"
      ></app-phone>
    </div>
  </div>
  <button class="authorized-btn authorized-btn-m-t-m" (click)="addItem()">
    <i class="healent-plus"></i>Add individual
  </button>
</div>
