export const painFeelList = [
  { title: 'Numbness', value: 'Numbness' },
  { title: 'Stabbing', value: 'Stabbing' },
  { title: 'Pins and Needles', value: 'Pins and Needles' },
  { title: 'Aching', value: 'Aching' },
];

export const markLabel: any = {
  'Numbness': 'N',
  'Stabbing': 'S',
  'Pins and Needles': 'PIN',
  'Aching': 'A',
}

export const painFeelTitle = 'Select how the pain feels';
