<div class="privacy-top"></div>
<mat-stepper
  animationDuration="50"
  class="privacy-main"
  headerPosition="bottom"
  #stepper
>
  <mat-step *ngFor="let privacy of privacyList; let i = index">
    <div #privacyContent style="height: 40px; display: block"></div>
    <div class="privacy-content">
      <div class="m-120">
        <p class="privacy-title">{{ privacy.title }}</p>
        <app-akps-financial-policy
          *ngIf="privacy.key === 'akpsFinancialPolicy'"
          class="privacy-body"
        ></app-akps-financial-policy>
        <app-akps-acknowledgment
          *ngIf="privacy.key === 'akpsAcknowledgment'"
          class="privacy-body"
        ></app-akps-acknowledgment>
        <app-akps-authorization-to-treat
          *ngIf="privacy.key === 'akpsAuthorizationTreat'"
          class="privacy-body"
        ></app-akps-authorization-to-treat>
        <app-akps-notice-of-privacy-practices
          *ngIf="privacy.key === 'akpsNoticeOfPrivacyPractices'"
          class="privacy-body"
        ></app-akps-notice-of-privacy-practices>
        <app-akps-controlled-substances-policy
          *ngIf="privacy.key === 'akpsControlledSubstancesPolicy'"
          class="privacy-body"
        ></app-akps-controlled-substances-policy>
        <app-akps-physician-ownership
          *ngIf="privacy.key === 'akpsPhysicianOwnership'"
          class="privacy-body"
        ></app-akps-physician-ownership>
        <app-akps-pain-medication-policy
          *ngIf="privacy.key === 'akpsPainMedicationPolicy'"
          class="privacy-body"
        ></app-akps-pain-medication-policy>
        <app-akps-preferred-contacts
          *ngIf="privacy.key === 'akpsPreferredContacts'"
          class="privacy-body"
        ></app-akps-preferred-contacts>
        <app-cwps-financial-policy
          *ngIf="privacy.key === 'cwpsFinancialPolicy'"
          class="privacy-body"
        ></app-cwps-financial-policy>
        <app-cwps-physician-financial-policy
          *ngIf="privacy.key === 'cwpsPhysicianFinancialInterestDisclosure'"
          class="privacy-body"
        ></app-cwps-physician-financial-policy>
        <app-cwps-authorization-to-treat
          *ngIf="privacy.key === 'cwpsAuthorizationTreat'"
          class="privacy-body"
        ></app-cwps-authorization-to-treat>
        <app-cwps-inform-consent
          *ngIf="privacy.key === 'cwpsInformConsent'"
          class="privacy-body"
        ></app-cwps-inform-consent>
        <app-cwps-hippa-notice
          *ngIf="privacy.key === 'cwpsHipaaPrivacy'"
          class="privacy-body"
        ></app-cwps-hippa-notice>
        <app-cwps-procedural-sedation-consent
          *ngIf="privacy.key === 'cwpsProceduralSedation'"
          class="privacy-body"
        ></app-cwps-procedural-sedation-consent>
        <app-cwps-consent-insurance
          *ngIf="privacy.key === 'cwpsConsentInsurance'"
          class="privacy-body"
        >
        </app-cwps-consent-insurance>
        <app-cwps-treatment
          *ngIf="privacy.key === 'cwpsTreatment'"
          class="privacy-body"
        ></app-cwps-treatment>
        <app-cwps-authorization-consent
          *ngIf="privacy.key === 'cwpsAuthorizationConsent'"
          class="privacy-body"
        ></app-cwps-authorization-consent>
        <app-cwps-procedure-consent
          *ngIf="privacy.key === 'cwpsProcedureConsent'"
          class="privacy-body"
        ></app-cwps-procedure-consent>
        <app-cwps-ccm-consent
          *ngIf="privacy.key === 'cwpsCcmConsent'"
          class="privacy-body"
        ></app-cwps-ccm-consent>
        <app-cwps-rtm-consent
          *ngIf="privacy.key === 'cwpsRtmConsent'"
          class="privacy-body"
        ></app-cwps-rtm-consent>
        <app-cwps-preferred-contacts
          *ngIf="privacy.key === 'cwpsPreferredContacts'"
          class="privacy-body"
        ></app-cwps-preferred-contacts>

        <p
          *ngIf="privacy.body"
          class="privacy-body"
          [innerHTML]="privacy.body"
        ></p>
      </div>
    </div>
  </mat-step>
</mat-stepper>
<div class="privacy-footer">
  <button
    *ngIf="hospitalSlug !== 'akps'"
    mat-raised-button
    class="decline-btn"
    (click)="decline()"
  >
    Decline
  </button>
  <button
    mat-raised-button
    class="accept-btn"
    [ngClass]="{ 'accept-btn-advanced': hospitalSlug !== 'akps' }"
    (click)="accept()"
  >
    {{ hospitalSlug === 'akps' ? 'Sign and Approve' : 'Approve' }}
  </button>
</div>
