import { Component } from '@angular/core';

@Component({
  selector: 'app-akps-authorization-to-treat',
  standalone: true,
  imports: [],
  templateUrl: './akps-authorization-to-treat.component.html',
  styleUrl: './akps-authorization-to-treat.component.css'
})
export class AkpsAuthorizationToTreatComponent {

}
