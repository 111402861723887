import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { SharedFormService } from '../../services/SharedFormService.service';
import { CommonModule } from '@angular/common';
import { CustomInputComponent } from '../custom-input/custom-input.component';
import { CustomSelectComponent } from '../custom-select/custom-select.component';
import { PhoneComponent } from '../phone/phone.component';
@Component({
  selector: 'app-authorized',
  templateUrl: './authorized.component.html',
  styleUrls: ['./authorized.component.css'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, CustomInputComponent, CustomSelectComponent, PhoneComponent]
})
export class AuthorizedComponent implements OnInit {
  @Input() parentForm!: FormGroup;
  @Input() controlName: string = 'authorized';

  inputItems: any = {
    name: { is_include: true, placeholder: 'Name' },
    primaryPhone: { is_include: true, placeholder: 'Primary phone' }
  };
  radioItems: any = {
    RelationshipToThePatient: { is_include: true, title: 'Relationship to the patient', options_title: "Select the type of relationship", options: ['SPOUSE', 'PARENT', 'CHILD', 'SIBLING', 'FRIEND', 'COUSIN', 'GUARDIAN', 'OTHER',], icon: 'healent-form-input-icon-36' }
  };
  constructor(private fb: FormBuilder, private sharedFormService: SharedFormService) { }

  ngOnInit(): void {
    if (!this.parentForm) {
      throw new Error('Parent form group is required.');
    } if (!this.parentForm.get(this.controlName)) {
      this.parentForm.addControl(this.controlName, this.fb.array([]));
    }

    // Track changes in the entire array and update the shared service
    this.authorizedArray.valueChanges.subscribe(() => {
      this.updateSharedFormService();
    });
  }

  get authorizedArray(): FormArray {
    return this.parentForm.get(this.controlName) as FormArray;
  }

  addItem() {
    const itemGroup = this.fb.group({
      name: ['', Validators.required],
      RelationshipToThePatient: ['', Validators.required],
      primaryPhone: ['', Validators.required],
    });
    this.authorizedArray.push(itemGroup);
    this.updateSharedFormService();
  }

  removeItem(index: number) {
    this.authorizedArray.removeAt(index);
    this.updateSharedFormService();
  }

  // Notify SharedFormService of updates
  updateSharedFormService() {
    this.sharedFormService.updateFormValues(this.parentForm.value);
  }
  getItemFormGroup(control: any): FormGroup {
    return control as FormGroup;
  }
}
