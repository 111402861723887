import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-akps-physician-ownership',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './akps-physician-ownership.component.html',
  styleUrl: './akps-physician-ownership.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class AkpsPhysicianOwnershipComponent {

}
