<p>
  My CP&S doctor may recommend that I have procedural sedation (“sedation”)
  during my procedure, and if recommended, it is my decision if I want to
  receive sedation.
</p>
<br />
<p>
  I have been informed, and understand, that I do not have to agree to the use
  of sedation during my procedure.
</p>
<br />

<p>
  I understand that sedation medication is given to me to depress my
  consciousness so I may be more comfortable during my procedure.
</p>
<br />

<p>
  I understand that after I am given sedation, I will be able to breathe on my
  own and respond to verbal commands.
</p>
<br />

<p>
  I understand that even though I am sedated, I may still feel the pressure and
  pain of the procedure being performed.
</p>
<br />

<p>
  I understand that I may be given a local anesthetic during the procedure and I
  consent to the administration of the local anesthesia.
</p>
<br />

<p>
  I understand, and agree, that sedation will be given to me by a certified
  registered nurse anesthetist (“CRNA”), an advanced practice registered nurse
  (“APRN”), a registered nurse (“RN”) that is under the supervision of a CP&S
  doctor, or/and another qualified medical professional. CRNA, APRN, and RN are
  referred to as the sedation provider and I authorize my CP&S doctor to select
  the sedation provider to administer my sedation.
</p>
<br />

<p>
  I understand that the sedation provider will administer medication to me
  through my IV. The medication used for sedation may be Versed or similar
  medications.
</p>
<br />

<p>
  I understand that all sedation medications involve risks of complications and
  serious possible damage including damage to vital organs such as the brain,
  heart, lung, liver, skin, and kidney, and that in some cases use of these
  medications may result in paralysis, cardiac arrest, and/or death from both
  known and unknown causes.
</p>
<br />

<p>
  I have had the opportunity to ask questions about sedation, and/or have my
  family, friend or caregiver participate in the process, and my questions have
  been answered to my satisfaction.
</p>
<br />

<p>
  I will not care for a dependent person while I am recovering from mild
  sedation;
</p>
<br />

<p>
  I am accompanied by a responsible adult and will be cared for by that adult
  after my procedure; unless I have only received local anesthesia during the
  procedure and it does not affect my cognitive or motor skills.
</p>
<br />

<p>
  I have read, or have had read to me, the information stated in this consent
  document and I understand the content of this consent. I am over the age of
  18, I am of sound mind, and I am not under the influence.
</p>
