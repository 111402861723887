import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NgIf, NgForOf, KeyValuePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateService } from '@ngx-translate/core';
import { formObjectByType } from '../../functions/functions';
// Custom components
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CustomDatePickerComponent } from '../../reusable/custom-date-picker/custom-date-picker.component';
import { CustomSelectComponent } from '../../reusable/custom-select/custom-select.component';
import { UploadComponent } from '../../reusable/upload-components/upload/upload.component';
import { SsnComponent } from '../../reusable/ssn/ssn.component';
import { SharedFormService } from '../../services/SharedFormService.service';
@Component({
  selector: 'app-identification-step-form',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    NgIf,
    KeyValuePipe,
    NgForOf,
    UploadComponent,
    CustomInputComponent,
    CustomCheckboxComponent,
    CustomDatePickerComponent,
    CustomSelectComponent,
    SsnComponent
  ],

  templateUrl: './identification-step-form.component.html',
  styleUrls: ['./identification-step-form.component.css']
})
export class IdentificationStepFormComponent implements OnInit, OnDestroy {
  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';
  formValues: any = {};
  value: any;

  minDate!: Date;
  maxDate!: Date;
  form_loaded = false;
  myForm!: FormGroup;
  checkboxItems: any = {};
  radioItems: any = {};
  inputItems: any = {};
  uploadItems: any = {};

  destroySubject = new Subject<void>();

  constructor(
    private _bottomSheet: MatBottomSheet,
    private translateService: TranslateService,
    private sharedFormService: SharedFormService
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 100, 0, 1);
    this.maxDate = new Date(currentYear - 18, 11, 31);

    this.myForm = new FormGroup({});
    const currentLang = this.translateService.currentLang;
    const lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {
      const translationsObj = translations['healent'][this.step];
      const translationsObjSlug = translations[this.hospital_slug][this.step];

      let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step);
      this.myForm = formObject['myForm'];
      this.radioItems = formObject['radioItems'];
      this.inputItems = formObject['inputItems'];
      this.checkboxItems = formObject['checkboxItems'];
      this.uploadItems = formObject['uploadItems'];

      if (this.patch_values) {
        this.myForm.patchValue(this.patch_values, { emitEvent: false });
      }
      this.control?.setValue(this.myForm.value, { emitEvent: true });
      this.formGroup = this.myForm;
      this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(val => {
        this.formValues = val;
        this.control?.setValue(this.myForm.value, { emitEvent: true });
        this.formGroup = this.myForm;
        this.sharedFormService.updateFormValues(this.myForm.value);
      });
      this.onCheckboxChange();
      this.form_loaded = true;
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onCheckboxChange() {
    this.myForm.get('hasPreviousName')?.valueChanges.subscribe(checked => {
      const prevLastName = this.myForm.get('prevLastName') as FormControl;
      const prevFirstName = this.myForm.get('prevFirstName') as FormControl;
      if (checked) {
        prevLastName.setValidators([Validators.required]);
        prevFirstName.setValidators([Validators.required]);
      } else {
        prevLastName.clearValidators();
        prevFirstName.clearValidators();
        prevLastName.reset();
        prevFirstName.reset();
      }
      prevLastName.updateValueAndValidity();
      prevFirstName.updateValueAndValidity();
    });



    this.myForm.get('patientResidesInFacility')?.valueChanges.subscribe(checked => {
      const patient_nursing_facilityControl = this.myForm.get('nameOfFacility') as FormControl;
      if (checked) {
        patient_nursing_facilityControl.setValidators([Validators.required]);
      } else {
        patient_nursing_facilityControl.clearValidators();
        patient_nursing_facilityControl.reset();
      }
      patient_nursing_facilityControl.updateValueAndValidity();
    });

    this.myForm.get('isHospicePatient')?.valueChanges.subscribe(checked => {
      const patient_hospiceControl = this.myForm.get('nameOfHospiceService') as FormControl;
      if (checked) {
        patient_hospiceControl.setValidators([Validators.required]);
      } else {
        patient_hospiceControl.clearValidators();
        patient_hospiceControl.reset();
      }
      patient_hospiceControl.updateValueAndValidity();
    });
  }
}
