<form [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <app-custom-input id="patient_lastname"
      [formGroup]="myForm"
      controlName="lastName"
      *ngIf="inputItems['lastName']?.is_include"
      placeholder="{{ inputItems['lastName']['placeholder'] }}"
      [iconClass]="inputItems['lastName']?.icon"
      [hint]="inputItems['lastName']?.hint"
      [maxlength]="20"
    ></app-custom-input>
    <app-custom-input id="patient_firstname"
      [formGroup]="myForm"
      controlName="firstName"
      *ngIf="inputItems['firstName']?.is_include"
      placeholder="{{ inputItems['firstName']['placeholder'] }}"
      [iconClass]="inputItems['firstName']?.icon"
      [maxlength]="20"
    ></app-custom-input>
    <div class="middleName">
      <app-custom-input id="patient_middlename"
        [formGroup]="myForm"
        controlName="middleName"
        *ngIf="inputItems['middleName']?.is_include"
        placeholder="{{ inputItems['middleName']['placeholder'] }}"
      ></app-custom-input>
      <app-custom-input id="patient_suffix"
        [formGroup]="myForm"
        controlName="suffix"
        *ngIf="inputItems['suffix']?.is_include"
        placeholder="{{ inputItems['suffix']['placeholder'] }}"
      ></app-custom-input>
    </div>
    <div class="p-b-m" *ngIf="checkboxItems['hasPreviousName']?.is_include">
      <app-custom-checkbox id="haspreviousname"
        [formGroup]="myForm"
        controlName="hasPreviousName"
        *ngIf="checkboxItems['hasPreviousName']?.is_include"
        label="{{ checkboxItems['hasPreviousName']['title'] }}"
      >
      </app-custom-checkbox>
      <div *ngIf="myForm.get('hasPreviousName')?.value">
        <app-custom-input id="patient_prevlastname"
          [formGroup]="myForm"
          controlName="prevLastName"
          *ngIf="inputItems['prevLastName']?.is_include"
          placeholder="{{ inputItems['prevLastName']['placeholder'] }}"
          [iconClass]="inputItems['prevLastName']?.icon"
        ></app-custom-input>
        <app-custom-input id="patient_prevfirstname"
          [formGroup]="myForm"
          controlName="prevFirstName"
          *ngIf="inputItems['prevFirstName']?.is_include"
          placeholder="{{ inputItems['prevFirstName']['placeholder'] }}"
          [iconClass]="inputItems['prevFirstName']?.icon"
        ></app-custom-input>
      </div>
    </div>
    <app-custom-input id="mother_maiden_name"
      [formGroup]="myForm"
      controlName="motherMaidenName"
      *ngIf="inputItems['motherMaidenName']?.is_include"
      placeholder="{{ inputItems['motherMaidenName']['placeholder'] }}"
      [iconClass]="inputItems['motherMaidenName']?.icon"
    ></app-custom-input>
    <app-custom-date-picker id="patient_dob"
      [formGroup]="myForm"
      controlName="dob"
      *ngIf="inputItems['dob']?.is_include"
      label="Date of Birth"
      [minDate]="minDate"
      [maxDate]="maxDate"
    >
    </app-custom-date-picker>
    <app-upload
      [formGroup]="myForm"
      *ngIf="uploadItems['front']?.is_include"
      [prefix]="uploadItems['front']?.redTitle"
      [redTitle]="uploadItems['front']?.redTitle"
      [title]="uploadItems['front']?.title"
      [icon]="uploadItems['front']?.icon"
      formName="front"
      [btn]="uploadItems['front']?.btn"
      [desc]="uploadItems['front']?.description"
      class="m-t-m d-block"
    ></app-upload>
    <app-upload
      [formGroup]="myForm"
      *ngIf="uploadItems['back']?.is_include"
      [prefix]="uploadItems['back']?.redTitle"
      [redTitle]="uploadItems['back']?.redTitle"
      [title]="uploadItems['back']?.title"
      [icon]="uploadItems['back']?.icon"
      formName="back"
      [btn]="uploadItems['back']?.btn"
      [desc]="uploadItems['back']?.description"
      class="p-b-m d-block"
    ></app-upload>
    <app-custom-select id="legal_sex"
      [formGroup]="myForm"
      controlName="legalSex"
      *ngIf="radioItems['legalSex']?.is_include"
      label="{{ radioItems['legalSex']['title'] }}"
      [options]="radioItems['legalSex']['options']"
      [iconClass]="radioItems['legalSex']?.icon"
      class="text-capitalize"
    >
    </app-custom-select>
    <app-ssn id="patient_ssn"
      [formGroup]="myForm"
      *ngIf="inputItems['ssn']?.is_include"
      title="{{ inputItems['ssn']['title'] }}"
      placeholder="{{ inputItems['ssn']['placeholder'] }}"
    ></app-ssn>
    <app-custom-input id="patient_id"
      [formGroup]="myForm"
      controlName="patientID"
      *ngIf="inputItems['patientID']?.is_include"
      placeholder="{{ inputItems['patientID']['placeholder'] }}"
      [type]="'number'"
      [iconClass]="inputItems['patientID']?.icon"
    >
    </app-custom-input>
    <app-custom-input id="id_number_override"
      [formGroup]="myForm"
      controlName="idNumberOverride"
      *ngIf="inputItems['idNumberOverride']?.is_include"
      placeholder="{{ inputItems['idNumberOverride']['placeholder'] }}"
      [type]="'number'"
      [iconClass]="inputItems['idNumberOverride']?.icon"
    >
    </app-custom-input>
    <app-custom-input id="legacy_patient_id"
      [formGroup]="myForm"
      controlName="legacyPatientID"
      *ngIf="inputItems['legacyPatientID']?.is_include"
      placeholder="{{ inputItems['legacyPatientID']['placeholder'] }}"
      [type]="'number'"
      [iconClass]="inputItems['legacyPatientID']?.icon"
    >
    </app-custom-input>
    <app-upload
      [formGroup]="myForm"
      *ngIf="uploadItems['patientPicture']?.is_include"
      [prefix]="uploadItems['patientPicture']?.redTitle"
      [redTitle]="uploadItems['patientPicture']?.redTitle"
      [title]="uploadItems['patientPicture']?.title"
      [btn]="uploadItems['patientPicture']?.btn"
      [icon]="uploadItems['patientPicture']?.icon"
      formName="patientPicture"
      [desc]="uploadItems['patientPicture']?.description"
      class="p-t-m p-b-m d-block"
      [required]="uploadItems['patientPicture']?.required"
    ></app-upload>
    <app-custom-input id="nickname"
      [formGroup]="myForm"
      controlName="nickName"
      *ngIf="inputItems['nickName']?.is_include"
      placeholder="{{ inputItems['nickName']['placeholder'] }}"
      [iconClass]="inputItems['nickName']?.icon"
    >
    </app-custom-input>
    <div
      class="m-t-l d-block"
      *ngIf="checkboxItems['patientResidesInFacility']?.is_include"
    >
      <app-custom-checkbox
        [formGroup]="myForm"
        controlName="patientResidesInFacility"
        *ngIf="checkboxItems['patientResidesInFacility']?.is_include"
        label="{{ checkboxItems['patientResidesInFacility']['title'] }}"
      >
      </app-custom-checkbox>
      <div *ngIf="myForm.get('patientResidesInFacility')?.value">
        <app-custom-input id="facility_name"
          [formGroup]="myForm"
          controlName="nameOfFacility"
          *ngIf="inputItems['nameOfFacility']?.is_include"
          placeholder="{{ inputItems['nameOfFacility']['placeholder'] }}"
          [iconClass]="inputItems['nameOfFacility']?.icon"
        ></app-custom-input>
      </div>
    </div>

    <div
      class="m-b-l d-block"
      *ngIf="checkboxItems['isHospicePatient']?.is_include"
    >
      <app-custom-checkbox
        [formGroup]="myForm"
        controlName="isHospicePatient"
        *ngIf="checkboxItems['isHospicePatient']?.is_include"
        label="{{ checkboxItems['isHospicePatient']['title'] }}"
      >
      </app-custom-checkbox>
      <div *ngIf="myForm.get('isHospicePatient')?.value">
        <app-custom-input id="hospital_name"
          [formGroup]="myForm"
          controlName="nameOfHospiceService"
          *ngIf="inputItems['nameOfHospiceService']?.is_include"
          placeholder="{{ inputItems['nameOfHospiceService']['placeholder'] }}"
          [iconClass]="inputItems['nameOfHospiceService']?.icon"
        ></app-custom-input>
        <app-custom-input id="primary_care_provider"
          [formGroup]="myForm"
          controlName="primaryCareProvider"
          *ngIf="inputItems['primaryCareProvider']?.is_include"
          placeholder="{{ inputItems['primaryCareProvider']['placeholder'] }}"
          [iconClass]="inputItems['primaryCareProvider']?.icon"
        ></app-custom-input>
      </div>
    </div>

    <app-custom-input id="referring_provider"
      [formGroup]="myForm"
      controlName="referringProvider"
      *ngIf="inputItems['referringProvider']?.is_include"
      placeholder="{{ inputItems['referringProvider']['placeholder'] }}"
      [iconClass]="inputItems['referringProvider']?.icon"
    ></app-custom-input>
    <app-custom-select id="living_with"
      [formGroup]="myForm"
      controlName="whoAreYouLivingWith"
      *ngIf="radioItems['whoAreYouLivingWith']?.is_include"
      label="{{ radioItems['whoAreYouLivingWith']['title'] }}"
      [options]="radioItems['whoAreYouLivingWith']['options']"
      [iconClass]="radioItems['whoAreYouLivingWith']?.icon"
    >
    </app-custom-select>
  </ng-container>
</form>
