import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private currentLanguage: any = { title: 'English', value: 'en' };

  constructor(private translate: TranslateService) {
  }

  // Method to get the current language
  getCurrentLanguage(): any {
    return this.currentLanguage;
  }

  // Method to set the current language
  setCurrentLanguage(language: any): void {
    this.currentLanguage = language;
    this.translate.use(language.value)
  }
}
