<form class="m-t-m" [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <app-body-viewer
      [formGroup]="myForm"
      controlName="bodySelector"
      *ngIf="inputItems['bodySelector']?.is_include"
      title="{{ inputItems['bodySelector']['title'] }}"
      placeholder="{{ inputItems['bodySelector']['placeholder'] }}"
      selectedListTitle="{{ inputItems['bodySelector']['selectedListTitle'] }}"
      worseListTitle="{{ inputItems['bodySelector']['worseListTitle'] }}"
      [worseListInclude]="inputItems['bodySelector']?.worseListInclude"
    ></app-body-viewer>
    <app-custom-textarea
      id="pain_radiate"
      [formGroup]="myForm"
      controlName="painRadiate"
      label="{{ inputItems['painRadiate']['placeholder'] }}"
      *ngIf="inputItems['painRadiate']?.is_include"
      class="p-b-0"
    >
    </app-custom-textarea>
    <app-pain-slider
      [formGroup]="myForm"
      controlName="painMostTimeScore"
      title="{{ inputItems['painMostTimeScore']['placeholder'] }}"
      *ngIf="inputItems['painMostTimeScore']?.is_include"
    >
    </app-pain-slider>
    <app-pain-slider
      [formGroup]="myForm"
      controlName="painTodayScore"
      title="{{ inputItems['painTodayScore']['placeholder'] }}"
      *ngIf="inputItems['painTodayScore']?.is_include"
    >
    </app-pain-slider>
    <app-custom-date-picker
      id="pain_first_begin_date"
      [formGroup]="myForm"
      controlName="painFirstBegin"
      *ngIf="inputItems['painFirstBegin']?.is_include"
      label="{{ inputItems['painFirstBegin']['placeholder'] }}"
      [minDate]="minDate"
      [maxDate]="maxDate"
    >
    </app-custom-date-picker>
    <app-custom-select
      id="maincause_of_pain"
      [formGroup]="myForm"
      controlName="mainCauseOfPain"
      *ngIf="radioItems['mainCauseOfPain']?.is_include"
      label="{{ radioItems['mainCauseOfPain']['title'] }}"
      [options]="radioItems['mainCauseOfPain']['options']"
      [iconClass]="radioItems['mainCauseOfPain']?.icon"
    >
    </app-custom-select>
    <app-custom-textarea
      id="additional_details_ifaccident"
      [formGroup]="myForm"
      controlName="additionalDetailsIfAccident"
      label="{{ inputItems['additionalDetailsIfAccident']['placeholder'] }}"
      *ngIf="inputItems['additionalDetailsIfAccident']?.is_include"
    >
    </app-custom-textarea>
    <p
      class="hint hint-input-margin"
      *ngIf="inputItems['additionalDetailsIfAccident']?.is_include"
    >
      If accident or trauma
    </p>
    <app-custom-select
      id="frequency_of_pain"
      [formGroup]="myForm"
      controlName="frequencyOfYourPain"
      *ngIf="radioItems['frequencyOfYourPain']?.is_include"
      label="{{ radioItems['frequencyOfYourPain']['title'] }}"
      [options]="radioItems['frequencyOfYourPain']['options']"
      [iconClass]="radioItems['frequencyOfYourPain']?.icon"
    >
    </app-custom-select>
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="whatMakesYourPainWorse"
      *ngIf="checkboxesItems['whatMakesYourPainWorse']?.is_include"
      label="{{ checkboxesItems['whatMakesYourPainWorse']['title'] }}"
      subLabel="{{ checkboxesItems['whatMakesYourPainWorse']['subTitle'] }}"
      subLabelClass="{{
        checkboxesItems['whatMakesYourPainWorse']['subTitleClass']
      }}"
      [options]="checkboxesItems['whatMakesYourPainWorse']['options']"
    >
    </app-custom-checkbox-group>
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="whatMakesYourPainBetter"
      *ngIf="checkboxesItems['whatMakesYourPainBetter']?.is_include"
      label="{{ checkboxesItems['whatMakesYourPainBetter']['title'] }}"
      subLabel="{{ checkboxesItems['whatMakesYourPainBetter']['subTitle'] }}"
      subLabelClass="{{
        checkboxesItems['whatMakesYourPainBetter']['subTitleClass']
      }}"
      [options]="checkboxesItems['whatMakesYourPainBetter']['options']"
    >
    </app-custom-checkbox-group>

    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="whatMakesYourPainInterfere"
      *ngIf="checkboxesItems['whatMakesYourPainInterfere']?.is_include"
      label="{{ checkboxesItems['whatMakesYourPainInterfere']['title'] }}"
      [options]="checkboxesItems['whatMakesYourPainInterfere']['options']"
    >
    </app-custom-checkbox-group>
    <app-chip-selection
      [formGroup]="myForm"
      title="{{ inputItems['allergiesIntolerance']['placeholder'] }}"
      *ngIf="inputItems['allergiesIntolerance']?.is_include"
      formName="allergiesIntolerance"
      class="m-t-l d-block"
    ></app-chip-selection>
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="selectAnyOtherHealthProblems"
      *ngIf="checkboxesItems['selectAnyOtherHealthProblems']?.is_include"
      label="{{ checkboxesItems['selectAnyOtherHealthProblems']['title'] }}"
      [options]="checkboxesItems['selectAnyOtherHealthProblems']['options']"
    >
    </app-custom-checkbox-group>

    <app-custom-select
      id="smoking_status"
      [formGroup]="myForm"
      controlName="smokingStatus"
      *ngIf="radioItems['smokingStatus']?.is_include"
      label="{{ radioItems['smokingStatus']['title'] }}"
      [options]="radioItems['smokingStatus']['options']"
      [iconClass]="radioItems['smokingStatus']?.icon"
    >
    </app-custom-select>
    <app-custom-select
      id="alcoho_use"
      [formGroup]="myForm"
      controlName="alcoholUse"
      *ngIf="radioItems['alcoholUse']?.is_include"
      label="{{ radioItems['alcoholUse']['title'] }}"
      [options]="radioItems['alcoholUse']['options']"
      [iconClass]="radioItems['alcoholUse']?.icon"
    >
    </app-custom-select>
  </ng-container>
</form>
