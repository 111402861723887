.upload-card-1 {
  cursor: pointer;
}

.mdc-card__actions {
  background: var(--primary-color);
  color: var(--white);
  font-size: 16px !important;
  font-weight: 700 !important;
  text-align: center !important;
  justify-content: center;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.error-upload {
  color: var(--mat-form-field-error-text-color) !important;
}

.upload-input {
  cursor: pointer;
}

.upload-icon {
  cursor: pointer;
}

.mdc-card__actions div {
  display: flex;
  align-items: center;
}

.prefix-title {
  color: var(--red);
}
.p-t-l-m {
  padding-top: 8px;
  padding-left: 16px;
}
