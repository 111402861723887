import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray, ReactiveFormsModule } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { CommonModule } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { formObjectByType } from "../../functions/functions";
import { MedicationComponent } from '../../reusable/medication/medication.component';

interface Medication {
  name: string;
  dose: string;
  freq: string;
}

interface MedicalUsage {
  medications: Medication[];
}

interface PatchValues {
  medicalUsage: MedicalUsage;
  [key: string]: any;
}

@Component({
  selector: 'app-medical-usage-step-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MedicationComponent
  ],

  templateUrl: './medical-usage-step-form.component.html',
  styleUrls: ['./medical-usage-step-form.component.css']
})
export class MedicalUsageStepFormComponent implements OnInit, OnDestroy {
  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values: PatchValues = { medicalUsage: { medications: [] } };
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';
  formValues: any = {};
  myForm!: FormGroup;
  form_loaded: boolean = false;
  destroySubject = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }
  ngOnInit(): void {
    this.initializeForm();

    const currentLang = this.translateService.currentLang;
    const lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(translations => {
        const translationsObj = translations['healent'][this.step];
        const translationsObjSlug = translations[this.hospital_slug][this.step];

        const formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step);
        this.myForm = formObject['myForm'];

        if (this.patch_values && this.patch_values['medicalUsage']) {
          if (Array.isArray(this.patch_values['medicalUsage']['medications'])) {
            this.setFormArray(this.patch_values['medicalUsage']['medications']);
          }
        } else if (this.patch_values && Array.isArray(this.patch_values['medications'])) {
          this.setFormArray(this.patch_values['medications']);
        } else {
          console.warn('medicalUsage is not defined or medications is missing:', this.patch_values);
        }

        this.control?.setValue(this.myForm.value, { emitEvent: true });
        this.formGroup = this.myForm;
        this.myForm.valueChanges
          .pipe(takeUntil(this.destroySubject))
          .subscribe(val => {
            this.formValues = val;
            this.control?.setValue(this.myForm.value, { emitEvent: true });
            this.formGroup = this.myForm;
          });

        this.form_loaded = true;
        this.cdr.detectChanges();
      });
  }




  initializeForm(): void {
    this.myForm = this.fb.group({
      medications: this.fb.array([]),
    });
  }

  setFormArray(data: Medication[]) {
    const medicationsFormArray = this.myForm.get('medications') as FormArray;
    medicationsFormArray.clear();
    if (data && data.length > 0) {
      data.forEach(item => {
        const group = this.fb.group({
          name: [item.name, Validators.required],
          dose: [item.dose],
          freq: [item.freq],
        });
        medicationsFormArray.push(group);
      });
    }
    this.cdr.detectChanges();
  }



  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}