import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { MatFormField, MatFormFieldModule, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { TranslateService } from "@ngx-translate/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatIcon } from "@angular/material/icon";
import { MatIconButton } from "@angular/material/button";
import { NgIf } from "@angular/common";
import { MatCheckbox } from "@angular/material/checkbox";
import { formObjectByType } from "../../functions/functions";
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CustomSelectComponent } from '../../reusable/custom-select/custom-select.component';
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';
import { CustomRadioGroupComponent } from '../../reusable/custom-radio-group/custom-radio-group.component';
import { CustomDatePickerComponent } from '../../reusable/custom-date-picker/custom-date-picker.component';
import { CustomTextareaComponent } from '../../reusable/custom-textarea/custom-textarea.component';
import { PhoneComponent } from '../../reusable/phone/phone.component';
import { AddressComponent } from '../../reusable/address/address.component';
import { SsnComponent } from '../../reusable/ssn/ssn.component';

interface PatchValues {
  responsibleToPayPatientsBill?: string;
}

@Component({
  selector: 'app-guarantor-step-form',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatIcon,
    MatIconButton,
    NgIf,
    MatFormFieldModule,
    MatCheckbox,
    CustomCheckboxComponent,
    CustomSelectComponent,
    CustomInputComponent,
    CustomRadioGroupComponent,
    CustomDatePickerComponent,
    CustomTextareaComponent,
    PhoneComponent,
    AddressComponent,
    SsnComponent
  ],
  templateUrl: './guarantor-step-form.component.html',
  styleUrls: ['./guarantor-step-form.component.css']
})
export class GuarantorStepFormComponent implements OnInit, OnDestroy {

  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values: PatchValues = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';
  formValues: any = {};
  myForm!: FormGroup;
  checkboxItems: any = {};
  inputItems: any = {};
  radioItems: any = {};
  form_loaded: boolean = false;
  minDate!: Date;
  maxDate!: Date;

  destroySubject = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private _bottomSheet: MatBottomSheet,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    const today = new Date();
    this.minDate = today;
    this.maxDate = today;
    this.myForm = new FormGroup({});
    const currentLang = this.translateService.currentLang;
    const lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {
      const translationsObj = translations['healent'][this.step];
      const translationsObjSlug = translations[this.hospital_slug][this.step];
      let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step);
      this.myForm = formObject['myForm'];
      this.radioItems = formObject['radioItems'];
      this.inputItems = formObject['inputItems'];
      this.checkboxItems = formObject['checkboxItems'];

      if (this.patch_values) {
        this.myForm.patchValue(this.patch_values, { emitEvent: false });
        const isAnotherPerson = this.myForm.get('responsibleToPayPatientsBill')?.value === 'anotherPerson';
        this.setupCheckboxChangeValidation(isAnotherPerson);
        this.myForm.updateValueAndValidity();
        this.cdr.detectChanges();
      }

      this.control?.setValue(this.myForm.value, { emitEvent: true });
      this.formGroup = this.myForm;
      this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(val => {
        this.formValues = val;
        this.control?.setValue(this.myForm.value, { emitEvent: true });
        this.formGroup = this.myForm;
      });

      this.form_loaded = true;

      this.myForm.get('responsibleToPayPatientsBill')?.valueChanges.subscribe(value => {
        this.setupCheckboxChangeValidation(value === 'anotherPerson');
      });
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  setupCheckboxChangeValidation(isAnotherPerson: boolean): void {
    const controlsToValidate = [
      'relationshipToThePatient',
      'lastName',
      'firstName',
      'dob',
      'address',
      'phoneNumber',
      'ssn'
    ];

    controlsToValidate.forEach(controlName => {
      const control = this.myForm.get(controlName);
      if (control) {
        if (isAnotherPerson) {
          control.setValidators([Validators.required]);
        } else {
          control.clearValidators();
          control.reset();
        }
        control.updateValueAndValidity({ emitEvent: false });
      }
    });
    const email = this.myForm.get('email');
    if (isAnotherPerson) {
      email?.reset();
    }

    this.myForm.updateValueAndValidity();
    this.cdr.detectChanges();
  }
}
