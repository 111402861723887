import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-akps-controlled-substances-policy',
  standalone: true,
  imports: [],
  templateUrl: './akps-controlled-substances-policy.component.html',
  styleUrl: './akps-controlled-substances-policy.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class AkpsControlledSubstancesPolicyComponent {

}
