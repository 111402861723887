import { Component, ViewEncapsulation } from '@angular/core';
import { SharedFormService } from '../../../../../services/SharedFormService.service';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-akps-pain-medication-policy',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './akps-pain-medication-policy.component.html',
  styleUrls: ['./akps-pain-medication-policy.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AkpsPainMedicationPolicyComponent {

}
