import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CustomDatePickerComponent } from '../../custom-date-picker/custom-date-picker.component';
import { VerificationPhoneComponent } from '../../verification-phone/verification-phone.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { LanguageService } from '../../../services/language.service';
import { TranslateModule } from "@ngx-translate/core";
import { VerificationService } from '../../../services/verification.service';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@Component({
  selector: 'app-user-info',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, CustomDatePickerComponent, VerificationPhoneComponent, TranslateModule, MatProgressSpinnerModule],

  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {
  userInfoForm!: FormGroup;
  @Output() userInfoVerified = new EventEmitter<void>();

  minDate!: Date;
  maxDate!: Date;
  userData: any = {};
  formVerification: any = {};
  hospital_slug: string = '';
  form_id: number = 0;
  hospital_id = 0;
  patient_id = 0;
  private destroySubject = new Subject<void>();
  isDataMatching: boolean = false;
  loading: boolean = false; // Add loading state
  isHidden: boolean = true;

  constructor(
    private fb: FormBuilder,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private verificationService: VerificationService,
    private router: Router,
    private http: HttpClient,
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 100, 0, 1);
    this.maxDate = new Date(currentYear - 18, 11, 31);
    this.hospital_slug = localStorage.getItem('hospital_slug') || '';
    this.hospital_id = Number(localStorage.getItem('hospital_id')) || 0;
    this.patient_id = Number(localStorage.getItem('patient_id')) || 0;
    this.form_id = Number(localStorage.getItem('form_id')) || 0;
    const currentLang = this.translateService.currentLang;
    let lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {
      const defaultFormVerification = translations['healent']?.['formVerification'];
      const hospitalFormVerification = translations[this.hospital_slug]?.['formVerification'];
      this.formVerification = { ...defaultFormVerification, ...hospitalFormVerification };
    });
    this.userInfoForm = this.fb.group({
      dob: ['', Validators.required],
      mobilePhone: ['', [Validators.required]]
    });
    setTimeout(() => {
      this.isHidden = false;
    }, 2500);
  }

  verifyUserInfo() {
    if (this.userInfoForm.invalid) {
      alert('Please fill in the required fields.');
      return;
    }

    const dobControl = this.userInfoForm.get('dob');
    const mobilePhoneControl = this.userInfoForm.get('mobilePhone');
    const dob = dobControl?.value;
    const phone = mobilePhoneControl?.value;
    const formDate = new Date(dob);

    // Prepare the payload in the correct format
    const year = formDate.getFullYear();
    const month = ('0' + (formDate.getMonth() + 1)).slice(-2);
    const day = ('0' + formDate.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    const payload = {
      phone: phone,
      date_of_birth: formattedDate
    };

    const verificationPayload = {
      userName: phone,
      dateOfBirth: formattedDate
    };

    const verifyCiphertext = localStorage.getItem('verifyCiphertext');
    const verificationEndpoint = `${environment.API_URL}/form/hmsa/verify/${verifyCiphertext}`;

    // Set loading state to true before sending request
    this.loading = true;

    // Send the data to the backend for verification
    this.http.post<any>(verificationEndpoint, payload).subscribe({
      next: (response) => {
        if (response && response.status === 200) {
          if (response.message === 403) {
            // Show an error message if the message is 403
            alert("The date of birth or phone number doesn't match our records.Please check and try again.");
            this.loading = false;

          } else {
            // If verification succeeds, send verification code
            this.verificationService.sendVerificationCode(verificationPayload).subscribe({
              next: () => {
                this.userInfoVerified.emit();  // Emit the verification event if successful
                this.verificationService.setPhoneNumber(phone);  // Save the phone and dob
                this.verificationService.setDOB(formattedDate);
                this.loading = false;
              },
              error: (e) => {
                console.error('Error sending verification code:', e);
                alert('Failed to send verification code. Please try again.');
              }
            });
          }
        } else {
          alert('Verification failed. Please try again.');
        }
      },
      error: (errorResponse) => {
        if (errorResponse.status === 403) {
          alert('The date of birth or phone number you entered is incorrect. Please check and try again.');
        } else {
          console.error('Error verifying user info:', errorResponse);
          alert('An error occurred while verifying your information. Please try again.');
        }
      },

    });
  }
}

