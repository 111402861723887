<div [formGroup]="formGroup">
  <mat-form-field appearance="fill">
    <mat-label>{{ title | translate }} </mat-label>

    <!-- Bind mat-select to the insuranceControl using formControlName -->
    <mat-select
      formControlName="{{ formName }}"
      (selectionChange)="onSelectionChange($event.value)"
    >
      <mat-option
        *ngFor="let option of insuranceOptions"
        [value]="option.insurancepackageid"
      >
        {{ option['insuranceproducttypename-city'] }}
      </mat-option>
    </mat-select>

    <mat-icon matSuffix>
      <i class="input-icon {{ icon ? icon : 'healent-form-input-icon-6' }}"></i>
    </mat-icon>
  </mat-form-field>
</div>
