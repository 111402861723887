import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SharedFormService {
    private formValuesSource = new BehaviorSubject<any>({});
    formValues$ = this.formValuesSource.asObservable();

    // Define showCCM as a BehaviorSubject
    private showCCMSource = new BehaviorSubject<boolean>(false);
    showCCM$ = this.showCCMSource.asObservable();

    private medicaidStatusSource = new BehaviorSubject<string | null>(null);
    medicaidStatus$ = this.medicaidStatusSource.asObservable();

    updateFormValues(newValues: any) {
        const currentValues = this.formValuesSource.value;
        this.formValuesSource.next({ ...currentValues, ...newValues });
    }
    updateMedicaidStatus(value: string | null) {
        this.medicaidStatusSource.next(value);
    }

    getMedicaidStatus() {
        return this.medicaidStatusSource.value;
    }

    getFormValues() {
        return this.formValuesSource.value;
    }
    setShowCCM(value: boolean) {
        this.showCCMSource.next(value);
    }
    getShowCCM() {
        return this.showCCMSource.value;
    }
}
