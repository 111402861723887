import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray, ReactiveFormsModule } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { debounceTime } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { formObjectByType } from "../../functions/functions";
import { TestAndImageComponent } from '../../reusable/test-and-image/test-and-image.component';
import { CustomCheckboxGroupComponent } from '../../reusable/custom-checkbox-group/custom-checkbox-group.component';
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';
import { CustomDatePickerComponent } from '../../reusable/custom-date-picker/custom-date-picker.component';
import { CustomRadioGroupComponent } from '../../reusable/custom-radio-group/custom-radio-group.component';
import { CustomTextareaComponent } from '../../reusable/custom-textarea/custom-textarea.component';
import { ChipSelectionComponent } from '../../reusable/chip-selection/chip-selection.component';
import { SelectAsArrayComponent } from '../../reusable/select-as-array/select-as-array.component';
@Component({
  selector: 'app-disability-index-step-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TestAndImageComponent,
    CustomCheckboxGroupComponent,
    CustomCheckboxComponent,
    CustomInputComponent,
    CustomDatePickerComponent,
    CustomRadioGroupComponent,
    CustomTextareaComponent,
    ChipSelectionComponent,
    SelectAsArrayComponent
  ],
  templateUrl: './disability-index-step-form.component.html',
  styleUrl: './disability-index-step-form.component.css'
})
export class DisabilityIndexStepFormComponent implements OnInit, OnDestroy {
  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';
  showODI: any;
  showNDI: any;

  formValues: any = {};
  value: any;
  form_loaded = false;
  myForm!: FormGroup;
  checkboxItems: any = {};
  radioItems: any = {};
  inputItems: any = {};
  destroySubject = new Subject<void>();

  constructor(private translateService: TranslateService) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    this.myForm = new FormGroup({});
    const currentLang = this.translateService.currentLang;
    let lang_form = `${currentLang}-${this.form_id}`;
    this.showODI = JSON.parse(localStorage.getItem('showOdi') || 'true');
    this.showNDI = JSON.parse(localStorage.getItem('showNdi') || 'true');


    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {
      const translationsObj = translations['healent'][this.step];
      const translationsObjSlug = translations[this.hospital_slug][this.step];
      let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step);

      Object.assign(this.myForm.controls, formObject['myForm'].controls);
      this.radioItems = formObject['radioItems'];
      this.inputItems = formObject['inputItems'];
      this.checkboxItems = formObject['checkboxItems'];

      if (this.patch_values) {
        this.myForm.patchValue(this.patch_values, { emitEvent: false });
      }

      this.control?.setValue(this.myForm.value, { emitEvent: false });
      this.formGroup = this.myForm;

      this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(val => {
        this.formValues = val;
        this.control?.setValue(this.myForm.value, { emitEvent: false });
        this.formGroup = this.myForm;
      });



      const backPainControl = this.myForm.get('doYouHaveBackPain') as FormControl | null;
      const neckPainControl = this.myForm.get('doYouHaveNeckPain') as FormControl | null;
      console.log(neckPainControl?.value)

      if (!this.showNDI && this.showODI) {
        this.removeAllValidators(
          neckPainControl,
        );
      } else if (!this.showODI && this.showNDI) {
        this.removeAllValidators(
          backPainControl
        );
      } else if (!this.showODI && !this.showNDI) {
        this.removeAllValidators(
          backPainControl, neckPainControl
        )
      }
      if (backPainControl && this.showODI) {
        backPainControl.setValue('yes', { emitEvent: false });
        this.toggleSelectValidators('yes', 'painIntensity', 'personalCareWashingDressingEtc', 'lifting', 'walking', 'sitting', 'standing', 'sleeping', 'socialLife', 'traveling');
      }

      if (neckPainControl?.value === 'yes') {
        this.toggleSelectValidators('yes', 'neckPainIntensity', 'neckPersonalCare', 'neckLifting', 'neckReading', 'neckHeadaches', 'neckConcentration', 'neckWork', 'neckDriving', 'neckSleeping', 'neckRecreation');
      }

      this.onCheckboxChange();
      this.subscribeToRadioChanges();
      this.form_loaded = true;
    });
  }
  removeAllValidators(...controls: (FormControl | null)[]) {
    controls.forEach(control => {
      if (control) {
        control.clearValidators();
        control.setValue('no', { emitEvent: false });
        control.updateValueAndValidity();
      }
    });
  }


  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onCheckboxChange() {
    // Handle any checkbox changes here
  }

  subscribeToRadioChanges() {
    const backPainControl = this.myForm.get('doYouHaveBackPain');
    const neckPainControl = this.myForm.get('doYouHaveNeckPain');

    backPainControl?.valueChanges.pipe(debounceTime(100), takeUntil(this.destroySubject)).subscribe(value => {
      this.toggleSelectValidators(value, 'painIntensity', 'personalCareWashingDressingEtc', 'lifting', 'walking', 'sitting', 'standing', 'sleeping', 'socialLife', 'traveling', 'sexLife');
    });

    neckPainControl?.valueChanges.pipe(debounceTime(100), takeUntil(this.destroySubject)).subscribe(value => {
      this.toggleSelectValidators(value, 'neckPainIntensity', 'neckPersonalCare', 'neckLifting', 'neckReading', 'neckHeadaches', 'neckConcentration', 'neckWork', 'neckDriving', 'neckSleeping', 'neckRecreation');
    });
  }

  toggleSelectValidators(parentValue: string, ...selectControlNames: string[]) {
    selectControlNames.forEach(controlName => {
      const control = this.myForm.get(controlName);
      if (!control) return;
      const hasRequiredValidator = control.hasValidator(Validators.required);
      if (parentValue === 'yes' && !hasRequiredValidator) {
        control.setValidators([Validators.required]);
        control.updateValueAndValidity({ emitEvent: false });
      } else if (parentValue !== 'yes' && hasRequiredValidator) {
        control.clearValidators();
        control.setValue(null, { emitEvent: false }); // Ensure no event loop
        control.updateValueAndValidity({ emitEvent: false });
      }
    });
  }
}
