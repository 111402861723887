import { Component, Input, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CommonModule } from '@angular/common';
import { countries } from './countries';

@Component({
  selector: 'app-phone',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    provideNgxMask(),
  ],
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.css']
})
export class PhoneComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() title!: string;
  @Input() formName!: string;
  @Input() iconClass?: string;
  @Input() readonly: boolean = false;
  @Input() id?: string;

  @ViewChild('phoneInput') phoneInput!: ElementRef;
  countries = countries;
  selectedCountry = this.countries.find(c => c.code === 'US'); // Default to US
  localPhoneControl = new FormControl('');
  phoneControl!: FormControl;
  inputFocused: boolean = false;
  inputTouched: boolean = false;
  isRequired: boolean = false;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.phoneControl = this.formGroup.get(this.formName) as FormControl;
    this.isRequired = this.phoneControl?.hasValidator(Validators.required) || false;

    const phoneValue = this.phoneControl?.value;
    if (phoneValue) {
      this.initializePhoneNumber(phoneValue);
    }

    this.localPhoneControl.valueChanges.subscribe((value) => {
      if (value !== null) {
        this.updatePhoneNumber(value);
      }
    });
  }


  onInputFocus(event: FocusEvent) {
    event.stopPropagation();
    this.inputFocused = true;
    this.inputTouched = true;

    const inputElement = this.phoneInput.nativeElement;

    // Check if the input value is empty or just the mask placeholder characters
    if (inputElement.value === '' || inputElement.value === inputElement.getAttribute('placeholder')) {
      setTimeout(() => {
        inputElement.setSelectionRange(0, 0); // Move cursor to the start
      }, 0);
    }
  }



  onInputBlur(event: FocusEvent) {
    this.inputFocused = false;
    this.phoneControl.markAsTouched(); // Ensure the control is marked as touched
    this.cdr.detectChanges(); // Detect changes to update the class binding
  }

  isInputActive(): boolean {
    return this.inputFocused || !!this.localPhoneControl.value;
  }

  isInvalid(): boolean {
    return this.phoneControl?.invalid && (this.phoneControl.touched || this.phoneControl.dirty) && this.isRequired;
  }

  private initializePhoneNumber(phone: string) {
    const country = this.countries.find(c => phone.startsWith(c.prefix));
    if (country) {
      this.selectedCountry = country;
      const localPhone = phone.replace(country.prefix, '').trim();
      this.localPhoneControl.setValue(localPhone);
    } else {
      this.selectedCountry = this.countries.find(c => c.code === 'US');
      this.localPhoneControl.setValue(phone);
    }
  }

  private updatePhoneNumber(localPhone: string) {
    const cleanedLocalPhone = localPhone.replace(/[^0-9]/g, '').trim();
    const phoneControl = this.formGroup.get(this.formName);

    if (this.selectedCountry && phoneControl) {
      const completePhoneNumber = cleanedLocalPhone.length > 0 ? `${this.selectedCountry.prefix}${cleanedLocalPhone}` : '';
      phoneControl.setValue(completePhoneNumber);

      if (!this.isRequired && cleanedLocalPhone.length === 0) {
        phoneControl.clearValidators();
        phoneControl.setErrors(null);
      } else if (cleanedLocalPhone.length === 0) {
        phoneControl.setValidators([Validators.required]);
        phoneControl.setErrors({ required: true });
      } else if (cleanedLocalPhone.length < 12) {
        phoneControl.setValidators([Validators.required, Validators.minLength(12)]);
        phoneControl.setErrors({ minlength: { requiredLength: 12, actualLength: cleanedLocalPhone.length } });
      } else {
        phoneControl.setValidators([Validators.required, Validators.minLength(12)]);
        phoneControl.setErrors(null); // No errors if valid
      }

      phoneControl.updateValueAndValidity();
    }
  }



  focusInput(event: MouseEvent) {
    event.stopPropagation();
    setTimeout(() => {
      this.phoneInput.nativeElement.focus();
    }, 0);
  }
}
