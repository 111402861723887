import {
  Component,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconModule } from "@angular/material/icon";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { Router, ActivatedRoute } from '@angular/router';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';
import { LanguageService } from '../../services/language.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [
    MatIconModule,
    LanguageSelectorComponent,
    TranslateModule,
  ],
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter<void>();

  currentPath!: string;
  formsInfo: Record<string, any> = {};
  hospital_slug: string = '';
  form_id: number = 0;
  supportPhoneNumber: string = '';

  private destroySubject = new Subject<void>();

  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }

  ngOnInit(): void {
    this.hospital_slug = localStorage.getItem('hospital_slug') || '';
    this.form_id = Number(localStorage.getItem('form_id')) || 0;

    const currentLang = this.translateService.currentLang || 'en';
    const lang_form = `${currentLang}-${this.form_id}`;
    this.translateService
      .getTranslation(lang_form)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(
        (translations) => {
          const defaultFormsInfo = translations['healent']?.['formsInfo'] || {};
          const hospitalFormsInfo = translations[this.hospital_slug]?.['formsInfo'] || {};
          this.formsInfo = { ...defaultFormsInfo, ...hospitalFormsInfo };
        },
        (error) => {
          console.error('Error loading translations:', error);
        }
      );

    this.setSupportPhoneNumber();
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  setSupportPhoneNumber(): void {
    if (this.hospital_slug?.toLowerCase() === 'akps') {
      this.supportPhoneNumber = '312-635-0973';
    } else if (this.hospital_slug?.toLowerCase() === 'cps') {
      this.supportPhoneNumber = '312-635-0973';
    } else {
      this.supportPhoneNumber = '';
    }
  }

  startForm(): void {
    this.close.emit();
  }
}
