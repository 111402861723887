<form [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <div class="p-b-m">
      <app-custom-checkbox
        [formGroup]="myForm"
        *ngIf="checkboxItems['isPatient']['is_include']"
        controlName="isPatient"
        [label]="checkboxItems['isPatient']?.title"
      >
      </app-custom-checkbox>
      <div *ngIf="myForm.get('isPatient')?.value">
        <app-custom-select id="proxy_relationship"
          [formGroup]="myForm"
          *ngIf="radioItems['proxy_relationship']['is_include']"
          controlName="proxy_relationship"
          [label]="radioItems['proxy_relationship']?.title"
          [optionsTitle]="radioItems['proxy_relationship']?.options_title"
          [options]="radioItems['proxy_relationship']?.options"
          [iconClass]="radioItems['proxy_relationship']?.icon"
        >
        </app-custom-select>
        <app-custom-input id="intro_name"
          [formGroup]="myForm"
          *ngIf="inputItems['proxy_full_name']['is_include']"
          controlName="proxy_full_name"
          [placeholder]="inputItems['proxy_full_name']?.placeholder"
          [iconClass]="inputItems['proxy_full_name']?.icon"
        >
        </app-custom-input>
      </div>
    </div>
    <app-custom-select id="hearus"
      [formGroup]="myForm"
      *ngIf="radioItems['hearUs']['is_include']"
      controlName="hearUs"
      [label]="radioItems['hearUs']?.title"
      [optionsTitle]="radioItems['hearUs']?.options_title"
      [options]="radioItems['hearUs']?.options"
      [iconClass]="radioItems['hearUs']?.icon"
    >
    </app-custom-select>
    <app-custom-radio-group
      [formGroup]="myForm"
      *ngIf="radioItems['deliveryPreference']['is_include']"
      controlName="deliveryPreference"
      [label]="radioItems['deliveryPreference']?.title"
      [options]="radioItems['deliveryPreference']?.options"
      class="radio-choice"
    >
    </app-custom-radio-group>
  </ng-container>
</form>
