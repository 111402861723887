<div class="popup-container" [formGroup]="myForm">
  <span class="title">{{ data.optionsTitle }}</span>
  <mat-radio-group
    formControlName="selected_value"
    (change)="onRadioSelect($event.value)"
  >
    <mat-radio-button *ngFor="let item of Items" [value]="item.key">
      {{ item.value }}
    </mat-radio-button>
  </mat-radio-group>
</div>
