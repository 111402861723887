<div>
  <div *ngIf="separateTitle" class="select-title-style">
    <div class="select-cercle">
      <h2 class="title-number">{{ titleNumber }}</h2>
    </div>
    <h2 class="selection-title">{{ label }}</h2>
  </div>
  <mat-form-field [formGroup]="formGroup" (click)="openOptions()">
    <mat-label *ngIf="!separateTitle">{{ label }}</mat-label>
    <mat-label *ngIf="separateTitle">{{ subTitle }}</mat-label>

    <input id="{{ id }}"
      matInput
      readonly
      placeholder=""
      [value]="displayValue"
      [formControlName]="controlName"
    />
    <mat-icon mat-icon-button matSuffix>
      <i [ngClass]="[iconClass || 'healent-form-input-icon-6']"></i>
    </mat-icon>
  </mat-form-field>

  <!-- Hint and Error message container -->
  <div
    [ngClass]="{ hintContainer: true, valid: control.valid && control.value }"
  >
    <!-- Display error message if control has error -->
    <div *ngIf="control.errors && control.touched">
      <span class="hintError">#Error</span>
      <span class="hintText"> - {{ getErrorMessage() }}</span>
    </div>

    <!-- Display required/optional hint if no error -->
    <div *ngIf="!control.errors || !control.touched">
      <span *ngIf="isFieldRequired(); else optionalField" class="hintRequired">
        *Required
      </span>
      <ng-template #optionalField>
        <span class="hintOptional">+Optional</span>
      </ng-template>
      <span *ngIf="hint" class="hintText"> - {{ hint }}</span>
    </div>
  </div>
</div>
