import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { FormInfo } from "../../services/form.interface";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class ApiService {

  url: string = `${environment.API_URL}`;
  formInfo: FormInfo | null = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    public dialog: MatDialog,
  ) { }

  setFormInfo(params: any) {
    this.formInfo = {
      patientId: params['patient_id'],
      formId: params['form_id'],
      hospitalId: params['hospital_id'],
      hospitalSlug: params['hospital_slug']
    };
  }

  get getFormInfo() {
    return this.formInfo;
  }

  getSignedUrl(name: string): Observable<any> {

    const ciphertext = localStorage.getItem('ciphertext');
    const url = `${this.url}/form/hmsa/presignedurl/put/${ciphertext}/${name}`;
    return this.http.get<any>(url);
  }

  getViewSignedUrl(name: string): Observable<any> {
    if (!this.formInfo) {
      console.error('Form info is not set');
      return of({ error: 'Form info is not set' });
    }
    const ciphertext = localStorage.getItem('ciphertext');
    const url = `${this.url}/form/hmsa/presignedurl/get/${ciphertext}/${name}`;
    return this.http.get<any>(url);
  }

  dataURItoBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: mimeString });
  }

  uploadImage(url: string, imageData: string) {
    const blob = this.dataURItoBlob(imageData);
    return fetch(url, {
      method: 'PUT',
      body: blob,
    });
  }

  getFormData(): Observable<any> {
    if (!this.formInfo) {
      console.error('Form info is not set');
      return of({ error: 'Form info is not set' });
    }
    const ciphertext = localStorage.getItem('ciphertext');
    const url = `${this.url}/form/hmsa/${ciphertext}`;
    return this.http.get<any>(url);
  }

  postFormData(formData: any) {
    if (!this.formInfo) {
      console.error('Form info is not set');
      return of({ error: 'Form info is not set' });
    }

    const data = {
      "status": "not_started",
      "form_data": formData
    };
    const ciphertext = localStorage.getItem('ciphertext');
    const url = `${this.url}/form/hmsa/${ciphertext}`;
    return this.http.post(url, data, { observe: 'response' });
  }

  putFormData(formData: any, status: string) {
    if (!this.formInfo) {
      console.error('Form info is not set');
      return of({ error: 'Form info is not set' });
    }

    const data = {
      "status": status,
      "form_data": formData
    };
    const ciphertext = localStorage.getItem('ciphertext');
    const url = `${this.url}/form/hmsa/${ciphertext}`;
    return this.http.put(url, data, { observe: 'response' });
  }

  post(endpoint: string, data: any, is_mocked: boolean = false) {
    return this.http.post(`${this.url}/${endpoint}`, data, { observe: 'response' });
  }

  put(endpoint: string, data: any, is_mocked: boolean = false) {
    return this.http.put(`${this.url}/${endpoint}`, data, { observe: 'response' });
  }

  patch(endpoint: string, data: any, is_mocked: boolean = false) {
    return this.http.patch(`${this.url}/${endpoint}`, data, { observe: 'response' });
  }

  getFormDataWithMock(endpoint: string, is_mocked: boolean = true): Observable<any> {
    if (is_mocked) {
      return of<any>({});
    }
    const url = `${this.url}/${endpoint}`;
    return this.http.get<any>(url);
  }

  getPurl(endpoint: string) {
    const url = `${this.url}/${endpoint}`;
    return this.http.get<any>(url);
  }

  putPurl(purl: string, data: string) {
    return this.http.put<any>(purl, data);
  }
}
