.bottom-title {
  color: var(--primary-color);
  font-size: var(--font-size-m);
  padding: 0;
  font-weight: 700;
  margin-bottom: var(--size-l);
  line-height: 1.5;
}

.radio-group {
  display: flex;
  padding-bottom: var(--size-sl);
  width: 100%;
}
.radio-group .mdc-radio {
  width: var(--size-sl);
  height: var(--size-sl);
}
.radio-group .mdc-radio__background {
  width: var(--size-sl);
  height: var(--size-sl);
}
.radio-group .mdc-form-field > label {
  padding-left: var(--size-m);
  width: 100%;
}
.radio-group .mat-mdc-radio-button.mat-accent,
.radio-group .mdc-form-field {
  width: 100%;
}
.h-main .selector .mdc-label {
  color: var(--primary-color);
  font-size: var(--font-size-s);
  font-weight: 500;
  padding-top: 0;
  width: 100%;
}
.radio-group
  .mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  background-color: var(--white);
  border-color: var(--primary-color);
  border-width: 1px;
}
.radio-group .mdc-radio__inner-circle {
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  background: var(--accent);
  border-width: 0;
  transform: translate(-50%, -50%) scale(0);
}
.radio-group
  .mdc-radio__native-control:checked
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  transform: translate(-50%, -50%) scale(1);
}
.radio-group
  .mat-mdc-radio-button
  .mdc-radio:not(:disabled):active
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: var(--primary-color);
}
.radio-group
  .mat-mdc-radio-button
  .mdc-radio:hover
  .mdc-radio__native-control:not([disabled]):not(:focus)
  ~ .mdc-radio__background::before,
.radio-group .mat-mdc-radio-button .mat-radio-ripple .mat-ripple-element,
.radio-group
  .mat-mdc-radio-button.mat-mdc-radio-checked
  .mdc-radio__background::before {
  display: none;
}
