import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, ReactiveFormsModule, AbstractControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';

@Component({
  selector: 'app-medication',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CustomInputComponent,
    CommonModule,
    MatButtonModule,
  ],
  templateUrl: './medication.component.html',
  styleUrls: ['./medication.component.css'],
})
export class MedicationComponent implements OnInit {
  @Input() parentForm!: FormGroup;
  @Input() controlName: string = 'medications';

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    if (!this.parentForm) {
      throw new Error('Parent form group is required.');
    }
  }

  get medications(): FormArray {
    return this.parentForm.get(this.controlName) as FormArray;
  }

  addMedicationSet(): void {
    const medicationGroup = this.fb.group({
      name: ['', Validators.required],
      dose: [''],
      freq: [''],
    });

    this.medications.push(medicationGroup);
  }

  removeMedicationSet(index: number): void {
    this.medications.removeAt(index);
  }

  getMedicationFormGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }
}
