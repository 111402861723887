<div class="back-selector">
  <svg
    #svgElement
    width="300"
    height="600"
    viewBox="0 0 800.00003 1580.7386"
    version="1.1"
    id="m_back"
    xmlns="http://www.w3.org/2000/svg"
    (click)="onSvgClick($event)"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      height="10"
      d="m 332.49934,80.881025 c -0.62341,43.209045 9.97937,91.426205 42.90017,121.517345 22.56792,13.23961 54.06975,5.43214 65.99754,-18.44732 C 468.69657,136.99937 477.89234,75.668685 454.29353,25.370857 426.08651,-15.229956 345.25261,-6.4044997 337.47688,46.850551 334.5002,57.95775 332.92967,69.400205 332.49934,80.881025 Z"
      id="B_head"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      height="1"
      width="20"
      d="m 345.05776,269.97694 c 8.55144,12.58148 30.11459,8.41156 43.62111,11.95971 21.77827,-0.004 46.02404,0.85794 65.57301,-9.50649 -5.22331,-32.23098 -15.83404,-65.33574 -9.74832,-98.83999 -0.89494,-11.40996 9.44165,-31.26999 -8.86547,-32.71797 -22.44869,-3.93952 -46.15448,-2.1266 -68.81536,-0.79816 -12.0133,0.82685 -17.24649,9.78327 -12.27565,21.07165 5.69088,37.01786 0.85918,73.10508 -9.48884,108.83108 z"
      id="B_neck"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      width="3"
      height="10"
      d="m 184.74644,343.38832 c -3.00271,19.05172 10.2096,44.71812 32.53924,36.87681 34.20381,-14.0732 76.30127,-20.25554 97.20263,-54.52781 12.49111,-18.66667 31.24365,-40.55438 25.89425,-64.55729 -19.25977,-20.73347 -51.55588,-5.3935 -75.42471,-3.22642 -36.98222,4.16982 -70.05688,30.93007 -76.95899,68.2627 -1.84209,5.52962 -1.82418,11.52576 -3.25242,17.17201 z"
      id="B_leftShoulder"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      d="m 103.15733,552.3222 c -1.21686,24.31286 32.9771,24.10862 44.91675,9.27675 51.29927,-53.82536 82.85423,-122.80027 117.96176,-187.48968 13.9618,-21.80606 3.28551,-52.30294 -22.32705,-58.63072 -28.67093,-12.06587 -48.23814,19.86073 -58.86218,41.50748 -29.6135,55.85032 -59.15063,112.04695 -75.99341,173.34003 -2.19817,7.23056 -5.15251,14.37662 -5.69587,21.99614 z"
      id="B_leftUpperArm"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      width="20"
      height="40"
      d="m 19.740589,729.08507 c 1.29012,16.84979 32.78345,25.86617 35.68246,4.67616 23.56328,-54.61695 73.417091,-91.84606 103.462091,-142.08389 9.92268,-22.58038 22.72325,-48.74726 15.51884,-73.63218 -16.23881,-24.83161 -59.08921,-28.1926 -74.33654,-0.0158 -41.800451,61.16092 -56.075521,135.59402 -79.743921,204.6494 -0.28747,2.11315 -0.46329,4.2728 -0.58327,6.40647 z"
      id="B_leftLowerArm"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      height="-20"
      d="m 104.9616,559.48795 c 4.53319,23.19107 38.54273,19.79941 42.6442,-2.03481 12.83054,-18.9008 -14.08396,-44.37918 -30.80773,-27.65851 -8.6658,6.66899 -13.18646,18.91751 -11.83647,29.69332 z"
      id="B_leftElbow"
    />
    <path
      id="B_leftHand"
      class="svgUnselected svgUnselectedback"
      stroke="#000000"
      stroke-width="3"
      d="m 28.410839,777.88734 c 3.0387,4.7382 0.752,18.09272 5.79059,19.59582 -0.70699,11.7257 7.85544,28.49788 20.31199,14.52737 6.92387,-9.4827 -8.02458,-19.53285 -4.86056,-30.52842 6.47071,-22.08369 -24.02975,-20.7558 -21.24202,-3.59477 z m -17.14541,25.30928 c -0.0848,6.81342 5.34997,16.6863 2.30748,21.66496 3.88257,2.47461 2.24092,18.67872 8.52015,15.19712 2.26088,12.90965 15.06526,20.57814 21.17262,5.42344 -6.03361,-8.59212 -13.03746,-14.27924 -15.01084,-25.264 3.34443,-3.99523 -1.3871,-14.92015 -2.59286,-20.94363 -4.79884,1.30581 -9.59771,2.61485 -14.39655,3.92211 z m -7.5790299,5.58495 c 3.68738,5.6419 -0.97529,20.73646 5.87417,22.80134 -8.50521,1.36276 3.8902499,18.99347 5.6220499,21.24809 8.00682,-3.45875 11.41725,-4.55318 1.48637,1.19117 6.3119,11.91482 12.61365,26.05411 24.84403,8.92645 -7.66428,-10.39415 -14.31603,-21.30957 -17.68975,-33.89429 2.9623,-2.41566 0.59285,-21.24047 -3.96921,-23.13559 -5.38903,0.95493 -10.7789199,1.90948 -16.1675599,2.86453 z m -1.44047,24.83418 c 5.04316,5.18557 -0.97668,26.9917 9.9805599,23.29294 -2.4479799,5.40565 5.36043,26.32051 11.95246,23.17275 21.02306,-1.5944 5.66712,-18.37443 1.6591,-28.6671 -6.95772,-15.72006 -1.66468,-29.76567 -4.38948,-46.269 -10.49108,-1.8692 -25.5198199,-3.35816 -18.4459999,12.69573 -1.2962,5.09083 3.67049,11.56518 -0.75624,15.77468 z m 0.81762,-8.38346 c 3.45884,4.76994 -5.57405,22.20349 3.4608,21.32088 -0.60414,12.47349 2.93701,30.66213 19.3849399,18.93619 2.26995,-14.40562 -9.5582,-30.606 -1.7869,-44.95898 11.67941,-13.82757 -2.93311,-29.66715 -14.9528499,-19.23082 -2.94831,7.72371 -1.07016,17.46279 -6.10536,23.93277 z M 0.48902905,796.24866 c -3.85642995,14.65502 16.08127995,26.43376 25.22407995,13.84123 10.46179,-13.54055 20.64563,-28.59304 27.07977,-44.69569 5.66744,-16.72359 5.58514,-46.31402 -18.78857,-45.10619 -20.65464,3.74069 -20.63759,31.21182 -28.1641099,46.78207 -2.52086,9.55681 -4.96628005,19.25734 -5.35056005,29.17864 z"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      width="60"
      height="10"
      d="m 458.18185,268.19603 c 5.09451,33.82058 28.2534,64.46452 54.96307,84.79293 24.46392,10.99517 49.89351,21.62415 75.79843,28.42819 28.01948,0.96246 29.75817,-35.51284 23.05007,-55.19968 -7.06817,-38.7676 -42.33733,-65.24983 -80.42737,-68.97982 -22.90982,-2.22233 -53.71901,-16.0562 -71.95614,3.94364 l -0.66895,2.7939 c 0,0 -0.75225,4.22108 -0.75225,4.22108 z"
      id="B_rightShoulder"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      d="m 527.85725,352.88163 c 10.17028,42.50048 39.31638,77.61579 57.6852,116.85598 22.35486,33.99481 41.5438,73.02094 74.72742,97.78489 14.15681,10.89981 41.72448,1.87283 36.07144,-18.70581 -15.814,-61.55317 -41.9358,-119.68108 -72.58428,-175.20845 -13.64297,-22.25763 -23.9471,-59.21653 -55.01298,-60.57663 -20.28133,1.05209 -43.34975,17.37345 -40.8868,39.85002 z"
      id="B_rightUpperArm"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      width="50"
      height="40"
      d="m 622.99008,532.88075 c 3.7737,44.80446 30.12409,82.58021 60.53124,113.90807 24.79399,29.80814 50.65022,59.70916 65.67266,95.80581 12.27528,11.2511 37.71451,-5.49331 29.93126,-21.96677 -24.37573,-69.73185 -37.97684,-145.86111 -82.32281,-206.59441 -17.18692,-26.22391 -63.66474,-18.44452 -73.34878,10.04836 l -0.14921,3.53832 -0.0894,2.14013 v 7e-5 z"
      id="B_rightLowerArm"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      height="-20"
      d="m 649.9253,541.03195 c -5.14846,22.05438 24.05069,48.52309 41.37503,26.69168 16.31788,-21.84363 -17.60766,-59.85565 -37.64284,-34.92651 -1.24787,2.74491 -2.48815,5.4897 -3.73219,8.23483 z"
      id="B_rightElbow"
    />
    <path
      id="B_rightHand"
      class="svgUnselected svgUnselectedback"
      stroke="#000000"
      stroke-width="3"
      d="m 756.72838,845.48287 c 6.56897,14.20785 16.96972,8.60466 21.24839,-4.07858 l -0.0757,-1.34535 c 6.28399,2.75486 5.7839,-13.11523 8.93155,-16.9828 l -1.48735,-0.68386 c 18.35906,-26.45055 -27.40253,-30.89492 -13.60337,-2.17278 -2.05794,11.06869 -8.86739,16.58112 -15.01348,25.26337 z m 1.75243,17.46482 c 12.16437,16.6794 18.53784,3.04097 24.84347,-8.92645 -16.67049,-11.6966 4.50146,5.40118 4.13652,-4.38756 -0.94978,-4.97874 12.00089,-16.90281 3.0631,-18.03652 5.88038,-6.86455 11.96174,-31.34429 -8.16694,-25.28605 -8.49417,-2.82348 -8.38356,20.69285 -6.18671,22.74274 -3.39852,12.80908 -9.91656,23.32058 -17.68929,33.89373 z m 5.74809,9.97599 c 8.79266,13.58347 21.42924,8.47871 22.24095,-7.48013 8.5226,-2.4741 -4.85706,-12.38718 5.63022,-7.81684 6.38955,-17.19553 3.23506,-33.04147 5.5791,-51.31752 -19.6228,-11.25594 -23.47404,7.59726 -20.61255,23.58377 5.04071,12.98295 -8.93441,30.66258 -12.83772,43.03072 z m 8.31345,-71.42748 c 4.7811,19.96793 9.31613,40.14482 0.33945,60.0321 5.41137,13.95916 25.56462,6.26609 20.43841,-9.89119 3.48287,-1.71166 -3.50961,-7.56742 3.1755,-5.105 3.3778,-16.41397 -4.06217,-28.58034 -5.32446,-44.65043 -1.61716,-8.78246 -13.35821,0.93254 -18.63024,-0.38545 z m -28.69213,1.89893 c -3.1091,16.49624 24.18236,18.38562 22.03157,-1.08673 -1.94293,-7.19515 4.78906,-6.1773 3.55772,-14.86782 6.5691,-13.4569 0.45011,-24.0504 -15.40594,-22.4257 -5.40939,13.1566 -1.04818,26.8589 -10.18315,38.37821 z m 0.20641,-56.93827 c 0.58564,24.49282 16.5818,44.55273 29.99578,63.39393 9.03462,12.77674 29.35488,1.34411 25.45662,-13.60254 -3.37333,-25.10572 -9.16382,-52.88569 -24.97715,-72.9557 -15.66902,-10.95132 -33.42794,6.57133 -30.47929,23.16414 z"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      height="40"
      d="m 290.64052,648.19887 c -2.36564,44.55388 37.92243,77.50559 79.41461,81.90305 48.04958,7.52408 111.95264,-0.68582 133.72932,-50.95451 15.93835,-49.49887 -11.74083,-99.90778 -5.17504,-150.39454 4.28418,-45.74435 -1.24299,-101.3258 -44.73071,-127.39942 -51.06926,-35.69382 -131.74858,-8.32353 -147.32692,52.95578 -13.39933,49.56645 2.34883,102.10487 -11.24142,152.00232 -2.16242,13.92279 -4.23226,27.77414 -4.66984,41.88732 z"
      id="B_lowerBody"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      width="40"
      d="m 249.61633,308.90757 c -3.75775,62.30795 14.30761,125.67966 46.55319,178.62182 20.09782,23.76315 54.21755,31.06811 83.78377,35.70175 41.75112,3.491 88.35762,-3.73324 120.77651,-31.60388 35.00385,-53.17387 52.923,-118.76173 49.35032,-182.53075 0.97998,-37.49648 -32.72411,-59.84231 -61.80231,-75.89322 -36.98251,-26.39802 -86.19218,-45.31593 -130.39141,-25.10655 -33.62723,16.41849 -67.95384,34.69191 -94.87353,61.0625 -8.87598,11.3767 -12.19806,25.64454 -13.39654,39.74833 z"
      id="B_upperBody"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      height="50"
      d="m 246.03825,880.58998 c 1.52204,62.13557 11.29921,123.84872 20.00403,185.24822 2.89686,27.3837 35.09307,57.1093 61.58402,37.4179 26.86926,-28.5068 20.7526,-72.2137 32.29317,-107.42382 21.70071,-71.75411 28.60495,-146.60236 28.46282,-221.29513 1.24485,-40.06058 -0.37278,-88.47336 -32.44704,-117.09089 -28.56556,-23.29088 -74.16822,-5.62796 -84.00094,28.84241 -19.08352,62.27961 -23.73231,129.44368 -25.89606,194.30131 z"
      id="B_leftUpperLeg"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      d="m 200.06107,1558.2891 c 0.78707,22.7325 31.39414,17.2394 46.44796,21.2222 11.18309,2.0865 25.41967,2.3115 32.13564,-8.0969 14.3271,-0.1617 33.20585,-5.2036 31.04302,-23.6205 -8.59383,-40.9148 0.76967,-86.9018 -22.85636,-123.6007 -8.2907,-15.9581 -23.99231,-0.9077 -25.94684,10.953 -15.32765,33.6659 -14.46116,77.8333 -47.86783,100.6713 -5.9198,6.2456 -12.83064,13.2753 -12.95542,22.4748 z"
      id="B_leftFoot"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      d="m 240.88988,1221.7665 c 8.04948,86.8723 7.01784,174.1799 8.46975,261.2347 -1.2848,21.9221 28.95249,46.9432 46.17336,26.3376 10.55417,-32.6749 3.72645,-68.8657 10.87994,-102.5857 10.19022,-89.7343 53.69849,-176.9518 37.07607,-268.64 -7.56871,-32.7016 -0.38322,-70.2476 -19.37779,-99.394 -16.83786,-23.7469 -51.96376,-7.9246 -56.52795,17.459 -15.79091,53.4277 -28.54683,109.5449 -26.69338,165.5884 z"
      id="B_leftLowerLeg"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      height="50"
      d="m 410.90325,747.33243 c 1.03823,81.27842 2.95007,163.65002 27.7464,241.8679 10.93465,35.50157 7.94926,75.89547 27.85224,108.16117 19.49508,25.9355 57.99739,6.087 64.18025,-20.8544 12.22663,-46.586 13.02221,-95.61561 20.39494,-143.18037 7.32838,-78.23018 -1.68174,-157.57558 -18.62944,-233.97724 -6.07034,-31.5491 -35.90439,-60.53748 -69.75455,-50.86071 -42.0977,10.58915 -48.20754,62.88282 -51.78984,98.84365 z"
      id="B_rightUpperLeg"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      d="m 490.30675,1547.7731 c -1.76288,15.8273 13.42967,23.5604 27.08788,23.0515 8.38656,-0.6465 10.50456,11.7924 20.1201,9.6699 19.45895,-2.2481 42.01374,-0.117 58.86201,-11.2241 12.98334,-21.5597 -15.67263,-36.7582 -27.13671,-50.9257 -21.10878,-30.3432 -16.23453,-73.0954 -41.81047,-100.603 -19.60056,-2.0839 -23.40387,26.6651 -28.06745,40.729 -3.77096,29.7275 -4.55119,59.6787 -9.05551,89.3069 z"
      id="B_rightFoot"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      d="m 452.97172,1203.0489 c 11.7058,92.6499 47.20868,181.5928 46.21603,275.9996 -2.48231,16.6005 4.85434,44.0813 26.92731,37.1707 34.38522,-12.3017 23.9278,-56.7125 25.39791,-84.7773 -2.29858,-78.4505 5.61577,-156.6227 7.75319,-234.8995 -4.31874,-51.2612 -12.48709,-103.4555 -31.88227,-151.2809 -10.25307,-24.0867 -46.5182,-23.8437 -56.27994,0.6269 -12.89603,36.2861 -10.11441,76.3088 -17.13135,114.0231 -1.39509,14.3853 -1.54977,28.7018 -1.00088,43.1374 z"
      id="B_rightLowerLeg"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected svgUnselectedback"
      d="m 273.26044,703.40746 c 2.09785,27.86332 25.56923,47.58649 29.90851,75.05647 9.07417,34.98922 52.56697,38.67936 82.46758,34.99379 33.75117,-1.90311 74.64368,9.5833 101.93473,-17.00337 13.89394,-21.87017 16.68261,-49.0787 32.7893,-70.01435 12.73932,-32.86685 4.10764,-72.95687 -16.22807,-101.09206 -26.5992,-27.16849 -67.81913,-32.60795 -104.04712,-35.23363 -36.30625,2.31849 -77.48412,8.00104 -104.13824,35.14142 -17.21631,21.56376 -22.49326,51.22746 -22.68669,78.15173 z"
      id="B_hips"
    />
  </svg>
</div>
