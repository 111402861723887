import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";



@Injectable({ providedIn: 'root' })
export class DirectorService {

  url: string = `${environment.API_URL}`

  constructor(private http: HttpClient) {
  }

  get(endpoint: string): Observable<any> {

    const url = `${this.url}/${endpoint}`;
    return this.http.get<any>(url);
  } //end login





} // end of class
