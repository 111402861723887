import {
  Component, forwardRef, Inject, OnDestroy, OnInit, ChangeDetectorRef
} from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, FormBuilder, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators, ReactiveFormsModule
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { MatRadioModule } from '@angular/material/radio';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NgForOf, CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatLine } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';

interface BottomSheetRadioInterface {
  selected_value: string;
}

@Component({
  selector: 'app-bottom-sheet-radio-form',
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    MatLine,
    MatRadioModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BottomSheetRadioFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BottomSheetRadioFormComponent),
      multi: true,
    },
  ],
  templateUrl: './bottom-sheet-radio-form.component.html',
  styleUrls: ['./bottom-sheet-radio-form.component.css']
})
export class BottomSheetRadioFormComponent implements ControlValueAccessor, Validator, OnDestroy, OnInit {
  Items: any[] = [];
  myForm = this.fb.group({
    selected_value: ['', [Validators.required]]
  });

  destroySubject = new Subject<void>();

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetRadioFormComponent>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) { }

  ngOnInit() {
    this.Items = this.data.items;

    // Set the initial form control value to the passed value key
    this.myForm.controls.selected_value.setValue(this.data.value);

    // Force change detection to ensure the template updates
    this.cdr.detectChanges();
  }


  registerOnChange(fn: any): void {
    this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.myForm.disable() : this.myForm.enable();
  }

  writeValue(data: BottomSheetRadioInterface): void {
    this.myForm.patchValue(data, { emitEvent: false });
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.myForm.valid ? null : { intro: true };
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onRadioSelect(selectedValue: string): void {
    this._bottomSheetRef.dismiss(selectedValue);
  }
}
