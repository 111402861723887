import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-body-right',
  standalone: true,
  imports: [],
  templateUrl: './body-right.component.html',
  styleUrl: './body-right.component.css'
})
export class BodyRightComponent {
  @Output() svgClick = new EventEmitter<MouseEvent>();

  onSvgClick(event: MouseEvent) {
    this.svgClick.emit(event);
  }
}

