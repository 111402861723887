import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomInputComponent } from '../custom-input/custom-input.component';
import { CustomSelectComponent } from '../custom-select/custom-select.component';
import { CustomDatePickerComponent } from '../custom-date-picker/custom-date-picker.component';
import { UploadComponent } from '../upload-test-and-image/upload/upload.component';

@Component({
  selector: 'app-test-and-image',
  templateUrl: './test-and-image.component.html',
  styleUrls: ['./test-and-image.component.css'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, CustomInputComponent, CustomSelectComponent, CustomDatePickerComponent, UploadComponent]
})
export class TestAndImageComponent implements OnInit {
  @Input() parentForm!: FormGroup;
  @Input() controlName: string = 'testAndImage';

  inputItems: any = {
    facility: { is_include: true, placeholder: 'Facility' },
    fileDate: { is_include: true, placeholder: 'Date' }
  };
  radioItems: any = {
    type: { is_include: true, title: 'Type', options: ['MRI', 'X Ray', 'CT Scan', 'EMG/Nerve Conduction'], icon: 'healent-form-input-icon-1' }
  };
  uploadItems: any = {
    fileName: { is_include: true }
  };
  minDate: Date;
  maxDate: Date;

  constructor(private fb: FormBuilder) {
    // Example date range for date picker
    this.minDate = new Date(1900, 0, 1);
    this.maxDate = new Date();
  }

  ngOnInit(): void {
    if (!this.parentForm) {
      throw new Error('Parent form group is required.');
    }

    if (!this.parentForm.get(this.controlName)) {
      this.parentForm.addControl(this.controlName, this.fb.array([]));
    }
  }

  get testAndImageArray(): FormArray {
    return this.parentForm.get(this.controlName) as FormArray;
  }

  addItem() {
    const itemGroup = this.fb.group({
      facility: ['', Validators.required],
      type: ['', Validators.required],
      fileDate: ['', Validators.required],
      fileName: ['', Validators.required]
    });
    this.testAndImageArray.push(itemGroup);
  }

  removeItem(index: number) {
    this.testAndImageArray.removeAt(index);
  }

  getItemFormGroup(control: any): FormGroup {
    return control as FormGroup;
  }
}
