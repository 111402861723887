<p>
  This agreement must be reviewed and signed in order to proceed with narcotic
  and/or non-narcotic treatment with Commonwealth Pain &amp; Spine. Controlled
  substance medications are very useful but have significant potential for
  misuse and are, therefore, closely controlled. This agreement is required to
  comply with the law regarding controlled pharmaceuticals and to prevent any
  misunderstandings about any treatments you receive.
</p>

<p>
  Because a CP&amp;S physician may be prescribing such medications as part of
  your plan of care, you must agree to the following:
</p>

<ol>
  <li>
    <p>
      I understand that the main goal of treatment is to improve my ability to
      function or work. In consideration of this goal and the fact that I am
      being given potent medication to help me reach that goal, I agree to help
      myself by following preventive and better health habits such as:
      exercising regularly, losing weight as directed by a physician, and
      abstaining from the use of tobacco, alcohol, and illicit drugs. I will
      also participate in physical therapy as prescribed.
    </p>
  </li>
  <li>
    <p>
      I agree to submit to a blood, urine or saliva test, if requested by my
      provider, to determine compliance with my program of pain medication and I
      waive privacy rights.
    </p>
  </li>
  <li>
    <p>
      I understand that my first office visit may be a consultation only and no
      pain medication given at that time if further investigation and/or testing
      are deemed necessary.
    </p>
  </li>
  <li>
    <p>
      I understand that I may be called any time to bring all prescribed
      medication for a mandatory pill count within a specified time period
      (usually 24 hours – but typically same day).
    </p>
  </li>
  <li>
    <p>
      I agree that I will use my medications ONLY as prescribed by my doctor. I
      understand that any change to my prescriptions will require an office
      visit. I understand that self-medicating is not tolerated. No refills will
      be made during evenings or weekends. I will call at least 24 hours, but no
      more than 48 hours before my medications run out to arrange for refills.
    </p>
  </li>
  <li>
    <p>
      I will not use any illegal substances, including marijuana, cocaine,
      methamphetamines, etc.
    </p>
  </li>
  <li>
    <p>
      I understand that lost or stolen medication or unfilled prescriptions WILL
      NOT be replaced, and I will safeguard my medication from theft.
    </p>
  </li>
  <li>
    <p>
      I understand that I will follow the guidelines on properly disposing of
      controlled substances that will be explained to me by clinical staff. I
      will not discard, flush, give away or in any way lose control of my
      medications.
    </p>
  </li>
  <li>
    <p>I will not share, sell or trade my medications with anyone.</p>
  </li>
  <li>
    <p>
      I will not alter the form of the medication nor will I take the medication
      in a route other than as prescribed by my provider.
    </p>
  </li>
  <li>
    <p>
      I will not attempt to obtain controlled medications from any other
      provider, nor will I borrow or buy medication from any other person (with
      the exception of certain benzodiazepines which are not prescribed by this
      practice).
    </p>
  </li>
  <li>
    <p>
      In the event of an emergency, if I do obtain controlled substances from
      another provider, I understand I am required to disclose this information
      to CP&amp;S within 48 hours of discharge or emergency service. I
      understand it is my responsibility to make sure CP&amp;S is notified of
      any such treatments and that I am to check with staff before combining any
      pain medication with the prescriptions CP&amp;S provides me.
    </p>
  </li>
  <li>
    <p>
      I will notify CP&amp;S of any change in name, address, or phone number. I
      understand that I must at all times have an updated phone number with my
      provider. I cannot be on dangerous medications, such as opioids, if my
      provider cannot reach me in a reasonable period of time (usually
      considered within 24 hours of initial attempt). I agree to return any
      phone call from CP&amp;S within 24 business hours.
    </p>
  </li>
  <li>
    <p>
      I authorize my provider to investigate fully any possible misuse of my
      pain medication using any city, state, or federal law enforcement agency,
      including this state’s Board of Pharmacy.
    </p>
  </li>
  <li>
    <p>
      I understand that any follow-up appointment may be scheduled with a
      Licensed Nurse Practitioner or Physician Assistant. Additionally, I
      understand that refusing to see one of CP&amp;S providers will likely
      result in my no longer being able to be treated by the practice.
    </p>
  </li>
  <li>
    <p>
      Patient has designated one and only one preferred pharmacy. Once a
      prescription has been filled, all questions regarding that prescription
      should be directed to that pharmacy. Our practice will only fill with this
      pharmacy.
    </p>
  </li>
  <li>
    <p>
      I understand CP&amp;S does not mail narcotic prescriptions under any
      circumstances.
    </p>
  </li>
  <li>
    <p>
      I understand that with any controlled substance that is prescribed to me
      there are inherent risks, namely:
    </p>
    <ul>
      <li>
        Loss of efficacy over time, symptoms of withdrawal if abruptly stopped,
        and addiction;
      </li>
      <li>
        Medication taken in excess (this is different for everyone – ranging
        from the prescribed dose to taking more than prescribed or combining
        with other controlled substances or even alcohol) may result in
        respiratory suppression or failure or death;
      </li>
      <li>
        Sedation, loss of function, impairment may also occur – I agree not to
        drive while under the influence of any prescribed controlled substance;
      </li>
      <li>
        Constipation, allergic reaction, itching, nausea, and dry mouth are also
        common side effects;
      </li>
      <li>
        My immune system may be suppressed, and my hormone levels may decrease
        over time while being on chronic opioids.
      </li>
    </ul>
  </li>
  <li>
    <p>
      I understand that the combination of controlled substances and alcohol are
      contra-indicated; the combination may result in serious harm or even
      death.
    </p>
  </li>
  <li>
    <p>
      I understand that non-professional or inappropriate behavior toward any
      CP&amp;S staff, affiliate or provider will not be tolerated. I agree to be
      respectful to other patients I may encounter in the waiting room, lobby,
      hallways, etc. I understand that I may not loiter in the parking lot of
      any CP&amp;S location.
    </p>
  </li>
  <li>
    <p>
      I understand that CP&amp;S providers utilize tests to determine the best
      option for my care. My unwillingness to complete the tests requested may
      result in being released from further care with CP&amp;S.
    </p>
  </li>
  <li>
    <p>
      I understand that non-compliance with my pain management treatment plan
      may result in providers’ inability to properly treat my symptoms and could
      cause symptoms to worsen or become life-threatening.
    </p>
  </li>
  <li>
    <p>
      I understand that I may be released from this practice for missing
      appointments or canceling/rescheduling appointments with less than 24-hour
      notice.
    </p>
  </li>
  <li>
    <p>
      I agree that the goals of pain management have been explained to me as to
      what is considered appropriate and reasonable and that alternative
      treatment plans, outside of the use of controlled pain medications, have
      been made available to me. I have agreed to proceed with pain management
      after a full explanation of the risks and benefits. I understand if I
      break this agreement, it will result in a change in my treatment plan,
      including the safe discontinuation of my opioid medications when
      applicable or complete termination of the provider/patient relationship.
    </p>
  </li>
</ol>

<p>
  I understand that, if I violate any of the above conditions, my controlled
  substance prescriptions may be immediately terminated. If the violation
  involves obtaining controlled substances from another individual, or providing
  controlled substances to another individual, I may also be reported to my
  other healthcare providers, medical facilities, and law enforcement officials.
</p>

<p>
  I have read this contract and have also been informed regarding psychological
  and physical dependence on controlled substances.
</p>

<p>Please see the front desk if you would like a paper copy of this consent.</p>
