<div>
  <mat-form-field class="no-label" appearance="fill">
    <mat-label *ngIf="!formGroup.get(formName)?.value">{{
      placeholder | translate
    }}</mat-label>
    <input
      matInput
      type="text"
      class="upload-input"
      [value]="formGroup.get(formName)?.value?.fileName || ''"
      readonly
      (click)="openActionSheet()"
      required="required"
    />
    <button
      mat-icon-button
      matSuffix
      *ngIf="formGroup.get(formName)?.value"
      (click)="clearFile()"
    >
      <mat-icon>
        <i class="input-icon healent-close-circle red-front"></i>
      </mat-icon>
    </button>
    <button
      mat-icon-button
      matSuffix
      *ngIf="!formGroup.get(formName)?.value"
      (click)="openActionSheet()"
    >
      <mat-icon>
        <i class="input-icon healent-form-input-icon-9"></i>
      </mat-icon>
    </button>
  </mat-form-field>
</div>
<span *ngIf="sizeError" class="d-block no-m p-t-l-m error-upload">
  {{ errorMessage | translate }}
</span>
