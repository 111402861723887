import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatButton } from "@angular/material/button";
import { MatRadioModule } from "@angular/material/radio";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-bottom-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatRadioModule,
    FormsModule,
    MatButton,
    TranslateModule
  ],
  templateUrl: './bottom-selector.component.html',
  styleUrl: './bottom-selector.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class BottomSelectorComponent {
  selectedItem: any;
  items: any[] = [];

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<BottomSelectorComponent>,
  ) {
    if (data) {
      this.items = data.items || [];
      this.selectedItem = data.selectedItem?.value || '';
    } else {
      console.error('No data passed to BottomSelectorComponent');
    }
  }

  select(lang: Event) {
    const selected = this.items.filter(l => l.value === lang)[0]
    this.bottomSheetRef.dismiss({ ...selected })
  }
}
