<form [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <app-address
      [formGroup]="myForm"
      *ngIf="inputItems['address']?.is_include"
      addressPlaceholder="{{ inputItems['address']['placeholder'] }}"
      unitPlaceholder="{{ inputItems['apartment']['placeholder'] }}"
      cityPlaceholder="{{ inputItems['city']['placeholder'] }}"
      statePlaceholder="{{ inputItems['state']['placeholder'] }}"
      zipPlaceholder="{{ inputItems['postalCode']['placeholder'] }}"
      countryPlaceholder="{{ inputItems['country']['placeholder'] }}"
      [required]="inputItems['address']['required']"
      class="m-t-m m-b-l d-block"
    ></app-address>
    <div *ngIf="checkboxItems['isDifferentAddress']?.is_include">
      <app-custom-checkbox
        [formGroup]="myForm"
        controlName="isDifferentAddress"
        label="{{ checkboxItems['isDifferentAddress']['title'] }}"
      >
      </app-custom-checkbox>
      <div *ngIf="myForm.get('isDifferentAddress')?.value">
        <app-custom-input
          [formGroup]="myForm"
          controlName="streetAddress"
          placeholder="{{ inputItems['streetAddress']['placeholder'] }}"
          [iconClass]="inputItems['streetAddress']?.icon"
        ></app-custom-input>
      </div>
    </div>
    <div
      class="phone-group m-t-l"
      *ngIf="inputItems['mobilePhone']?.is_include"
      [ngClass]="{
        'no-radio-preference': !radioItems['mobilePhonePreference']?.is_include
      }"
    >
      <app-phone
        [formGroup]="myForm"
        [title]="inputItems['mobilePhone']['placeholder']"
        formName="mobilePhone"
      ></app-phone>
      <app-custom-radio-group
        [formGroup]="myForm"
        controlName="mobilePhonePreference"
        label="{{ radioItems['mobilePhonePreference']['title'] }}"
        [options]="radioItems['mobilePhonePreference']['options']"
        *ngIf="radioItems['mobilePhonePreference']?.is_include"
      >
      </app-custom-radio-group>
    </div>
    <div
      class="phone-group"
      *ngIf="inputItems['homePhone']?.is_include"
      [ngClass]="{
        'no-radio-preference': !radioItems['homePhonePreference']?.is_include
      }"
    >
      <app-phone
        [formGroup]="myForm"
        [title]="inputItems['homePhone']['placeholder']"
        formName="homePhone"
        *ngIf="inputItems['homePhone']?.is_include"
      ></app-phone>
      <app-custom-radio-group
        [formGroup]="myForm"
        controlName="homePhonePreference"
        label="{{ radioItems['homePhonePreference']['title'] }}"
        [options]="radioItems['homePhonePreference']['options']"
        *ngIf="radioItems['homePhonePreference']?.is_include"
      >
      </app-custom-radio-group>
    </div>
    <div
      class="phone-group"
      *ngIf="inputItems['workPhone']?.is_include"
      [ngClass]="{
        'no-radio-preference': !radioItems['workPhonePreference']?.is_include
      }"
    >
      <app-phone
        [formGroup]="myForm"
        [title]="inputItems['workPhone']['placeholder']"
        formName="workPhone"
      ></app-phone>
      <app-custom-radio-group
        [formGroup]="myForm"
        controlName="workPhonePreference"
        label="{{ radioItems['workPhonePreference']['title'] }}"
        [options]="radioItems['workPhonePreference']['options']"
        *ngIf="radioItems['workPhonePreference']?.is_include"
      >
      </app-custom-radio-group>
    </div>
    <app-custom-input
      [formGroup]="myForm"
      controlName="email"
      *ngIf="inputItems['email']?.is_include"
      placeholder="{{ inputItems['email']['placeholder'] }}"
      [iconClass]="inputItems['email']?.icon"
      [type]="'email'"
      [readonly]="inputItems['email']?.is_prefilled_disabled"
    ></app-custom-input>
    <app-custom-checkbox
      [formGroup]="myForm"
      controlName="consentToCall"
      *ngIf="checkboxItems['consentToCall']?.is_include"
      label="{{ checkboxItems['consentToCall']['title'] }}"
      required
    >
    </app-custom-checkbox>
    <app-custom-checkbox
      [formGroup]="myForm"
      controlName="consentToText"
      *ngIf="checkboxItems['consentToText']?.is_include"
      label="{{ checkboxItems['consentToText']['title'] }}"
    >
    </app-custom-checkbox>
    <app-custom-select
      [formGroup]="myForm"
      controlName="preferredContactMethod"
      *ngIf="radioItems['preferredContactMethod']?.is_include"
      label="{{ radioItems['preferredContactMethod']['title'] }}"
      [optionsTitle]="radioItems['preferredContactMethod']['optionsTitle']"
      [options]="radioItems['preferredContactMethod']['options']"
      [iconClass]="radioItems['preferredContactMethod']?.icon"
      class="p-t-m d-block"
    >
    </app-custom-select>
  </ng-container>
</form>
