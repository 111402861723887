import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-body-back',
  standalone: true,
  templateUrl: './body-back.component.html',
  styleUrl: './body-back.component.css'
})
export class BodyBackComponent {
  @Output() svgClick = new EventEmitter<MouseEvent>();

  onSvgClick(event: MouseEvent) {
    this.svgClick.emit(event);
  }

}

