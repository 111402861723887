<div class="left-selector">
  <svg
    #svgElement
    width="300"
    height="600"
    viewBox="0 0 1.7460802665149737 11.303058618150343"
    version="1.1"
    id="L_svg197"
    sodipodi:docname="m_left.svg"
    inkscape:version="1.1 (c4e8f9e, 2021-05-24)"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns="http://www.w3.org/2000/svg"
    (click)="onSvgClick($event)"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs id="L_defs201" />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      height="10"
      width="20"
      d="M 1.3960183,1.8350267 C 1.1593567,1.8914819 0.91876715,1.943932 0.68584776,2.0091882 0.60902422,2.0439814 0.68815875,1.833417 0.66005872,1.7923148 0.67206523,1.5576824 0.63940288,1.3260118 0.58961421,1.0972591 0.81609742,1.0636773 1.0438684,1.0337383 1.2681552,0.99047883 1.2686921,1.1522101 1.2589216,1.3373838 1.3311474,1.4957664 1.359024,1.607216 1.3729908,1.7223675 1.3960183,1.8350267 Z"
      id="L_neck"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      width="10"
      d="M 1.6684957,2.2877092 C 1.6499605,2.7370572 1.2743981,3.0539435 1.123642,3.4576125 1.0741351,3.5730182 1.0138415,3.7987409 0.84231125,3.7451865 0.72929317,3.6816798 0.62453175,3.6053219 0.49629775,3.5712223 0.26608153,3.4668695 0.04683487,3.2632543 0.00110099,3.006537 0.00635222,2.5818003 0.28310195,2.2304166 0.52275384,1.9036735 0.6924127,1.7309421 0.83952778,1.4938644 1.0877757,1.4315009 1.3389463,1.4087441 1.4513618,1.688198 1.5580404,1.8668052 c 0.067106,0.13021 0.1105675,0.2735099 0.1104553,0.420904 z"
      id="L_upperBody"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 1.1371992,4.6420573 C 1.1610235,4.9776255 0.81692551,5.1859352 0.52527128,5.2336678 0.37486716,5.2715892 0.146437,5.2427415 0.12436077,5.0519291 -9.3977396e-4,4.6686032 0.11056918,4.2652488 0.06216713,3.8727062 0.05855507,3.57246 0.02867284,3.246974 0.16616562,2.9698908 0.33112373,2.6998271 0.78855819,2.6873215 0.97615342,2.9362922 1.1211968,3.1836742 0.99687989,3.4817985 1.0095786,3.7499129 0.98852031,4.0563939 1.09653,4.343584 1.1371992,4.6420573 Z"
      id="L_lowerBody"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 1.4351063,0.74509227 C 1.3374234,1.1011924 0.95477585,1.2464026 0.6518802,1.3873515 0.51784505,1.4202217 0.3779893,1.5120718 0.23849661,1.4815849 0.11517481,1.3978547 0.12523717,1.2157915 0.09812936,1.0837871 0.06537958,0.73104995 0.11437948,0.28365111 0.46084016,0.0966258 0.77544081,-0.08702544 1.2923964,-0.01871187 1.4206884,0.36322108 c 0.042808,0.12199007 0.045311,0.25677165 0.014418,0.38187119 z"
      id="L_head"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      height="50"
      d="M 1.2582278,5.8716976 C 1.2647764,6.4612849 1.1951524,7.0533261 1.0639133,7.6272216 1.0076305,7.7877479 0.8379898,8.0025681 0.64713087,7.9124671 0.40916388,7.7300699 0.41120562,7.3923909 0.32622979,7.1289485 0.12272778,6.4526458 0.07812645,5.7336835 0.17757536,5.0357172 0.19384172,4.8306774 0.34114462,4.6000968 0.57188884,4.624757 c 0.3545583,0.016816 0.55451276,0.3787592 0.61876806,0.6896128 0.042704,0.1827982 0.061391,0.3699923 0.067571,0.5573278 z"
      id="L_leftUpperLeg"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 1.5125989,8.5695217 C 1.4775365,9.0350207 1.3845152,9.4956833 1.3784858,9.9641709 1.3457183,10.199532 1.4330772,10.479951 1.2763242,10.681344 1.2028588,10.759927 1.0764547,10.920112 0.96096145,10.817855 0.84879727,10.613647 0.93460771,10.364541 0.91880247,10.142675 0.90384014,9.5005492 0.76170975,8.8699517 0.61029778,8.2483701 0.57080037,7.9863843 0.48720059,7.6771607 0.64796476,7.4392517 0.76064782,7.2808994 1.0064753,7.3342615 1.0830359,7.5005871 c 0.1565275,0.301396 0.3332462,0.6011477 0.4183316,0.9321097 0.00374,0.045608 0.00749,0.091217 0.011231,0.1368249 z"
      id="L_leftLowerLeg"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 1.380934,5.4257763 C 1.400549,5.6557454 1.2092526,5.8704151 0.97472472,5.8408931 0.75903846,5.8184928 0.54313744,5.7377051 0.32577702,5.806731 0.19382775,5.8533356 0.01364288,5.7928671 0.01021987,5.6330941 -0.01314121,5.3448551 0.0973169,5.0648792 0.09405221,4.7759672 0.10633809,4.6149026 0.06486494,4.4067584 0.23287685,4.3092195 0.46363139,4.1494024 0.78927027,4.2361476 0.96169205,4.4428093 1.2335658,4.685577 1.3434184,5.0595212 1.38033,5.4105568 Z"
      id="L_abdomen"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="m 1.447796,11.060158 c 0.019501,0.160276 -0.1577799,0.210776 -0.2821417,0.178608 -0.3328149,0.0067 -0.66871778,0.09964 -1.00047468,0.0464 -0.12191622,-0.01877 -0.13040824,-0.190325 -0.0214709,-0.235304 0.23173603,-0.144506 0.57211668,-0.157834 0.69391715,-0.440975 0.0912018,-0.154956 0.12834801,-0.351545 0.27081536,-0.468727 0.1788313,-0.03855 0.2434356,0.190313 0.2460522,0.328703 0.00245,0.202953 0.054401,0.395026 0.093303,0.591298 z"
      id="L_leftFoot"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 0.72658885,7.770751 C 0.73382292,7.8713496 0.72624868,8.0378732 0.59453663,8.0507919 0.42794818,7.9977007 0.39222375,7.7823731 0.39827154,7.630381 0.39810399,7.5194138 0.54709636,7.3975142 0.63061599,7.5098247 c 0.0660281,0.069497 0.0815925,0.1702794 0.0959729,0.2609263 z"
      id="L_leftKnee"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 1.7154157,3.2157372 C 1.6854549,3.4616603 1.7497422,3.7255468 1.654446,3.959199 1.5599727,4.1231382 1.3202874,4.0598726 1.247047,3.9097674 1.1382496,3.6833178 1.0327973,3.4505238 0.96005221,3.2092889 0.90573584,2.9254169 0.94884491,2.6122452 1.0943199,2.3615687 1.2009485,2.1844934 1.509776,2.1565732 1.5913945,2.3712178 1.6951161,2.6381901 1.7262327,2.9309372 1.7154157,3.2157372 Z"
      id="L_leftUpperArm"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      height="20"
      d="M 1.6132698,2.1459589 C 1.6339578,2.3762597 1.4337838,2.5531645 1.2536016,2.6601897 1.1018642,2.7475228 0.90633552,2.7025502 0.81160191,2.5537445 0.58256365,2.3734753 0.68790531,1.9898405 0.92214226,1.8648986 1.1021146,1.8005548 1.3514187,1.715854 1.5063674,1.8706819 c 0.06983,0.072879 0.092091,0.1789945 0.1069024,0.275277 z"
      id="L_leftShoulder"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      height="-10"
      d="M 1.7460803,3.8564432 C 1.7596575,3.9938825 1.5922398,4.1636878 1.4663826,4.0504786 1.3464222,3.9188058 1.5144646,3.6394051 1.6901882,3.7377756 c 0.043715,0.026545 0.050912,0.072178 0.055892,0.1186676 z"
      id="L_leftElbow"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 1.6501643,3.8568919 C 1.6640144,4.3367177 1.3281802,4.7204736 1.1930388,5.1610359 1.173012,5.304702 1.0034729,5.3219059 0.90125172,5.2601674 0.84065413,5.1489689 0.92418721,5.008438 0.92387243,4.8897601 0.99988488,4.5403479 0.94884756,4.1583555 1.1377632,3.8394057 1.1798868,3.67724 1.3420983,3.4666801 1.5298082,3.5484632 1.6386417,3.6070415 1.6503232,3.747044 1.6501643,3.8568919 Z"
      id="L_leftLowerArm"
    />
    <path
      id="L_leftHand"
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 0.98046875,5.1054688 C 0.69005697,5.1904741 0.61594891,5.5110871 0.48632824,5.7461013 0.64791713,5.9339899 0.76237351,5.3601113 0.70313021,5.7574031 0.70450506,5.8833225 0.57625879,6.0911004 0.67675767,6.1523445 0.80202938,6.1694669 0.86157445,5.4768434 0.8396451,5.9559027 0.78034577,6.1203584 0.88563122,6.4490322 0.945445,6.0994286 0.94930846,5.8938874 1.0274387,5.6275095 1.0175696,5.979659 0.98072272,6.2074506 1.2005556,6.2720984 1.14069,5.9609406 1.1488911,5.4596331 1.1698422,6.29633 1.3115367,6.0064346 1.2143475,5.7189703 1.3852736,5.1708846 0.98046089,5.1054662 Z"
    />
  </svg>
</div>
