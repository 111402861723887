<form class="m-t-m" [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <app-test-and-image
      [parentForm]="myForm"
      controlName="testAndImage"
    ></app-test-and-image>
    <div class="title-with-icon m-t-l">
      <label
        ><i class="healent-form-input-icon-39"></i>Pain treatment history</label
      >
    </div>
    <div *ngIf="checkboxItems['chiropractic_Therapy']?.is_include">
      <app-custom-checkbox
        [formGroup]="myForm"
        controlName="chiropractic_Therapy"
        label="{{ checkboxItems['chiropractic_Therapy']['title'] }}"
      >
      </app-custom-checkbox>
      <div *ngIf="myForm.get('chiropractic_Therapy')?.value">
        <app-custom-input
          [formGroup]="myForm"
          controlName="chiropractic_Therapy_Facility"
          placeholder="{{
            inputItems['chiropractic_Therapy_Facility']['placeholder']
          }}"
          [iconClass]="inputItems['chiropractic_Therapy_Facility']?.icon"
        ></app-custom-input>
        <app-custom-date-picker
          [formGroup]="myForm"
          controlName="chiropractic_Therapy_date"
          label="{{ inputItems['chiropractic_Therapy_date']['placeholder'] }}"
          [minDate]="minDate"
          [maxDate]="maxDate"
        >
        </app-custom-date-picker>
      </div>
    </div>

    <div *ngIf="checkboxItems['physical_Therapy']?.is_include">
      <app-custom-checkbox
        [formGroup]="myForm"
        controlName="physical_Therapy"
        label="{{ checkboxItems['physical_Therapy']['title'] }}"
      >
      </app-custom-checkbox>
      <div *ngIf="myForm.get('physical_Therapy')?.value">
        <app-custom-input
          [formGroup]="myForm"
          controlName="physical_Therapy_Facility"
          placeholder="{{
            inputItems['physical_Therapy_Facility']['placeholder']
          }}"
          [iconClass]="inputItems['physical_Therapy_Facility']?.icon"
        ></app-custom-input>
        <app-custom-date-picker
          [formGroup]="myForm"
          controlName="physical_Therapy_date"
          label="{{ inputItems['physical_Therapy_date']['placeholder'] }}"
          [minDate]="minDate"
          [maxDate]="maxDate"
        >
        </app-custom-date-picker>
      </div>
    </div>

    <div *ngIf="checkboxItems['epidural_Steroid_Injection']?.is_include">
      <app-custom-checkbox
        [formGroup]="myForm"
        controlName="epidural_Steroid_Injection"
        label="{{ checkboxItems['epidural_Steroid_Injection']['title'] }}"
      >
      </app-custom-checkbox>
      <div *ngIf="myForm.get('epidural_Steroid_Injection')?.value">
        <app-custom-input
          [formGroup]="myForm"
          controlName="epidural_Steroid_Injection_Facility"
          placeholder="{{
            inputItems['epidural_Steroid_Injection_Facility']['placeholder']
          }}"
          [iconClass]="inputItems['epidural_Steroid_Injection_Facility']?.icon"
        ></app-custom-input>
        <app-custom-date-picker
          [formGroup]="myForm"
          controlName="epidural_Steroid_Injection_date"
          label="{{
            inputItems['epidural_Steroid_Injection_date']['placeholder']
          }}"
          [minDate]="minDate"
          [maxDate]="maxDate"
        >
        </app-custom-date-picker>
      </div>
    </div>

    <div *ngIf="checkboxItems['facet_Joint_Steroid_Injection']?.is_include">
      <app-custom-checkbox
        [formGroup]="myForm"
        controlName="facet_Joint_Steroid_Injection"
        label="{{ checkboxItems['facet_Joint_Steroid_Injection']['title'] }}"
      >
      </app-custom-checkbox>
      <div *ngIf="myForm.get('facet_Joint_Steroid_Injection')?.value">
        <app-custom-input
          [formGroup]="myForm"
          controlName="facet_Joint_Steroid_Injection_Facility"
          placeholder="{{
            inputItems['facet_Joint_Steroid_Injection_Facility']['placeholder']
          }}"
          [iconClass]="
            inputItems['facet_Joint_Steroid_Injection_Facility']?.icon
          "
        ></app-custom-input>
        <app-custom-date-picker
          [formGroup]="myForm"
          controlName="facet_Joint_Steroid_Injection_date"
          label="{{
            inputItems['facet_Joint_Steroid_Injection_date']['placeholder']
          }}"
          [minDate]="minDate"
          [maxDate]="maxDate"
        >
        </app-custom-date-picker>
      </div>
    </div>

    <div *ngIf="checkboxItems['joint_Injection']?.is_include">
      <app-custom-checkbox
        [formGroup]="myForm"
        controlName="joint_Injection"
        label="{{ checkboxItems['joint_Injection']['title'] }}"
      >
      </app-custom-checkbox>
      <div *ngIf="myForm.get('joint_Injection')?.value">
        <app-custom-input
          [formGroup]="myForm"
          controlName="joint_Injection_Facility"
          placeholder="{{
            inputItems['joint_Injection_Facility']['placeholder']
          }}"
          [iconClass]="inputItems['joint_Injection_Facility']?.icon"
        ></app-custom-input>
        <app-custom-date-picker
          [formGroup]="myForm"
          controlName="joint_Injection_date"
          label="{{ inputItems['joint_Injection_date']['placeholder'] }}"
          [minDate]="minDate"
          [maxDate]="maxDate"
        >
        </app-custom-date-picker>
      </div>
    </div>

    <div *ngIf="checkboxItems['spinal_Cord_Stimulator']?.is_include">
      <app-custom-checkbox
        [formGroup]="myForm"
        controlName="spinal_Cord_Stimulator"
        label="{{ checkboxItems['spinal_Cord_Stimulator']['title'] }}"
      >
      </app-custom-checkbox>
      <div *ngIf="myForm.get('spinal_Cord_Stimulator')?.value">
        <app-custom-input
          [formGroup]="myForm"
          controlName="spinal_Cord_Stimulator_Facility"
          placeholder="{{
            inputItems['spinal_Cord_Stimulator_Facility']['placeholder']
          }}"
          [iconClass]="inputItems['spinal_Cord_Stimulator_Facility']?.icon"
        ></app-custom-input>
        <app-custom-date-picker
          [formGroup]="myForm"
          controlName="spinal_Cord_Stimulator_date"
          label="{{ inputItems['spinal_Cord_Stimulator_date']['placeholder'] }}"
          [minDate]="minDate"
          [maxDate]="maxDate"
        >
        </app-custom-date-picker>
      </div>
    </div>

    <app-custom-radio-group
      [formGroup]="myForm"
      controlName="haveYouEverHadGeneticTestingDone"
      label="{{ radioItems['haveYouEverHadGeneticTestingDone']['title'] }}"
      [options]="radioItems['haveYouEverHadGeneticTestingDone']['options']"
      *ngIf="radioItems['haveYouEverHadGeneticTestingDone']?.is_include"
      class="flex-row-reverse"
    >
    </app-custom-radio-group>
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="haveYouHadAnyOfTheFollowingToAssistInTheEvaluationOfYourPain"
      *ngIf="
        checkboxesItems[
          'haveYouHadAnyOfTheFollowingToAssistInTheEvaluationOfYourPain'
        ]?.is_include
      "
      label="{{
        checkboxesItems[
          'haveYouHadAnyOfTheFollowingToAssistInTheEvaluationOfYourPain'
        ]['title']
      }}"
      [options]="
        checkboxesItems[
          'haveYouHadAnyOfTheFollowingToAssistInTheEvaluationOfYourPain'
        ]['options']
      "
    >
    </app-custom-checkbox-group>

    <app-custom-checkbox
      [formGroup]="myForm"
      controlName="iHadInjectionsToAssistWithTheTreatmentOfMyPain"
      label="{{
        checkboxItems['iHadInjectionsToAssistWithTheTreatmentOfMyPain']['title']
      }}"
      *ngIf="
        checkboxItems['iHadInjectionsToAssistWithTheTreatmentOfMyPain']
          ?.is_include
      "
    >
    </app-custom-checkbox>
    <div
      *ngIf="
        myForm.get('iHadInjectionsToAssistWithTheTreatmentOfMyPain')?.value
      "
    >
      <app-custom-input
        [formGroup]="myForm"
        *ngIf="inputItems['typeOfInjection']['is_include']"
        controlName="typeOfInjection"
        [placeholder]="inputItems['typeOfInjection']?.placeholder"
        [iconClass]="inputItems['typeOfInjection']?.icon"
      >
      </app-custom-input>
    </div>
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="haveYouHadAnyOfTheFollowingRelatedToYourPain"
      *ngIf="
        checkboxesItems['haveYouHadAnyOfTheFollowingRelatedToYourPain']
          ?.is_include
      "
      label="{{
        checkboxesItems['haveYouHadAnyOfTheFollowingRelatedToYourPain']['title']
      }}"
      [options]="
        checkboxesItems['haveYouHadAnyOfTheFollowingRelatedToYourPain'][
          'options'
        ]
      "
      class="m-t-l d-block"
    >
    </app-custom-checkbox-group>
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="haveYouHadAnyOfTheFollowingSurgeries"
      *ngIf="
        checkboxesItems['haveYouHadAnyOfTheFollowingSurgeries']?.is_include
      "
      label="{{
        checkboxesItems['haveYouHadAnyOfTheFollowingSurgeries']['title']
      }}"
      [options]="
        checkboxesItems['haveYouHadAnyOfTheFollowingSurgeries']['options']
      "
    >
    </app-custom-checkbox-group>
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="haveYouHadAnyOfTheFollowingTherapies"
      *ngIf="
        checkboxesItems['haveYouHadAnyOfTheFollowingTherapies']?.is_include
      "
      label="{{
        checkboxesItems['haveYouHadAnyOfTheFollowingTherapies']['title']
      }}"
      [options]="
        checkboxesItems['haveYouHadAnyOfTheFollowingTherapies']['options']
      "
    >
    </app-custom-checkbox-group>
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="haveYouHadOrDoneAnyOfTheFollowingToAssistWithTheTreatmentOfYourPain"
      *ngIf="
        checkboxesItems[
          'haveYouHadOrDoneAnyOfTheFollowingToAssistWithTheTreatmentOfYourPain'
        ]?.is_include
      "
      label="{{
        checkboxesItems[
          'haveYouHadOrDoneAnyOfTheFollowingToAssistWithTheTreatmentOfYourPain'
        ]['title']
      }}"
      [options]="
        checkboxesItems[
          'haveYouHadOrDoneAnyOfTheFollowingToAssistWithTheTreatmentOfYourPain'
        ]['options']
      "
    >
    </app-custom-checkbox-group>
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="selectAllAntiInflammatoryMedicationsBelowThatHaveBeenHelpful"
      *ngIf="
        checkboxesItems[
          'selectAllAntiInflammatoryMedicationsBelowThatHaveBeenHelpful'
        ]?.is_include
      "
      label="{{
        checkboxesItems[
          'selectAllAntiInflammatoryMedicationsBelowThatHaveBeenHelpful'
        ]['title']
      }}"
      [options]="
        checkboxesItems[
          'selectAllAntiInflammatoryMedicationsBelowThatHaveBeenHelpful'
        ]['options']
      "
    >
    </app-custom-checkbox-group>
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="selectAllMuscleRelaxerMedicationsBelowThatHaveBeenHelpful"
      *ngIf="
        checkboxesItems[
          'selectAllMuscleRelaxerMedicationsBelowThatHaveBeenHelpful'
        ]?.is_include
      "
      label="{{
        checkboxesItems[
          'selectAllMuscleRelaxerMedicationsBelowThatHaveBeenHelpful'
        ]['title']
      }}"
      [options]="
        checkboxesItems[
          'selectAllMuscleRelaxerMedicationsBelowThatHaveBeenHelpful'
        ]['options']
      "
    >
    </app-custom-checkbox-group>
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="selectAllNarcoticMedicationsBelowThatHaveBeenHelpful"
      *ngIf="
        checkboxesItems['selectAllNarcoticMedicationsBelowThatHaveBeenHelpful']
          ?.is_include
      "
      label="{{
        checkboxesItems['selectAllNarcoticMedicationsBelowThatHaveBeenHelpful'][
          'title'
        ]
      }}"
      [options]="
        checkboxesItems['selectAllNarcoticMedicationsBelowThatHaveBeenHelpful'][
          'options'
        ]
      "
    >
    </app-custom-checkbox-group>
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="selectAnyOfTheOtherMedicationsBelowThatHaveBeenHelpful"
      *ngIf="
        checkboxesItems[
          'selectAnyOfTheOtherMedicationsBelowThatHaveBeenHelpful'
        ]?.is_include
      "
      label="{{
        checkboxesItems[
          'selectAnyOfTheOtherMedicationsBelowThatHaveBeenHelpful'
        ]['title']
      }}"
      [options]="
        checkboxesItems[
          'selectAnyOfTheOtherMedicationsBelowThatHaveBeenHelpful'
        ]['options']
      "
    >
    </app-custom-checkbox-group>
    <app-custom-radio-group
      [formGroup]="myForm"
      controlName="doYouHaveAnyStreetDrugUse"
      label="{{ radioItems['doYouHaveAnyStreetDrugUse']['title'] }}"
      [options]="radioItems['doYouHaveAnyStreetDrugUse']['options']"
      *ngIf="radioItems['doYouHaveAnyStreetDrugUse']?.is_include"
      class="flex-row-reverse"
    >
    </app-custom-radio-group>
    <div
      class="m-t-m d-block"
      *ngIf="myForm.get('doYouHaveAnyStreetDrugUse')?.value === 'yes'"
    >
      <app-custom-textarea
        [formGroup]="myForm"
        controlName="explain"
        label="{{ inputItems['explain']['placeholder'] }}"
        hint="{{ inputItems['explain']['hint'] }}"
        *ngIf="inputItems['explain']?.is_include"
      >
      </app-custom-textarea>
    </div>
  </ng-container>
</form>
