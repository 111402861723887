<div class="verification-body w-100" [ngClass]="{ hide: isHidden }">
  <div class="verification-info">
    <h1>{{ formSMSVerification.title }}</h1>
    <p>{{ formSMSVerification.description }}</p>
    <form [formGroup]="verificationForm" novalidate class="m-t-l">
      <div formArrayName="verificationCodeArray" class="code-input-container">
        <input
          id="verification_code"
          *ngFor="
            let control of verificationCodeControls.controls;
            let i = index
          "
          #firstInput
          type="tel"
          maxlength="1"
          inputmode="numeric"
          pattern="[0-9]*"
          [formControlName]="i"
          (input)="onInput($event, i)"
          (keydown)="onKeyDown($event, i)"
          (keypress)="validateInput($event)"
          (focus)="onFocus($event)"
          [class.has-value]="control.value"
          [class.invalid-code]="
            control.invalid && (control.dirty || control.touched)
          "
          class="code-input"
          autocomplete="off"
        />
      </div>
      <div class="text-center">
        <a class="resend-text m-t-s" (click)="resendCode()">{{
          formSMSVerification.resend
        }}</a>
      </div>
    </form>
  </div>
</div>
