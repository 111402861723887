.error-main {
  height: 100vh;
  padding: 0 30px 30px 30px;
}
.error-icon {
  font-size: 180px;
  color: var(--red);
  line-height: 1;
}
.error-body,
.error-title {
  text-align: center;
}
.error-title {
  font-size: var(--font-size-l);
  font-weight: bold;
  line-height: 1;
  color: var(--primary-color);
  margin: 0;
  padding: 0;
}
.error-body {
  font-size: var(--font-size-s);
  font-weight: 500;
  line-height: var(--line-height-l);
  color: var(--primary-color);
  margin: 0;
  padding: 0;
  padding-top: var(--size-m);
}
.error-main .btn {
  width: 100%;
  margin-top: var(--size-l);
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: var(--font-size-s);
  letter-spacing: var(--mdc-text-button-label-text-tracking);
  font-weight: bold;
  text-transform: var(--mdc-text-button-label-text-transform);
  height: var(--size-xml);
  border-radius: 5px;
  background: var(--primary-color);
  color: var(--white);
  border: 0;
  cursor: pointer;
  margin-left: 16px;
  margin-right: 16px;
  max-width: 360px;
}
@media only screen and (max-width: 600px) {
  .error-icon {
    font-size: 90px;
  }
  .error-title {
    font-size: var(--font-size-m);
  }
}
