import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, FormBuilder, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';

interface CheckboxOptions {
  [key: string]: string;
}

// Custom validator to check if at least one checkbox is checked
export function atLeastOneCheckedValidator(controlName: string, required: boolean): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    if (!required) return null; // Skip validation if not required

    const control = group.get(controlName) as FormGroup;
    if (!control) return null;

    const isAtLeastOneChecked = Object.values(control.value).some(value => value === true);
    return isAtLeastOneChecked ? null : { atLeastOneChecked: true };
  };
}

@Component({
  selector: 'app-custom-checkbox-group',
  standalone: true,
  imports: [ReactiveFormsModule, MatCheckboxModule, CommonModule],
  templateUrl: './custom-checkbox-group.component.html',
  styleUrls: ['./custom-checkbox-group.component.css']
})
export class CustomCheckboxGroupComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() options!: CheckboxOptions;
  @Input() label!: string;
  @Input() icon!: string;
  @Input() subLabel!: string;
  @Input() subLabelClass!: string;
  @Input() required: boolean = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    // Create controls for each checkbox option
    const controls = Object.keys(this.options).reduce((acc, key) => {
      acc[key] = this.fb.control(false);
      return acc;
    }, {} as { [key: string]: FormControl });

    // Create a FormGroup for the checkboxes and add it to the main form group
    const checkboxGroup = this.fb.group(controls);
    this.formGroup.addControl(this.controlName, checkboxGroup);

    // Apply the custom validator to the main form group
    this.formGroup.setValidators(atLeastOneCheckedValidator(this.controlName, this.required));

    // Update validation on checkbox changes
    checkboxGroup.valueChanges.subscribe(() => {
      this.formGroup.updateValueAndValidity(); // Re-validate on each change
    });
  }

  get group(): FormGroup {
    return this.formGroup.get(this.controlName) as FormGroup;
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
}
