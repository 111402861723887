import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

if (environment.production) {
  window.console.log = () => { };
}

bootstrapApplication(AppComponent, appConfig).then(appRef => {
  const router = appRef.injector.get(Router);

  router.events.pipe(
    filter(event => event instanceof NavigationEnd)
  ).subscribe(() => {
    updateProviderNameClass(router.url);
  });

  // Initial call to set the class
  updateProviderNameClass(router.url);

  // Set up MutationObserver for aria-hidden on app-root
  observeAppRootAriaHidden();
}).catch(err => console.error(err));

function updateProviderNameClass(url: string) {
  // Retrieve the providerName from localStorage
  const providerName = localStorage.getItem('hospital_slug');

  if (providerName) {
    // Get existing classes
    const existingClasses = document.body.className.split(' ').filter(className => className && !className.startsWith('provider-'));
    // Add the provider class
    existingClasses.push(`provider-${providerName}`);
    // Update the body class
    document.body.className = existingClasses.join(' ');
    // Set global providerName
    (window as any).providerName = providerName;
  }
}

// Separate function to observe aria-hidden on app-root
function observeAppRootAriaHidden() {
  const appRoot = document.querySelector('app-root');

  if (appRoot) {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'aria-hidden') {
          if (appRoot.getAttribute('aria-hidden') === 'true') {
            // Force aria-hidden to "false" if it was set to "true" dynamically
            appRoot.setAttribute('aria-hidden', 'false');
          }
        }
      });
    });

    observer.observe(appRoot, { attributes: true });
  }
}
