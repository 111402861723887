<form [formGroup]="myForm" style="margin-top: -16px">
  <ng-container *ngIf="form_loaded">
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="pleaseCheckAllDiseaseOrDisordersYouHaveHad"
      *ngIf="
        checkboxesItems['pleaseCheckAllDiseaseOrDisordersYouHaveHad']
          ?.is_include
      "
      label="{{
        checkboxesItems['pleaseCheckAllDiseaseOrDisordersYouHaveHad']['title']
      }}"
      [options]="
        checkboxesItems['pleaseCheckAllDiseaseOrDisordersYouHaveHad']['options']
      "
      class="m-t-l d-block"
    >
    </app-custom-checkbox-group>
    <app-chip-selection
      [formGroup]="myForm"
      title="{{ inputItems['pastSurgicalHistory']['placeholder'] }}"
      *ngIf="inputItems['pastSurgicalHistory']?.is_include"
      formName="pastSurgicalHistory"
      class="m-t-l d-block"
    ></app-chip-selection>
    <p
      class="hint-text p-l-s p-t-s p-b-m"
      *ngIf="inputItems['pastSurgicalHistory']?.is_include"
    >
      Type and press "Enter" to add all surgeries you have had
    </p>

    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="pleaseCheckAllDiseaseOrDisordersYourFamilyHasHad"
      *ngIf="
        checkboxesItems['pleaseCheckAllDiseaseOrDisordersYourFamilyHasHad']
          ?.is_include
      "
      label="{{
        checkboxesItems['pleaseCheckAllDiseaseOrDisordersYourFamilyHasHad'][
          'title'
        ]
      }}"
      [options]="
        checkboxesItems['pleaseCheckAllDiseaseOrDisordersYourFamilyHasHad'][
          'options'
        ]
      "
      class="m-t-l d-block"
    >
    </app-custom-checkbox-group>
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="pleaseCheckEachOfTheFollowingSymptomsProblemsThatYouCurrentlyHaveMarkAllThatApply"
      *ngIf="
        checkboxesItems[
          'pleaseCheckEachOfTheFollowingSymptomsProblemsThatYouCurrentlyHaveMarkAllThatApply'
        ]?.is_include
      "
      label="{{
        checkboxesItems[
          'pleaseCheckEachOfTheFollowingSymptomsProblemsThatYouCurrentlyHaveMarkAllThatApply'
        ]['title']
      }}"
      [options]="
        checkboxesItems[
          'pleaseCheckEachOfTheFollowingSymptomsProblemsThatYouCurrentlyHaveMarkAllThatApply'
        ]['options']
      "
      class="m-t-l d-block"
    >
    </app-custom-checkbox-group>
  </ng-container>
</form>
