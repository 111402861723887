<div>
  <div class="bottom-title"><b>Select your primary language</b></div>
  <mat-radio-group
    aria-labelledby="radio-group-label"
    class="radio-group"
    (ngModelChange)="selectLanguage($event)"
    *ngFor="let language of languages"
    [(ngModel)]="selectedLanguage">
    <mat-radio-button class="radio-button" [value]="language.value">{{language.title}}</mat-radio-button>
  </mat-radio-group>
</div>