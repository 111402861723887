import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-cwps-procedural-sedation-consent',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './cwps-procedural-sedation-consent.component.html',
  styleUrl: './cwps-procedural-sedation-consent.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class CwpsProceduralSedationConsentComponent {

}
