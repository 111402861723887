<div class="bottom-slider">
  <div class="slider-title">Rate your pain at this area</div>
  <mat-slider
    [color]="'primary'"
    min="1"
    max="10"
    step="1"
    class="w-90"
    discrete
    [displayWith]="formatLabel"
    [class]="getSliderThumbClass()"
  >
    <input matSliderThumb [(ngModel)]="value" />
  </mat-slider>
  <div class="labels">
    <span style="text-align: left"
      >No <br />
      Pain</span
    >
    <span style="text-align: right"
      >Unbearable <br />
      Pain</span
    >
  </div>
  <button mat-raised-button class="next-btn btn-slider" (click)="submit()">
    Submit
  </button>
</div>
