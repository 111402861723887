<form class="contact" [formGroup]="formGroup">
  <div class="adress-group">
    <mat-form-field>
      <mat-label>{{ addressPlaceholder }}</mat-label>
      <mat-icon matSuffix>
        <i class="input-icon healent-form-input-icon-24"></i>
      </mat-icon>
      <input
        matInput
        #searchInput
        formControlName="address"
        [required]="required"
        maxlength="100"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ unitPlaceholder }}</mat-label>
      <input matInput formControlName="apartment" maxlength="100" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ cityPlaceholder }}</mat-label>
      <input matInput formControlName="city" maxlength="30" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ statePlaceholder }}</mat-label>
      <input matInput [matAutocomplete]="auto" formControlName="state" />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayStateName.bind(this)"
      >
        <mat-option
          *ngFor="let state of filteredStates | async"
          [value]="state.code"
        >
          {{ state.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ zipPlaceholder }}</mat-label>
      <input matInput formControlName="postalCode" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ countryPlaceholder }}</mat-label>
      <input matInput formControlName="country" />
    </mat-form-field>
  </div>
  <div class="hintContainer" [ngClass]="{ valid: isAddressValid }">
    <!-- Display required/optional hint if no error -->
    <div>
      <span *ngIf="required; else optionalField" class="hintRequired">
        *Required
      </span>
      <ng-template #optionalField>
        <span class="hintOptional">+Optional</span>
      </ng-template>
    </div>
  </div>
</form>
