<form [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <app-custom-input
      [formGroup]="myForm"
      controlName="name"
      *ngIf="inputItems['name']?.is_include"
      placeholder="{{ inputItems['name']['placeholder'] }}"
      [iconClass]="inputItems['name']?.icon"
    ></app-custom-input>
    <app-phone
      [formGroup]="myForm"
      [title]="inputItems['phone']['placeholder']"
      formName="phone"
    ></app-phone>
    <app-address
      [formGroup]="myForm"
      *ngIf="inputItems['address']?.is_include"
      addressPlaceholder="{{ inputItems['address']['placeholder'] }}"
      unitPlaceholder="{{ inputItems['apartment']['placeholder'] }}"
      cityPlaceholder="{{ inputItems['city']['placeholder'] }}"
      statePlaceholder="{{ inputItems['state']['placeholder'] }}"
      zipPlaceholder="{{ inputItems['postalCode']['placeholder'] }}"
      countryPlaceholder="{{ inputItems['country']['placeholder'] }}"
    ></app-address>
  </ng-container>
</form>
