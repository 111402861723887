<form [formGroup]="formGroup">
  <div (click)="focusInput($event)" [ngClass]="{ 'readonly-class': readonly }">
    <mat-form-field
      class="h-phone"
      [ngClass]="{
        labelActive: !isInputActive(),
        'mdc-text-field--invalid': isInvalid()
      }"
    >
      <mat-label>
        {{ title | translate
        }}<span
          *ngIf="isRequired"
          [ngClass]="{
            'required-text': !isInputActive(),
          }"
        >
          *</span
        >
      </mat-label>
      <mat-icon matSuffix>
        <i [ngClass]="['input-icon', iconClass || 'healent-phone']"></i>
      </mat-icon>
      <div class="phoneInput" [ngClass]="{ hide: !isInputActive() }">
        <span class="country-prefix p-r-xs">+1</span>
        <input id="{{ id }}"
          #phoneInput
          matInput
          [formControl]="localPhoneControl"
          ngxMask
          [mask]="selectedCountry?.mask || '(000) 000-0000'"
          [showMaskTyped]="false"
          [specialCharacters]="['+', ' ', '(', ')', '-', '.']"
          inputmode="numeric"
          (focus)="onInputFocus($event)"
          (blur)="onInputBlur($event)"
          class="pointer-all"
          placeholder="(___) ___-____"
          [readonly]="readonly"
        />
      </div>
    </mat-form-field>
  </div>
</form>
