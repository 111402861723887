import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { formObjectByType } from "../../functions/functions";
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CustomSelectComponent } from '../../reusable/custom-select/custom-select.component';
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';
import { CustomRadioGroupComponent } from '../../reusable/custom-radio-group/custom-radio-group.component';
import { CustomDatePickerComponent } from '../../reusable/custom-date-picker/custom-date-picker.component';
import { CustomTextareaComponent } from '../../reusable/custom-textarea/custom-textarea.component';
import { PhoneComponent } from '../../reusable/phone/phone.component';
import { CommonModule } from '@angular/common';
import { BodyViewerComponent } from '../../reusable/body-viewer/body-viewer.component';
import { PainSliderComponent } from '../../reusable/pain-slider/pain-slider.component';
import { CustomCheckboxGroupComponent } from '../../reusable/custom-checkbox-group/custom-checkbox-group.component';
import { ChipSelectionComponent } from '../../reusable/chip-selection/chip-selection.component';
@Component({
  selector: 'app-medical-info-step-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CustomCheckboxComponent,
    CustomSelectComponent,
    CustomInputComponent,
    CustomRadioGroupComponent,
    CustomDatePickerComponent,
    CustomTextareaComponent,
    PhoneComponent,
    BodyViewerComponent,
    PainSliderComponent,
    CustomCheckboxGroupComponent,
    ChipSelectionComponent
  ],
  templateUrl: './medical-info-step-form.component.html',
  styleUrls: ['./medical-info-step-form.component.css']
})
export class MedicalInfoStepFormComponent implements OnInit, OnDestroy {

  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';
  formValues: any = {};
  myForm!: FormGroup;
  checkboxItems: any = {};
  checkboxesItems: any = {};
  inputItems: any = {};
  radioItems: any = {};
  form_loaded: boolean = false;
  minDate!: Date;
  maxDate!: Date;

  private destroy$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private _bottomSheet: MatBottomSheet,
    private fb: FormBuilder,
  ) {
    translateService.setDefaultLang('en'); ``
    translateService.use('en');
  }

  ngOnInit(): void {
    this.maxDate = new Date();
    this.myForm = new FormGroup({});
    const currentLang = this.translateService.currentLang;
    let lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroy$)).subscribe(translations => {
      const translationsObj = translations['healent'][this.step];
      const translationsObjSlug = translations[this.hospital_slug][this.step];

      let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step);
      this.myForm = formObject['myForm'];
      this.inputItems = formObject['inputItems'];
      this.radioItems = formObject['radioItems'];
      this.checkboxItems = formObject['checkboxItems'];
      this.checkboxesItems = formObject['checkboxesItems'];

      if (this.patch_values) {
        this.myForm.patchValue(this.patch_values, { emitEvent: false });
      }
      this.control?.setValue(this.myForm.value, { emitEvent: true }); // set initial value
      this.formGroup = this.myForm;
      this.myForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
        this.formValues = val;
        this.control?.setValue(this.myForm.value, { emitEvent: true });
        this.formGroup = this.myForm;
      });
      this.onValueChanges();
      this.form_loaded = true;
    });
  }

  onValueChanges() { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
