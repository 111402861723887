import { OnInit, AfterViewInit, Component, Inject, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { MatStepper, MatStepperModule } from "@angular/material/stepper";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AkpsAcknowledgmentComponent } from "../agreements/akps/akps-acknowledgment/akps-acknowledgment.component";
import { AkpsPhysicianOwnershipComponent } from "../agreements/akps/akps-physician-ownership/akps-physician-ownership.component";
import { AkpsControlledSubstancesPolicyComponent } from "../agreements/akps/akps-controlled-substances-policy/akps-controlled-substances-policy.component";
import { AkpsPreferredContactsComponent } from "../agreements/akps/akps-preferred-contacts/akps-preferred-contacts.component";
import { AkpsFinancialPolicyComponent } from "../agreements/akps/akps-financial-policy/akps-financial-policy.component";
import { AkpsAuthorizationToTreatComponent } from '../agreements/akps/akps-authorization-to-treat/akps-authorization-to-treat.component';
import { AkpsNoticeOfPrivacyPracticesComponent } from '../agreements/akps/akps-notice-of-privacy-practices/akps-notice-of-privacy-practices.component';
import { AkpsPainMedicationPolicyComponent } from '../agreements/akps/akps-pain-medication-policy/akps-pain-medication-policy.component';
import { CwpsPhysicianFinancialPolicyComponent } from "../agreements/cwps/cwps-physician-financial-policy/cwps-physician-financial-policy.component";
import { CwpsProceduralSedationConsentComponent } from "../agreements/cwps/cwps-procedural-sedation-consent/cwps-procedural-sedation-consent.component";
import { CwpsHippaNoticeComponent } from "../agreements/cwps/cwps-hippa-notice/cwps-hippa-notice.component";
import { CwpsTreatmentComponent } from "../agreements/cwps/cwps-treatment/cwps-treatment.component";
import { CwpsFinancialPolicyComponent } from "../agreements/cwps/cwps-financial-policy/cwps-financial-policy.component";
import { CwpsAuthorizationToTreatComponent } from '../agreements/cwps/cwps-authorization-to-treat/cwps-authorization-to-treat.component';
import { CwpsInformConsentComponent } from '../agreements/cwps/cwps-inform-consent/cwps-inform-consent.component';
import { CwpsConsentInsuranceComponent } from '../agreements/cwps/cwps-consent-insurance/cwps-consent-insurance.component';
import { CwpsAuthorizationConsentComponent } from '../agreements/cwps/cwps-authorization-consent/cwps-authorization-consent.component';
import { CwpsProcedureConsentComponent } from '../agreements/cwps/cwps-procedure-consent/cwps-procedure-consent.component';
import { CwpsCcmConsentComponent } from '../agreements/cwps/cwps-ccm-consent/cwps-ccm-consent.component';
import { CwpsRtmConsentComponent } from '../agreements/cwps/cwps-rtm-consent/cwps-rtm-consent.component';
import { CwpsPreferredContactsComponent } from '../agreements/cwps/cwps-preferred-contacts copy/cwps-preferred-contacts.component';
@Component({
  selector: 'app-privacy-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatStepperModule,
    MatButtonModule,
    TranslateModule,
    AkpsFinancialPolicyComponent,
    AkpsAcknowledgmentComponent,
    AkpsAuthorizationToTreatComponent,
    AkpsNoticeOfPrivacyPracticesComponent,
    AkpsControlledSubstancesPolicyComponent,
    AkpsPhysicianOwnershipComponent,
    AkpsPreferredContactsComponent,
    AkpsPainMedicationPolicyComponent,
    CwpsFinancialPolicyComponent,
    CwpsPhysicianFinancialPolicyComponent,
    CwpsAuthorizationToTreatComponent,
    CwpsInformConsentComponent,
    CwpsHippaNoticeComponent,
    CwpsProceduralSedationConsentComponent,
    CwpsConsentInsuranceComponent,
    CwpsTreatmentComponent,
    CwpsAuthorizationConsentComponent,
    CwpsProcedureConsentComponent,
    CwpsCcmConsentComponent,
    CwpsRtmConsentComponent,
    CwpsPreferredContactsComponent
  ],
  templateUrl: './privacy-modal.component.html',
  styleUrl: './privacy-modal.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class PrivacyModalComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild('privacyContent') privacyContent!: ElementRef; // Access the modal content

  accepted: any = [];
  declined: any = [];
  privacyList: any = [];
  initialKey: string = '';
  remainingSteps: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PrivacyModalComponent>,
  ) {
    this.privacyList = data.privacyList;
    this.initialKey = data.initialKey;
  }
  hospitalSlug!: string | null;

  ngOnInit() {
    this.hospitalSlug = localStorage.getItem('hospital_slug');
  }

  ngAfterViewInit() {
    const initialIndex = this.privacyList.findIndex((privacy: any) => privacy.key === this.initialKey);
    if (initialIndex !== -1) {
      this.stepper.selectedIndex = initialIndex;
    }
    this.initializeRemainingSteps(initialIndex);
    this.scrollToTop();
  }

  initializeRemainingSteps(initialIndex: number) {
    this.remainingSteps = [];
    // Add steps after the initial index
    for (let i = initialIndex; i < this.privacyList.length; i++) {
      this.remainingSteps.push(i);
    }
    // Add steps before the initial index
    for (let i = 0; i < initialIndex; i++) {
      this.remainingSteps.push(i);
    }
  }
  scrollToTop() {
    if (this.privacyContent) {
      this.privacyContent.nativeElement.scrollIntoView({ block: 'start' });
    }
  }


  decline() {
    const index = this.stepper.selectedIndex;
    const key = this.privacyList[index].key;
    this.declined.push(key);
    this.moveToNextStep(index);
  }

  accept() {
    const index = this.stepper.selectedIndex;
    const key = this.privacyList[index].key;

    // Push the key and its approval timestamp
    this.accepted.push({
      key,
      signed_on_utc: this.formatDate(new Date()), // Add the current timestamp
    });

    this.moveToNextStep(index);
  }

  private formatDate(date: Date): string {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }


  moveToNextStep(currentIndex: number) {
    // Remove the current step from remaining steps
    this.scrollToTop();

    const nextStepIndex = this.remainingSteps.indexOf(currentIndex);
    if (nextStepIndex !== -1) {
      this.remainingSteps.splice(nextStepIndex, 1);
    }

    if (this.remainingSteps.length === 0) {
      this.dialogRef.close({ accepted: this.accepted, declined: this.declined });
      return;
    }

    const nextIndex = this.remainingSteps[0];
    this.stepper.selectedIndex = nextIndex;
  }

}
