.privacy-main {
  height: 100%;
  padding: 0 0 180px 0;
  overflow: hidden;
  overflow-y: auto;
}
.privacy-main li {
  text-align: justify;
}
.privacy-content {
  margin: auto;
  color: var(--primary-color) !important;
}
.m-120 {
  max-width: 720px;
  margin: auto;
}
.privacy-title {
  font-size: var(--font-size-m);
  font-weight: 700;
  color: var(--primary-color);
}

.privacy-body {
  font-size: var(--font-size-s);
  font-weight: 400;
  line-height: 26px;
}

.privacy-body p {
  font-size: var(--font-size-s);
  font-weight: 400;
  line-height: 26px;
  text-align: justify;
  word-spacing: 0px;
  text-align-last: left;
}

.privacy-top {
  position: absolute;
  top: 0;
  display: block;
  width: -webkit-fill-available;
  text-align: center;
  height: 0px;
  padding-bottom: 40px;
  background: #f5f9f9;
  background: linear-gradient(
    180deg,
    var(--h-main-background-color) 60%,
    rgb(245 249 249 / 0%) 100%
  );
  z-index: 1;
}

.privacy-footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  gap: var(--size-m);
  margin: auto;
  width: -webkit-fill-available;
  text-align: center;
  height: 84px;
  padding-top: 90px;
  background: rgb(245, 249, 249);
  background: linear-gradient(
    0deg,
    var(--h-main-background-color) 60%,
    rgb(245 249 249 / 0%) 100%
  );
}
.decline-btn {
  width: 140px;
  height: 56px;
  font-size: 16px !important;
}
.accept-btn {
  width: 200px;
  height: 56px;
  font-size: 16px !important;
  background: var(--accent) !important;
  padding: 0 32px;
  margin: 0 16px;
  max-width: 360px;
}
.accept-btn-advanced {
  width: 200px;
  height: 56px;
  font-size: 16px !important;
  background: var(--accent) !important;
  padding: 0 32px;
  margin: 0 0;
  max-width: 360px;
}
.mat-horizontal-stepper-header-container {
  display: none;
}
.mat-stepper-header-position-bottom .mat-horizontal-content-container {
  padding: 0px 24px 0 24px;
}
@media screen and (max-width: 600px) {
  .accept-btn {
    width: 140px;
  }
  .table-authorizedContacts td {
    font-size: 14px;
  }
}
