<app-header
  [ngClass]="{ 'fixed-header': currentStep !== 'stepper' }"
></app-header>

<ng-container *ngIf="is_loading">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</ng-container>
<div class="body-container">
  <ng-container>
    <ng-container *ngIf="currentStep === 'welcome'">
      <app-welcome
        (next)="goToNextStep()"
        class="welcome-container"
      ></app-welcome>
    </ng-container>
    <ng-container *ngIf="currentStep === 'userInfo'">
      <app-user-info
        (userInfoVerified)="onUserInfoVerified()"
        class="welcome-container"
      ></app-user-info>
    </ng-container>
    <ng-container *ngIf="currentStep === 'verification'">
      <app-user-info-verification
        (codeVerified)="onCodeVerified()"
        class="welcome-container"
      ></app-user-info-verification>
    </ng-container>
    <ng-container *ngIf="currentStep === 'error'">
      <app-error></app-error>
    </ng-container>
  </ng-container>
</div>
