import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from "@sentry/angular";
import { browserTracingIntegration } from "@sentry/angular";

import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

const startTime = performance.now();

if (environment.production) {
  window.console.log = () => { };

  // Determine the Clarity script ID based on the URL
  const clarityScriptId = window.location.hostname === 'forms.cps.healent.com'
    ? 'pubx6wayf5'
    : 'ptw0rfopqu';

  // Dynamically add the Clarity script
  const clarityScript = document.createElement('script');
  clarityScript.type = 'text/javascript';
  clarityScript.textContent = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${clarityScriptId}");
  `;
  document.head.appendChild(clarityScript);
}


Sentry.init({
  dsn: "https://491175d087ec69bfd31eefb506e1fb43@o121057.ingest.us.sentry.io/4508019797983232",
  integrations: [
    browserTracingIntegration(),
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.05,
});

bootstrapApplication(AppComponent, {
  providers: [
    ...appConfig.providers,
  ],
}).then(appRef => {
  const endTime = performance.now();
  const initializationTime = endTime - startTime;
  if (initializationTime > 500) { Sentry.captureMessage(`Angular initialization time: ${initializationTime}ms`, "info"); }

  const router = appRef.injector.get(Router);

  router.events.pipe(
    filter(event => event instanceof NavigationEnd)
  ).subscribe(() => {
    updateProviderNameClass(router.url);
  });

  // Initial call to set the class
  updateProviderNameClass(router.url);

  // Set up MutationObserver for aria-hidden on app-root
  observeAppRootAriaHidden();
}).catch((err) => {
  console.error(err);
  Sentry.captureException(err);
});

function updateProviderNameClass(url: string) {
  const providerName = localStorage.getItem('hospital_slug');
  if (providerName) {
    const existingClasses = document.body.className.split(' ').filter(className => className && !className.startsWith('provider-'));
    existingClasses.push(`provider-${providerName}`);
    document.body.className = existingClasses.join(' ');
    (window as any).providerName = providerName;
  }
}

function observeAppRootAriaHidden() {
  if (typeof MutationObserver !== 'undefined') {
    const appRoot = document.querySelector('app-root');
    if (appRoot) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'aria-hidden') {
            if (appRoot.getAttribute('aria-hidden') === 'true') {
              appRoot.setAttribute('aria-hidden', 'false');
            }
          }
        });
      });
      observer.observe(appRoot, { attributes: true });
    }
  }
}
