<div [formGroup]="parentForm">
  <div formArrayName="testAndImage">
    <h2 class="test-and-image-title">Tests and images</h2>
    <div
      *ngFor="let item of testAndImageArray.controls; let i = index"
      [formGroupName]="i"
      class="test-and-image-item p-t-l p-b-s"
    >
      <i class="healent-close-circle close-icon" (click)="removeItem(i)"></i>
      <!-- Custom Upload Component -->
      <app-upload
        [formGroup]="getItemFormGroup(item)"
        formName="fileName"
        placeholder="Upload a test file"
      ></app-upload>

      <!-- Custom Input Component -->
      <app-custom-input
        [formGroup]="getItemFormGroup(item)"
        controlName="facility"
        *ngIf="inputItems['facility']?.is_include"
        placeholder="{{ inputItems['facility']['placeholder'] }}"
      ></app-custom-input>
      <div class="dose-freq">
        <!-- Custom Select Component -->
        <app-custom-select
          [formGroup]="getItemFormGroup(item)"
          controlName="type"
          *ngIf="radioItems['type']?.is_include"
          label="{{ radioItems['type']['title'] }}"
          [options]="radioItems['type']['options']"
          [iconClass]="radioItems['type']?.icon"
        ></app-custom-select>

        <!-- Custom Date Picker Component -->
        <app-custom-date-picker
          [formGroup]="getItemFormGroup(item)"
          controlName="fileDate"
          *ngIf="inputItems['fileDate']?.is_include"
          label="{{ inputItems['fileDate']['placeholder'] }}"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></app-custom-date-picker>
      </div>
    </div>
  </div>
  <button class="test-and-imag-btn test-and-imag-btn-m-t-m" (click)="addItem()">
    <i class="healent-form-input-icon-9"></i>Upload a test file
  </button>
</div>
