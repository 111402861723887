import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { MatError, MatFormField } from "@angular/material/form-field";
import { KeyValuePipe, NgForOf, NgIf, CommonModule } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

import { formObjectByType } from "../../functions/functions";
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CustomSelectComponent } from '../../reusable/custom-select/custom-select.component';
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';
import { CustomRadioGroupComponent } from '../../reusable/custom-radio-group/custom-radio-group.component';
import { CustomCheckboxGroupComponent } from '../../reusable/custom-checkbox-group/custom-checkbox-group.component';
@Component({
  selector: 'app-demographic-step-form',
  standalone: true,
  imports: [
    MatFormField,
    ReactiveFormsModule,
    NgIf,
    MatError,
    KeyValuePipe,
    NgForOf,
    CustomCheckboxComponent,
    CustomSelectComponent,
    CustomInputComponent,
    CustomRadioGroupComponent,
    CustomCheckboxGroupComponent,
    CommonModule
  ],
  templateUrl: './demographic-step-form.component.html',
  styleUrl: './demographic-step-form.component.css'
})
export class DemographicStepFormComponent implements OnInit, OnDestroy {

  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';
  formValues: any = {};
  value: any;
  form_loaded = false;
  myForm!: FormGroup;
  checkboxesItems: any = {}
  radioItems: any = {}
  inputItems: any = {}
  destroySubject = new Subject<void>();

  constructor(
    private translateService: TranslateService,
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    // start subscription to toggle validation rules based on checkbox
    this.myForm = new FormGroup({});
    const currentLang = this.translateService.currentLang;
    const lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {
      const translationsObj = translations['healent'][this.step];
      const translationsObjSlug = translations[this.hospital_slug][this.step];
      let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step)
      this.myForm = formObject['myForm'];
      this.radioItems = formObject['radioItems'];
      this.inputItems = formObject['inputItems'];
      this.checkboxesItems = formObject['checkboxesItems'];

      if (this.patch_values) {
        this.myForm.patchValue(this.patch_values, { emitEvent: false });
      }
      this.control?.setValue(this.myForm.value, { emitEvent: true }); // set initial value
      this.formGroup = this.myForm;
      this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(val => {
        this.formValues = val;
        this.control?.setValue(this.myForm.value, { emitEvent: true });
        this.formGroup = this.myForm;
      });
      this.onCheckboxChange()
      this.form_loaded = true;

    }); // end translateService.getTranslation

  } // end ngOnInit

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onCheckboxChange() {
    /**
     * When we toggle a checkbox and if true we want to add validation to the form controls
     * when false we want to remove the validation.
     * place on ngOnInit

     */
  }

} // end class


