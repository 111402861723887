<ng-container *ngIf="formStatus === 'not_started'">
  <div class="welcome-body">
    <div class="welcome-info">
      <h1>
        {{ formsInfo.title }}
      </h1>
      <p>{{ formsInfo.description }}</p>
      <button mat-button (click)="goToNextStep()">{{ formsInfo.btn }}</button>
    </div>
    <div class="welcome-footer">
      <!--       <p class="language" (click)="selectLanguage()">
        {{ getLanguage }}
        <mat-icon matSuffix>
          <i class="input-icon healent-form-input-icon-19"></i>
        </mat-icon>
      </p> -->
    </div>
  </div>
</ng-container>
<ng-container *ngIf="formStatus === 'in_progress'">
  <div class="welcome-body">
    <div class="welcome-info">
      <h1>
        {{ formsInfo.progressTitle }}
        {{ getDisplayName() }}
      </h1>
      <p>
        {{ formsInfo.progressDescription1
        }}<span class="red-front">{{ formsInfo.progressDescription2 }}</span
        >{{ formsInfo.progressDescription3 }}
      </p>
      <button mat-button (click)="goToNextStep()">
        {{ formsInfo.progressBtn }}
      </button>
    </div>
  </div>
</ng-container>
