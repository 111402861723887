<form class="m-t-m" [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <app-authorized
      [parentForm]="myForm"
      controlName="authorized"
    ></app-authorized>
    <div class="title-with-icon noicon m-t-l">
      <app-custom-checkbox
        [formGroup]="myForm"
        *ngIf="checkboxItems['utilize']['is_include']"
        controlName="utilize"
        [label]="checkboxItems['utilize']?.title"
      >
      </app-custom-checkbox>
      <div *ngIf="myForm.get('utilize')?.value">
        <app-custom-radio-group
          [formGroup]="myForm"
          controlName="providerGiveInformation"
          *ngIf="radioItems['providerGiveInformation']?.is_include"
          label="{{ radioItems['providerGiveInformation']['title'] }}"
          [options]="radioItems['providerGiveInformation']['options']"
          class="flex-row-reverse"
        >
        </app-custom-radio-group>
      </div>
      <app-custom-checkbox
        [formGroup]="myForm"
        *ngIf="checkboxItems['attorney']['is_include']"
        controlName="attorney"
        [label]="checkboxItems['attorney']?.title"
        class="d-block"
      >
      </app-custom-checkbox>
      <div *ngIf="myForm.get('attorney')?.value">
        <app-upload
          [formGroup]="myForm"
          *ngIf="uploadItems['Medical']?.is_include"
          [title]="uploadItems['Medical']?.title"
          [btn]="uploadItems['Medical']?.btn"
          [icon]="uploadItems['Medical']?.icon"
          formName="Medical"
          [desc]="uploadItems['Medical']?.description"
          [required]="true"
        ></app-upload>
      </div>

      <app-custom-checkbox-group
        [formGroup]="myForm"
        controlName="notices"
        *ngIf="checkboxesItems['notices']?.is_include"
        label="{{ checkboxesItems['notices']['title'] }}"
        [options]="checkboxesItems['notices']['options']"
      >
      </app-custom-checkbox-group>
    </div>
    <div class="title-with-icon">
      <app-custom-radio-group
        [formGroup]="myForm"
        controlName="medicationHistoryAuthority"
        *ngIf="radioItems['medicationHistoryAuthority']?.is_include"
        label="{{ radioItems['medicationHistoryAuthority']['title'] }}"
        [options]="radioItems['medicationHistoryAuthority']['options']"
        class="flex-row-reverse"
      >
      </app-custom-radio-group>
    </div>
    <div class="title-with-icon m-t-l">
      <app-custom-textarea
        [formGroup]="myForm"
        controlName="patientNotes"
        label="{{ inputItems['patientNotes']['placeholder'] }}"
        hint="{{ inputItems['patientNotes']['hint'] }}"
        *ngIf="inputItems['patientNotes']?.is_include"
      >
      </app-custom-textarea>
    </div>
  </ng-container>
</form>
