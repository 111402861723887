<mat-form-field class="textarea" appearance="fill" style="width: 100%">
  <mat-label>{{ label }}</mat-label>
  <textarea id="{{ id }}" matInput [formControl]="control" [rows]="rows"></textarea>
</mat-form-field>
<!-- Display required/optional hint -->
<div class="hintContainer">
  <span *ngIf="isRequired(); else optionalField" class="hintRequired">
    *Required
  </span>
  <ng-template #optionalField>
    <span class="hintOptional">+Optional</span>
  </ng-template>
  <span *ngIf="hint" class="hintText"> - {{ hint }}</span>
</div>
