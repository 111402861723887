// body-front.component.ts
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-body-front',
  standalone: true,
  templateUrl: './body-front.component.html',
  styleUrls: ['./body-front.component.css']
})
export class BodyFrontComponent {
  @Output() svgClick = new EventEmitter<MouseEvent>();

  onSvgClick(event: MouseEvent) {
    this.svgClick.emit(event);
  }
}
