import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray, ReactiveFormsModule } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { CommonModule } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { formObjectByType } from "../../functions/functions";
import { AuthorizedComponent } from '../../reusable/authorized/authorized.component';
import { CustomCheckboxGroupComponent } from '../../reusable/custom-checkbox-group/custom-checkbox-group.component';
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';
import { CustomDatePickerComponent } from '../../reusable/custom-date-picker/custom-date-picker.component';
import { CustomTextareaComponent } from '../../reusable/custom-textarea/custom-textarea.component';
import { CustomRadioGroupComponent } from '../../reusable/custom-radio-group/custom-radio-group.component';
import { SharedFormService } from '../../services/SharedFormService.service';
import { UploadComponent } from '../../reusable/upload-components/upload/upload.component';
import { CustomSelectComponent } from '../../reusable/custom-select/custom-select.component';
import { skip } from 'rxjs/operators';

interface Authorized {
  name: string;
  RelationshipToThePatient: string;
  primaryPhone: string;
}

interface PatchValues {
  authorized: Authorized[];
  [key: string]: any;
}
@Component({
  selector: 'app-privacy-preferences-step-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    AuthorizedComponent,
    CustomCheckboxGroupComponent,
    CustomCheckboxComponent,
    CustomInputComponent,
    CustomDatePickerComponent,
    CustomTextareaComponent,
    CustomRadioGroupComponent,
    UploadComponent,
    CustomSelectComponent
  ],
  templateUrl: './privacy-preferences-step-form.component.html',
  styleUrl: './privacy-preferences-step-form.component.css'
})
export class PrivacyPreferencesStepFormComponent implements OnInit, OnDestroy {
  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values: PatchValues = { authorized: [] };
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';
  formValues: any = {};
  myForm!: FormGroup;
  form_loaded: boolean = false;
  destroySubject = new Subject<void>();
  checkboxesItems: any = {};
  checkboxItems: any = {};
  radioItems: any = {};
  uploadItems: any = {};
  inputItems: any = {};
  minDate!: Date;
  maxDate!: Date;

  constructor(
    private translateService: TranslateService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private sharedFormService: SharedFormService
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    if (!Array.isArray(this.patch_values.authorized)) {
      this.patch_values.authorized = [];
    }
    this.maxDate = new Date();
    let isInitializing = true; // Flag for initialization phase
    let lastAuthorizedValue: Authorized[] = [...this.patch_values.authorized];

    this.initializeForm();
    this.onValueChanges();

    const currentLang = this.translateService.currentLang;
    let lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {
      const translationsObj = translations['healent'][this.step];
      const translationsObjSlug = translations[this.hospital_slug][this.step];

      let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step);
      this.myForm = formObject['myForm'];
      this.radioItems = formObject['radioItems'];
      this.inputItems = formObject['inputItems'];
      this.checkboxesItems = formObject['checkboxesItems'];
      this.checkboxItems = formObject['checkboxItems'];
      this.uploadItems = formObject['uploadItems'];

      if (this.patch_values) {
        this.setFormArray(this.patch_values.authorized);
        this.myForm.patchValue(this.patch_values, { emitEvent: false });
      }

      this.control?.setValue(this.myForm.value, { emitEvent: true }); // Set initial value
      this.formGroup = this.myForm;

      this.sharedFormService.setShowPreferred(false);

      lastAuthorizedValue = [...this.patch_values.authorized];
      const authorizedFormArray = this.myForm.get('authorized') as FormArray;

      authorizedFormArray.valueChanges
        .pipe(takeUntil(this.destroySubject))
        .subscribe((value: Authorized[]) => {
          if (isInitializing) return;
          if (JSON.stringify(value) !== JSON.stringify(lastAuthorizedValue)) {
            this.sharedFormService.setShowPreferred(true);
            lastAuthorizedValue = [...value];
          }
        });

      setTimeout(() => {
        isInitializing = false;
      });

      // Subscribe to overall form changes
      this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(val => {
        this.formValues = val;
        this.control?.setValue(this.myForm.value, { emitEvent: true });
        this.formGroup = this.myForm;
        this.sharedFormService.updateFormValues(this.myForm.value);
      });

      this.form_loaded = true;
      this.cdr.detectChanges();
    });
  }


  // end ngOnInit

  initializeForm(): void {
    this.myForm = this.fb.group({
      authorized: this.fb.array([]),
      attorney: [false],
      Medical: [''],
    });
  }

  setFormArray(data: Authorized[]) {
    const authorizedFormArray = this.myForm.get('authorized') as FormArray;
    authorizedFormArray.clear();
    if (data && data.length > 0) {
      data.forEach(item => {
        const group = this.fb.group({
          name: [item.name, Validators.required],
          RelationshipToThePatient: [item.RelationshipToThePatient, Validators.required],
          primaryPhone: [item.primaryPhone, Validators.required],
        });
        authorizedFormArray.push(group);
      });
    }
    this.sharedFormService.updateFormValues({ authorized: authorizedFormArray.value });
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onValueChanges() {
    this.myForm.get('utilize')?.valueChanges.subscribe(checked => {
      const providerGiveInformation = this.myForm.get('providerGiveInformation');
      if (checked) {
        providerGiveInformation!.setValidators([Validators.required]);
      } else {
        providerGiveInformation!.clearValidators();
      }
      providerGiveInformation!.updateValueAndValidity();
    });
    this.myForm.get('attorney')?.valueChanges.subscribe(checked => {
      const medicalControl = this.myForm.get('Medical');

      if (medicalControl) {
        if (checked) {
          medicalControl.setValidators([Validators.required]);
        } else {
          medicalControl.clearValidators();
        }
        medicalControl.updateValueAndValidity();
      }
    });
  }
}
