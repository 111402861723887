<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Consent for Procedure</title>
  </head>
  <body>
    <h3>CONSENT FOR PROCEDURE</h3>
    <table class="table-v1">
      <tr>
        <td><b>Procedure Type:</b></td>
        <td>
          <b>{{ procedureType }}</b>
        </td>
      </tr>
    </table>

    <h4>Epidural Steroid Injection</h4>
    <p>
      An epidural steroid injection is performed to reduce swelling that
      compresses your spinal cord. Using a fluoroscope, your doctor inserts a
      needle into the epidural space of your spine and injects a combination of
      local anesthetic and a steroid.
    </p>

    <h4>Medial Branch Blocks (MBB)</h4>
    <p>
      A MBB injection is performed to find out if your facet joints are
      contributing to your pain. Using a fluoroscope, your doctor inserts a
      needle into the nerves and injects a combination of local anesthetic and a
      steroid. If the MBB procedure blocks your pain, the next step would be to
      consider a RFA procedure.
    </p>

    <h4>Radiofrequency Ablation (RFA)</h4>
    <p>
      RFA uses heat to destroy the nerve fibers that send pain signals to your
      brain. Using a fluoroscope, your doctor inserts a needle into the precise
      site of the malfunctioning nerve and sends a radiofrequency current
      through the needle for about 90 seconds. The current makes a small and
      precise burn on your nerve called a lesion. The lesion disrupts the pain
      signals produced by that nerve.
    </p>

    <h4>Nerve Block Injection</h4>
    <p>
      A nerve block injection is used to diagnose and treat nerve pain. Using a
      fluoroscope, your doctor targets the nerves and injects a combination of
      local anesthetic and a steroid to interrupt the transmission of pain
      signals to your brain. Nerve block injections can be used to pinpoint the
      exact location of pain signals.
    </p>

    <h4>Intra-articular Injection</h4>
    <p>
      An intra-articular injection is a procedure where a combination of local
      anesthetic and a steroid are injected into the joint space for the purpose
      of relieving joint pain.
    </p>

    <h4>Spinal Cord Stimulator</h4>
    <p>
      A spinal cord stimulator uses electrical signals to replace the sensation
      of pain with a tingling sensation to help alleviate your pain. It is a
      two-stage procedure; in the first procedure, wires are placed through your
      skin of your back into the epidural space next to the spinal cord, then
      the wires are connected to a stimulating generator. If the first procedure
      is successful, the second procedure places a permanent generator under the
      skin of your back and the wires are connected to the stimulating
      generator.
    </p>

    <h4>Occipital Nerve Block (ONB)</h4>
    <p>
      The ONB procedure is performed to manage migraines or cluster headaches.
      The occipital nerve is located at the back of your head, and a combination
      of local anesthetic and a steroid are injected with a small needle into
      the nerve to reduce swelling and relieve pain.
    </p>

    <h4>Lumbar Discography</h4>
    <p>
      A lumbar discography is a diagnostic procedure that can identify if a
      damaged spinal disc is the cause of your chronic back pain. Using a
      fluoroscope, your doctor determines the exact site of the disc and injects
      a sterile liquid into the disc to trigger pain. Following each injection
      into the disc, your doctor will ask you to rate your pain level to
      determine which discs are responsible for your pain. Following your
      discography, your doctor reviews the results and can then discuss your
      options for treatment.
    </p>

    <h4>Pre-Procedure</h4>
    <p>
      I understand that I am consenting to have a minimally invasive outpatient
      procedure today. I understand that I may ask additional questions before
      my procedure. To prepare for my procedure today, I have followed the
      pre-procedure instructions provided to me by CP&S. I agree to honestly
      answer all questions asked of me today. I have been informed not to eat or
      drink anything for a set amount of time before my procedure and I have
      been truthful when reporting the last time I ate, drank, and if
      applicable, taken my medication. If I am not honest, then I am proceeding
      with my procedure against CP&S orders and I am responsible for my actions.
      Let CP&S know if you’re pregnant, may be pregnant, or are breastfeeding.
    </p>

    <h4>Blood Thinners</h4>
    <p>
      I acknowledge that I have been asked by CP&S, and I have informed CP&S,
      that I am not taking any blood thinning medication, otherwise known as
      non-steroidal anti-inflammatory drug “NSAIDS”, which can be prescribed or
      over-the-counter medication, including herbal supplements, like ginseng
      and ginger. I have been advised by CP&S that taking blood thinners while
      having my procedure may cause excessive bleeding. If I am still taking a
      prescription blood thinner, I am doing so based on the recommendation of
      my medical provider that prescribes the blood thinner; my medical provider
      stated it is safe for CP&S to perform my procedure while I continue taking
      my prescribed blood thinning medication. If I do not have permission by a
      medical provider to continue taking my blood thinning medication, and I am
      still taking NSAIDS/blood thinners at the time of my procedure, I am doing
      so against CP&S orders and will be responsible for my decision.
    </p>

    <h4>Anesthesia</h4>
    <p>
      My CP&S doctor may recommend that I have mild sedation anesthesia during
      my procedure, and if recommended, it is my decision if I want to receive
      the mild sedation. I understand that the mild sedation anesthesia is given
      by a certified registered nurse anesthetist (“CRNA”) that is not employed
      by CP&S, so I will talk to the CRNA, or their staff, if I have questions
      before I consent to receive the mild sedation.
    </p>

    <p>
      I know that I will be awake throughout the procedure and I will be given
      local anesthesia in the area of my injection site to minimize pain. I give
      my consent to the administration of the local anesthesia.
    </p>

    <h4>RISKS, COMPLICATIONS & ADVERSE OUTCOMES</h4>
    <p>
      My procedure may require my doctor to inject me with medications. I
      understand that the medications are a necessary part of my procedure. My
      procedure may include the use of steroids which are frequently used in
      pain management injections. In high doses, steroids may have a negative
      effect on immunity; the therapeutic dose used for injections is generally
      a low dose. I understand that every medication has side effects and CP&S
      staff has explained this to me. I also understand that are risks to me
      when receiving the medications. The risks include allergic reactions that
      range from minor discomfort to severe pain, temporary or permanent
      paralysis, or death. Additional risks are heart attack; aspiration;
      irritation and swelling of a vein; and discoloration and injury to blood
      vessels, skin, subcutaneous tissue and nerves. I have asked questions
      about the medication I will receive today and the potential side effects;
      I consent to the administration of the medication and want to proceed with
      my procedure.
    </p>

    <p>
      The most common risks associated with my procedure include post-procedure
      bleeding, pain at the injection site, post-procedure infection,
      lightheadedness, headache, fainting, swelling, bruising, scarring, and
      discomfort. Other possible risks and complications are injury to tissue;
      muscle injury; nerve damage involving temporary or permanent
      pain/numbness/weakness; numbness and tingling; delayed wound healing;
      injury to nearby organs, vessels and nerves; urinary, heart, or breathing
      difficulties; inflammation of a vein (phlebitis); blood clots in the
      pelvic or leg veins which may break loose and travel to the lungs
      (pulmonary embolism); allergic reaction; paralysis; meningitis; permanent
      nerve damage; dual puncture; seizure; cardiovascular collapse; and death.
    </p>

    <p>
      I understand that if there is a procedure complication, I may require
      surgery, additional procedures, or other intervention to attempt to
      resolve the complication. I have been fully informed by CP&S about the
      risks, complications, and adverse outcomes associated with my procedure. I
      had the opportunity to ask questions about the risks, complications, and
      adverse outcomes of my procedure and CP&S has answered all questions to my
      satisfaction. I consent to CP&S performing my procedure, regardless of the
      risks, complications, and adverse outcomes.
    </p>

    <h4>PROCEDURE</h4>
    <p>
      I agree to ask the CP&S medical staff for help at any time before, during,
      or after the procedure. I understand that when in the procedure room I
      will be positioned on the procedure table, and if asked by the medical
      staff, I may have to get in different positions.
    </p>

    <h4>Fluoroscope</h4>
    <p>
      I have been informed about the use of fluoroscope x-ray during my
      procedure today. Fluoroscope is an x-ray machine that is placed over my
      body on the procedure table. This machine allows my doctor to see the
      internal structure of my body on a monitor with real-time images so they
      can guide the placement of the needle or other medical instrument. I
      understand that fluoroscopy carries some risks and the radiation dose I
      will receive varies depending on my procedure. Radiation-related risks
      associated with fluoroscopy include radiation-induced burn injuries to the
      skin and underlying tissues which occur shortly after the exposure and
      radiation-induced cancers which may occur sometime later in life. I know I
      will be given a lead shield or apron to wear to protect me from
      unnecessary radiation exposure. I have been made aware of the side effects
      of fluoroscope x-ray use, although they are extremely rare, and I accept
      them and consent to the use of the fluoroscope during my procedure.
    </p>

    <h4>Unforeseen Circumstances</h4>
    <p>
      I understand that unforeseen conditions or circumstances may arise before,
      during, or after my procedure; including, but not limited to, a change in
      the entry point or position of the injection due to my internal anatomy or
      other circumstances that require a different invasive action to be
      performed that has not been discussed with me or described in the
      procedure paperwork. If the unforeseen condition or circumstance happens,
      I authorize and consent for my doctor to use their best medical judgment
      to perform my procedure in a different manner than we discussed and to do
      whatever they decide is necessary or preferable in the immediate
      situation.
    </p>

    <h4>After Procedure</h4>
    <p>
      I understand that I may spend time in a recovery area after my procedure
      so I can be observed. I understand that I will be given my post-procedure
      instructions before the procedure and I agree to follow those
      instructions, including, but not limited to; I will not operate a motor
      vehicle or other dangerous machinery for at least 12 hours after my
      procedure; I will not care for a dependent person while I am recovering
      from mild sedation; I am accompanied by a responsible adult and will be
      cared for by that adult after my procedure; unless I have only received
      local anesthesia during the procedure and it does not affect my cognitive
      or motor skills.
    </p>

    <h4>Female Patients</h4>
    <p>
      I understand that if I am pregnant, could be pregnant, or am
      breastfeeding, my procedure can have adverse effects to me and my fetus or
      baby. I am not pregnant, could not be pregnant, and I am not
      breastfeeding. If I am not honest about my condition, then I am proceeding
      with my procedure against CP&S orders and I am responsible for my actions.
    </p>

    <h4>Informed Consent</h4>
    <p>
      I have read, or have had read to me, the information stated in this
      consent document and I understand the content of this consent. I am over
      the age of 18, I am of sound mind, and I am not under the influence of any
      substance or person that would render me unable to freely give consent. I
      fully understand the type of procedure I am to undergo today and my CP&S
      medical provider has informed me about alternative treatments. I
      understand the risks, complications, and adverse outcomes associated with
      my procedure, including risks which may not have been specifically
      mentioned above, including rare complications, and even death, and with
      those concerns in mind, I accept them and freely consent and authorize my
      procedure to be performed. I consent for my procedure to be performed by
      the undersigned CP&S doctor. I fully consent to the performance of my
      procedure based upon my analysis of the risks, benefits, and alternatives,
      all of which have been explained to me to my complete understanding and
      satisfaction.
    </p>

    <h4>Legal Guardian</h4>
    <p>
      If the patient is unable to sign this form, or give consent, by signing
      this consent below, the following person states that they have the legal
      authority to consent to the performance of the procedure on behalf of the
      patient. By signing their name below, the Legal Guardian is stating, on
      behalf of the patient, they understand the content of this consent, they
      have had the opportunity to ask questions about the procedure and the
      information within this consent, and they are freely giving CP&S, and the
      undersigned CP&S doctor, informed consent to perform the procedure for the
      patient.
    </p>

    <h4>Physician Declaration</h4>
    <p>
      My CP&S support staff and I have explained to the patient the nature of
      the procedure, the possible risks, complications and adverse outcomes
      associated with the procedure, and the alternative treatments available to
      the patient. I have answered all questions asked by the patient, or the
      patient’s Legal Guardian, in a manner that they could understand. I am
      relying on the patient’s truth and veracity when they communicated about
      their condition, behaviors, and other information pertinent to the
      procedure, therefore, I am unaware of any condition or reason the patient
      should not receive this procedure.
    </p>

    <p>
      To the best of my knowledge and belief, the patient has been adequately
      informed about the procedure and the patient has fully consented to the
      performance of the procedure.
    </p>
  </body>
</html>
