import { Component, ElementRef, Inject, NgZone, ViewChild } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { CameraComponent } from '../camera/camera.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-upload-file',
  standalone: true,
  imports: [CommonModule, FormsModule, MatButton],
  templateUrl: './upload-file.component.html',
  styleUrl: './upload-file.component.css',
})
export class UploadFileComponent {
  @ViewChild('fileInput') fileInput!: ElementRef;
  action = 'camera';
  cameraAvailable!: boolean;
  sizeError!: boolean;

  constructor(
    private ngZone: NgZone,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<UploadFileComponent>,
    public dialog: MatDialog
  ) {
    this.checkCameraAvailability()
  }

  checkCameraAvailability(): void {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          const videoInputs = devices.filter(device => device.kind === 'videoinput');
          this.cameraAvailable = videoInputs.length > 0;
        })
        .catch(error => {
          console.error('Error enumerating devices:', error);
        });
    } else {
      this.sizeError = true
      console.log('enumerateDevices not supported');
    }
  }

  onActionClick(action: string): void {
    this.action = action;
    if (action === 'camera') {
      if (!this.cameraAvailable) {
        return
      }
      this.openDialog();
    } else if (action === 'gallery') {
      this.fileInput.nativeElement.click();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CameraComponent, {
      width: 'calc(100% - 32px)',
      maxWidth: '640px',
      data: {},
    });
    const fileName = this.generateFileName();
    dialogRef.afterClosed().subscribe((result) => {
      this.bottomSheetRef.dismiss({
        ...result,
        fileName,
        ...this.data,
        action: this.action,
      });
    });
  }

  onFileSelected(event: any) {
    this.sizeError = false;
    const file = event.target.files[0];
    if (!file.type.startsWith('image/')) {
      this.bottomSheetRef.dismiss({
        sizeError: true,
        message: 'common.uploadTypeError',
        data: null,
      });
      return;  // Return here to prevent further execution if the file type is incorrect
    }
    const fileName = file.name;
    const maxSize = 60 * 1024 * 1024; // 6MB in bytes
    if (file) {
      if (file.size <= maxSize) {
        this.readFileContent(file)
          .then((content) => {
            this.bottomSheetRef.dismiss({
              data: content,
              fileName,
              ...this.data,
              action: this.action,
            });
          })
          .catch((error) => {
            console.error('Error reading file:', error);
          });
      } else {
        this.bottomSheetRef.dismiss({
          sizeError: true,
          message: 'common.uploadSizeError',
          data: null,
          fileName,
        });
      }
    }
  }


  readFileContent(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const img = new Image();
        img.src = event.target.result as string;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 800;
          const scaleSize = MAX_WIDTH / img.width;
          canvas.width = MAX_WIDTH;
          canvas.height = img.height * scaleSize;

          const ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            const compressedBase64 = canvas.toDataURL('image/jpeg', 0.7);

            resolve(compressedBase64);
          } else {
            reject(new Error('Canvas context not available'));
          }
        };

        img.onerror = (error) => {
          reject(error);
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }


  generateFileName() {
    const currentDateTime = new Date();
    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, '0');
    const day = String(currentDateTime.getDate()).padStart(2, '0');
    const hours = String(currentDateTime.getHours()).padStart(2, '0');
    const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');
    const seconds = String(currentDateTime.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}_${hours}${minutes}${seconds}.png`;
  }
}