import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MatInputModule } from "@angular/material/input";
import { MatSliderModule } from "@angular/material/slider";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: 'app-bottom-slider',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSliderModule,
    TranslateModule,
    MatButtonModule
  ],
  templateUrl: './bottom-slider.component.html',
  styleUrl: './bottom-slider.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class BottomSliderComponent {
  value: any = 5;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomSliderComponent>,
  ) {
  }

  formatLabel(value: number): string {
    return `${value}`;
  }
  getSliderThumbClass(): string {
    const sliderValue = this.value;
    return `value-${sliderValue}`;
  }
  submit() {
    this.bottomSheetRef.dismiss({ rate: this.value })
  }
}
