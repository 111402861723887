import { Component, ElementRef, Input, ViewChild, OnInit, AfterViewInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormGroup, ReactiveFormsModule, FormsModule, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, Subscription, BehaviorSubject, debounceTime } from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

declare const google: any;

interface State {
  code: string;
  name: string;
}

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [
    FormsModule,
    MatInputModule,
    MatIconModule,
    TranslateModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    CommonModule
  ],
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() formGroup!: FormGroup;
  @Input() addressPlaceholder!: string;
  @Input() unitPlaceholder!: string;
  @Input() cityPlaceholder!: string;
  @Input() statePlaceholder!: string;
  @Input() zipPlaceholder!: string;
  @Input() countryPlaceholder!: string;
  @Input() required!: boolean;
  @Input() id?: string;

  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('stateInput', { read: MatAutocompleteTrigger }) stateInputTrigger!: MatAutocompleteTrigger;

  usaStates: State[] = [
    { name: "Alabama", code: "AL" },
    { name: "Alaska", code: "AK" },
    { name: "Arizona", code: "AZ" },
    { name: "Arkansas", code: "AR" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "Delaware", code: "DE" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Hawaii", code: "HI" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Iowa", code: "IA" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Maine", code: "ME" },
    { name: "Maryland", code: "MD" },
    { name: "Massachusetts", code: "MA" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Mississippi", code: "MS" },
    { name: "Missouri", code: "MO" },
    { name: "Montana", code: "MT" },
    { name: "Nebraska", code: "NE" },
    { name: "Nevada", code: "NV" },
    { name: "New Hampshire", code: "NH" },
    { name: "New Jersey", code: "NJ" },
    { name: "New Mexico", code: "NM" },
    { name: "New York", code: "NY" },
    { name: "North Carolina", code: "NC" },
    { name: "North Dakota", code: "ND" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Pennsylvania", code: "PA" },
    { name: "Rhode Island", code: "RI" },
    { name: "South Carolina", code: "SC" },
    { name: "South Dakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Vermont", code: "VT" },
    { name: "Virginia", code: "VA" },
    { name: "Washington", code: "WA" },
    { name: "West Virginia", code: "WV" },
    { name: "Wisconsin", code: "WI" },
    { name: "Wyoming", code: "WY" }
  ];

  states$ = new BehaviorSubject<State[]>(this.usaStates);
  private searchTermSubject = new BehaviorSubject<string>('');
  private addressSubscription!: Subscription;
  apartment = '';
  city = '';
  state = '';
  postalCode = '';
  country = '';
  isReadonly: boolean = true;

  ngOnInit(): void {
    // Apply validation to form controls
    //this.applyValidators();

    // Clear search term after 2 seconds of inactivity
    this.searchTermSubject.pipe(
      debounceTime(2000)
    ).subscribe(() => {
      this.searchTermSubject.next('');
    });

    this.subscribeToAddressChanges();

    // Sync with form control if it has an initial value
    const initialState = this.formGroup.get('state')?.value;
    if (initialState) {
      this.state = initialState;
    }
  }

  ngAfterViewInit() {
    this.initializeGoogleAutocomplete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formGroup'] && this.formGroup) {
      const addressControl = this.formGroup.get('address');
      if (addressControl && addressControl.value?.length >= 5) {
        this.isReadonly = false;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.addressSubscription) {
      this.addressSubscription.unsubscribe();
    }
  }

  // Open dropdown with all states on focus
  onFocus() {
    this.searchTermSubject.next('');
    this.stateInputTrigger.openPanel();
  }

  // Handle keyboard input to jump to an option
  onKeydown(event: KeyboardEvent) {
    event.preventDefault(); // Disable typing in the input
    if (this.isReadonly) return; // Respect readonly state

    const key = event.key.toLowerCase();

    if (/^[a-z]$/.test(key)) { // Append alphabetic keys
      const currentSearch = this.searchTermSubject.value + key;
      this.searchTermSubject.next(currentSearch);
      this.jumpToOption(currentSearch);
    } else if (key === 'backspace') { // Remove last character
      const currentSearch = this.searchTermSubject.value.slice(0, -1);
      this.searchTermSubject.next(currentSearch);
      this.jumpToOption(currentSearch);
    }

    this.stateInputTrigger.openPanel(); // Keep dropdown open
  }

  // Jump to the matching option without filtering
  private jumpToOption(searchTerm: string) {
    if (!searchTerm) return;

    const searchLower = searchTerm.toLowerCase();
    const index = this.usaStates.findIndex(state =>
      state.name.toLowerCase().includes(searchLower) || state.code.toLowerCase().includes(searchLower)
    );

    if (index === -1) return;

    const attemptScroll = () => {
      const panel = this.stateInputTrigger.autocomplete.panel?.nativeElement as HTMLElement;
      if (!panel) {
        console.warn('Autocomplete panel not found via trigger, retrying...');
        setTimeout(attemptScroll, 100);
        return;
      }

      const options = panel.querySelectorAll('mat-option');
      if (options.length === 0) {
        console.warn('No mat-option elements found, retrying...');
        setTimeout(attemptScroll, 100);
        return;
      }

      const option = options[index] as HTMLElement;
      if (option) {
        const optionTop = option.offsetTop;
        const optionHeight = option.offsetHeight;
        const panelHeight = panel.offsetHeight;
        const lastOption = options[options.length - 1] as HTMLElement
        const totalContentHeight = lastOption.offsetTop + lastOption.offsetHeight;

        // Position the option at the top of the panel
        panel.scrollTop = optionTop;

        // Adjust if the option is near the end and the panel would show empty space
        if (optionTop + panelHeight > totalContentHeight) {
          panel.scrollTop = Math.max(0, totalContentHeight - panelHeight);
        }

        console.log(`Jumped to index ${index}: ${this.usaStates[index].name}, scrollTop: ${panel.scrollTop}`);
      } else {
        console.warn(`Option at index ${index} not found`);
      }
    };

    setTimeout(attemptScroll, 50);
  }

  // Handle state selection
  onStateSelected(event: any) {
    const selectedState = event.option.value;
    this.formGroup.get('state')?.setValue(selectedState);
    this.searchTermSubject.next('');
  }

  private initializeGoogleAutocomplete(): void {
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchInput.nativeElement,
      {
        componentRestrictions: { country: 'US' },
        types: ['address'],
      }
    );
    autocomplete.addListener('place_changed', () => this.handlePlaceChange(autocomplete));
  }

  private handlePlaceChange(autocomplete: any): void {
    const place = autocomplete.getPlace();
    if (!place.address_components) return;

    this.apartment = '';
    this.city = '';
    this.state = '';
    this.postalCode = '';
    this.country = '';

    let fullAddress = '';

    place.address_components.forEach((component: any) => {
      const types = component.types;

      if (types.includes('premise')) {
        fullAddress += `${component.long_name} `;
        this.apartment = component.long_name;
      }

      if (types.includes('route')) {
        fullAddress += `${component.long_name} `;
        this.apartment = this.apartment || component.long_name;
      }

      if (types.includes('street_number')) {
        fullAddress = `${component.long_name} ${fullAddress}`;
      }

      if (types.includes('locality')) {
        this.city = component.long_name;
        fullAddress += `${component.long_name}, `;
      }

      if (types.includes('administrative_area_level_1')) {
        const stateObj = this.usaStates.find(s => s.name === component.long_name);
        this.state = stateObj ? stateObj.code : '';
        fullAddress += `${component.short_name} `;
      }

      if (types.includes('postal_code')) {
        this.postalCode = component.long_name;
        fullAddress += `${component.long_name} `;
      }

      if (types.includes('country')) {
        this.country = component.long_name;
        fullAddress += component.long_name;
      }
    });

    this.formGroup.patchValue({
      address: fullAddress.trim(),
      apartment: this.apartment,
      city: this.city,
      state: this.state,
      postalCode: this.postalCode,
      country: this.country,
    });

    this.isReadonly = false;
  }

  private subscribeToAddressChanges(): void {
    const addressControl = this.formGroup.get('address');
    if (addressControl) {
      this.addressSubscription = addressControl.valueChanges.subscribe((value: string) => {
        this.isReadonly = value && value.length >= 5 ? false : true;
      });
    }
  }

  displayStateName(stateCode: string | null): string {
    if (!stateCode) return '';
    const state = this.usaStates.find(s => s.code === stateCode);
    return state ? state.name : '';
  }

  get isUSACountry(): boolean {
    return this.formGroup.get('country')!.value === 'USA' || this.formGroup.get('country')!.value === 'United States';
  }

  get invalidAddress() {
    return !this.apartment || !this.city || !this.state || !this.postalCode || !this.country;
  }

  get isAddressValid(): boolean {
    const addressControl = this.formGroup.get('address');
    return addressControl?.valid && addressControl?.value;
  }
  applyValidators() {
    const controls = ['address', 'city', 'state', 'postalCode'];

    controls.forEach(controlName => {
      const control = this.formGroup.get(controlName);

      if (control) {
        control.setValidators([
          ...(this.required ? [this.requiredValidator, this.whitespaceValidator] : [])
        ]);

        control.valueChanges.subscribe(value => {
          if (typeof value === 'string' && value.trim().length === 0) {
            control.setValue('', { emitEvent: false });
          }
        });

        control.updateValueAndValidity();
      }
    });
  }

  // Validators as separate functions for cleaner code
  private requiredValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (this.required && (control.value === null || control.value.trim().length === 0)) {
      return { required: true };
    }
    return null;
  };

  private whitespaceValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (this.required && typeof control.value === 'string' && control.value.trim().length === 0) {
      return { whitespace: true };
    }
    return null;
  };

  // onBlur function to trim whitespace when user leaves the input field
  onBlur(controlName: string) {
    const control = this.formGroup.get(controlName);
    if (control && typeof control.value === 'string' && control.value.trim().length === 0) {
      control.setValue('', { emitEvent: false });
    }
  }

}
