import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'app-insurance-package',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    TranslateModule
  ],
  templateUrl: './insurance-package.component.html',
  styleUrls: ['./insurance-package.component.css']
})
export class InsurancePackageComponent implements OnInit, OnChanges {
  @Input() formGroup!: FormGroup;
  @Input() title!: string;
  @Input() formName!: string;
  @Input() icon!: string;

  insuranceOptions = [
    { "insuranceproducttypename-city": "Medicare B-Traditional-MECHANICSBURG", "insurancepackageid": "2800" },
    { "insuranceproducttypename-city": "PPO-DALLAS", "insurancepackageid": "11993" },
    { "insuranceproducttypename-city": "HMO-DALLAS", "insurancepackageid": "315123" },
    { "insuranceproducttypename-city": "Medicare PPO-SALT LAKE CITY", "insurancepackageid": "70322" },
    { "insuranceproducttypename-city": "OTHER-ATLANTA", "insurancepackageid": "982" },
    { "insuranceproducttypename-city": "Medicare Supplemental Plan-ATLANTA", "insurancepackageid": "1910" },
    { "insuranceproducttypename-city": "OTHER-CHATTANOOGA", "insurancepackageid": "74" },
    { "insuranceproducttypename-city": "Medicare PPO-EL PASO", "insurancepackageid": "74229" },
    { "insuranceproducttypename-city": "Medicare Supplemental Plan-OMAHA", "insurancepackageid": "33347" },
    { "insuranceproducttypename-city": "Medicare PPO-LEXINGTON", "insurancepackageid": "47006" },
    { "insuranceproducttypename-city": "OTHER-DALLAS", "insurancepackageid": "52947" },
    { "insuranceproducttypename-city": "Medicare Supplemental Plan-LEXINGTON", "insurancepackageid": "15660" },
    { "insuranceproducttypename-city": "OTHER-MADISON", "insurancepackageid": "475714" },
    { "insuranceproducttypename-city": "Medicare HMO-SALT LAKE CITY", "insurancepackageid": "481355" },
    { "insuranceproducttypename-city": "Medicare HMO-LEXINGTON", "insurancepackageid": "60678" },
    { "insuranceproducttypename-city": "Medicare HMO-TAMPA", "insurancepackageid": "32989" },
    { "insuranceproducttypename-city": "POS-EL PASO", "insurancepackageid": "38684" },
    { "insuranceproducttypename-city": "Medicare HMO-SALT LAKE CITY", "insurancepackageid": "2718" },
    { "insuranceproducttypename-city": "Medicare PPO-SALT LAKE CITY", "insurancepackageid": "255659" },
    { "insuranceproducttypename-city": "Medicare Supplemental Plan-MADISON", "insurancepackageid": "60966" },
    { "insuranceproducttypename-city": "EPO-EL PASO", "insurancepackageid": "3150" },
    { "insuranceproducttypename-city": "Medicare PPO-SCRANTON", "insurancepackageid": "404775" },
    { "insuranceproducttypename-city": "OTHER-NASHVILLE", "insurancepackageid": "62543" },
    { "insuranceproducttypename-city": "Medicare HMO-TAMPA", "insurancepackageid": "86362" },
    { "insuranceproducttypename-city": "Medicare HMO-EAGAN", "insurancepackageid": "523532" },
    { "insuranceproducttypename-city": "POS-EL PASO", "insurancepackageid": "16991" },
    { "insuranceproducttypename-city": "POS-DALLAS", "insurancepackageid": "455673" }
  ];

  insuranceControl = new FormControl('', Validators.required);

  ngOnInit() {
    // Add the control to the form group
    this.formGroup.addControl(this.formName, this.insuranceControl);
    this.initializeControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['formGroup']) {
      this.initializeControl();
    }
  }

  // Prepopulate the form control if a value exists
  initializeControl() {
    const existingPackageId = this.formGroup.get(this.formName)?.value;

    if (existingPackageId !== null && existingPackageId !== undefined) {
      const selectedOption = this.insuranceOptions.find(option => option.insurancepackageid === existingPackageId);
      if (selectedOption) {
        this.insuranceControl.setValue(selectedOption.insurancepackageid, { emitEvent: false });
      }
    }
  }

  // Function to handle selection change and update the form key
  onSelectionChange(packageId: string) {
    this.formGroup.get(this.formName)?.setValue(packageId);
  }

  isRequired(): boolean {
    const control = this.formGroup.get(this.formName);
    return control?.hasValidator(Validators.required) || false;
  }

  isValid(): boolean {
    return this.insuranceControl.valid;
  }
}