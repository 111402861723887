import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { NgIf, CommonModule } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { formObjectByType } from "../../functions/functions";
import { CustomRadioGroupComponent } from '../../reusable/custom-radio-group/custom-radio-group.component';
import { SharedFormService } from '../../services/SharedFormService.service';

@Component({
  selector: 'app-ccm-step-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    CustomRadioGroupComponent,
    CommonModule,
  ],
  templateUrl: './ccm-step-form.component.html',
  styleUrls: ['./ccm-step-form.component.css']
})
export class CcmStepFormComponent implements OnInit, OnDestroy {

  @Input() control?: FormControl;
  @Input() hospital_slug = '';
  @Input() form_id = 0;
  @Input() patch_values: any = {};
  @Input() formGroup?: FormGroup;
  @Input() step = '';

  formValues: any = {};
  form_loaded = false;
  myForm = new FormGroup({});
  checkboxItems: any = {};
  radioItems: any = {};
  inputItems: any = {};
  formsCCM: any = {};
  destroySubject = new Subject<void>();

  constructor(private translateService: TranslateService, private sharedFormService: SharedFormService
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    this.loadTranslations();
    this.myForm.get('interestedJoiningCCM')?.valueChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe(value => {
        const showCCM = value === 'yes';
        this.sharedFormService.setShowCCM(showCCM);
      });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  loadTranslations() {
    const currentLang = this.translateService.currentLang;
    const lang_form = `${currentLang}-${this.form_id}`;

    this.translateService.getTranslation(lang_form)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(translations => {
        const { healent, [this.hospital_slug]: hospitalTranslations } = translations;
        const formTranslations = healent?.[this.step] ?? {};
        const slugTranslations = hospitalTranslations?.[this.step] ?? {};
        this.formsCCM = { ...formTranslations, ...slugTranslations };

        const formObject = formObjectByType(formTranslations, slugTranslations, this.myForm, this.step);
        this.myForm = formObject.myForm;
        this.radioItems = formObject.radioItems;
        this.inputItems = formObject.inputItems;
        this.checkboxItems = formObject.checkboxItems;

        if (this.patch_values) {
          this.myForm.patchValue(this.patch_values, { emitEvent: false });
        }

        this.applyInitialValidators();
        this.syncControlWithForm();

        this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(() => {
          this.formValues = this.myForm.getRawValue();
          this.syncControlWithForm();
        });

        this.form_loaded = true;
        this.monitorCheckboxChanges();
      });
  }

  applyInitialValidators() {
    this.setValidatorsBasedOnValue('enrolledWithOtherProvider', ['no', 'notSure'], 'interestedJoiningCCM', this.firstValidators.bind(this));
    this.setValidatorsBasedOnValue('interestedJoiningCCM', ['no'], 'learnMoreAboutCCM', this.secondValidators.bind(this));
  }

  monitorCheckboxChanges() {
    this.setupChangeListener('enrolledWithOtherProvider', ['no', 'notSure'], this.firstValidators.bind(this));
    this.setupChangeListener('interestedJoiningCCM', ['no'], this.secondValidators.bind(this));
  }

  setupChangeListener(controlName: string, validValues: string[], callback: (isChecked: boolean) => void) {
    this.myForm.get(controlName)?.valueChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe(value => callback(validValues.includes(value)));
  }

  setValidatorsBasedOnValue(controlName: string, validValues: string[], targetControlName: string, callback: (isChecked: boolean) => void) {
    const controlValue = this.myForm.get(controlName)?.value;
    callback(validValues.includes(controlValue));
  }

  syncControlWithForm() {
    this.control?.setValue(this.myForm.getRawValue(), { emitEvent: true });
    this.formGroup = this.myForm;
  }

  firstValidators(isChecked: boolean) {
    this.toggleValidators('interestedJoiningCCM', isChecked);
  }

  secondValidators(isChecked: boolean) {
    this.toggleValidators('learnMoreAboutCCM', isChecked);
  }

  toggleValidators(controlName: string, isChecked: boolean) {
    const control = this.myForm.get(controlName);
    if (isChecked) {
      control?.setValidators([Validators.required]);
    } else {
      control?.clearValidators();
      control?.reset();
    }
    control?.updateValueAndValidity();
    if (controlName === 'interestedJoiningCCM') {
      const showCCM = control?.value === 'yes';
      this.sharedFormService.setShowCCM(showCCM);
    }
    this.myForm.get('interestedJoiningCCM')?.valueChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe(value => {
        const showCCM = value === 'yes';
        this.sharedFormService.setShowCCM(showCCM);
      });
    this.sharedFormService.updateFormValues(this.myForm.value);

  }
}
