import { Component, Input } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-custom-checkbox',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    CommonModule
  ],
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.css']
})
export class CustomCheckboxComponent {
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() errorMessages!: { [key: string]: string }; // Added errorMessages for error handling

  // Getter for the form control
  get control(): FormControl {
    const control = this.formGroup.get(this.controlName);
    if (control instanceof FormControl) {
      return control;
    } else {
      throw new Error("The control is not a FormControl");
    }
  }
  get isRequired(): boolean {
    const control = this.formGroup.get(this.controlName);

    if (control && control.validator) {
      const validator = control.validator({} as AbstractControl);
      return validator?.['required'] !== undefined;
    }

    return false;
  }
}