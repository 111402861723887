<div class="h-body-selector selector">
  <div class="bottom-title">Select how the pain feels</div>
  <mat-radio-group
    aria-labelledby="radio-group-label"
    class="bottom-radio"
    (ngModelChange)="select($event)"
    [(ngModel)]="selectedItem"
    *ngFor="let item of items"
  >
    <mat-radio-button class="radio-button" [value]="item?.value">{{
      item?.title | translate
    }}</mat-radio-button>
  </mat-radio-group>
</div>
