<form [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <app-custom-checkbox
      [formGroup]="myForm"
      controlName="applicableForGuardian"
      *ngIf="checkboxItems['applicableForGuardian']?.is_include"
      label="{{ checkboxItems['applicableForGuardian']['title'] }}"
    >
    </app-custom-checkbox>
    <div *ngIf="myForm.get('applicableForGuardian')?.value">
      <app-custom-input
        [formGroup]="myForm"
        controlName="lastName"
        *ngIf="inputItems['lastName']?.is_include"
        placeholder="{{ inputItems['lastName']['placeholder'] }}"
        [iconClass]="inputItems['lastName']?.icon"
        [maxlength]="20"
      ></app-custom-input>
      <app-custom-input
        [formGroup]="myForm"
        controlName="firstName"
        *ngIf="inputItems['firstName']?.is_include"
        placeholder="{{ inputItems['firstName']['placeholder'] }}"
        [iconClass]="inputItems['firstName']?.icon"
        [maxlength]="20"
      ></app-custom-input>

      <div class="middleName">
        <app-custom-input
          [formGroup]="myForm"
          controlName="middleName"
          *ngIf="inputItems['middleName']?.is_include"
          placeholder="{{ inputItems['middleName']['placeholder'] }}"
          [maxlength]="20"
        ></app-custom-input>
        <app-custom-input
          [formGroup]="myForm"
          controlName="suffix"
          *ngIf="inputItems['suffix']?.is_include"
          placeholder="{{ inputItems['suffix']['placeholder'] }}"
          [maxlength]="20"
        ></app-custom-input>
      </div>
      <app-custom-select
        [formGroup]="myForm"
        *ngIf="radioItems['relationshipToThePatient']['is_include']"
        controlName="relationshipToThePatient"
        [label]="radioItems['relationshipToThePatient']?.title"
        [optionsTitle]="radioItems['relationshipToThePatient']?.options_title"
        [options]="radioItems['relationshipToThePatient']?.options"
        [iconClass]="radioItems['relationshipToThePatient']?.icon"
      >
      </app-custom-select>
    </div>
  </ng-container>
</form>
