import { Component, Input, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { MatFormField, MatFormFieldModule, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { TranslateService } from "@ngx-translate/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatIcon } from "@angular/material/icon";
import { MatIconButton } from "@angular/material/button";
import { NgIf } from "@angular/common";
import { MatCheckbox } from "@angular/material/checkbox";
import { formObjectByType } from "../../functions/functions";
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CustomSelectComponent } from '../../reusable/custom-select/custom-select.component';
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';
import { CustomRadioGroupComponent } from '../../reusable/custom-radio-group/custom-radio-group.component';
import { CustomDatePickerComponent } from '../../reusable/custom-date-picker/custom-date-picker.component';
import { UploadComponent } from '../../reusable/upload-components/upload/upload.component';
import { SsnComponent } from '../../reusable/ssn/ssn.component';
import { InsurancePackageComponent } from '../../reusable/insurance-package/insurance-package.component';
import { ImageUploaderComponent } from '../../reusable/image-uploader/image-uploader.component';
import { SharedFormService } from '../../services/SharedFormService.service';

@Component({
  selector: 'app-patient-insurance-step-form',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatIcon,
    MatIconButton,
    NgIf,
    MatFormFieldModule,
    MatCheckbox,
    CustomCheckboxComponent,
    CustomSelectComponent,
    CustomInputComponent,
    CustomRadioGroupComponent,
    CustomDatePickerComponent,
    UploadComponent,
    SsnComponent,
    InsurancePackageComponent,
    ImageUploaderComponent
  ],
  templateUrl: './patient-insurance-step-form.component.html',
  styleUrl: './patient-insurance-step-form.component.css',
})
export class PatientInsuranceStepFormComponent implements OnInit, OnDestroy {

  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';
  formValues: any = {};
  myForm!: FormGroup;
  checkboxItems: any = {};
  uploadItems: any = {};
  inputItems: any = {};
  radioItems: any = {}
  form_loaded: boolean = false;
  minDate!: Date;
  maxDate!: Date;

  destroySubject = new Subject<void>();

  constructor(private translateService: TranslateService,
    private _bottomSheet: MatBottomSheet,
    private fb: FormBuilder,
    private sharedFormService: SharedFormService
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    const today = new Date();
    this.minDate = today;
    this.maxDate = today;

    this.myForm = new FormGroup({});
    const currentLang = this.translateService.currentLang;
    let lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(translations => {
        const translationsObj = translations['healent'][this.step];
        const translationsObjSlug = translations[this.hospital_slug][this.step];

        /* UPLOADS */
        this.uploadItems = translationsObj['uploads'];
        for (let [key, value] of Object.entries(this.uploadItems as Object)) {
          const formGroupName = `${key}`;
          const nestedFormGroup = new FormGroup({});
          if (value.hasOwnProperty('required') && value['required'] === true) {
            nestedFormGroup.setValidators(Validators.required);
          }
          this.myForm.addControl(formGroupName, new FormControl(''));
        }

        let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step);
        this.myForm = formObject['myForm'];
        this.inputItems = formObject['inputItems'];
        this.radioItems = formObject['radioItems'];
        this.checkboxItems = formObject['checkboxItems'];

        if (this.patch_values) {
          this.myForm.patchValue(this.patch_values, { emitEvent: false });
        }

        this.control?.setValue(this.myForm.value, { emitEvent: true }); // set initial value
        this.formGroup = this.myForm;

        this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(val => {
          this.formValues = val;
          this.control?.setValue(this.myForm.value, { emitEvent: true });
          this.formGroup = this.myForm;
        });

        this.onValueChanges();
        this.form_loaded = true;

        const healthInsuranceControl = this.myForm.get('doYouHaveHealthInsurance');
        const secondaryInsuranceControl = this.myForm.get('doYouHaveSecondaryHealthInsurance');

        if (healthInsuranceControl) {
          healthInsuranceControl.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(checked => {
            this.togglePrimaryAndSecondaryControls(checked === 'yes');
          });
        }

        /*       if (secondaryInsuranceControl) {
                secondaryInsuranceControl.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(checked => {
                  this.toggleSecondaryInsuranceControls(checked === 'yes');
                });
              } */
        const medicaidControl = this.myForm.get('isYourInsuranceAssociatedWithMedicaid');
        if (medicaidControl) {
          medicaidControl.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(value => {
            this.sharedFormService.updateMedicaidStatus(value); // Update SharedFormService with new value
          });
        }
      }); // end translateService.getTranslation
  }
  // end ngOnInit

  private togglePrimaryAndSecondaryControls(enable: boolean): void {
    const primaryInsuranceControls = [
      this.myForm.get('primaryInsurance'),
      /*     this.myForm.get('insurancePackage'),
          this.myForm.get('cardholder'),
          this.myForm.get('dob'),
          this.myForm.get('ssn'),
          this.myForm.get('isYourInsuranceAssociatedWithMedicaid'), */
    ];

    /*     const secondaryInsuranceControls = [
          this.myForm.get('secondaryInsurance'),
          this.myForm.get('secondaryInsurancePackage'),
          this.myForm.get('secondary_is_cardholder'),
          this.myForm.get('secondaryCardholder'),
          this.myForm.get('secondaryDob'),
          this.myForm.get('secondarySsn'),
        ]; */

    primaryInsuranceControls.forEach(control => {
      if (control) {
        if (enable) {
          control.setValidators([Validators.required]);
        } else {
          control.clearValidators();
          control.setValue(null); // Clear value directly
        }
        control.updateValueAndValidity();
      }
    });
  }

  /*  private toggleSecondaryInsuranceControls(enable: boolean): void {
     const secondaryInsuranceControls = [
       this.myForm.get('secondaryInsurance'),
       this.myForm.get('secondaryInsurancePackage'),
       this.myForm.get('secondaryCardholder'),
       this.myForm.get('secondaryDob'),
       this.myForm.get('secondarySsn'),
     ];
 
     secondaryInsuranceControls.forEach(control => {
       if (control) {
         if (enable) {
           control.setValidators([Validators.required]);
         } else {
           control.clearValidators();
           control.setValue(null);
         }
         control.updateValueAndValidity();
       }
     });
   } */

  onValueChanges() {
    const cardholderControl = this.myForm.get('is_cardholder');
    if (cardholderControl) {
      cardholderControl.valueChanges.subscribe((checked) => {
        this.toggleCardholderControls(checked, 'cardholder', 'dob', 'ssn');
      });
    }
    const secondaryCardholderControl = this.myForm.get('secondary_is_cardholder');
    if (secondaryCardholderControl) {
      secondaryCardholderControl.valueChanges.subscribe((checked) => {
        this.toggleCardholderControls(checked, 'secondaryCardholder', 'secondaryDob', 'secondarySsn');
      });
    }
  }

  private toggleCardholderControls(enable: boolean, ...controlNames: string[]): void {
    controlNames.forEach(name => {
      const control = this.myForm.get(name);
      if (control) {
        if (enable) {
          control.setValidators([Validators.required]);
        } else {
          control.clearValidators();
          control.setValue(null); // Clear value directly
        }
        control.updateValueAndValidity();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

} // end PatientInsuranceStepFormComponent
