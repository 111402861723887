import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        // Never store routes for reuse
        return false;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
        // No implementation needed as we're not storing routes
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        // Always return false as we're not reattaching stored routes
        return false;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        // Always return null as we're not retrieving stored routes
        return null;
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        // Reuse only if the route configuration and parameters match exactly
        return future.routeConfig === current.routeConfig && JSON.stringify(future.params) === JSON.stringify(current.params);
    }
}
