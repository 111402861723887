<form class="m-t-m" [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <app-custom-input
      [formGroup]="myForm"
      controlName="name"
      placeholder="{{ inputItems['name']['placeholder'] }}"
      *ngIf="inputItems['name']?.is_include"
      [iconClass]="inputItems['name']?.icon"
      [maxlength]="20"
    ></app-custom-input>
    <app-custom-select
      [formGroup]="myForm"
      *ngIf="radioItems['relationshipToThePatient']?.is_include"
      controlName="relationshipToThePatient"
      [label]="radioItems['relationshipToThePatient']?.title"
      [optionsTitle]="radioItems['relationshipToThePatient']?.options_title"
      [options]="radioItems['relationshipToThePatient']?.options"
      [iconClass]="radioItems['relationshipToThePatient']?.icon"
    >
    </app-custom-select>
    <app-phone
      [formGroup]="myForm"
      *ngIf="inputItems['phoneNumber']?.is_include"
      [title]="inputItems['phoneNumber']['placeholder']"
      formName="phoneNumber"
    ></app-phone>
  </ng-container>
</form>
