import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StepProgressService {
  stepChange: EventEmitter<number> = new EventEmitter();
  private totalSteps: number = 11;
  private currentStep: number = 1;

  constructor() { }

  getCurrentStep(): number {
    return this.currentStep;
  }

  getTotalSteps(): number {
    return this.totalSteps;
  }

  emitStepChange(step: number): void {
    this.currentStep = step;
    this.stepChange.emit(step);
  }
}
