<form class="m-t-m d-block" [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <app-custom-input
      [formGroup]="myForm"
      controlName="employerName"
      *ngIf="inputItems['employerName']?.is_include"
      placeholder="{{ inputItems['employerName']['placeholder'] }}"
      [iconClass]="inputItems['employerName']?.icon"
    ></app-custom-input>
    <app-phone
      [formGroup]="myForm"
      *ngIf="inputItems['employerPhone']?.is_include"
      [title]="inputItems['employerPhone']['placeholder']"
      formName="employerPhone"
    ></app-phone>
    <app-custom-radio-group
      [formGroup]="myForm"
      controlName="status"
      *ngIf="radioItems['status']?.is_include"
      label="{{ radioItems['status']['title'] }}"
      [options]="radioItems['status']['options']"
      class="m-b-l m-t-l d-block"
    >
    </app-custom-radio-group>
    <app-custom-input
      [formGroup]="myForm"
      controlName="usualOccupation"
      *ngIf="inputItems['usualOccupation']?.is_include"
      placeholder="{{ inputItems['usualOccupation']['placeholder'] }}"
      [iconClass]="inputItems['usualOccupation']?.icon"
    ></app-custom-input>
    <app-custom-input
      [formGroup]="myForm"
      controlName="usualIndustry"
      *ngIf="inputItems['usualIndustry']?.is_include"
      placeholder="{{ inputItems['usualIndustry']['placeholder'] }}"
      [iconClass]="inputItems['usualIndustry']?.icon"
    ></app-custom-input>
    <app-custom-checkbox
      [formGroup]="myForm"
      controlName="isWorkVehicleRelated"
      *ngIf="checkboxItems['isWorkVehicleRelated']?.is_include"
      label="{{ checkboxItems['isWorkVehicleRelated']['title'] }}"
    >
    </app-custom-checkbox>
    <div *ngIf="myForm.get('isWorkVehicleRelated')?.value">
      <app-custom-date-picker
        [formGroup]="myForm"
        controlName="injuryDate"
        *ngIf="inputItems['injuryDate']?.is_include"
        label="{{ inputItems['injuryDate']['placeholder'] }}"
        [minDate]="minDate"
        [maxDate]="maxDate"
      >
      </app-custom-date-picker>
      <app-custom-input
        [formGroup]="myForm"
        controlName="claim"
        *ngIf="inputItems['claim']?.is_include"
        [iconClass]="inputItems['claim']?.icon"
        placeholder="{{ inputItems['claim']['placeholder'] }}"
        [type]="'number'"
      ></app-custom-input>
      <app-custom-input
        [formGroup]="myForm"
        controlName="caseManager"
        *ngIf="inputItems['caseManager']?.is_include"
        [iconClass]="inputItems['caseManager']?.icon"
        placeholder="{{ inputItems['caseManager']['placeholder'] }}"
      ></app-custom-input>
    </div>
  </ng-container>
</form>
