<div class="bottom-slider">
  <div class="slider-title">{{ title | translate }}</div>
  <mat-slider
    [color]="'primary'"
    min="0"
    max="10"
    step="1"
    class="w-90"
    discrete
    [displayWith]="formatLabel"
    [class]="getSliderThumbClass()"
  >
    <input matSliderThumb [(ngModel)]="value" />
  </mat-slider>
  <div class="labels">
    <span style="text-align: left"
      >No <br />
      Pain</span
    >
    <span style="text-align: right"
      >Unbearable <br />
      Pain</span
    >
  </div>
</div>
