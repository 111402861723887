import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { UploadComponent } from '../upload-components/upload/upload.component';

@Component({
  selector: 'app-image-uploader',
  standalone: true,
  imports: [CommonModule, UploadComponent],
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.css']
})
export class ImageUploaderComponent {
  @Input() formGroup!: FormGroup;
  @Input() uploadItems: any;
  @Input() controlName!: string;
  @Input() frontFormName: string = 'front';
  @Input() backFormName: string = 'back';

  frontImage: string | null = null;
  backImage: string | null = null;
  combinedImage: string | null = null;

  ngOnInit() {
    if (this.formGroup?.value[this.frontFormName]) {
      this.frontImage = this.formGroup.value[this.frontFormName] ?? null;
    }
    if (this.formGroup?.value[this.backFormName]) {
      this.backImage = this.formGroup.value[this.backFormName] ?? null;
    }

    if (this.frontImage || this.backImage) {
      this.combineImages();
    }
  }

  onImageSet(imageData: string | null, imageType: 'front' | 'back') {
    if (imageType === 'front') {
      this.frontImage = imageData ?? null;
    } else {
      this.backImage = imageData ?? null;
    }
    this.combineImages();
  }

  combineImages() {
    // Check if both images are missing; if so, return early.
    if (!this.frontImage && !this.backImage) {
      return;
    }

    const frontImg = new Image();
    const backImg = new Image();

    // Set the image sources, handling null values.
    frontImg.src = this.frontImage || '';
    backImg.src = this.backImage || '';

    const MAX_WIDTH = 800;

    const loadImage = (img: HTMLImageElement) => {
      return new Promise<HTMLImageElement | null>((resolve) => {
        img.onload = () => resolve(img);
        img.onerror = () => resolve(null); // Resolve with null if the image fails to load.
      });
    };

    Promise.all([loadImage(frontImg), loadImage(backImg)]).then((images) => {
      // Filter out any null images.
      const loadedImages = images.filter((img): img is HTMLImageElement => img !== null);

      // If no images are loaded, exit early.
      if (loadedImages.length === 0) {
        return;
      }

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      // Calculate dimensions only for images that are available.
      const scales = loadedImages.map((img) => MAX_WIDTH / img.width);
      const widths = loadedImages.map((img) => Math.min(img.width, MAX_WIDTH));
      const heights = loadedImages.map((img, index) => img.height * scales[index]);

      // Set canvas size based on the loaded images.
      canvas.width = MAX_WIDTH;
      canvas.height = heights.reduce((acc, height) => acc + height, 0);

      let currentHeight = 0;

      if (context) {
        loadedImages.forEach((img, index) => {
          context.drawImage(img, 0, currentHeight, widths[index], heights[index]);
          currentHeight += heights[index];
        });

        // Convert the canvas to a blob and patch the form value.
        canvas.toBlob((blob) => {
          if (blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              this.combinedImage = reader.result as string | null;
              if (this.formGroup && this.controlName) {
                // Update form with the combined image, using an empty string if combinedImage is null.
                this.formGroup.patchValue({ [this.controlName]: this.combinedImage || '' });
              }
            };
          }
        }, 'image/jpeg', 0.7);
      }
    });
  }



}
