import { Component, OnInit } from '@angular/core';
import { SharedFormService } from '../../../../../services/SharedFormService.service';

@Component({
  selector: 'app-cwps-ccm-consent',
  standalone: true,
  imports: [],
  templateUrl: './cwps-ccm-consent.component.html',
  styleUrl: './cwps-ccm-consent.component.css'
})
export class CwpsCcmConsentComponent implements OnInit {
  patientName: string = '';
  guardian: string = '';

  constructor(private sharedFormService: SharedFormService) { }

  ngOnInit(): void {

  }
}
