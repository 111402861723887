<form [formGroup]="myForm" class="select-after" style="margin-top: -16px">
  <ng-container *ngIf="form_loaded">
    <app-custom-radio-group
      [formGroup]="myForm"
      controlName="doYouHaveNeckPain"
      label="{{ radioItems['doYouHaveNeckPain']['title'] }}"
      [options]="radioItems['doYouHaveNeckPain']['options']"
      *ngIf="radioItems['doYouHaveNeckPain']?.is_include"
      class="flex-row-reverse"
    >
    </app-custom-radio-group>
    <div
      class="m-t-m d-block"
      *ngIf="myForm.get('doYouHaveNeckPain')?.value === 'yes'"
    >
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['neckPainIntensity']['is_include']"
        controlName="neckPainIntensity"
        [label]="radioItems['neckPainIntensity']?.title"
        [options]="radioItems['neckPainIntensity']?.options"
        [iconClass]="radioItems['neckPainIntensity']?.icon"
        [hint]="radioItems['neckPainIntensity']?.hint"
        [questionId]="radioItems['neckPainIntensity']?.questionId"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['neckPersonalCare']['is_include']"
        controlName="neckPersonalCare"
        [label]="radioItems['neckPersonalCare']?.title"
        [options]="radioItems['neckPersonalCare']?.options"
        [iconClass]="radioItems['neckPersonalCare']?.icon"
        [hint]="radioItems['neckPersonalCare']?.hint"
        [questionId]="radioItems['neckPersonalCare']?.questionId"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['neckLifting']['is_include']"
        controlName="neckLifting"
        [label]="radioItems['neckLifting']?.title"
        [options]="radioItems['neckLifting']?.options"
        [iconClass]="radioItems['neckLifting']?.icon"
        [hint]="radioItems['neckLifting']?.hint"
        [questionId]="radioItems['neckLifting']?.questionId"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['neckReading']['is_include']"
        controlName="neckReading"
        [label]="radioItems['neckReading']?.title"
        [options]="radioItems['neckReading']?.options"
        [iconClass]="radioItems['neckReading']?.icon"
        [hint]="radioItems['neckReading']?.hint"
        [questionId]="radioItems['neckReading']?.questionId"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['neckHeadaches']['is_include']"
        controlName="neckHeadaches"
        [label]="radioItems['neckHeadaches']?.title"
        [options]="radioItems['neckHeadaches']?.options"
        [iconClass]="radioItems['neckHeadaches']?.icon"
        [hint]="radioItems['neckHeadaches']?.hint"
        [questionId]="radioItems['neckHeadaches']?.questionId"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['neckConcentration']['is_include']"
        controlName="neckConcentration"
        [label]="radioItems['neckConcentration']?.title"
        [options]="radioItems['neckConcentration']?.options"
        [iconClass]="radioItems['neckConcentration']?.icon"
        [hint]="radioItems['neckConcentration']?.hint"
        [questionId]="radioItems['neckConcentration']?.questionId"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['neckWork']['is_include']"
        controlName="neckWork"
        [label]="radioItems['neckWork']?.title"
        [options]="radioItems['neckWork']?.options"
        [iconClass]="radioItems['neckWork']?.icon"
        [hint]="radioItems['neckWork']?.hint"
        [questionId]="radioItems['neckWork']?.questionId"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['neckDriving']['is_include']"
        controlName="neckDriving"
        [label]="radioItems['neckDriving']?.title"
        [options]="radioItems['neckDriving']?.options"
        [iconClass]="radioItems['neckDriving']?.icon"
        [hint]="radioItems['neckDriving']?.hint"
        [questionId]="radioItems['neckDriving']?.questionId"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['neckSleeping']['is_include']"
        controlName="neckSleeping"
        [label]="radioItems['neckSleeping']?.title"
        [options]="radioItems['neckSleeping']?.options"
        [iconClass]="radioItems['neckSleeping']?.icon"
        [hint]="radioItems['neckSleeping']?.hint"
        [questionId]="radioItems['neckSleeping']?.questionId"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
      <app-select-as-array
        [formGroup]="myForm"
        *ngIf="radioItems['neckRecreation']['is_include']"
        controlName="neckRecreation"
        [label]="radioItems['neckRecreation']?.title"
        [options]="radioItems['neckRecreation']?.options"
        [iconClass]="radioItems['neckRecreation']?.icon"
        [hint]="radioItems['neckRecreation']?.hint"
        [questionId]="radioItems['neckRecreation']?.questionId"
        [hasScore]="true"
        class="m-t-l d-block"
      >
      </app-select-as-array>
    </div>
  </ng-container>
</form>
