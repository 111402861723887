import { Component, OnInit } from '@angular/core';
import { SharedFormService } from '../../../../../services/SharedFormService.service';

@Component({
  selector: 'app-cwps-rtm-consent',
  standalone: true,
  imports: [],
  templateUrl: './cwps-rtm-consent.component.html',
  styleUrl: './cwps-rtm-consent.component.css'
})
export class CwpsRtmConsentComponent implements OnInit {
  patientName: string = '';
  patientPhone: string = '';
  date: string = '';

  constructor(private sharedFormService: SharedFormService) { }

  ngOnInit(): void {

  }
}
