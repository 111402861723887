import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { MatError, MatFormField } from "@angular/material/form-field";
import { KeyValuePipe, NgForOf, NgIf, CommonModule } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { formObjectByType } from "../../functions/functions";
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CustomSelectComponent } from '../../reusable/custom-select/custom-select.component';
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';
import { CustomRadioGroupComponent } from '../../reusable/custom-radio-group/custom-radio-group.component';
import { CustomDatePickerComponent } from '../../reusable/custom-date-picker/custom-date-picker.component';
import { PhoneComponent } from '../../reusable/phone/phone.component';
import { AddressComponent } from '../../reusable/address/address.component';

@Component({
  selector: 'app-primary-care-physician-step',
  standalone: true,
  imports: [
    MatFormField,
    ReactiveFormsModule,
    NgIf,
    MatError,
    KeyValuePipe,
    NgForOf,
    CustomCheckboxComponent,
    CustomSelectComponent,
    CustomInputComponent,
    CustomRadioGroupComponent,
    CustomDatePickerComponent,
    CommonModule,
    AddressComponent,
    PhoneComponent
  ],
  templateUrl: './primary-care-physician-step.component.html',
  styleUrls: ['./primary-care-physician-step.component.css']
})
export class PrimaryCarePhysicianStepComponent implements OnInit, OnDestroy {

  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';

  formValues: any = {};
  form_loaded = false;
  myForm!: FormGroup;
  checkboxItems: any = {};
  radioItems: any = {};
  inputItems: any = {};
  destroySubject = new Subject<void>();

  constructor(private translateService: TranslateService) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    this.myForm = new FormGroup({});
    const currentLang = this.translateService.currentLang;
    const lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {
      const translationsObj = translations['healent'][this.step];
      const translationsObjSlug = translations[this.hospital_slug][this.step];
      let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step);
      this.myForm = formObject['myForm'];
      this.radioItems = formObject['radioItems'];
      this.inputItems = formObject['inputItems'];
      this.checkboxItems = formObject['checkboxItems'];

      if (this.patch_values) {
        this.myForm.patchValue(this.patch_values, { emitEvent: false });
      }

      if (this.myForm.get('doYouHavePrimaryCarePhysician')?.value === 'yes') {
        this.applyPrimaryCarePhysicianValidators(true);
      }

      this.control?.setValue(this.myForm.getRawValue(), { emitEvent: true });
      this.formGroup = this.myForm;

      this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(val => {
        this.formValues = val;
        this.control?.setValue(this.myForm.getRawValue(), { emitEvent: true });
        this.formGroup = this.myForm;
      });

      this.form_loaded = true;

      this.onCheckboxChange();
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onCheckboxChange() {
    this.myForm.get('doYouHavePrimaryCarePhysician')?.valueChanges.subscribe(value => {
      this.applyPrimaryCarePhysicianValidators(value === 'yes');
    });
  }

  applyPrimaryCarePhysicianValidators(isChecked: boolean) {
    const primaryCarePhysicianFirstName = this.myForm.get('primaryCarePhysicianFirstName');
    const primaryCarePhysicianLastName = this.myForm.get('primaryCarePhysicianLastName');

    if (isChecked) {
      primaryCarePhysicianFirstName?.setValidators([Validators.required]);
      primaryCarePhysicianLastName?.setValidators([Validators.required]);
    } else {
      primaryCarePhysicianFirstName?.clearValidators();
      primaryCarePhysicianLastName?.clearValidators();
      primaryCarePhysicianFirstName?.reset();
      primaryCarePhysicianLastName?.reset();
    }

    primaryCarePhysicianFirstName?.updateValueAndValidity();
    primaryCarePhysicianLastName?.updateValueAndValidity();
  }
}
