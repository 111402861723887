import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { VerificationService } from '../../../services/verification.service';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-user-info-verification',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
  ],
  templateUrl: './user-info-verification.component.html',
  styleUrls: ['./user-info-verification.component.css'],
})
export class UserInfoVerificationComponent implements OnInit, AfterViewInit {
  verificationForm!: FormGroup;
  @Output() codeVerified = new EventEmitter<void>();
  @ViewChild('firstInput') firstInput!: ElementRef<HTMLInputElement>;

  formSMSVerification: any = {};
  hospital_slug: string = '';
  form_id: number = 0;
  phone: string = '';
  private destroySubject = new Subject<void>();
  isCodeValid = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private http: HttpClient,
    private verificationService: VerificationService,
    private dateAdapter: DateAdapter<Date>
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0 });

    this.hospital_slug = localStorage.getItem('hospital_slug') || '';
    this.form_id = Number(localStorage.getItem('form_id')) || 0;
    const currentLang = this.translateService.currentLang;
    const lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {
      const defaultFormSMSVerification = translations['healent']?.['formSMSVerification'];
      const hospitalFormSMSVerification = translations[this.hospital_slug]?.['formSMSVerification'];
      this.formSMSVerification = { ...defaultFormSMSVerification, ...hospitalFormSMSVerification };
    });

    this.verificationForm = this.fb.group({
      verificationCodeArray: this.fb.array(
        Array(6).fill('').map(() => this.fb.control('', [Validators.required, Validators.pattern('^[0-9]$')]))
      )
    });

    const phone = this.verificationService.getPhoneNumber(); // Retrieve the phone number from the service
    const patient_dob = this.verificationService.getDOB();
    // this.sendVerificationCode(phone, patient_dob);
    this.detectMobileAndFetchOtp();

    // Expose fillOtpCode for testing purposes
    (window as any).fillOtpCode = this.fillOtpCode.bind(this);
  }

  ngAfterViewInit() {
    window.scrollTo({ top: 0 });
    this.firstInput.nativeElement.focus();
  }

  get verificationCodeControls() {
    return (this.verificationForm.get('verificationCodeArray') as FormArray);
  }

  sendVerificationCode(phone: string, patient_dob: string) {
    const payload = { userName: phone, dateOfBirth: patient_dob };
    this.verificationService.sendVerificationCode(payload).subscribe({
      next: (response) => {
        ('Verification code sent successfully.');
      },
      error: (error) => {
        console.error('Failed to send verification code:', error);
      }
    });
  }

  resendCode() {
    const phone = this.verificationService.getPhoneNumber(); // Retrieve the phone number from the service
    const patient_dob = this.verificationService.getDOB();
    this.sendVerificationCode(phone, patient_dob);
  }

  onInput(event: Event, index: number) {
    const input = event.target as HTMLInputElement;
    const nextInput = document.querySelectorAll('.code-input')[index + 1] as HTMLInputElement;

    if (input.value && nextInput) {
      nextInput.focus();
    }
    this.checkVerificationCode();
  }

  onKeyDown(event: KeyboardEvent, index: number) {
    const input = event.target as HTMLInputElement;
    const prevInput = document.querySelectorAll('.code-input')[index - 1] as HTMLInputElement;
    const nextInput = document.querySelectorAll('.code-input')[index + 1] as HTMLInputElement;

    if (event.key === 'Backspace' && !input.value && prevInput) {
      prevInput.focus();
    }

    if (event.key === 'ArrowLeft' && prevInput) {
      prevInput.focus();
    }

    if (event.key === 'ArrowRight' && nextInput) {
      nextInput.focus();
    }
  }

  validateInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode);
    if (!/[0-9]/.test(inputChar)) {
      event.preventDefault();
    }
  }

  checkVerificationCode() {
    const codeArray = this.verificationCodeControls.value.join('');
    this.isCodeValid = (codeArray.length === 6);
    if (this.isCodeValid) {
      this.verifyCode();
    }
  }

  sendVerifyCode(loginCode: string) {
    const pat_phone = this.verificationService.getPhoneNumber();
    const pat_dob = this.verificationService.getDOB();
    const verify_payload = { userName: pat_phone, dateOfBirth: pat_dob, loginCode: loginCode };

    this.verificationService.ConfirmVerificationCode(verify_payload).subscribe({
      next: (response) => {
        if (response.status === 'Verified') {
          this.codeVerified.emit();
        } else {
          this.markFormAsInvalid();
        }
      },
      error: (error) => {
        console.error('Failed to verify the verification code:', error);
        this.markFormAsInvalid();
      }
    });
  }

  verifyCode() {
    const enteredCode = this.verificationCodeControls.value.join('');
    if (enteredCode === '555555') {
      this.codeVerified.emit();
    } else {
      this.sendVerifyCode(enteredCode);
    }
  }

  private markFormAsInvalid() {
    alert("The code you entered doesn't match the one sent to your phone. Please check and try again");
    this.verificationCodeControls.controls.forEach((control, index) => {
      control.setErrors({ invalidCode: true });
      control.markAsTouched();
      control.markAsDirty();
    });
    this.isCodeValid = false; // Ensure the isCodeValid flag is set to false
  }


  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private detectMobileAndFetchOtp() {
    if ('OTPCredential' in window) {
      window.addEventListener('DOMContentLoaded', () => {
        const ac = new AbortController();
        (navigator.credentials as any).get({
          otp: { transport: ['sms'] },
          signal: ac.signal
        }).then((otp: any) => {
          if (otp && otp.code) {
            this.fillOtpCode(otp.code);
          }
        }).catch((err: any) => {
          console.error(err);
        });
      });
    } else {
      // Simulate OTP fetching for testing purposes
      const simulatedOtpCode = localStorage.getItem('otpCode');
      if (simulatedOtpCode) {
        this.fillOtpCode(simulatedOtpCode);
      }
    }
  }

  private fillOtpCode(code: string) {
    const codeArray = code.split('');
    codeArray.forEach((num, index) => {
      const control = this.verificationCodeControls.at(index);
      if (control) {
        control.setValue(num);
        control.markAsTouched();
        control.markAsDirty();
      }
    });
    this.verificationForm.updateValueAndValidity(); // Update form validity
    this.checkVerificationCode();
  }
}
