import { Component, EventEmitter, Input, Output, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { FormGroup } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { UploadFileComponent } from "../upload-file/upload-file.component";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { ApiService } from "../../../services/api.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-upload',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule
  ],
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UploadComponent implements AfterViewInit {
  @Output() setImage = new EventEmitter<any>();
  @Input() formGroup!: FormGroup;
  @Input() prefix!: string;
  @Input() icon!: string;
  @Input() desc!: string;
  @Input() title!: string;
  @Input() btn!: string;
  @Input() formName!: string;
  @Input() uploadImg!: string;
  @Input() placeholder!: string;
  sizeError = false;
  errorMessage!: string;
  uploadedUrl!: string;

  constructor(
    private bottomSheet: MatBottomSheet,
    private apiService: ApiService,
    private http: HttpClient
  ) { }

  get validation() {
    return this.formGroup.get(this.formName)?.valid;
  }

  ngAfterViewInit(): void {
    // Trigger the openActionSheet method automatically when the component is initialized
    this.openActionSheet();
  }

  openActionSheet(): void {
    this.sizeError = false;
    const bottomSheetRef = this.bottomSheet.open(UploadFileComponent, {
      data: { type: 'photo' },
    });

    bottomSheetRef.afterDismissed().subscribe((result) => {
      if (!result || !result.data) return;

      if (result.sizeError) {
        this.sizeError = true;
        this.errorMessage = result.message;
        this.setImage.emit(null);
        this.formGroup.patchValue({
          [this.formName]: null,
        });
        return;
      }

      const base64Image = result.data;
      const fileName = result.fileName;
      this.setImage.emit(base64Image);

      this.apiService.getSignedUrl(fileName).subscribe({
        next: (data: any) => {
          const preSignedUrl = data.message.presigned_url;
          this.uploadedUrl = preSignedUrl;
          if (preSignedUrl) {
            this.uploadImage(preSignedUrl, base64Image).subscribe({
              next: response => console.log('Upload successful!', response),
              error: error => console.error('Upload failed', error)
            });

            const fileData = {
              base64: base64Image,
              fileName: fileName,
              url: preSignedUrl
            };

            this.formGroup.patchValue({
              [this.formName]: fileData,
            });
          }
        },
        error: error => console.error('Failed to get pre-signed URL', error)
      });
    });
  }

  clearFile(): void {
    this.setImage.emit(null);
    this.formGroup.patchValue({
      [this.formName]: null,
    });
  }

  private uploadImage(url: string, imageData: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/octet-stream' });
    return this.http.put(url, this.convertBase64ToBlob(imageData), { headers });
  }

  private convertBase64ToBlob(base64: string): Blob {
    const parts = base64.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }
}
