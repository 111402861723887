<p>
  We want to ensure that patients and caregivers have clear communication and
  safe, effective procedures for obtaining controlled substances when medically
  necessary. There are a variety of medications and therapies available to help
  individuals with both acute and chronic pain. Our health care providers strive
  to ensure that each individual gets the most appropriate treatment for the
  individual's diagnosis or condition. Our office uses the state designated
  monitoring resources, which allows our health care providers to request
  information regarding a patient’s previously dispensed controlled substance
  prescriptions. This tool assists our health care providers in establishing the
  patient's treatment history and making treatment decisions.
</p>
<br />
<p>
  In order to comply with state and federal laws, it is important that you, as a
  patient, understand and follow this
  <strong>Controlled Substances Policy</strong>. All patients must sign this
  informed consent.
</p>
<br />

<p>
  1. All requests for controlled substances prescriptions must be made during
  normal business hours. Please plan accordingly as we will not prescribe any
  controlled substances, including refill prescriptions after-hours.
</p>
<br />

<p>
  2. If a prescription for controlled substances is lost or stolen, we will not
  reissue another prescription for controlled substances until your next fill
  due date at which time a determination by your health care provider in their
  sole discretion regarding the continued medical necessity of the controlled
  substances will be made. If your health care provider determines that
  controlled substances are no longer the most appropriate treatment option,
  they will not issue another prescription for controlled substances.
</p>
<br />

<p>
  3. If your condition or diagnosis requires an on-going prescription for
  controlled substances, you will need to see your health care provider in
  person at least every thirty (30) days and more frequently as may be
  determined by your health care provider in their sole discretion.
</p>
<br />

<p>
  4. If this practice is treating you for acute or chronic pain, it is important
  that we are the only prescribing health care providers for your condition.
</p>
<br />

<p>
  5. Our health care providers may lower the dose or decline to issue a
  controlled substance prescription at any time and may refer you for
  consultation with appropriate medical specialists in their sole discretion.
</p>
<br />

<p>
  6. As a condition of continued treatment by the practice, patients may be
  asked to submit to urine comprehensive drug screening or comparable
  alternative testing. Failure to comply with such requests for screening or
  screening results that indicate failure to comply with the prescribed dosage,
  multiple sources of controlled substances, or illegal or illicit drug usage
  may result in the patient being dismissed from the practice.
</p>
<br />

<p>
  7. If it comes to our attention that another health care provider outside the
  practice including a hospital or urgent care center is prescribing controlled
  substances to you, you may be dismissed from the practice Immediately at our
  sole discretion.
</p>
<br />

<p>
  I, ______________(Patient Name),<strong>
    acknowledge that I have read and acknowledge the above information. I
    understand that failing to comply with the Controlled Substances Policy may
    cause a delay in receiving my controlled substances prescription (or future
    prescriptions for controlled substances) or discharge from the practice in
    the practice's sole discretion.
  </strong>
</p>
