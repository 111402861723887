<div class="upload-dialog">
  <div class="upload-title"><b>Upload a file</b></div>
  <div
    class="option option-border"
    [className]="
      !cameraAvailable
        ? 'option option-border disable-camera'
        : 'option option-border'
    "
    (click)="onActionClick('camera')"
  >
    Camera
  </div>
  <div class="option" (click)="onActionClick('gallery')">Gallery</div>
  <input
    type="file"
    #fileInput
    style="display: none"
    (change)="onFileSelected($event)"
    accept="image/*"
  />
  <!--  <div class="option" (click)="onActionClick('cloud')">Cloud</div>-->
</div>
