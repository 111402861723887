<div [formGroup]="formGroup">
  <mat-checkbox [formControl]="control">
    {{ label }}
  </mat-checkbox>
  <!-- Hint and Error message container -->
  <div class="hintContainer">
    <!-- Display required/optional hint if no error -->
    <div>
      <span *ngIf="isRequired; else optionalField" class="hintRequired">
        *Required
      </span>
      <ng-template #optionalField>
        <span class="hintOptional">+Optional</span>
      </ng-template>
    </div>
  </div>
</div>
