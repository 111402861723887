<div class="h-body-selector">
  <h2 class="h-body-select-title">{{ title }}</h2>
  <p class="h-body-select-placeholder">{{ placeholder }}</p>
  <mat-button-toggle-group
    (change)="selectBodyDirection($event.value)"
    [value]="bodyDirection"
  >
    <mat-button-toggle value="front">Front</mat-button-toggle>
    <mat-button-toggle value="right">Right</mat-button-toggle>
    <mat-button-toggle value="back">Back</mat-button-toggle>
    <mat-button-toggle value="left">Left</mat-button-toggle>
  </mat-button-toggle-group>
  <div class="body-selector" #bodyContainer>
    <app-body-front
      [class.hidden]="bodyDirection !== 'front'"
      (svgClick)="handleSvgClick($event)"
    ></app-body-front>
    <app-body-back
      [class.hidden]="bodyDirection !== 'back'"
      (svgClick)="handleSvgClick($event)"
    ></app-body-back>
    <app-body-left
      [class.hidden]="bodyDirection !== 'left'"
      (svgClick)="handleSvgClick($event)"
    ></app-body-left>
    <app-body-right
      [class.hidden]="bodyDirection !== 'right'"
      (svgClick)="handleSvgClick($event)"
    ></app-body-right>
  </div>
</div>

<div class="h-body-select-Selected-list">
  <h3 class="h-body-select-Selected">{{ selectedListTitle }}</h3>
  <span *ngFor="let point of points">
    {{ formatPointIdForSelectList(point.id) }}
  </span>
  <p *ngIf="points.length === 0">Please select your pain area</p>
</div>

<div *ngIf="points.length > 0 && worseListInclude">
  <h3 class="h-body-select-worseListTitle">{{ worseListTitle }}</h3>
  <mat-button-toggle-group multiple class="h-worseList-toggle-group">
    <mat-button-toggle
      *ngFor="let point of points"
      [checked]="point.worstArea"
      (change)="handleCheckboxChange($event, point.id)"
      class="h-worseList-button-toggle"
      [class.selected]="point.worstArea"
    >
      <mat-icon *ngIf="point.worstArea" class="p-b-xs">check</mat-icon>
      {{ formatPointIdForWorstList(point.id) }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
