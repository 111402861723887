<form [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <app-custom-radio-group
      [formGroup]="myForm"
      controlName="responsibleToPayPatientsBill"
      label="{{ radioItems['responsibleToPayPatientsBill']['title'] }}"
      [options]="radioItems['responsibleToPayPatientsBill']['options']"
      class="flex-row-reverse m-b-l d-block"
      *ngIf="radioItems['responsibleToPayPatientsBill']?.is_include"
    >
    </app-custom-radio-group>
    <div
      *ngIf="
        myForm.get('responsibleToPayPatientsBill')?.value === 'anotherPerson'
      "
    >
      <app-custom-select
        id="guarantor_relationship"
        [formGroup]="myForm"
        *ngIf="radioItems['relationshipToThePatient']?.is_include"
        controlName="relationshipToThePatient"
        [label]="radioItems['relationshipToThePatient']?.title"
        [optionsTitle]="radioItems['relationshipToThePatient']?.options_title"
        [options]="radioItems['relationshipToThePatient']?.options"
        [iconClass]="radioItems['relationshipToThePatient']?.icon"
      >
      </app-custom-select>
      <app-custom-input
        id="guarantor_lastname"
        [formGroup]="myForm"
        controlName="lastName"
        placeholder="{{ inputItems['lastName']['placeholder'] }}"
        [iconClass]="inputItems['lastName']?.icon"
        [required]="inputItems['lastName']?.required"
        *ngIf="inputItems['lastName']?.is_include"
        [maxlength]="20"
      ></app-custom-input>
      <app-custom-input
        id="guarantor_firstname"
        [formGroup]="myForm"
        controlName="firstName"
        placeholder="{{ inputItems['firstName']['placeholder'] }}"
        [iconClass]="inputItems['firstName']?.icon"
        [required]="inputItems['firstName']?.required"
        *ngIf="inputItems['firstName']?.is_include"
        [maxlength]="20"
      ></app-custom-input>
      <div class="middleName">
        <app-custom-input
          id="guarantor_middlename"
          [formGroup]="myForm"
          controlName="middleName"
          *ngIf="inputItems['middleName']?.is_include"
          placeholder="{{ inputItems['middleName']['placeholder'] }}"
          [maxlength]="20"
        ></app-custom-input>
        <app-custom-input
          id="guarantor_suffix"
          [formGroup]="myForm"
          controlName="suffix"
          *ngIf="inputItems['suffix']?.is_include"
          placeholder="{{ inputItems['suffix']['placeholder'] }}"
          [maxlength]="20"
        ></app-custom-input>
      </div>
      <app-custom-date-picker
        id="guarantor_dob"
        [formGroup]="myForm"
        controlName="dob"
        *ngIf="inputItems['dob']?.is_include"
        label="{{ inputItems['dob']['placeholder'] }}"
        [maxDate]="maxDate"
        [startView]="'multi-year'"
        [showMinus50Years]="true"
      >
      </app-custom-date-picker>
      <app-address
        id="guarantor_address"
        [formGroup]="myForm"
        *ngIf="inputItems['address']?.is_include"
        addressPlaceholder="{{ inputItems['address']['placeholder'] }}"
        unitPlaceholder="{{ inputItems['apartment']['placeholder'] }}"
        cityPlaceholder="{{ inputItems['city']['placeholder'] }}"
        statePlaceholder="{{ inputItems['state']['placeholder'] }}"
        zipPlaceholder="{{ inputItems['postalCode']['placeholder'] }}"
        countryPlaceholder="{{ inputItems['country']['placeholder'] }}"
        [required]="inputItems['address']?.required"
        class="m-t-l m-b-l d-block"
      ></app-address>
      <app-ssn
        id="guarantor_ssn"
        [formGroup]="myForm"
        *ngIf="inputItems['ssn']?.is_include"
        title="{{ inputItems['ssn']['title'] }}"
        placeholder="{{ inputItems['ssn']['placeholder'] }}"
      ></app-ssn>
      <app-phone
        id="guarantor_phone"
        [formGroup]="myForm"
        [title]="inputItems['phoneNumber']['placeholder']"
        *ngIf="inputItems['phoneNumber']?.is_include"
        [required]="inputItems['phoneNumber']?.required"
        formName="phoneNumber"
      ></app-phone>
      <app-custom-input
        id="guarantor_email"
        [formGroup]="myForm"
        controlName="email"
        placeholder="{{ inputItems['email']['placeholder'] }}"
        [iconClass]="inputItems['email']?.icon"
        *ngIf="inputItems['email']?.is_include"
        [type]="'email'"
      ></app-custom-input>
    </div>
  </ng-container>
</form>
