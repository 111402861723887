import { Component } from '@angular/core';

@Component({
  selector: 'app-cwps-consent-insurance',
  standalone: true,
  imports: [],
  templateUrl: './cwps-consent-insurance.component.html',
  styleUrl: './cwps-consent-insurance.component.css'
})
export class CwpsConsentInsuranceComponent {

}
