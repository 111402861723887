<form [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <app-custom-select id="spoken_language"
      [formGroup]="myForm"
      controlName="spokenLanguage"
      *ngIf="radioItems['spokenLanguage']?.is_include"
      label="{{ radioItems['spokenLanguage']['title'] }}"
      optionsTitle="{{ radioItems['spokenLanguage']['optionsTitle'] }}"
      [options]="radioItems['spokenLanguage']['options']"
      [iconClass]="radioItems['spokenLanguage']?.icon"
    >
    </app-custom-select>
    <app-custom-checkbox-group
      [formGroup]="myForm"
      controlName="race"
      *ngIf="checkboxesItems['race']?.is_include"
      label="{{ checkboxesItems['race']['title'] }}"
      [options]="checkboxesItems['race']['options']"
      [required]="checkboxesItems['race']?.required"
      class="m-t-l d-block"
    >
    </app-custom-checkbox-group>
    <app-custom-select id="ethnic_identity"
      [formGroup]="myForm"
      controlName="ethnicIdentity"
      *ngIf="radioItems['ethnicIdentity']?.is_include"
      label="{{ radioItems['ethnicIdentity']['title'] }}"
      optionsTitle="{{ radioItems['ethnicIdentity']['optionsTitle'] }}"
      [options]="radioItems['ethnicIdentity']['options']"
      [iconClass]="radioItems['ethnicIdentity']?.icon"
    >
    </app-custom-select>
    <app-custom-select id="marital_status"
      [formGroup]="myForm"
      controlName="maritalStatus"
      *ngIf="radioItems['maritalStatus']?.is_include"
      label="{{ radioItems['maritalStatus']['title'] }}"
      optionsTitle="{{ radioItems['maritalStatus']['optionsTitle'] }}"
      [options]="radioItems['maritalStatus']['options']"
      [iconClass]="radioItems['maritalStatus']?.icon"
    >
    </app-custom-select>
    <app-custom-select id="sexual_orientation"
      [formGroup]="myForm"
      controlName="sexualOrientation"
      *ngIf="radioItems['sexualOrientation']?.is_include"
      label="{{ radioItems['sexualOrientation']['title'] }}"
      optionsTitle="{{ radioItems['sexualOrientation']['optionsTitle'] }}"
      [options]="radioItems['sexualOrientation']['options']"
      [iconClass]="radioItems['sexualOrientation']?.icon"
    >
    </app-custom-select>
    <app-custom-select id="gender_identity"
      [formGroup]="myForm"
      controlName="genderIdentity"
      *ngIf="radioItems['genderIdentity']?.is_include"
      label="{{ radioItems['genderIdentity']['title'] }}"
      optionsTitle="{{ radioItems['genderIdentity']['optionsTitle'] }}"
      [options]="radioItems['genderIdentity']['options']"
      [iconClass]="radioItems['genderIdentity']?.icon"
    >
    </app-custom-select>
    <app-custom-select id="assigned_sex_birth"
      [formGroup]="myForm"
      controlName="assignedSexAtBirth"
      *ngIf="radioItems['assignedSexAtBirth']?.is_include"
      label="{{ radioItems['assignedSexAtBirth']['title'] }}"
      optionsTitle="{{ radioItems['assignedSexAtBirth']['optionsTitle'] }}"
      [options]="radioItems['assignedSexAtBirth']['options']"
      [iconClass]="radioItems['assignedSexAtBirth']?.icon"
    >
    </app-custom-select>
    <app-custom-select id="pronouns"
      [formGroup]="myForm"
      controlName="pronouns"
      *ngIf="radioItems['pronouns']?.is_include"
      label="{{ radioItems['pronouns']['title'] }}"
      optionsTitle="{{ radioItems['pronouns']['optionsTitle'] }}"
      [options]="radioItems['pronouns']['options']"
      [iconClass]="radioItems['pronouns']?.icon"
    >
    </app-custom-select>
    <app-custom-radio-group
      [formGroup]="myForm"
      controlName="homeboundStatus"
      *ngIf="radioItems['homeboundStatus']?.is_include"
      label="{{ radioItems['homeboundStatus']['title'] }}"
      [options]="radioItems['homeboundStatus']['options']"
    >
    </app-custom-radio-group>
  </ng-container>
</form>
