<form [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <app-custom-radio-group
      [formGroup]="myForm"
      controlName="doYouHavePrimaryCarePhysician"
      label="{{ radioItems['doYouHavePrimaryCarePhysician']['title'] }}"
      [options]="radioItems['doYouHavePrimaryCarePhysician']['options']"
      class="flex-row-reverse"
    >
    </app-custom-radio-group>

    <div
      *ngIf="myForm.get('doYouHavePrimaryCarePhysician')?.value === 'yes'"
      class="m-t-m d-block"
    >
      <app-custom-input id="primarycare_physician_lastname"
        [formGroup]="myForm"
        *ngIf="inputItems['primaryCarePhysicianLastName']?.is_include"
        controlName="primaryCarePhysicianLastName"
        placeholder="{{
          inputItems['primaryCarePhysicianLastName']['placeholder']
        }}"
        hint="{{ inputItems['primaryCarePhysicianLastName']['hint'] }}"
        [iconClass]="inputItems['primaryCarePhysicianLastName']?.icon"
      ></app-custom-input>
      <app-custom-input id="primarycare_physician_firstname"
        [formGroup]="myForm"
        *ngIf="inputItems['primaryCarePhysicianFirstName']?.is_include"
        controlName="primaryCarePhysicianFirstName"
        placeholder="{{
          inputItems['primaryCarePhysicianFirstName']['placeholder']
        }}"
        hint="{{ inputItems['primaryCarePhysicianFirstName']['hint'] }}"
        [iconClass]="inputItems['primaryCarePhysicianFirstName']?.icon"
      ></app-custom-input>
      <app-custom-input id="registration_department"
        [formGroup]="myForm"
        *ngIf="inputItems['registrationDepartment']?.is_include"
        controlName="registrationDepartment"
        placeholder="{{ inputItems['registrationDepartment']['placeholder'] }}"
        [iconClass]="inputItems['registrationDepartment']?.icon"
      ></app-custom-input>
      <app-custom-input id="primary_department"
        [formGroup]="myForm"
        *ngIf="inputItems['primaryDepartment']?.is_include"
        controlName="primaryDepartment"
        placeholder="{{ inputItems['primaryDepartment']['placeholder'] }}"
        [iconClass]="inputItems['primaryDepartment']?.icon"
      ></app-custom-input>
      <app-custom-input id="physician_clinic"
        [formGroup]="myForm"
        *ngIf="inputItems['physicianClinic']?.is_include"
        controlName="physicianClinic"
        placeholder="{{ inputItems['physicianClinic']['placeholder'] }}"
        [iconClass]="inputItems['physicianClinic']?.icon"
      ></app-custom-input>
      <app-phone id="clinic_phonenumber"
        [formGroup]="myForm"
        title="{{ inputItems['clinicPhoneNumber']['placeholder'] }}"
        formName="clinicPhoneNumber"
        *ngIf="inputItems['clinicPhoneNumber']?.is_include"
      ></app-phone>
      <app-address id="clinic_address"
        [formGroup]="myForm"
        *ngIf="inputItems['address']?.is_include"
        addressPlaceholder="{{ inputItems['address']['placeholder'] }}"
        unitPlaceholder="{{ inputItems['apartment']['placeholder'] }}"
        cityPlaceholder="{{ inputItems['city']['placeholder'] }}"
        statePlaceholder="{{ inputItems['state']['placeholder'] }}"
        zipPlaceholder="{{ inputItems['postalCode']['placeholder'] }}"
        countryPlaceholder="{{ inputItems['country']['placeholder'] }}"
        [required]="inputItems['address']['required']"
      ></app-address>
      <app-custom-date-picker id="registration_date"
        [formGroup]="myForm"
        *ngIf="inputItems['registrationDate']?.is_include"
        controlName="registrationDate"
        label="{{ inputItems['registrationDate']['placeholder'] }}"
      >
      </app-custom-date-picker>
      <app-custom-radio-group
        [formGroup]="myForm"
        controlName="isYourPrimaryCarePhysicianYourreferringDoctor"
        *ngIf="
          radioItems['isYourPrimaryCarePhysicianYourreferringDoctor']
            ?.is_include
        "
        label="{{
          radioItems['isYourPrimaryCarePhysicianYourreferringDoctor']['title']
        }}"
        [options]="
          radioItems['isYourPrimaryCarePhysicianYourreferringDoctor']['options']
        "
        class="flex-row-reverse m-b-l m-t-l d-block"
      >
      </app-custom-radio-group>
      <app-custom-radio-group
        [formGroup]="myForm"
        controlName="hasYourPrimaryCareProvidedYouWithReferraToBeSeen"
        *ngIf="
          radioItems['hasYourPrimaryCareProvidedYouWithReferraToBeSeen']
            ?.is_include
        "
        label="{{
          radioItems['hasYourPrimaryCareProvidedYouWithReferraToBeSeen'][
            'title'
          ]
        }}"
        [options]="
          radioItems['hasYourPrimaryCareProvidedYouWithReferraToBeSeen'][
            'options'
          ]
        "
        class="flex-row-reverse m-b-l d-block"
      >
      </app-custom-radio-group>
    </div>
  </ng-container>
</form>
