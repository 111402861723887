import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { LottieComponent } from 'ngx-lottie';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-lottie-player',
  template: `
    <ng-lottie 
      *ngIf="options && options.path" 
      [options]="options" 
      (animationCreated)="animationCreated($event)" 
      (error)="handleError($event)">
    </ng-lottie>
    <p *ngIf="errorMessage">{{ errorMessage }}</p>
  `,
  standalone: true,
  imports: [LottieComponent, CommonModule],
})
export class LottiePlayerComponent implements OnChanges {
  @Input() animationPath!: string;
  options: any | undefined;
  errorMessage: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['animationPath'] && this.animationPath) {
      // Use a delay to ensure the animation is loaded after path is set
      setTimeout(() => {
        this.options = {
          path: this.animationPath,
          renderer: 'svg',
          loop: true,
          autoplay: true,
        };
      }, 300); // Delay of 100ms to prevent race condition
    }
  }

  animationCreated(animationItem: AnimationItem): void {
  }

  handleError(event: any): void {
    this.errorMessage = 'Failed to load the Lottie animation.';
    console.error('Lottie error:', event);
  }
}
