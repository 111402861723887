import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  logo = '';
  showCloseButton = false;
  header: any = {
    healent: '../../../assets/akps/logo.svg',
    akps: '../../../assets/akps/logo.svg',
    cps: '../../../assets/cwps/logo.svg',
    heal: '../../../assets/heal/heal.png',
  };

  ngOnInit() {
    this.getCurrentPath();
    this.checkIfInsideReactNative();
  }

  getCurrentPath() {
    const providerName = localStorage.getItem('hospital_slug');
    this.logo = providerName ? this.header[providerName] || '' : '';
  }

  checkIfInsideReactNative() {
    this.showCloseButton = !!window.ReactNativeWebView;
  }

  closeReact() {
    if (window.ReactNativeWebView) {
      const message = { flag: 'close-btn' };
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    } else {
      console.warn('ReactNativeWebView is not available');
    }
  }
}
