<form class="contact" [formGroup]="formGroup">
  <div class="adress-group">
    <mat-form-field>
      <mat-label>{{ addressPlaceholder }}</mat-label>
      <mat-icon matSuffix>
        <i class="input-icon healent-form-input-icon-24"></i>
      </mat-icon>
      <input
        id="{{ id }}"
        matInput
        #searchInput
        formControlName="address"
        [required]="required"
        maxlength="60"
        (blur)="onBlur('address')"
      />
    </mat-form-field>
    <mat-form-field [ngClass]="{ readonly: isReadonly }">
      <mat-label>{{ unitPlaceholder }}</mat-label>
      <input
        matInput
        formControlName="apartment"
        maxlength="60"
        [readonly]="isReadonly"
      />
    </mat-form-field>
    <mat-form-field [ngClass]="{ readonly: isReadonly }">
      <mat-label>{{ cityPlaceholder }}</mat-label>
      <input
        matInput
        formControlName="city"
        maxlength="30"
        [readonly]="isReadonly"
        (blur)="onBlur('city')"
      />
    </mat-form-field>
    <mat-form-field [ngClass]="{ readonly: isReadonly }">
      <mat-label>{{ statePlaceholder }}</mat-label>
      <input
        matInput
        #stateInput="matInput"
        [matAutocomplete]="auto"
        formControlName="state"
        [readonly]="isReadonly"
        (focus)="onFocus()"
        (keydown)="onKeydown($event)"
        (blur)="onBlur('state')"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayStateName.bind(this)"
        (optionSelected)="onStateSelected($event)"
      >
        <mat-option *ngFor="let state of states$ | async" [value]="state.code">
          {{ state.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field [ngClass]="{ readonly: isReadonly }">
      <mat-label>{{ zipPlaceholder }}</mat-label>
      <input
        matInput
        formControlName="postalCode"
        [readonly]="isReadonly"
        (blur)="onBlur('postalCode')"
      />
    </mat-form-field>
    <mat-form-field [ngClass]="{ readonly: isReadonly }">
      <mat-label>{{ countryPlaceholder }}</mat-label>
      <input matInput formControlName="country" [readonly]="isReadonly" />
    </mat-form-field>
  </div>
  <div class="hintContainer" [ngClass]="{ valid: isAddressValid }">
    <!-- Display required/optional hint if no error -->
    <div>
      <span *ngIf="required; else optionalField" class="hintRequired">
        *Required
      </span>
      <ng-template #optionalField>
        <span class="hintOptional">+Optional</span>
      </ng-template>
    </div>
  </div>
</form>
