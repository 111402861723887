import { Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, Validators, FormControl } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

import {
  MatChip,
  MatChipEditedEvent,
  MatChipGrid,
  MatChipInput, MatChipInputEvent,
  MatChipRemove,
  MatChipRow
} from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { LiveAnnouncer } from "@angular/cdk/a11y";

@Component({
  selector: 'app-chip-selection',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    TranslateModule,
    MatChipGrid,
    MatChipInput,
    MatChipRemove,
    MatChip,
    MatChipRow,
  ],
  templateUrl: './chip-selection.component.html',
  styleUrls: ['./chip-selection.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ChipSelectionComponent {
  @Input() formGroup!: FormGroup;
  @Input() title!: string;
  @Input() formName!: string;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  addOnBlur = true;
  announcer = inject(LiveAnnouncer);

  get chips(): Array<string> {
    return this.formGroup.get(this.formName)?.value || []
  }

  // Add chip to the list
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.formGroup.patchValue({
        [this.formName]: this.chips.concat(value)
      });
    }
    event.chipInput!.clear();
  }

  // Remove chip from the list
  remove(chip: string): void {
    const index = this.chips.indexOf(chip);
    if (index >= 0) {
      this.chips.splice(index, 1);
      this.formGroup.patchValue({
        [this.formName]: this.chips
      });
      this.announcer.announce(`Removed ${chip}`);
    }
  }

  // Edit an existing chip
  edit(chip: string, event: MatChipEditedEvent) {
    const value = event.value.trim();
    if (!value) {
      this.remove(chip);
      return;
    }
    const index = this.chips.indexOf(chip);
    if (index >= 0) {
      this.chips[index] = value;
    }
    this.formGroup.patchValue({
      [this.formName]: this.chips
    });
  }

  // Getter for form control
  get control(): FormControl {
    return this.formGroup.get(this.formName) as FormControl;
  }

  // Method to generate error messages
  getErrorMessage(): string {
    if (this.control.hasError('required')) {
      return 'This field is required.';
    }
    return 'Invalid input.';
  }

  // Method to check if the field is required
  isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }
}
