import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule, FormControl, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-custom-textarea',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule
  ],
  templateUrl: './custom-textarea.component.html',
  styleUrls: ['./custom-textarea.component.css']
})
export class CustomTextareaComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() hint!: string;
  @Input() rows: number = 4;  // Default rows set to 4
  @Input() id?: string;

  ngOnInit(): void {
    // Ensure the control is part of the group
    if (!this.formGroup.controls[this.controlName]) {
      this.formGroup.addControl(this.controlName, new FormControl(''));
    }
  }

  // Getter for form control
  get control(): FormControl {
    return this.formGroup.get(this.controlName) as FormControl;
  }

  // Method to generate error messages
  getErrorMessage(): string {
    if (this.control.hasError('required')) {
      return 'This field is required.';
    }
    return 'Invalid input.';
  }

  // Method to check if the field is required
  isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }
}
