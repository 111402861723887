import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { NgIf } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { formObjectByType } from "../../functions/functions";
import { ActivatedRoute } from "@angular/router";
import { CheckboxGroupAsArrayComponent } from '../../reusable/checkbox-group-as-array/checkbox-group-as-array.component';
import { CustomRadioGroupComponent } from '../../reusable/custom-radio-group/custom-radio-group.component';

@Component({
  selector: 'app-ort',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    CheckboxGroupAsArrayComponent,
    CustomRadioGroupComponent
  ],
  templateUrl: './ort.component.html',
  styleUrls: ['./ort.component.css']
})
export class OrtComponent implements OnInit, OnDestroy {

  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values: any = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';

  formValues: any = {};
  form_loaded = false;
  myForm!: FormGroup;
  checkboxesItems: any = {};
  radioItems: any = {};
  transformedValues: any = {};

  destroySubject = new Subject<void>();

  sexualAbuseOptionsMale = {
    historyOfPreadolescentSexualAbuse: {
      questionId: "4335",
      label: "I have a History of Preadolescent Sexual Abuse",
      score: "0"
    }
  };
  sexualAbuseOptionsFemale = {
    historyOfPreadolescentSexualAbuse: {
      questionId: "4336",
      label: "I have a History of Preadolescent Sexual Abuse",
      score: "3"
    }
  };

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef // Add ChangeDetectorRef for manual detection
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    this.myForm = new FormGroup({});
    const currentLang = this.translateService.currentLang;
    const lang_form = `${currentLang}-${this.form_id}`;

    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {
      const translationsObj = translations['healent'][this.step];
      const translationsObjSlug = translations[this.hospital_slug][this.step];
      const formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step);

      this.myForm = formObject.myForm;
      this.checkboxesItems = formObject.checkboxesItems;
      this.radioItems = formObject['radioItems'];

      if (this.patch_values) {
        this.myForm.patchValue(this.patch_values, { emitEvent: false });
      }

      this.initializeTransformedValues();

      this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(() => {
        this.updateTransformedValues();
        this.updateControlValue();
      });

      // Initial set for sexualAbuse options based on gender
      this.updateSexualAbuseOptionsBasedOnGender();

      this.form_loaded = true;
    });
  }

  private initializeTransformedValues() {
    Object.keys(this.checkboxesItems).forEach(formGroupName => {
      const itemData = this.checkboxesItems[formGroupName];
      const formGroup = this.myForm.get(formGroupName) as FormGroup;

      this.transformedValues[formGroupName] = {
        code: formGroupName,
        question: itemData.title,
        questionId: itemData.questionId,
        options: Object.keys(itemData.options).map(optionKey => ({
          questionId: itemData.options[optionKey]?.questionId,
          label: itemData.options[optionKey]?.label,
          value: formGroup?.get(optionKey)?.value ? "yes" : "no"
        }))
      };
    });

    Object.keys(this.radioItems).forEach(formGroupName => {
      const itemData = this.radioItems[formGroupName];
      const formControl = this.myForm.get(formGroupName) as FormControl;

      this.transformedValues[formGroupName] = {
        code: formGroupName,
        question: itemData.title,
        questionId: itemData.questionId,
        selectedOption: {
          questionId: itemData.options[formControl.value]?.questionId,
          label: itemData.options[formControl.value]?.label,
          value: formControl.value
        }
      };
    });
  }

  // Method to set sexualAbuse options based on gender selection
  private updateSexualAbuseOptionsBasedOnGender() {
    const genderControl = this.myForm.get('gender') as FormControl;

    // If gender is already selected, apply the condition immediately
    if (genderControl?.value) {
      this.setSexualAbuseOptions(genderControl.value);
    }

    // Listen for changes in the gender control
    genderControl?.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(genderValue => {
      this.setSexualAbuseOptions(genderValue);
    });
  }

  // Apply options based on gender
  private setSexualAbuseOptions(gender: string) {
    if (gender === 'male') {
      this.checkboxesItems['sexualAbuse'].options = this.sexualAbuseOptionsMale;
    } else if (gender === 'female') {
      this.checkboxesItems['sexualAbuse'].options = this.sexualAbuseOptionsFemale;
    }
    this.cdr.detectChanges(); // Trigger change detection to reflect changes in the UI
  }

  private updateTransformedValues() {
    Object.keys(this.checkboxesItems).forEach(formGroupName => {
      const formGroup = this.myForm.get(formGroupName) as FormGroup;
      if (formGroup) {
        this.transformedValues[formGroupName].options = Object.keys(this.checkboxesItems[formGroupName].options).map(optionKey => ({
          questionId: this.checkboxesItems[formGroupName].options[optionKey]?.questionId,
          label: this.checkboxesItems[formGroupName].options[optionKey]?.label,
          score: this.checkboxesItems[formGroupName].options[optionKey]?.score,
          value: formGroup.get(optionKey)?.value ? "yes" : "no"
        }));
      }
    });

    Object.keys(this.radioItems).forEach(formGroupName => {
      const formControl = this.myForm.get(formGroupName) as FormControl;
      if (formControl) {
        this.transformedValues[formGroupName].selectedOption = {
          questionId: this.radioItems[formGroupName].options[formControl.value]?.questionId,
          label: this.radioItems[formGroupName].options[formControl.value]?.label,
          score: this.radioItems[formGroupName].options[formControl.value]?.score,
          value: formControl.value
        };
      }
    });
  }

  private updateControlValue() {
    if (this.control) {
      this.control.setValue(this.transformedValues, { emitEvent: true });
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
