import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { MatError, MatFormField } from "@angular/material/form-field";
import { KeyValuePipe, NgForOf, NgIf, CommonModule } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

import { formObjectByType } from "../../functions/functions";
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CustomSelectComponent } from '../../reusable/custom-select/custom-select.component';
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';
import { CustomRadioGroupComponent } from '../../reusable/custom-radio-group/custom-radio-group.component';
import { SharedFormService } from '../../services/SharedFormService.service';
@Component({
  selector: 'app-intro-step-form',
  standalone: true,
  imports: [
    MatFormField,
    ReactiveFormsModule,
    NgIf,
    MatError,
    KeyValuePipe,
    NgForOf,
    CustomCheckboxComponent,
    CustomSelectComponent,
    CustomInputComponent,
    CustomRadioGroupComponent,
    CommonModule
  ],
  templateUrl: './intro-step-form.component.html',
  styleUrls: ['./intro-step-form.component.css']
})
export class IntroStepFormComponent implements OnInit, OnDestroy {
  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';
  formValues: any = {};
  value: any;
  form_loaded = false;
  myForm!: FormGroup;
  checkboxItems: any = {};
  radioItems: any = {};
  inputItems: any = {};
  destroySubject = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private sharedFormService: SharedFormService,
    private cdr: ChangeDetectorRef

  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {

    this.myForm = new FormGroup({});
    const currentLang = this.translateService.currentLang;
    let lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {

      const translationsObj = translations['healent'][this.step];
      //let slug_form = `${this.hospital_slug}-${this.form_id}`;
      const translationsObjSlug = translations[this.hospital_slug][this.step];
      let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step)
      this.myForm = formObject['myForm'];
      this.radioItems = formObject['radioItems'];
      this.inputItems = formObject['inputItems'];
      this.checkboxItems = formObject['checkboxItems'];

      if (this.patch_values) {
        this.myForm.patchValue(this.patch_values, { emitEvent: false });
      }
      if (this.myForm.get('isPatient')?.value) {
        this.applyProxyValidators(true);
      }
      this.control?.setValue(this.myForm.value, { emitEvent: true });
      this.formGroup = this.myForm;
      this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(val => {
        this.formValues = val;
        this.control?.setValue(this.myForm.value, { emitEvent: true });
        this.formGroup = this.myForm;
        this.sharedFormService.updateFormValues(this.myForm.value);
      });
      this.onCheckboxChange()
      this.form_loaded = true;
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onCheckboxChange() {
    this.myForm.get('isPatient')?.valueChanges.subscribe(checked => {
      this.applyProxyValidators(checked);
    });
  }

  applyProxyValidators(isPatientChecked: boolean) {
    const proxy_relationshipControl = this.myForm.get('proxy_relationship');
    const proxy_full_nameControl = this.myForm.get('proxy_full_name');

    if (isPatientChecked) {
      proxy_relationshipControl?.setValidators([Validators.required]);
      proxy_full_nameControl?.setValidators([Validators.required]);
    } else {
      proxy_relationshipControl?.clearValidators();
      proxy_full_nameControl?.clearValidators();
      proxy_relationshipControl?.reset();
      proxy_full_nameControl?.reset();
    }

    proxy_relationshipControl?.updateValueAndValidity();
    proxy_full_nameControl?.updateValueAndValidity();
    this.cdr.detectChanges();

  }
}
