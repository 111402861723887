import { Component, Input, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Subject, combineLatest, takeUntil } from "rxjs";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { PrivacyModalComponent } from './privacy-modal/privacy-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CheckboxItemInterface } from "../../interfaces/interfaces";
import { formObjectByType } from "../../functions/functions";
import { SharedFormService } from '../../services/SharedFormService.service';


@Component({
  selector: 'app-privacy-and-agreements-step-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    TranslateModule,
    CustomCheckboxComponent
  ],
  templateUrl: './privacy-and-agreements-step-form.component.html',
  styleUrls: ['./privacy-and-agreements-step-form.component.css']
})
export class PrivacyAndAgreementsStepFormComponent implements OnDestroy, AfterViewInit {
  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values: Record<string, {
    is_signed: boolean;
    signed_on_utc: string | null;
    meeting_id: number | null;
    current: string | null;
  }> = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';

  formValues: any = {};
  form_loaded = false;
  myForm!: FormGroup;
  checkboxItems: CheckboxItemInterface = {};
  hasDisplayableItems: boolean = false;
  accepted: any = [];
  declined: any = [];
  today!: Date;
  signedBy!: string;
  showCCM: boolean = false;
  showPreferred: boolean = false;
  private destroySubject = new Subject<void>();
  private backupCwpsCcmConsent: any = null;
  private backupCwpsPreferredContacts: any = null;

  constructor(
    private _bottomSheet: MatBottomSheet,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private sharedFormService: SharedFormService,
    private cdr: ChangeDetectorRef
  ) {
    this.myForm = new FormGroup({});
    this.today = new Date();
  }
  ngAfterViewInit() {
    this.today = new Date();
    const currentLang = this.translateService.currentLang;
    const lang_form = `${currentLang}-${this.form_id}`;

    combineLatest([
      this.sharedFormService.showCCM$,
      this.translateService.getTranslation(lang_form),
      this.sharedFormService.showPreferred$
    ])
      .pipe(takeUntil(this.destroySubject))
      .subscribe(([showCCMValue, translations, showPreferredValue]) => {
        this.showCCM = showCCMValue;
        this.showPreferred = showPreferredValue;

        const translationsObj = translations['healent'][this.step];
        const translationsObjSlug = translations[this.hospital_slug][this.step];
        let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step);
        this.myForm = formObject['myForm'];
        this.checkboxItems = formObject['checkboxItems'];

        Object.keys(this.checkboxItems).forEach(key => {
          // Ensure `current` is initialized in checkboxItems
          this.checkboxItems[key] = {
            ...this.checkboxItems[key],
            current: null
          };

          // Ensure `current` is set to null in patch_values coming from the backend
          this.patch_values[key] = {
            ...this.patch_values[key],
            current: null
          };

          // Add `current` as part of the form values explicitly
          this.formValues[key] = {
            ...this.patch_values[key]
          };
        });


        // Add the new function logic here
        const checkboxKeys = Object.keys(this.checkboxItems);
        if (checkboxKeys.length === 1 && checkboxKeys[0] === 'cwpsCcmConsent') {
          if (!this.myForm.contains('cwpsCcmConsent')) {
            this.myForm.addControl('cwpsCcmConsent', new FormControl(false));
          }
        } else {
          // Proceed with showCCM logic
          if (!this.showCCM) {
            if (this.checkboxItems['cwpsCcmConsent']) {
              this.backupCwpsCcmConsent = this.checkboxItems['cwpsCcmConsent'];
            }
            delete this.checkboxItems['cwpsCcmConsent'];

            if (this.myForm.contains('cwpsCcmConsent')) {
              this.myForm.removeControl('cwpsCcmConsent');
            }
          } else {
            if (this.backupCwpsCcmConsent) {
              this.checkboxItems['cwpsCcmConsent'] = this.backupCwpsCcmConsent;
            }
            if (!this.myForm.contains('cwpsCcmConsent')) {
              this.myForm.addControl('cwpsCcmConsent', new FormControl(false));
            }
          }
        }

        if (this.showPreferred) {
          this.myForm.removeControl('cwpsPreferredContacts');

          const defaultData = {
            is_signed: false,
            signed_on_utc: null,
            meeting_id: null,
            current: null,
          };

          // Overwrite backend data for cwpsPreferredContacts
          this.patch_values['cwpsPreferredContacts'] = defaultData;

          // Add the control with default values
          this.myForm.addControl('cwpsPreferredContacts', new FormControl(defaultData.is_signed));

          // Initialize the formValues with the default data
          this.formValues['cwpsPreferredContacts'] = {
            is_signed: defaultData.is_signed,
            signed_on_utc: defaultData.signed_on_utc,
            meeting_id: defaultData.meeting_id,
          };

          // Add to checkboxItems with all required properties
          this.checkboxItems['cwpsPreferredContacts'] = {
            title: 'Preferred Contacts Consent',
            required: false, // Adjust as needed
            body: '', // Add meaningful content if applicable
            is_include: true,
            showDecline: false,
          };

          // Update the control value in the main form
          this.control?.setValue(this.formValues, { emitEvent: true });
        }

        // Filter checkboxItems to include only items with `is_include: true`
        this.checkboxItems = Object.keys(this.checkboxItems)
          .filter(key => this.checkboxItems[key]?.is_include)
          .reduce((acc, key) => {
            acc[key] = this.checkboxItems[key];
            return acc;
          }, {} as CheckboxItemInterface);

        const patchData: Record<string, { is_signed: boolean; signed_on_utc: string | null; meeting_id: number | null }> = this.patch_values || {};
        const meetingId = Number(localStorage.getItem('meeting_id')) || null;

        // Add all items to the form
        Object.keys(this.checkboxItems).forEach(key => {
          const backendData = patchData[key];
          const defaultData = {
            is_signed: false,
            signed_on_utc: null,
            meeting_id: null,
            current: null,
          };

          const mergedData = backendData && typeof backendData === 'object'
            ? { ...defaultData, ...backendData }
            : defaultData;

          if (!this.myForm.contains(key)) {
            this.myForm.addControl(key, new FormControl(mergedData.is_signed));
          }
        });

        // Filter checkboxItems to display only unsigned items
        const displayCheckboxItems = Object.keys(this.checkboxItems)
          .filter(key => !(patchData[key]?.is_signed))
          .reduce((acc, key) => {
            acc[key] = this.checkboxItems[key];
            return acc;
          }, {} as CheckboxItemInterface);
        this.checkboxItems = displayCheckboxItems;
        this.hasDisplayableItems = Object.keys(this.checkboxItems).length > 0; // Update state
        this.cdr.detectChanges();

        // Initialize transformed values
        const initialTransformedValues = Object.keys(this.myForm.controls).reduce((acc: Record<string, { is_signed: boolean; signed_on_utc: string | null; meeting_id: number | null }>, key) => {
          const controlValue = this.myForm.get(key)?.value;
          const backendData = patchData[key];
          const defaultData = {
            is_signed: false,
            signed_on_utc: null,
            meeting_id: null,
            current: null,
          };

          acc[key] = backendData && typeof backendData === 'object'
            ? { ...defaultData, ...backendData }
            : {
              is_signed: controlValue,
              signed_on_utc: controlValue ? this.formatDate(new Date()) : null,
              meeting_id: controlValue ? meetingId : null,
            };

          return acc;
        }, {});

        this.formValues = initialTransformedValues;
        this.control?.setValue(this.formValues, { emitEvent: true });
        this.formGroup = this.myForm;

        // Subscribe to form changes
        Object.keys(this.myForm.controls).forEach(key => {
          const control = this.myForm.get(key);
          if (control) {
            control.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(value => {
              // Update only the clicked checkbox's timestamp
              this.formValues[key] = {
                is_signed: value,
                signed_on_utc: value ? this.formatDate(new Date()) : null,
                meeting_id: value ? meetingId : null,
              };

              // Update the FormControl's value in the main form
              this.control?.setValue(this.formValues, { emitEvent: true });
            });
          }
        });

        this.form_loaded = true;
        this.cdr.detectChanges();
      });
  }

  private formatDate(date: Date): string {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }



  openPrivacyCheck(event: Event, key: string) {
    const currentMeetingId = Number(localStorage.getItem('meeting_id')) || null;

    this.myForm.patchValue({
      [key]: false
    });

    const list = Object.keys(this.checkboxItems)
      .filter(k => this.checkboxItems[k].is_include && !this.myForm.get(k)?.value)
      .map(k => {
        const { is_include, ...rest } = this.checkboxItems[k];
        return { key: k, ...rest };
      });

    const dialogRef = this.dialog.open(PrivacyModalComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100vh',
      data: { privacyList: list, initialKey: key },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;

      // Update the form values based on accepted items
      result.accepted.forEach((item: { key: string; signed_on_utc: string }) => {
        const { key, signed_on_utc } = item;

        this.myForm.patchValue({ [key]: true }); // Mark checkbox as signed
        this.formValues[key] = {
          is_signed: true,
          signed_on_utc,
          meeting_id: currentMeetingId,
          current: true
        };

      });

      // Handle declined items
      result.declined.forEach((key: string) => {
        this.myForm.patchValue({ [key]: false });
        this.formValues[key] = {
          is_signed: false,
          signed_on_utc: null,
          meeting_id: null,
          current: null
        };
      });

      // Update the control with the new formValues
      this.control?.setValue(this.formValues, { emitEvent: true });
    });
  }

}

