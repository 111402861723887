<form [formGroup]="myForm">
  <app-custom-input
    [formGroup]="myForm"
    controlName="name"
    placeholder="{{ inputItems['name']['placeholder'] }}"
    *ngIf="inputItems['name']?.is_include"
    [iconClass]="inputItems['name']?.icon"
  ></app-custom-input>

  <app-custom-radio-group
    [formGroup]="myForm"
    controlName="radioTest"
    label="{{ radioItems['radioTest']['title'] }}"
    [options]="radioItems['radioTest']['options']"
    *ngIf="radioItems['radioTest']?.is_include"
  >
  </app-custom-radio-group>
</form>
