<form [formGroup]="myForm">
  <h2 class="ccmTitle">{{ formsCCM.title }}</h2>
  <p [innerHTML]="formsCCM.paragraph"></p>
  <h3 class="ccmListTitle">{{ formsCCM.list_title }}</h3>
  <ul class="ccmList">
    <li>{{ formsCCM.list_1 }}</li>
    <li>{{ formsCCM.list_2 }}</li>
    <li>{{ formsCCM.list_3 }}</li>
  </ul>

  <ng-container *ngIf="form_loaded">
    <!-- First Question -->
    <app-custom-radio-group
      [formGroup]="myForm"
      controlName="enrolledWithOtherProvider"
      label="{{ radioItems['enrolledWithOtherProvider']['title'] }}"
      [options]="radioItems['enrolledWithOtherProvider']['options']"
      class="m-t-l radios-vertical orderlast"
    ></app-custom-radio-group>

    <!-- Second Question -->
    <div
      *ngIf="
        myForm.get('enrolledWithOtherProvider')?.value === 'no' ||
        myForm.get('enrolledWithOtherProvider')?.value === 'notSure'
      "
      class="m-t-l d-block"
    >
      <app-custom-radio-group
        [formGroup]="myForm"
        controlName="interestedJoiningCCM"
        label="{{ radioItems['interestedJoiningCCM']['title'] }}"
        [options]="radioItems['interestedJoiningCCM']['options']"
        class="flex-row-reverse"
      ></app-custom-radio-group>
    </div>

    <!-- Third Question -->
    <div
      *ngIf="myForm.get('interestedJoiningCCM')?.value === 'no'"
      class="m-t-l d-block"
    >
      <app-custom-radio-group
        [formGroup]="myForm"
        controlName="learnMoreAboutCCM"
        label="{{ radioItems['learnMoreAboutCCM']['title'] }}"
        [options]="radioItems['learnMoreAboutCCM']['options']"
        class="flex-row-reverse"
      ></app-custom-radio-group>
    </div>
  </ng-container>
</form>
