.w-100 {
  width: 100% !important;
}

.m-h-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.slider-title {
  color: var(--primary-color);
  margin-top: 32px;
  margin-bottom: 42px;
  padding-left: 32px;
  padding-right: 32px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.labels {
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.labels span {
  font-size: 14px;
  color: var(--primary-color);
  font-weight: 500;
  line-height: 16px;
}
.mat-mdc-slider .mdc-slider__track--active {
  height: 0px !important;
}
.mdc-slider__track--active_fill {
  border-color: var(--primary-color) !important;
}
.mdc-slider__track--inactive {
  background: var(--primary-color) !important;
  opacity: 1 !important;
  height: 2px !important;
}
.mdc-slider__track--inactive_fill {
  border-color: var(--primary-color) !important;
}
