<form class="medical" [formGroup]="formGroup">
  <mat-form-field class="chip-panel">
    <mat-label>{{ title | translate }}</mat-label>
    <mat-chip-grid #chipGrid>
      <ng-container *ngFor="let chip of chips">
        <mat-chip-row
          (removed)="remove(chip)"
          [editable]="true"
          (edited)="edit(chip, $event)"
          [aria-description]="'press enter to edit ' + chip"
        >
          {{ chip }}
          <button matChipRemove [attr.aria-label]="'remove ' + chip">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </ng-container>
      <input
        placeholder="New ..."
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-grid>
  </mat-form-field>
  <div class="hintContainer">
    <span *ngIf="isRequired(); else optionalField" class="hintRequired">
      *Required
    </span>
    <ng-template #optionalField>
      <span class="hintOptional">+Optional</span>
    </ng-template>
    <span class="hintText"> - Type and press "Enter" to add a value</span>
  </div>
</form>
