import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { Router, NavigationEnd, RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './app/custom-reuse-strategy';
import { provideRouter } from '@angular/router';
import { filter } from 'rxjs/operators';

if (environment.production) {
  window.console.log = () => { };
}

bootstrapApplication(AppComponent, {
  providers: [
    ...appConfig.providers,
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
  ],
}).then(appRef => {
  const router = appRef.injector.get(Router);

  router.events.pipe(
    filter(event => event instanceof NavigationEnd)
  ).subscribe(() => {
    updateProviderNameClass(router.url);
  });

  // Initial call to set the class
  updateProviderNameClass(router.url);

  // Set up MutationObserver for aria-hidden on app-root
  observeAppRootAriaHidden();
}).catch(err => console.error(err));

function updateProviderNameClass(url: string) {
  const providerName = localStorage.getItem('hospital_slug');
  if (providerName) {
    const existingClasses = document.body.className.split(' ').filter(className => className && !className.startsWith('provider-'));
    existingClasses.push(`provider-${providerName}`);
    document.body.className = existingClasses.join(' ');
    (window as any).providerName = providerName;
  }
}

function observeAppRootAriaHidden() {
  const appRoot = document.querySelector('app-root');
  if (appRoot) {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'aria-hidden') {
          if (appRoot.getAttribute('aria-hidden') === 'true') {
            appRoot.setAttribute('aria-hidden', 'false');
          }
        }
      });
    });
    observer.observe(appRoot, { attributes: true });
  }
}
