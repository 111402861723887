<p>
  Welcome to AK Pain & Spine Center. We are a state-of-the-art multidisciplinary
  pain practice committed to providing our patients with individualized care,
  novel therapeutic options, and the best treatment possible. We are dedicated
  to providing a comprehensive treatment program for people dealing with chronic
  and acute pain. Our staff is tuned to the challenge faced by those in this
  health state, and our goal is to help you restore your functionality and
  return to an active lifestyle.
</p>
<br />
<p>
  On your first visit, our doctors will review your medical record to come up
  with a diagnosis for what might be actually causing your pain and the nerves
  involved in that process. While medication therapy is important to getting you
  on your road to recovery, it is not the only step. Please keep in mind that in
  evaluating your record, our doctors will not be able to prescribe medications
  on the first visit as this is an initial consultation.
</p>
<br />
<p>
  Together we will come up with a treatment plan that is best for your health
  and wellbeing while being aware of other medical conditions you might have. We
  seek to identify the actual cause of pain for each patient and provide
  effective treatment to reduce pain symptoms, and it is the basis of our three-
  phase goal directed therapy. We look forward to being an important part of
  your health care needs.
</p>
