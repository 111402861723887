import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CommonModule } from '@angular/common';
import moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-custom-date-picker',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatMomentDateModule,
    MatIconModule
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.css']
})
export class CustomDatePickerComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() readonly: boolean = false;
  @Input() id?: string;
  private readonly DEFAULT_MIN_DATE = new Date(1900, 0, 1);
  private readonly DEFAULT_MAX_DATE = new Date(new Date().setFullYear(new Date().getFullYear() + 10));

  ngOnInit(): void {
    this.minDate = this.minDate || this.DEFAULT_MIN_DATE;
    this.maxDate = this.maxDate || this.DEFAULT_MAX_DATE;
    this.initializeFormControl();
  }

  private initializeFormControl(): void {
    if (!this.formGroup.controls[this.controlName]) {
      this.formGroup.addControl(this.controlName, new FormControl('', Validators.required)); // Adding Validators.required if necessary
    }

    const initialValue = this.formGroup.controls[this.controlName].value;
    if (initialValue) {
      const parsedDate = this.parseDate(initialValue);
      if (parsedDate) {
        this.control.setValue(parsedDate);
        this.formGroup.controls[this.controlName].setErrors(null);
        this.formGroup.controls[this.controlName].markAsTouched();
        this.formGroup.controls[this.controlName].markAsDirty();
        this.formGroup.controls[this.controlName].updateValueAndValidity();
      }
    }
  }

  get control(): FormControl {
    return this.formGroup.get(this.controlName) as FormControl;
  }

  private parseDate(dateString: string | Date): Date | null {
    const date = moment(dateString, 'MM/DD/YYYY', true);
    return date.isValid() ? date.toDate() : null;
  }

  isDateInvalid(): boolean {
    const value = this.control.value;
    return !value || !moment(value, 'MM/DD/YYYY', true).isValid();
  }

  getErrorMessage(): string {
    const value = this.control.value;
    if (!value || !moment(value, 'MM/DD/YYYY', true).isValid()) {
      return 'Please enter a valid date.';
    }

    const date = moment(value, 'MM/DD/YYYY', true).toDate();

    if (this.maxDate && date > this.maxDate) {
      if (this.isToday(this.maxDate)) {
        return 'Only past dates are allowed.';
      }
      return `Date must be before ${this.formatDate(this.maxDate)}.`;
    }

    if (this.minDate && date < this.minDate) {
      return `Date must be after ${this.formatDate(this.minDate)}.`;
    }

    return '';
  }

  private formatDate(date: Date): string {
    return moment(date).format('MM/DD/YYYY');
  }

  private isToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  // Method to check if the field is required
  isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }
}
