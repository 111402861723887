import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray, ReactiveFormsModule } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { CommonModule } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { formObjectByType } from "../../functions/functions";
import { TestAndImageComponent } from '../../reusable/test-and-image/test-and-image.component';
import { CustomCheckboxGroupComponent } from '../../reusable/custom-checkbox-group/custom-checkbox-group.component';
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';
import { CustomDatePickerComponent } from '../../reusable/custom-date-picker/custom-date-picker.component';
import { CustomRadioGroupComponent } from '../../reusable/custom-radio-group/custom-radio-group.component';
import { CustomTextareaComponent } from '../../reusable/custom-textarea/custom-textarea.component';
interface TestAndImage {
  facility: string;
  type: string;
  fileDate: string;
  fileName: string;
}

interface PatchValues {
  testAndImage: TestAndImage[];
  [key: string]: any;
}

@Component({
  selector: 'app-treatment-history-step-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TestAndImageComponent,
    CustomCheckboxGroupComponent,
    CustomCheckboxComponent,
    CustomInputComponent,
    CustomDatePickerComponent,
    CustomRadioGroupComponent,
    CustomTextareaComponent
  ],
  templateUrl: './treatment-history-step-form.component.html',
  styleUrls: ['./treatment-history-step-form.component.css'],
})
export class TreatmentHistoryStepFormComponent implements OnInit, OnDestroy {
  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values: PatchValues = { testAndImage: [] };
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';
  formValues: any = {};
  myForm!: FormGroup;
  form_loaded: boolean = false;
  destroySubject = new Subject<void>();
  checkboxesItems: any = {}
  checkboxItems: any = {}
  radioItems: any = {}
  inputItems: any = {}
  minDate!: Date;
  maxDate!: Date;

  constructor(
    private translateService: TranslateService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    this.maxDate = new Date();

    this.initializeForm();

    const currentLang = this.translateService.currentLang;
    let lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {

      const translationsObj = translations['healent'][this.step];
      const translationsObjSlug = translations[this.hospital_slug][this.step];

      let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step)
      this.myForm = formObject['myForm'];
      this.radioItems = formObject['radioItems'];
      this.inputItems = formObject['inputItems'];
      this.checkboxesItems = formObject['checkboxesItems'];
      this.checkboxItems = formObject['checkboxItems'];

      if (this.patch_values) {
        this.myForm.patchValue(this.patch_values, { emitEvent: false });
      }
      this.control?.setValue(this.myForm.value, { emitEvent: true }); // set initial value
      this.formGroup = this.myForm;
      this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(val => {
        this.formValues = val;
        this.control?.setValue(this.myForm.value, { emitEvent: true });
        this.formGroup = this.myForm;
      });

      this.form_loaded = true;
      this.cdr.detectChanges();

      // Call the onCheckboxChange method for initial setup
      this.onCheckboxChange();
    })
  } // end ngOnInit

  initializeForm(): void {
    this.myForm = this.fb.group({
      testAndImage: this.fb.array([]),
      // Initialize other necessary controls here if needed
    });
  }

  setFormArray(data: TestAndImage[]) {
    const testAndImageFormArray = this.myForm.get('testAndImage') as FormArray;
    testAndImageFormArray.clear();
    if (data && data.length > 0) {
      data.forEach(item => {
        const group = this.fb.group({
          facility: [item.facility, Validators.required],
          type: [item.type, Validators.required],
          fileDate: [item.fileDate, Validators.required],
          fileName: [item.fileName, Validators.required],
        });
        testAndImageFormArray.push(group);
      });
    }
    this.cdr.detectChanges();
  }


  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onCheckboxChange() {
    const checkboxesControls = [
      { checkbox: 'chiropractic_Therapy', inputs: ['chiropractic_Therapy_Facility', 'chiropractic_Therapy_date'] },
      { checkbox: 'physical_Therapy', inputs: ['physical_Therapy_Facility', 'physical_Therapy_date'] },
      { checkbox: 'epidural_Steroid_Injection', inputs: ['epidural_Steroid_Injection_Facility', 'epidural_Steroid_Injection_date'] },
      { checkbox: 'facet_Joint_Steroid_Injection', inputs: ['facet_Joint_Steroid_Injection_Facility', 'facet_Joint_Steroid_Injection_date'] },
      { checkbox: 'joint_Injection', inputs: ['joint_Injection_Facility', 'joint_Injection_date'] },
      { checkbox: 'spinal_Cord_Stimulator', inputs: ['spinal_Cord_Stimulator_Facility', 'spinal_Cord_Stimulator_date'] },
      { checkbox: 'iHadInjectionsToAssistWithTheTreatmentOfMyPain', inputs: ['typeOfInjection'] },
    ];

    checkboxesControls.forEach(group => {
      this.myForm.get(group.checkbox)?.valueChanges.subscribe(checked => {
        group.inputs.forEach(controlName => {
          const control = this.myForm.get(controlName);
          if (checked) {
            control?.setValidators([Validators.required]);
          } else {
            control?.clearValidators();
          }
          control?.updateValueAndValidity();
        });
      });
    });
  }
}
