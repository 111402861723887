import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-cwps-physician-financial-policy',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './cwps-physician-financial-policy.component.html',
  styleUrl: './cwps-physician-financial-policy.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class CwpsPhysicianFinancialPolicyComponent {

}
