.done-main {
  height: 100vh;
  padding: 0 30px 30px 30px;
}
.done-icon {
  font-size: 180px;
  color: var(--accent);
  line-height: 1;
}
.download-img {
  height: 48px;
  width: auto;
  display: inline-block;
}
.finish-btn {
  width: 160px;
  margin-top: var(--size-xxl);
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: var(--font-size-m);
  letter-spacing: var(--mdc-text-button-label-text-tracking);
  font-weight: bold;
  text-transform: var(--mdc-text-button-label-text-transform);
  height: var(--size-xml);
  border-radius: 5px;
  background: var(--primary-color);
  color: var(--white);
  cursor: pointer;
}
.gap-m {
  gap: var(--size-m);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.hide-done {
  display: none;
}
