import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-custom-radio-group',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatFormFieldModule
  ],
  templateUrl: './custom-radio-group.component.html',
  styleUrls: ['./custom-radio-group.component.css']
})
export class CustomRadioGroupComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() options!: { [key: string]: string };
  @Input() selectFirstOption: boolean = false;  // New input to control auto-selection

  ngOnInit() {
    // Set the first option as selected if selectFirstOption is true
    if (this.selectFirstOption && this.options && Object.keys(this.options).length > 0) {
      const firstOptionValue = Object.keys(this.options)[0];
      this.control.setValue(firstOptionValue);
      this.control.markAsTouched();
    }
  }

  get control(): FormControl {
    const control = this.formGroup.get(this.controlName);
    if (!control) {
      throw new Error(`Control '${this.controlName}' not found in FormGroup`);
    }
    return control as FormControl;
  }

  // Method to check if the field is required
  get isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }
}
