<div class="verification-body" [ngClass]="{ hide: isHidden }">
  <div class="verification-info">
    <h1>{{ formVerification.title }}</h1>
    <p>{{ formVerification.description }}</p>
    <form [formGroup]="userInfoForm" novalidate class="m-t-l">
      <app-custom-date-picker
        id="login_dob"
        [formGroup]="userInfoForm"
        controlName="dob"
        label="{{ formVerification.dob }}"
        [minDate]="minDate"
        [maxDate]="maxDate"
      >
      </app-custom-date-picker>
      <app-verification-phone
        id="login_phone"
        [formGroup]="userInfoForm"
        title="{{ formVerification.PhoneNumber }}"
        formName="mobilePhone"
      ></app-verification-phone>
    </form>
    <button
      mat-raised-button
      class="get-verefi-btn m-t-l"
      (click)="verifyUserInfo()"
    >
      <ng-container>
        <div class="d-flex">
          {{ formVerification.btn }}
          <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
        </div>
      </ng-container>
    </button>
  </div>
</div>
