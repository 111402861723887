import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedFormService } from '../../../../../services/SharedFormService.service';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-akps-acknowledgment',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './akps-acknowledgment.component.html',
  styleUrl: './akps-acknowledgment.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class AkpsAcknowledgmentComponent implements OnInit {
  firstName: string = '';
  homePhone: string | null = null;
  mobilePhone: string | null = null;
  workPhone: string | null = null;
  email: string | null = null;
  authorizedContacts: any[] = [];

  constructor(private sharedFormService: SharedFormService) { }

  ngOnInit() {
    this.sharedFormService.formValues$.subscribe(values => {
      this.firstName = values.firstName || '';
      this.homePhone = values.homePhone;
      this.mobilePhone = values.mobilePhone;
      this.workPhone = values.workPhone;
      this.email = values.contact.email;
      this.authorizedContacts = values.privacyPreference?.authorized || [];
    });
  }


  get current() {
    const now = new Date();
    return now.toLocaleString(); // Adjust format as needed
  }
}
