<div class="front-selector">
  <svg
    #svgElement
    width="300"
    height="600"
    viewBox="0 0 5.7170705636960335 11.314693437610776"
    version="1.1"
    id="F_m_front"
    xmlns="http://www.w3.org/2000/svg"
    (click)="onSvgClick($event)"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="m 2.4721062,1.9378279 c 0.03288,0.078608 0.169525,0.04939 0.2408686,0.074382 0.1780344,0.010447 0.3694666,0.0098 0.5355094,-0.05939 C 3.2475076,1.7687732 3.1504574,1.5752526 3.1766182,1.3813285 3.1772788,1.2698112 3.1900385,1.158428 3.1939611,1.0471312 3.0455253,0.95680096 2.8586547,1.028197 2.694809,1.0032761 2.6266582,1.0168209 2.4800423,1.0198897 2.5354105,1.1314346 2.5761693,1.4065 2.5557808,1.6723668 2.4721062,1.9378279 Z"
      id="F_neck"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 0.76640798,3.9739361 C 0.75756974,4.1345279 0.98196733,4.14569 1.0694804,4.0514254 1.4231921,3.696465 1.6273757,3.2250653 1.8779723,2.7975423 1.9702801,2.6574638 2.0403512,2.427868 1.8634337,2.3203536 1.7338603,2.2126069 1.528446,2.2406337 1.4497941,2.3955716 1.2644923,2.6992093 1.1115577,3.0239101 0.96483122,3.3478891 0.89596661,3.5544797 0.80987225,3.760406 0.76640798,3.9739361 Z"
      id="F_rightUpperArm"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      width="5"
      d="M 1.332926,2.5430803 C 1.3190997,2.6944814 1.4877491,2.7881259 1.615563,2.7139654 1.85568,2.6197258 2.1387509,2.5633082 2.2806948,2.3219042 2.3648772,2.1935161 2.4958686,2.0463554 2.4598564,1.8818981 2.33194,1.7359294 2.1055102,1.837516 1.9424946,1.8470935 1.6773365,1.8757754 1.4090577,2.0390803 1.3572751,2.3183497 c -0.02018,0.072953 -0.02693,0.1492167 -0.024349,0.2247306 z"
      id="F_rightShoulder"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 0.13652757,5.2195423 C 0.13910027,5.3362748 0.3519633,5.4163911 0.38877202,5.2792043 0.56872357,4.8629013 0.97021475,4.5967759 1.1768505,4.1993104 1.2306408,4.0398558 1.3603152,3.8210587 1.2259519,3.6704482 1.0824877,3.5356925 0.82858317,3.5538851 0.73145983,3.7358992 0.43451544,4.166429 0.32738123,4.6895697 0.14414885,5.1719971 c -4.527e-4,0.016222 -0.006736,0.031426 -0.007621,0.047545 z"
      id="F_rightLowerArm"
    />
    <path
      id="F_rightHand"
      fill-rule="evenodd"
      class="svgUnselected"
      stroke="#000000"
      stroke-width="0.03"
      d="M 0.24609375,5.1640625 C 0.17936322,5.1676763 0.11735738,5.2206469 0.11101154,5.2885883 0.07768331,5.3915329 0.0360046,5.4923098 0.01525957,5.5990224 c -0.00899043,0.049147 -0.01663901,0.098848 -0.02087907,0.1482124 0.01555656,0.037414 0.01413638,0.07032 0.01208289,0.1106326 0.00341219,0.035104 0.00653334,0.075482 -0.00728657,0.1037716 0.00749069,0.027446 -0.0166436803,0.084364 0.0201515897,0.1113766 0.01758962,0.044811 0.0020873,0.1003645 0.03766486,0.1385615 0.02179704,0.015488 0.04590881,0.013166 0.04571287,0.046728 0.006323,0.056673 0.0804156,0.073863 0.11114883,0.026876 0.0363894,-0.012239 0.009588,-0.057592 0.0491529,-0.064288 0.0442658,-0.024781 0.0144124,-0.064239 -0.009102,-0.090035 0.0412804,-0.00759 0.0796437,-0.054458 0.0556254,-0.095851 C 0.28594608,5.9931806 0.23399841,5.9712705 0.22376101,5.9209421 0.20925468,5.8751252 0.20923861,5.8265545 0.203125,5.7793 c 0.009437,-0.015878 0.0218178,-0.052376 0.0268078,-0.014179 0.0176774,0.041081 0.0287198,0.117135 0.0887912,0.07862 0.060094,-0.020271 0.0638624,-0.099211 0.0279646,-0.1431018 -0.0269271,-0.04757 -0.0321051,-0.1130736 -0.0104941,-0.162486 0.0438935,-0.091141 0.0729112,-0.1999367 0.0365277,-0.2984465 -0.0194601,-0.050027 -0.0741286,-0.079838 -0.1266286,-0.075643 z"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="m 3.8035858,2.5466318 c 0.07552,0.3017633 0.2779388,0.553498 0.4092266,0.832794 0.159126,0.2434405 0.3006689,0.5183654 0.5372169,0.6952429 C 4.8611167,4.1596858 5.068039,4.0751796 5.0066108,3.9181933 4.8885999,3.4915077 4.712503,3.0854639 4.4948625,2.7005189 4.3913867,2.5396809 4.3203923,2.2609808 4.0907981,2.2504225 c -0.14917,0.00923 -0.3076487,0.134055 -0.2872123,0.2962093 z"
      id="F_leftUpperArm"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      width="40"
      d="m 3.3010989,1.9305972 c 0.034081,0.2386866 0.1917518,0.459209 0.3811049,0.6030627 0.171845,0.077367 0.3509626,0.1533383 0.533012,0.1999338 C 4.4247634,2.7372871 4.4508414,2.4564242 4.3787692,2.3111608 4.3165778,2.0420534 4.0646498,1.8690676 3.7988956,1.8446495 3.6439654,1.8310517 3.4322054,1.7394317 3.3127043,1.8807769 c -0.00387,0.016607 -0.00774,0.033214 -0.011605,0.04982 z"
      id="F_leftShoulder"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 4.4742456,3.8075512 C 4.4908422,4.1248337 4.6714449,4.3974405 4.8867505,4.6185268 5.0691874,4.8375625 5.2524736,5.06281 5.3635339,5.3260483 5.4692018,5.39551 5.63965,5.2608839 5.566191,5.1371297 5.4001775,4.6458356 5.31931,4.1031887 5.0001969,3.6803928 4.8730024,3.5027718 4.5482229,3.5645656 4.4777759,3.760781 l -0.00191,0.025282 z"
      id="F_leftLowerArm"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="m 3.2528086,8.6430242 c 0.092124,0.681855 0.357048,1.3388913 0.3288666,2.0361098 -0.023606,0.130263 0.1147156,0.252855 0.2326249,0.16661 C 4.03648,10.6978 3.9349096,10.386024 3.9588167,10.163976 3.9442387,9.6314399 4.0017803,9.1009118 4.0087417,8.5693271 3.9693411,8.2033406 3.9114464,7.8294536 3.7681289,7.4886486 3.6995757,7.3296711 3.4594986,7.3085115 3.3789295,7.4677945 3.2686742,7.6868385 3.3230529,7.9494965 3.2724346,8.1846905 3.2569251,8.3370884 3.2441259,8.4897266 3.2528086,8.6430242 Z"
      id="F_leftLowerLeg"
    />
    <path
      id="F_leftHand"
      fill-rule="evenodd"
      class="svgUnselected"
      stroke="#000000"
      stroke-width="0.03"
      d="m 5.4531223,5.0126039 c 0.066731,0.00361 0.1287364,0.056584 0.1350822,0.1245258 0.033328,0.1029446 0.075007,0.2037215 0.095752,0.3104341 0.00899,0.049147 0.016639,0.098848 0.020879,0.1482124 -0.015557,0.037414 -0.014136,0.07032 -0.012083,0.1106326 -0.00341,0.035104 -0.00653,0.075482 0.00729,0.1037716 -0.00749,0.027446 0.016644,0.084364 -0.020152,0.1113766 -0.01759,0.044811 -0.00209,0.1003645 -0.037665,0.1385615 -0.021797,0.015488 -0.045909,0.013166 -0.045713,0.046728 -0.00632,0.056673 -0.080416,0.073863 -0.1111488,0.026876 -0.036389,-0.012239 -0.00959,-0.057592 -0.049153,-0.064288 -0.044266,-0.024781 -0.014412,-0.064239 0.0091,-0.090035 -0.04128,-0.00759 -0.079644,-0.054458 -0.055625,-0.095851 0.023585,-0.041826 0.075533,-0.063737 0.08577,-0.114065 0.014506,-0.045817 0.014522,-0.094388 0.020636,-0.1416421 -0.00944,-0.015878 -0.021818,-0.052376 -0.026808,-0.014179 -0.017677,0.041081 -0.02872,0.117135 -0.088791,0.07862 -0.060094,-0.020271 -0.063862,-0.099211 -0.027965,-0.1431018 0.026927,-0.04757 0.032105,-0.1130736 0.010494,-0.162486 -0.043894,-0.091141 -0.072911,-0.1999367 -0.036528,-0.2984465 0.01946,-0.050027 0.074129,-0.079838 0.1266286,-0.075643 z"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      height="50"
      d="m 2.921798,5.3325336 c 0.00778,0.581234 0.013643,1.1714533 0.1975368,1.7296029 0.087208,0.2601117 0.055652,0.5609119 0.2051053,0.7977846 C 3.4625695,8.0441505 3.7393899,7.9014027 3.7861385,7.7107737 3.8788722,7.3782616 3.8821814,7.0262698 3.9353732,6.6857547 3.9860657,6.1226712 3.9164425,5.5512792 3.7851813,5.0036933 3.7429842,4.7688057 3.5082164,4.5593397 3.2624034,4.6451002 2.9864815,4.7405308 2.9508488,5.0864594 2.921798,5.3325336 Z"
      id="F_leftUpperLeg"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="m 3.5260873,11.138862 c 0.00571,0.09574 0.1052822,0.114602 0.1816811,0.121202 0.097956,0.109568 0.2665323,0.01937 0.3909059,0.02494 0.115056,0.0097 0.2164408,-0.1123 0.1348885,-0.214898 -0.093717,-0.121354 -0.2237875,-0.220603 -0.2457037,-0.385357 -0.05688,-0.175364 -0.064847,-0.384917 -0.202119,-0.521273 -0.1300481,-0.01943 -0.1609695,0.170412 -0.1955962,0.259262 -0.041616,0.214828 -0.01633,0.437177 -0.067906,0.651238 -5.641e-4,0.02179 6.141e-4,0.04333 0.00385,0.06488 z"
      id="F_leftFoot"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 3.3565421,7.6850333 C 3.3470004,7.8264061 3.390351,8.0545194 3.5678173,8.0599501 3.7468307,7.9912388 3.7457021,7.7312859 3.6789281,7.5834322 3.6374069,7.4196272 3.3856347,7.4485706 3.3731508,7.6136774 c -0.00689,0.02365 -0.014118,0.046724 -0.016609,0.071356 z"
      id="F_leftKnee"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="m 1.7275553,8.6007259 c 0.032448,0.6219743 0.082544,1.2441557 0.059989,1.8678611 -0.024626,0.161723 0.032139,0.420372 0.241981,0.404072 0.176876,-0.03983 0.1172179,-0.275781 0.1426174,-0.409035 C 2.1859789,9.814418 2.4417959,9.2025125 2.4973696,8.5601823 2.4957624,8.2293858 2.4300913,7.9005371 2.3787008,7.5750289 2.3545552,7.3968281 2.1100246,7.2721869 1.9846964,7.4326625 1.8289969,7.6851983 1.8117637,7.9974146 1.7540615,8.2823996 1.7383349,8.3880376 1.7320669,8.4941378 1.7275553,8.6007259 Z"
      id="F_rightLowerLeg"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      height="50"
      d="M 1.7526293,6.0789845 C 1.7298984,6.6007961 1.8260602,7.117956 1.8917575,7.6331706 1.9091165,7.8314781 2.139742,8.0447545 2.3309034,7.9034244 2.5226601,7.7030521 2.4831028,7.3930148 2.5630073,7.1436642 2.7304745,6.5442227 2.7819743,5.9188503 2.7632723,5.2987264 2.7397844,5.0417223 2.6637111,4.689242 2.3634395,4.6347832 2.1048894,4.5800927 1.9062636,4.8195612 1.8768622,5.0549856 1.8035855,5.390756 1.7774069,5.736753 1.7526293,6.0789845 Z"
      id="F_rightUpperLeg"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="m 1.3987508,11.158636 c 0.00117,0.157307 0.2126645,0.123718 0.3164238,0.148482 0.076578,0.0079 0.1693694,0.02298 0.2302723,-0.03454 0.1135365,-0.0065 0.3005069,-0.0093 0.2858183,-0.174206 -0.052104,-0.294368 -0.00556,-0.621824 -0.1668766,-0.886752 -0.071884,-0.124344 -0.1844121,0.02007 -0.1985381,0.108095 -0.095063,0.22694 -0.106431,0.522423 -0.3427544,0.658551 -0.051103,0.05021 -0.1208504,0.102799 -0.1243453,0.18037 z"
      id="F_rightFoot"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 1.9536784,7.7784088 C 1.9393606,7.9033509 2.0416364,8.1324981 2.1935679,8.037397 2.337311,7.930863 2.3487462,7.6924314 2.2679511,7.5468147 2.1743379,7.3844077 1.9477546,7.5230839 1.9628466,7.681371 c -0.00401,0.032296 -0.00901,0.064412 -0.00917,0.097038 z"
      id="F_rightKnee"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      height="60"
      d="m 1.9364367,4.9974196 c -0.00351,0.2256812 0.1994191,0.3748116 0.2419613,0.5887653 0.072709,0.2552333 0.3914908,0.2762218 0.6100462,0.243761 0.2353914,-0.00803 0.5122051,0.063304 0.7072135,-0.111927 C 3.6006924,5.5602664 3.6010633,5.3566386 3.7112773,5.1995498 3.8009944,4.9533254 3.7297078,4.6513397 3.5615368,4.4546037 3.3703924,4.2762679 3.0878254,4.2372804 2.836486,4.2219897 2.5570825,4.2428973 2.216523,4.2824279 2.0466651,4.5341538 1.9679869,4.6739159 1.9412017,4.8388946 1.9364367,4.9974196 Z"
      id="F_abdomen"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      height="60"
      d="m 2.0532034,4.6386435 c -0.02021,0.3071392 0.2493909,0.5428343 0.5362565,0.5794917 0.3507226,0.059962 0.824481,0.00689 0.9871553,-0.3601311 C 3.6944876,4.505242 3.5025621,4.1426867 3.546946,3.7821618 3.5756924,3.4472413 3.5305775,3.0356653 3.2029976,2.8565081 2.8162294,2.6054013 2.2285322,2.8490259 2.1511308,3.3090132 c -0.05897,0.3500161 0.032284,0.7117862 -0.066529,1.0592431 -0.015347,0.08967 -0.02833,0.1793282 -0.031398,0.2703872 z"
      id="F_lowerBody"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 1.7868524,2.240731 C 1.7506907,2.6793262 1.8762629,3.1294117 2.1077562,3.4995494 2.2750751,3.6680302 2.5305613,3.7321771 2.7625198,3.7537042 3.0587999,3.7680704 3.4073445,3.7148881 3.602269,3.4710952 3.8283797,3.0485456 3.9998911,2.5570808 3.921455,2.0733626 3.8368243,1.8041516 3.5299653,1.7116076 3.3178497,1.5641139 3.01539,1.368402 2.6131604,1.4141292 2.3301091,1.6243536 2.1058302,1.7626117 1.7990588,1.9026668 1.7921138,2.2087904 l -0.00412,0.025017 z"
      id="F_upperBody"
    />
    <path
      fill-rule="evenodd"
      class="svgUnselected"
      d="M 2.3690674,0.64279961 C 2.3901029,0.92161662 2.4352158,1.239883 2.6481093,1.4411573 2.8262533,1.5441202 3.0623792,1.4580521 3.151745,1.2769103 3.3602017,0.95826858 3.40962,0.5293534 3.245234,0.18131684 3.044998,-0.1065187 2.4793028,-0.04733288 2.4070932,0.32126428 2.3780356,0.42571315 2.3667339,0.53457517 2.3690674,0.64279961 Z"
      id="F_head"
    />
  </svg>
</div>
