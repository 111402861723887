import { Component, OnInit } from '@angular/core';
import { SharedFormService } from '../../../../../services/SharedFormService.service';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-cwps-procedure-consent',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cwps-procedure-consent.component.html',
  styleUrl: './cwps-procedure-consent.component.css'
})
export class CwpsProcedureConsentComponent implements OnInit {
  procedureDate: string = '';
  patientName: string = '';
  procedureType: string = '';

  constructor(private sharedFormService: SharedFormService) { }

  ngOnInit(): void {
    const formName = localStorage.getItem('form_name');
    if (formName) {
      this.procedureType = formName;
    }
  }
}
