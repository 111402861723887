import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedFormService } from '../../../../../services/SharedFormService.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-akps-preferred-contacts',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './akps-preferred-contacts.component.html',
  styleUrls: ['./akps-preferred-contacts.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AkpsPreferredContactsComponent implements OnInit {
  firstName: string = '';
  homePhone: string | null = null;
  mobilePhone: string | null = null;
  workPhone: string | null = null;
  email: string | null = null;
  authorizedContacts: any[] = [];

  constructor(private sharedFormService: SharedFormService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    const values = this.sharedFormService.getFormValues();
    this.firstName = values.firstName || '';
    this.homePhone = values.homePhone || null;
    this.mobilePhone = values.mobilePhone || null;
    this.workPhone = values.workPhone || null;
    this.email = values.contact ? values.contact.email : null;
    this.authorizedContacts = values?.authorized || [];
    this.cdr.detectChanges();
  }



  get current() {
    const now = new Date();
    return now.toLocaleString(); // Adjust format as needed
  }
}
