import { Component, Input, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { BottomSheetRadioFormComponent } from '../bottom-sheet-radio-form/bottom-sheet-radio-form.component';
import { startWith } from 'rxjs/operators';

interface OptionWithScore {
  label: string;
  score: string;
}

interface Options {
  [key: string]: string | OptionWithScore;
}

@Component({
  selector: 'app-select-as-array',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatIconModule
  ],
  templateUrl: './select-as-array.component.html',
  styleUrls: ['./select-as-array.component.css']
})
export class SelectAsArrayComponent implements OnInit, AfterViewInit {
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() label!: string; // This will be the question
  @Input() optionsTitle!: string;
  @Input() options!: Options;
  @Input() iconClass?: string;
  @Input() errorMessages!: { [key: string]: string }; // Custom error messages
  @Input() separateTitle?: boolean = false;
  @Input() titleNumber!: string;
  @Input() subTitle!: string;
  @Input() hint!: string;
  @Input() questionId!: number;
  @Input() hasScore: boolean = false; // New prop for conditional score

  displayValue: string = '';

  constructor(private bottomSheet: MatBottomSheet, private cdr: ChangeDetectorRef) { }

  // Getter for the form control
  get control(): FormControl {
    const control = this.formGroup.get(this.controlName);
    if (!control) {
      throw new Error(`Control with name '${this.controlName}' not found in form group`);
    }
    return control as FormControl;
  }

  ngOnInit(): void {
    const initialValue = this.control.value;
    this.control.setValue({
      code: this.controlName,
      questionId: this.questionId,
      question: this.label,
      value: {
        label: initialValue?.value?.label || '',
        value: initialValue?.value?.value || '',
        ...(this.hasScore && { score: initialValue?.value?.score || '' }) // Conditionally add score
      }
    });

    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    this.control.valueChanges.pipe(startWith(this.control.value)).subscribe(value => {
      if (value && typeof value === 'object' && value.value && value.value.label) {
        this.displayValue = this.capitalizeFirstLetter(value.value.label);  // Display the label
      } else {
        this.control.reset()
      }

      this.cdr.detectChanges();
    });
  }

  openOptions(): void {
    const currentValue = this.control.value;

    // Map options to ensure each item has key and value properties
    const itemsArray = Object.entries(this.options).map(([key, value]) => {
      if (this.hasScore && typeof value === 'object') {
        // Structure with score: use label as the displayed value
        return { key, value: value.label };
      } else {
        // Structure without score: use value directly as the displayed value
        return { key, value: value as string };
      }
    });

    const bottomSheetRef = this.bottomSheet.open(BottomSheetRadioFormComponent, {
      data: {
        value: currentValue && typeof currentValue === 'object' && currentValue.value?.value
          ? Object.keys(this.options).find(key => key === currentValue.value.value)
          : null,
        items: itemsArray,
        optionsTitle: this.optionsTitle || "Select the correct option"
      }
    });

    bottomSheetRef.afterDismissed().subscribe(selectedKey => {
      if (selectedKey) {
        const selectedOption = this.options[selectedKey];

        // Determine label and score based on selected option structure
        const selectedLabel = this.hasScore && typeof selectedOption === 'object' ? selectedOption.label : selectedOption as string;
        const selectedScore = this.hasScore && typeof selectedOption === 'object' ? selectedOption.score : '';

        this.control.setValue({
          code: this.controlName,
          questionId: this.questionId,
          value: {
            label: selectedLabel,
            value: selectedKey,
            ...(this.hasScore && { score: selectedScore }) // Add score if hasScore is true
          },
          question: this.label
        });

        this.displayValue = this.capitalizeFirstLetter(selectedLabel);
        this.cdr.detectChanges();
      }
    });
  }

  capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  getErrorMessage(): string {
    const controlErrors = this.control.errors;

    if (controlErrors) {
      for (const key in controlErrors) {
        if (controlErrors.hasOwnProperty(key) && this.errorMessages && this.errorMessages[key]) {
          return this.errorMessages[key];
        }
      }
    }

    return `Invalid selection`;
  }

  isFieldRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }
}
