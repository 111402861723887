import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-body-left',
  standalone: true,
  imports: [],
  templateUrl: './body-left.component.html',
  styleUrl: './body-left.component.css'
})
export class BodyLeftComponent {
  @Output() svgClick = new EventEmitter<MouseEvent>();

  onSvgClick(event: MouseEvent) {
    this.svgClick.emit(event);
  }

}

