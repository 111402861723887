<p>
  I hereby authorize the assignment of benefits (payments) directly to
  Commonwealth Pain & Spine for all my insurance claims related to services
  received. I agree to pay any and all charges that exceed, or are not covered
  by my insurance. I understand that co-pays, deductibles and non-covered
  services are due at the time of service. I authorize the release of any
  medical information necessary for the purpose of processing claims with my
  insurance company. I permit a copy of this authorization to be used in place
  of the original.
</p>
<br />
<br />
<p>
  Authorization will remain in effect from date signed until revoked in writing
  by patient or patient representative
</p>
<br />
<p>Please see the front desk if you would like paper copy of this consent</p>
