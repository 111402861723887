<p>
  The HIPAA Privacy Rule gives individuals the right to direct how and where
  their healthcare provider communicates with them, such as sending
  correspondence to the individual's office instead of the individual's home. We
  invite you to share with us your preferred place and manner of communication.
  You may update or change this information at any time; please do so in
  writing. I prefer to be contacted in the following manner:
</p>
<table class="table-info">
  <tr *ngIf="homePhone">
    <td><p class="nowrap">Home Telephone:</p></td>
    <td>
      <p class="lineBreak">{{ homePhone }}</p>
    </td>
  </tr>
  <tr *ngIf="mobilePhone">
    <td><p class="nowrap">Cell Phone:</p></td>
    <td>
      <p class="lineBreak">{{ mobilePhone }}</p>
    </td>
  </tr>
  <tr *ngIf="workPhone">
    <td><p class="nowrap">Work Telephone:</p></td>
    <td>
      <p class="lineBreak">{{ workPhone }}</p>
    </td>
  </tr>
  <tr *ngIf="email">
    <td><p class="nowrap">Email Address:</p></td>
    <td>
      <p class="lineBreak">{{ email }}</p>
    </td>
  </tr>
</table>

<h4>Preferred Contacts:</h4>

<p>
  We respect your right to indicate who you prefer we involve in your treatment
  or payment decisions and/or who we share your Information with. including
  about your general medical condition and diagnosis (such as treatment and
  payment options), access to medical records (PHI), prescription pick up and
  scheduling appointments. Please note, however, that we share your information
  as set forth in our Notice of Privacy Practices to other persons as needed for
  your care or treatment or the payment of services we have provided. Please
  update this information if your preference changes.
</p>

<p>
  Please indicate the person(s) you prefer we share your information with below:
</p>
<table class="table-authorizedContacts">
  <tr>
    <td>Name</td>
    <td>Telephone</td>
    <td>Relationship</td>
  </tr>
  <tr *ngIf="authorizedContacts.length === 0">
    <td></td>
    <td></td>
    <td></td>
  </tr>
  <tr *ngFor="let contact of authorizedContacts">
    <td>{{ contact.name }}</td>
    <td>{{ contact.primaryPhone }}</td>
    <td>{{ contact.RelationshipToThePatient }}</td>
  </tr>
</table>
<p>
  (To be signed by patient's parent or legal guardian if patient is a minor or
  otherwise not competent)
</p>
