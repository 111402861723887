<form [formGroup]="formGroup" style="position: relative">
  <mat-form-field>
    <mat-label>{{ title }}</mat-label>
    <mat-icon matSuffix (click)="toggleIcon()">
      <i class="input-icon" [ngClass]="iconClass"></i>
    </mat-icon>
    <input id="{{ id }}"
      #inputRef
      matInput
      formControlName="ssn"
      inputmode="numeric"
      [mask]="mask"
      (input)="updateDisplayedValue(inputRef.value)"
      class="ssn-input"
    />
    <input
      #inputStars
      matInput
      [value]="displayedValue"
      disabled
      class="ssn-stars"
    />
  </mat-form-field>
</form>
<!-- Display required/optional hint if no error -->
<div
  [ngClass]="{ hintContainer: true, valid: control?.valid && control?.value }"
>
  <div *ngIf="control?.errors && control?.touched">
    <span class="hintError">#Error</span>
    <span class="hintText"> - {{ getErrorMessage() }}</span>
  </div>
  <div *ngIf="!control?.errors || !control?.touched">
    <span *ngIf="isRequired(); else optionalField" class="hintRequired">
      *Required
    </span>
    <ng-template #optionalField>
      <span class="hintOptional">+Optional</span>
    </ng-template>
    <span *ngIf="placeholder" class="hintText"> - {{ placeholder }}</span>
  </div>
</div>
