import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-cwps-hippa-notice',
  standalone: true,
  imports: [],
  templateUrl: './cwps-hippa-notice.component.html',
  styleUrl: './cwps-hippa-notice.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class CwpsHippaNoticeComponent {

}
