<p>
  I, the undersigned patient, hereby authorize Commonwealth Pain & Spine and its
  staff to administer such treatment as is necessary, and to perform services
  and/or procedures as are considered necessary on the basis of findings during
  the course of delivery of health care services and treatment.
</p>
<br />
<p>
  I have read and fully understand the above Authorization to Treat, the reasons
  why the treatment is considered necessary, its advantages and possible
  complications, if any, as well as possible alternative methods of treatment
  which have been explained to me. I also certify that no guarantee or assurance
  has been made as to the results that may be obtained by services received at
  Commonwealth Pain & Spine.
</p>
<br />
<p>Please see the front desk if you would like paper copy of this consent.</p>
