import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { debounceTime } from 'rxjs/operators';
import { NgIf } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { formObjectByType } from "../../functions/functions";
import { ActivatedRoute } from "@angular/router";
import { CheckboxGroupAsArrayComponent } from '../../reusable/checkbox-group-as-array/checkbox-group-as-array.component';
import { CustomRadioGroupArrayComponent } from '../../reusable/custom-radio-group-array/custom-radio-group-array.component';
import { SharedFormService } from '../../services/SharedFormService.service';

@Component({
  selector: 'app-ort',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    CheckboxGroupAsArrayComponent,
    CustomRadioGroupArrayComponent,
  ],
  templateUrl: './ort.component.html',
  styleUrls: ['./ort.component.css']
})
export class OrtComponent implements OnInit, OnDestroy {

  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values: any = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';

  formValues: any = {};
  form_loaded = false;
  myForm!: FormGroup;
  checkboxesItems: any = {};
  radioItems: any = {};
  transformedValues: any = {};

  destroySubject = new Subject<void>();
  familyHistoryOptionsMale = {
    alcoholMale: {
      questionId: "4323",
      label: "Alcohol",
      score: "3"
    },
    illegalDrugsMale: {
      questionId: "4325",
      label: "Illegal Drugs",
      score: "3"
    },
    prescriptionDrugs: {
      questionId: "4327",
      label: "Prescription Drugs",
      score: "4"
    },
  };
  familyHistoryOptionsFemale = {
    alcoholMale: {
      questionId: "4324",
      label: "Alcohol",
      score: "1"
    },
    illegalDrugsMale: {
      questionId: "4326",
      label: "Illegal Drugs",
      score: "2"
    },
    prescriptionDrugs: {
      questionId: "4327",
      label: "Prescription Drugs",
      score: "4"
    },
  };
  sexualAbuseOptionsMale = {
    historyOfPreadolescentSexualAbuse: {
      questionId: "4335",
      label: "I have a History of Preadolescent Sexual Abuse",
      score: "0"
    }
  };
  sexualAbuseOptionsFemale = {
    historyOfPreadolescentSexualAbuse: {
      questionId: "4336",
      label: "I have a History of Preadolescent Sexual Abuse",
      score: "3"
    }
  };

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private sharedFormService: SharedFormService
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    this.myForm = new FormGroup({});
    if (this.myForm) {
      this.myForm.statusChanges.subscribe(() => {
        this.sharedFormService.updateFormValues({ ortValid: this.myForm.valid });
      });
    }
    const currentLang = this.translateService.currentLang;
    const lang_form = `${currentLang}-${this.form_id}`;

    this.translateService.getTranslation(lang_form)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(translations => {
        const translationsObj = translations['healent'][this.step];
        const translationsObjSlug = translations[this.hospital_slug][this.step];
        const formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step);

        this.myForm = formObject['myForm'];
        this.checkboxesItems = formObject['checkboxesItems'];
        this.radioItems = formObject['radioItems'];

        if (this.patch_values) {
          this.myForm.patchValue(this.patch_values, { emitEvent: false });
          this.applyGenderSelection();
          this.updateTransformedValues();
          this.sharedFormService.updateFormValues({ ortValid: this.myForm.valid });
          if (this.patch_values?.gender?.value) {
            console.log(this.patch_values?.gender?.value);
            this.myForm.get('gender')?.setValue(this.patch_values.gender.value, { emitEvent: false });
            this.myForm.get('gender')?.markAsTouched();
            this.myForm.get('gender')?.markAsDirty();
            this.myForm.get('gender')?.updateValueAndValidity();
          }
          this.cdr.detectChanges();
        }

        setTimeout(() => {
          this.myForm.updateValueAndValidity();
          this.sharedFormService.updateFormValues({ ortValid: this.myForm.valid });
          this.cdr.detectChanges();
        }, 100);


        this.myForm.valueChanges.pipe(debounceTime(100), takeUntil(this.destroySubject)).subscribe(val => {
          this.updateTransformedValues();
          this.updateControlValue();
        });

        this.form_loaded = true;
      });
  }


  private updateTransformedValues() {
    Object.keys(this.checkboxesItems).forEach(formGroupName => {
      const formGroup = this.myForm.get(formGroupName) as FormGroup;
      if (formGroup) {
        this.transformedValues[formGroupName] = {
          code: formGroupName,
          question: this.checkboxesItems[formGroupName].title,
          questionId: this.checkboxesItems[formGroupName].questionId,
          options: Object.keys(this.checkboxesItems[formGroupName].options).map(optionKey => {
            const questionId = this.checkboxesItems[formGroupName].options[optionKey]?.questionId;

            const isChecked = formGroup.get(optionKey)?.value;
            const backendValue = this.patch_values[formGroupName]?.options?.some(
              (opt: any) => opt.questionId === questionId && opt.value === "yes"
            );

            return {
              questionId,
              label: this.checkboxesItems[formGroupName].options[optionKey]?.label,
              score: this.checkboxesItems[formGroupName].options[optionKey]?.score,
              value: isChecked ? "yes" : "no"
            };
          })
        };
      }
    });
    if (this.myForm.get('gender')) {
      this.transformedValues['gender'] = {
        value: this.myForm.get('gender')?.value
      };
    }
    if (this.control) {
      this.control.setValue(this.transformedValues, { emitEvent: false });
    }
  }

  private setSexualAbuseOptions(gender: string) {

    if (gender === 'male') {
      this.checkboxesItems['sexualAbuse'].options = this.sexualAbuseOptionsMale;
    } else if (gender === 'female') {
      this.checkboxesItems['sexualAbuse'].options = this.sexualAbuseOptionsFemale;
    }

    if (this.patch_values?.sexualAbuse?.options) {

      const formGroup = this.myForm.get('sexualAbuse') as FormGroup;
      if (formGroup) {
        Object.keys(this.checkboxesItems['sexualAbuse'].options).forEach(optionKey => {
          const questionId = this.checkboxesItems['sexualAbuse'].options[optionKey]?.questionId;
          const patchedValue = this.patch_values.sexualAbuse.options.some(
            (opt: any) => opt.questionId === questionId && opt.value === "yes"
          );

          formGroup.get(optionKey)?.setValue(patchedValue, { emitEvent: false });
        });
      }
    }

    this.updateTransformedValues();
    this.cdr.detectChanges();
  }
  private setFamilyHistoryOptions(gender: string) {

    if (gender === 'male') {
      this.checkboxesItems['familyHistory'].options = this.familyHistoryOptionsMale;
    } else if (gender === 'female') {
      this.checkboxesItems['familyHistory'].options = this.familyHistoryOptionsFemale;
    }

    if (this.patch_values?.familyHistory?.options) {

      const formGroup = this.myForm.get('familyHistory') as FormGroup;
      if (formGroup) {
        Object.keys(this.checkboxesItems['familyHistory'].options).forEach(optionKey => {
          const questionId = this.checkboxesItems['familyHistory'].options[optionKey]?.questionId;
          const patchedValue = this.patch_values.familyHistory.options.some(
            (opt: any) => opt.questionId === questionId && opt.value === "yes"
          );

          formGroup.get(optionKey)?.setValue(patchedValue, { emitEvent: false });
        });
      }
    }

    this.updateTransformedValues();
    this.cdr.detectChanges();
  }

  private applyGenderSelection() {
    const genderControl = this.myForm.get('gender') as FormControl;

    if (genderControl) {
      const patchedGender = this.patch_values['gender']?.selectedOption?.value;

      if (patchedGender && !genderControl.value) {
        genderControl.setValue(patchedGender, { emitEvent: false });

        // Mark gender as touched and update validity
        genderControl.markAsTouched();
        genderControl.markAsDirty();
        genderControl.updateValueAndValidity({ emitEvent: false });
      }

      this.setSexualAbuseOptions(genderControl.value || patchedGender);
      this.setFamilyHistoryOptions(genderControl.value || patchedGender);

      genderControl.valueChanges.pipe(debounceTime(100), takeUntil(this.destroySubject)).subscribe(genderValue => {
        this.setSexualAbuseOptions(genderValue);
        this.setFamilyHistoryOptions(genderValue);
        // Ensure gender is valid on change
        genderControl.markAsTouched();
        genderControl.markAsDirty();
        genderControl.updateValueAndValidity({ emitEvent: false });
      });
    }
  }





  private updateControlValue() {
    if (this.control) {
      this.control.setValue(this.transformedValues, { emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
