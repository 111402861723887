<form class="m-t-m" [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <app-custom-input
      [formGroup]="myForm"
      controlName="contactName"
      placeholder="{{ inputItems['contactName']['placeholder'] }}"
      *ngIf="inputItems['contactName']?.is_include"
      [iconClass]="inputItems['contactName']?.icon"
      [maxlength]="20"
    ></app-custom-input>
    <app-custom-select
      [formGroup]="myForm"
      controlName="relationShipToPatient"
      *ngIf="radioItems['relationShipToPatient']?.is_include"
      label="{{ radioItems['relationShipToPatient']['title'] }}"
      [optionsTitle]="radioItems['relationShipToPatient']['optionsTitle']"
      [options]="radioItems['relationShipToPatient']['options']"
      [iconClass]="radioItems['relationShipToPatient']?.icon"
    >
    </app-custom-select>
    <app-phone
      [formGroup]="myForm"
      [title]="inputItems['mobilePhone']['placeholder']"
      formName="mobilePhone"
      *ngIf="inputItems['mobilePhone']?.is_include"
      [iconClass]="inputItems['mobilePhone']?.icon"
    ></app-phone>
    <app-phone
      [formGroup]="myForm"
      [title]="inputItems['homePhone']['placeholder']"
      formName="homePhone"
      *ngIf="inputItems['homePhone']?.is_include"
      [iconClass]="inputItems['homePhone']?.icon"
    ></app-phone>
  </ng-container>
</form>
