import { Component, ElementRef, Input, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

declare const google: any;

interface State {
  code: string;
  name: string;
}

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [
    FormsModule,
    MatInputModule,
    MatIconModule,
    TranslateModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    CommonModule
  ],
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit, AfterViewInit {
  @Input() formGroup!: FormGroup;
  @Input() addressPlaceholder!: string;
  @Input() unitPlaceholder!: string;
  @Input() cityPlaceholder!: string;
  @Input() statePlaceholder!: string;
  @Input() zipPlaceholder!: string;
  @Input() countryPlaceholder!: string;
  @Input() required!: boolean;

  @ViewChild('searchInput') searchInput!: ElementRef;
  usaStates: State[] = [
    { name: "Alabama", code: "AL" },
    { name: "Alaska", code: "AK" },
    { name: "Arizona", code: "AZ" },
    { name: "Arkansas", code: "AR" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "Delaware", code: "DE" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Hawaii", code: "HI" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Iowa", code: "IA" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Maine", code: "ME" },
    { name: "Maryland", code: "MD" },
    { name: "Massachusetts", code: "MA" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Mississippi", code: "MS" },
    { name: "Missouri", code: "MO" },
    { name: "Montana", code: "MT" },
    { name: "Nebraska", code: "NE" },
    { name: "Nevada", code: "NV" },
    { name: "New Hampshire", code: "NH" },
    { name: "New Jersey", code: "NJ" },
    { name: "New Mexico", code: "NM" },
    { name: "New York", code: "NY" },
    { name: "North Carolina", code: "NC" },
    { name: "North Dakota", code: "ND" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Pennsylvania", code: "PA" },
    { name: "Rhode Island", code: "RI" },
    { name: "South Carolina", code: "SC" },
    { name: "South Dakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Vermont", code: "VT" },
    { name: "Virginia", code: "VA" },
    { name: "Washington", code: "WA" },
    { name: "West Virginia", code: "WV" },
    { name: "Wisconsin", code: "WI" },
    { name: "Wyoming", code: "WY" }
  ];
  filteredStates!: Observable<State[]>;
  apartment = '';
  city = '';
  state = '';
  postalCode = '';
  country = '';

  ngOnInit(): void {
    this.filteredStates = this.formGroup.get('state')!.valueChanges.pipe(
      startWith(''),
      map(value => this._filterStates(value))
    );
  }

  ngAfterViewInit() {
    this.initializeGoogleAutocomplete();
  }

  private initializeGoogleAutocomplete(): void {
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchInput.nativeElement,
      {
        componentRestrictions: { country: 'US' },
        types: ['address'],
      }
    );
    autocomplete.addListener('place_changed', () => this.handlePlaceChange(autocomplete));
  }


  private handlePlaceChange(autocomplete: any): void {
    const place = autocomplete.getPlace();
    if (!place.address_components) return;

    this.apartment = '';
    this.city = '';
    this.state = '';
    this.postalCode = '';
    this.country = '';

    let fullAddress = '';

    place.address_components.forEach((component: any) => {
      const types = component.types;

      if (types.includes('premise')) {
        fullAddress += `${component.long_name} `;
        this.apartment = component.long_name;
      }

      if (types.includes('route')) {
        fullAddress += `${component.long_name} `;
        this.apartment = this.apartment || component.long_name;
      }

      if (types.includes('street_number')) {
        fullAddress = `${component.long_name} ${fullAddress}`;
      }

      if (types.includes('locality')) {
        this.city = component.long_name;
        fullAddress += `${component.long_name}, `;
      }

      if (types.includes('administrative_area_level_1')) {
        const stateObj = this.usaStates.find(s => s.name === component.long_name);
        this.state = stateObj ? stateObj.code : '';
        fullAddress += `${component.short_name} `;
      }

      if (types.includes('postal_code')) {
        this.postalCode = component.long_name;
        fullAddress += `${component.long_name} `;
      }

      if (types.includes('country')) {
        this.country = component.long_name;
        fullAddress += component.long_name;
      }
    });

    this.formGroup.patchValue({
      address: fullAddress.trim(),
      apartment: this.apartment,
      city: this.city,
      state: this.state,
      postalCode: this.postalCode,
      country: this.country,
    });

    this.searchInput.nativeElement.focus();
  }


  displayStateName(stateCode: string | null): string {
    if (!stateCode) return '';
    const state = this.usaStates.find(s => s.code === stateCode);
    return state ? state.name : '';
  }

  private _filterStates(value: string): State[] {
    const filterValue = value.toLowerCase();
    return this.usaStates.filter(state => state.name.toLowerCase().includes(filterValue) || state.code.toLowerCase().includes(filterValue));
  }

  get isUSACountry(): boolean {
    return this.formGroup.get('country')!.value === 'USA' || this.formGroup.get('country')!.value === 'United States';
  }

  get invalidAddress() {
    return !this.apartment || !this.city || !this.state || !this.postalCode || !this.country;
  }
  get isAddressValid(): boolean {
    const addressControl = this.formGroup.get('address');
    /*    const apartmentControl = this.formGroup.get('apartment');
       const cityControl = this.formGroup.get('city');
       const stateControl = this.formGroup.get('state');
       const postalCodeControl = this.formGroup.get('postalCode');
       const countryControl = this.formGroup.get('country'); */

    return (
      addressControl?.valid && addressControl?.value /* &&
      apartmentControl?.valid && apartmentControl?.value &&
      cityControl?.valid && cityControl?.value &&
      stateControl?.valid && stateControl?.value &&
      postalCodeControl?.valid && postalCodeControl?.value &&
      countryControl?.valid && countryControl?.value */
    );
  }

}
