import { HttpClient } from '@angular/common/http';
import {
  Component,
  ComponentRef, DestroyRef,
  inject,
  NgZone,
  OnChanges,
  OnInit,
  OnDestroy,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ChangeDetectorRef,
  ElementRef,
  HostListener,
} from '@angular/core';
import { CommonModule, JsonPipe, NgClass, NgComponentOutlet, NgForOf, NgIf, Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatStepperModule, MatStep, MatStepLabel, MatStepper, MatStepperNext, MatStepperPrevious } from "@angular/material/stepper";
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { IntroStepFormComponent } from "../form-steps/intro-step-form/intro-step-form.component";
import { IdentificationStepFormComponent } from "../form-steps/identification-step-form/identification-step-form.component";
import { ContactInformationStepFormComponent } from "../form-steps/contact-information-step-form/contact-information-step-form.component";
import { PrimaryCarePhysicianStepComponent } from '../form-steps/primary-care-physician-step/primary-care-physician-step.component';
import { DemographicStepFormComponent } from '../form-steps/demographic-step-form/demographic-step-form.component';
import { EmergencyContactStepFormComponent } from "../form-steps/emergency-contact-step-form/emergency-contact-step-form.component";
import { BillingStepFormComponent } from '../form-steps/billing-step-form/billing-step-form.component';
import { GuarantorStepFormComponent } from '../form-steps/guarantor-step-form/guarantor-step-form.component';
import { GuardianStepFormComponent } from '../form-steps/guardian-step-form/guardian-step-form.component';
import { NextOfKinStepFormComponent } from '../form-steps/next-of-kin-step-form/next-of-kin-step-form.component';
import { MedicalInfoStepFormComponent } from '../form-steps/medical-info-step-form/medical-info-step-form.component';
import { MedicalUsageStepFormComponent } from '../form-steps/medical-usage-step-form/medical-usage-step-form.component';
import { DisabilityIndexStepFormComponent } from '../form-steps/disability-index-step-form/disability-index-step-form.component';
import { PreferredPharmacyStepFormComponent } from "../form-steps/preferred-pharmacy-step-form/preferred-pharmacy-step-form.component";
import { EmployerInformationStepFormComponent } from "../form-steps/employer-information-step-form/employer-information-step-form.component";
import { PatientInsuranceStepFormComponent } from "../form-steps/patient-insurance-step-form/patient-insurance-step-form.component";
import { PrivacyPreferencesStepFormComponent } from "../form-steps/privacy-preferences-step-form/privacy-preferences-step-form.component";
import { PrivacyAndAgreementsStepFormComponent } from "../form-steps/privacy-and-agreements-step-form/privacy-and-agreements-step-form.component";
import { MedicalEvaluationsStepFormComponent } from "../form-steps/medical-evaluations-step-form/medical-evaluations-step-form.component";
import { TreatmentHistoryStepFormComponent } from "../form-steps/treatment-history-step-form/treatment-history-step-form.component";
import { SurgicalHistoryStepFormComponent } from "../form-steps/surgical-history-step-form/surgical-history-step-form.component";
import { HelloComponent } from '../form-steps/hello/hello.component';
import { CcmStepFormComponent } from '../form-steps/ccm-step-form/ccm-step-form.component';
import { Phq9Component } from '../form-steps/phq9/phq9.component';
import { Gad7Component } from '../form-steps/gad7/gad7.component';
import { OrtComponent } from '../form-steps/ort/ort.component';
import { NdiComponent } from '../form-steps/NDI/ndi.component';
import { OdiComponent } from '../form-steps/ODI/odi.component';
import { ApiService } from "../services/api.service";
import { ActivatedRoute, Router, NavigationStart } from "@angular/router";
import { AppService } from "../services/form.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { StepItemInterface } from "../interfaces/interfaces";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { HeaderComponent } from '../reusable/header/header.component';
import { WelcomeComponent } from '../reusable/welcome-steps/welcome/welcome.component';
import { UserInfoComponent } from '../reusable/welcome-steps/user-info/user-info.component';
import { UserInfoVerificationComponent } from '../reusable/welcome-steps/user-info-verification/user-info-verification.component';
import { environment } from "../../../environments/environment";
import { interval, retry, Subject, takeUntil, take } from "rxjs";
import { BlueFormService } from "./blue-client.service";
import { DoneComponent } from '../reusable/done/done.component';
import { ErrorComponent } from '../reusable/error/error.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedFormService } from '../services/SharedFormService.service';
import { LottiePlayerComponent } from '../reusable/lottie-player/lottie-player.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomConfirmationSnackBarComponent } from '../reusable/custom-confirmation-snackbar/custom-confirmation-snackbar.component';
interface PatchValues {
  [key: string]: {
    // Add other possible properties here
  };
}

interface ServerResponse {
  message: {
    form_data: any;
  };
}

@Component({
  selector: 'app-blue-client',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatStepperModule,
    MatStep,
    MatStepLabel,
    MatStepperNext,
    MatStepperPrevious,
    IntroStepFormComponent,
    IdentificationStepFormComponent,
    ContactInformationStepFormComponent,
    PrimaryCarePhysicianStepComponent,
    DemographicStepFormComponent,
    EmergencyContactStepFormComponent,
    PatientInsuranceStepFormComponent,
    EmployerInformationStepFormComponent,
    BillingStepFormComponent,
    GuardianStepFormComponent,
    GuarantorStepFormComponent,
    NextOfKinStepFormComponent,
    MedicalInfoStepFormComponent,
    MedicalUsageStepFormComponent,
    PreferredPharmacyStepFormComponent,
    TreatmentHistoryStepFormComponent,
    SurgicalHistoryStepFormComponent,
    DisabilityIndexStepFormComponent,
    PrivacyPreferencesStepFormComponent,
    PrivacyAndAgreementsStepFormComponent,
    MedicalEvaluationsStepFormComponent,
    JsonPipe,
    NgClass,
    NgIf,
    NgForOf,
    NgComponentOutlet,
    TranslateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    HeaderComponent,
    WelcomeComponent,
    UserInfoComponent,
    UserInfoVerificationComponent,
    DoneComponent,
    ErrorComponent,
    MatProgressSpinnerModule,
    LottiePlayerComponent,
    HelloComponent,
    CcmStepFormComponent,
    Phq9Component,
    Gad7Component,
    OrtComponent,
    NdiComponent,
    OdiComponent,
    CustomConfirmationSnackBarComponent
  ],
  templateUrl: './blue-client.component.html',
  styleUrls: ['./blue-client.component.css']
})
export class BlueClientComponent implements OnInit, OnChanges, OnDestroy {

  currentStep: string = 'stepper';
  step_component_lookup: { [key: string]: any } = {
    'intro': IntroStepFormComponent,
    'identification': IdentificationStepFormComponent,
    'contact': ContactInformationStepFormComponent,
    'primaryCarePhysician': PrimaryCarePhysicianStepComponent,
    'demographic': DemographicStepFormComponent,
    'emergencyContact': EmergencyContactStepFormComponent,
    'employment': EmployerInformationStepFormComponent,
    'patientInsurance': PatientInsuranceStepFormComponent,
    'billing': BillingStepFormComponent,
    'guardian': GuardianStepFormComponent,
    'guarantor': GuarantorStepFormComponent,
    'nextOfKin': NextOfKinStepFormComponent,
    'medicalInfo': MedicalInfoStepFormComponent,
    'medicalUsage': MedicalUsageStepFormComponent,
    'preferredPharmacy': PreferredPharmacyStepFormComponent,
    'treatmentHistory': TreatmentHistoryStepFormComponent,
    'surgicalHistory': SurgicalHistoryStepFormComponent,
    'disabilityIndex': DisabilityIndexStepFormComponent,
    'privacyPreference': PrivacyPreferencesStepFormComponent,
    'privacyAgreement': PrivacyAndAgreementsStepFormComponent,
    'medicalEvaluations': MedicalEvaluationsStepFormComponent,
    'hello': HelloComponent,
    'ccm': CcmStepFormComponent,
    'phq9': Phq9Component,
    'gad7': Gad7Component,
    'ort': OrtComponent,
    'ndi': NdiComponent,
    'odi': OdiComponent,
  }

  dynamicComponentRef: { [key: string]: ComponentRef<any> } = {};

  private destroyRef = inject(DestroyRef);
  destroyed_interval = new Subject();
  medicaidStatus: string | null = null;
  ccmStepIndex: number | null = null;
  is_loading = true;
  form_loaded = false;
  showForm = false;
  myForm!: FormGroup;
  stepItems: StepItemInterface[] = [];
  isLinear = true;
  formValues: any = {};
  is_next_disabled = false;
  patch_values: PatchValues = {};
  formStatus: any = {};
  isReactNativeWebView: boolean = false;
  formCount: number = 1;
  isSubmitting: boolean = false;
  pollingStarted = false;
  is_verified = false;
  isNavigating = false;
  isSafari: boolean = false;
  isCcmStepPresent = true;
  @ViewChild('stepper') stepper!: MatStepper;
  vcr = inject(ViewContainerRef);
  @ViewChildren("containers", { read: ViewContainerRef }) containers!: QueryList<ViewContainerRef>;
  @ViewChildren('stepTitle', { read: ElementRef }) stepTitles!: QueryList<ElementRef>;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    private appService: AppService,
    private translateService: TranslateService,
    private http: HttpClient,
    private ngZone: NgZone,
    private blueFormService: BlueFormService,
    private cdr: ChangeDetectorRef,
    private sharedFormService: SharedFormService,
    private location: Location,
    private _snackBar: MatSnackBar,

  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  hospital_id = 0;
  patient_id = 0;
  form_id = 0;
  hospital_slug = '';
  step_lookup: { [key: string]: string } = {};
  control = new FormControl('');


  get animationPath(): string {
    if (localStorage.getItem('hospital_slug') === 'akps') {
      return '/assets/akps/AKPS_Submit.json';
    } else if (localStorage.getItem('hospital_slug') === 'cps') {
      return '/assets/cwps/CPS_Submit.json';
    } else {
      return '/assets/akps/AKPS_Submit.json';
    }
  }

  ngOnInit() {
    this.isReactNativeWebView = localStorage.getItem('isReactNativeWebView') === 'true';

    const storedFormCount = localStorage.getItem('formCount');
    if (storedFormCount) {
      this.formCount = Number(storedFormCount);
    }
    this.detectSafari();
    const isVerifyStorage = localStorage.getItem('is_verified');
    this.is_verified = isVerifyStorage === 'true';
    window.onbeforeunload = (event: any) => {
      if (!this.is_verified) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    this.destroyRef.onDestroy(() => {
      this.destroyed_interval.complete();
    });

    this.hospital_slug = localStorage.getItem('hospital_slug') || '';
    this.hospital_id = Number(localStorage.getItem('hospital_id')) || 0;
    this.patient_id = Number(localStorage.getItem('patient_id')) || 0;
    this.form_id = Number(localStorage.getItem('form_id')) || 0;
    this.appService.setFavIcon(this.hospital_slug);
    this.myForm = this.fb.group({

    });


    const currentLang = this.translateService.currentLang;
    let lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).subscribe(translations => {
      if (translations && translations[this.hospital_slug] && translations[this.hospital_slug]["steps_list"]) {
        this.stepItems = translations[this.hospital_slug]["steps_list"];
        this.ccmStepIndex = this.stepItems.findIndex(step => step.key === 'ccm');

        for (let item of this.stepItems) {
          const formGroupName = item.key;
          this.step_lookup[item.key] = item.title;
          if (!this.myForm.get(formGroupName)) {
            this.myForm.addControl(formGroupName, new FormControl({}));
          }
          if (item.required) {
            this.myForm.get(formGroupName)?.setValidators(Validators.required);
          }
        }
        //this.form_loaded = false;
        // this.initializeStepComponents();
        this.fetchAndPopulateFormData();
      } else {
        console.error("Error: Translations or steps_list not found.");
      }
    });
    if (this.currentStep === 'stepper' && !this.pollingStarted) {
      this.pollingTokenRefreshService();
      this.pollingStarted = true;
    }
    history.pushState(null, '', window.location.href);

  }
  getFilteredStepItems(): any[] {
    return this.stepItems.filter((step) => {
      /*       if (step.key === 'privacyAgreement' && this.isPrivacyAgreementSigned()) {
              return false;
            } */
      return true;
    });
  }



  private checkAndTrackMedicaidStatus() {
    if (this.ccmStepIndex === -1) return; // Only proceed if CCM step exists

    // Perform the initial check on form load
    this.sharedFormService.formValues$.pipe(take(1)).subscribe(values => {
      const medicaidInsuranceValue = values?.patientInsurance?.isYourInsuranceAssociatedWithMedicaid;
      this.medicaidStatus = medicaidInsuranceValue || null;
      this.sharedFormService.updateMedicaidStatus(this.medicaidStatus);
      this.updateCcmStep();
    });

    // Continuously track medicaidStatus and update CCM step accordingly
    this.sharedFormService.medicaidStatus$.subscribe(status => {
      this.medicaidStatus = status;
      this.updateCcmStep();
    });
  }

  private updateCcmStep() {
    // Conditionally add or remove CCM step based on the current medicaidStatus
    if (this.medicaidStatus === 'medicare') {
      this.restoreCcmStep();
    } else {
      this.removeCcmStep();
    }
  }

  private removeCcmStep(): void {
    this.stepItems = this.stepItems.filter(step => step.key !== 'ccm');
    this.cdr.detectChanges();
  }

  private restoreCcmStep(): void {
    if (!this.stepItems.some((step) => step.key === 'ccm') && this.ccmStepIndex !== null) {
      const ccmStep = {
        key: 'ccm',
        title: 'CCM Program',
        required: true,
        description: 'Description for CCM Step',
      };
      this.stepItems.splice(this.ccmStepIndex, 0, ccmStep); // Add at the correct index
      this.cdr.detectChanges(); // Ensure UI is updated

      // Ensure form control exists for CCM step
      if (!this.myForm.contains('ccm')) {
        this.myForm.addControl('ccm', this.fb.group({}));
      }

      // Initialize the CCM component if necessary
      setTimeout(() => {
        if (this.myForm.contains('ccm')) {
          this.initializeCCMStepComponent();
        }
      }, 100);
    }
  }


  initializeCCMStepComponent() {
    if (this.ccmStepIndex !== null && this.ccmStepIndex < this.containers.length) {
      const ccmContainer = this.containers.toArray()[this.ccmStepIndex];

      if (ccmContainer) {
        // Clear existing component if any and initialize new one
        ccmContainer.clear();
        if (this.dynamicComponentRef['ccm']) {
          this.dynamicComponentRef['ccm'].destroy();
          delete this.dynamicComponentRef['ccm'];
        }

        if (!this.myForm.contains('ccm')) {
          this.myForm.addControl('ccm', new FormGroup({}));
        }

        const component = this.step_component_lookup['ccm'];
        const compRef: ComponentRef<any> = ccmContainer.createComponent(component);

        compRef.instance.control = this.myForm.controls['ccm'];
        compRef.instance.formGroup = this.myForm.controls['ccm'];
        compRef.instance.step = 'ccm';
        compRef.instance.hospital_slug = this.hospital_slug;
        compRef.instance.form_id = this.form_id;
        compRef.instance.patch_values = this.patch_values['ccm'] || {};

        // Track the created component reference to prevent duplication
        this.dynamicComponentRef['ccm'] = compRef;
        compRef.changeDetectorRef.detectChanges();
      } else {
        console.error("CCM container is not found at the specified index.");
      }
    } else {
      console.error("Invalid or null `ccmStepIndex`.");
    }
  }


  detectSafari() {
    const userAgent = navigator.userAgent.toLowerCase();
    this.isSafari = /safari/.test(userAgent) && !/chrome/.test(userAgent);
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event: any): void {
    if (!this.is_verified && !this.isNavigating) {
      const confirmed = confirm('Are you sure you want to leave this page? Your progress may not be saved.');
      if (!confirmed) {
        history.pushState(null, '', window.location.href);
      } else {
        this.isNavigating = true;
      }

      /*       if (this.isSafari) {
              this.handleSafariBackNavigation();
            } else {
              const confirmed = confirm('Are you sure you want to leave this page? Your progress may not be saved.');
              if (!confirmed) {
                history.pushState(null, '', window.location.href);
              } else {
                this.isNavigating = true;
              }
            } */
    }
  }
  /*   handleSafariBackNavigation() {
      const snackBarRef = this._snackBar.openFromComponent(CustomConfirmationSnackBarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
  
      snackBarRef.afterDismissed().subscribe(result => {
        if (result.dismissedByAction) {
          history.pushState(null, '', window.location.href);
        } else {
          this.isNavigating = true;
          history.back();
        }
      });
    } */


  fetchAndPopulateFormData() {
    const formCiphertext = localStorage.getItem('formCiphertext');
    const endpoint = `${environment.API_URL}/form/hmsa/${formCiphertext}`;
    this.is_loading = true;

    this.http.get<any>(endpoint).subscribe(
      (response: ServerResponse | null) => {
        if (response && response.message && response.message.form_data) {
          this.patch_values = response.message.form_data || {};
          this.sharedFormService.updateFormValues(this.patch_values);

          this.initializeStepComponents();
          this.checkAndTrackMedicaidStatus();

          this.formStatus = response.message;
          if (this.formStatus.status === 'complete') {
            this.router.navigate(['/done']);
            this.isSubmitting = false;
          }
        } else {
          console.warn('No form data found or response is null');
          this.patch_values = {};  // Initialize with an empty object to avoid errors
        }

        this.cdr.detectChanges();
        this.is_loading = false;
        this.form_loaded = true;
        setTimeout(() => {
          this.showForm = true;
          this.cdr.detectChanges();
        }, 2000);
      },
      (error) => {
        this.currentStep = 'error';
        this.is_loading = false;
        this.form_loaded = true;

        if (error.status === 404) {
          console.log('No existing data found.');
        } else {
          console.error('Error fetching data:', error);
        }
      }
    );
  }

  isPrivacyAgreementSigned(): boolean {
    const agreements = this.patch_values?.['privacyAgreement'] || {};
    if (Object.keys(agreements).length === 0) {
      return false;
    }
    return Object.values(agreements).every(
      (agreement: any) => agreement?.is_signed === true
    );
  }
  updatePrivacyAgreementInForm(): void {
    if (this.isPrivacyAgreementSigned() && this.patch_values?.['privacyAgreement']) {
      this.myForm.patchValue({
        privacyAgreement: this.patch_values['privacyAgreement']
      }, { emitEvent: false });
    }
  }




  isSingleStep(): boolean {
    return this.stepItems && this.stepItems.length === 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  initializeStepComponents() {
    if (!this.stepItems || this.stepItems.length === 0) {
      console.error('Error: stepItems is not properly initialized.');
      return;
    }

    let patch_values: PatchValues = this.patch_values;

    const intervalId = setInterval(() => {
      // Check if all form controls are initialized
      if (this.allFormControlsInitialized()) {
        let count = 0;
        this.containers.forEach(div => {
          let step = this.stepItems[count].key; //step_list[count]
          let component = this.step_component_lookup[step];
          let compRef: ComponentRef<any> = this.vcr.createComponent(component);
          compRef.instance.control = this.myForm.controls[step];
          compRef.instance.formGroup = this.myForm.controls[step];
          compRef.instance.step = step;
          compRef.instance.hospital_slug = this.hospital_slug;
          compRef.instance.form_id = this.form_id;
          if (patch_values && patch_values.hasOwnProperty(step)) {
            compRef.instance.patch_values = patch_values[step];
          } else {
            compRef.instance.patch_values = {};
          }

          this.containers.toArray()[count].insert(compRef.hostView, 0);
          compRef.changeDetectorRef.detectChanges();
          this.dynamicComponentRef[step] = compRef;
          count++;
        });
        this.myForm.statusChanges.subscribe(val => {
          this.formValues = val;
        });
        //this.is_loading = false;
        clearInterval(intervalId);
        this.pollingTokenRefreshService(); //start polling service
      }
    }, 100);
  }

  onStepChange(event: StepperSelectionEvent) {
    const selectedStep = this.getFilteredStepItems()[event.selectedIndex];

    if (selectedStep.key === 'privacyAgreement') { // Replace with your unique key for the step
      // Access the `PrivacyAndAgreementsStepFormComponent` instance
      const componentInstance = this.dynamicComponentRef[selectedStep.key]?.instance;

      if (componentInstance) {
        // Execute the logic
        const isIncludeList = Object.keys(componentInstance.checkboxItems)
          .filter(key => componentInstance.checkboxItems[key]?.is_include);

        if (isIncludeList.length === 1) {
          const key = isIncludeList[0];
          const event = new Event('click');
          componentInstance.openPrivacyCheck(event, key);
        }
      }
    }
    this.scrollToTopOfSelectedStep();
    this.cdr.detectChanges();
  }



  allFormControlsInitialized(): boolean {
    for (let key in this.myForm.controls) {
      if (!this.myForm.controls[key].value) {
        return false;
      }
    }
    return true;
  }

  isStepValid(step: string, idx: number): boolean {
    let is_valid = false;
    if (this.dynamicComponentRef.hasOwnProperty(step) && this.dynamicComponentRef[step]?.instance?.formGroup) {
      if (idx === 0) {
        is_valid = this.dynamicComponentRef[step].instance.formGroup.valid;
      } else {
        for (let i = 0; i < idx; i++) {
          let prev_step = this.stepItems[i].key;
          if (this.dynamicComponentRef.hasOwnProperty(prev_step) && this.dynamicComponentRef[prev_step]?.instance?.formGroup) {
            if (!this.dynamicComponentRef[prev_step].instance.formGroup.valid) {
              return false;
            }
          }
        }
        is_valid = this.dynamicComponentRef[step].instance.formGroup.valid;
      }
    } else {
      console.warn(`Form group for step "${step}" is undefined or not initialized.`);
    }
    return is_valid;
  }


  scrollToTopOfSelectedStep() {
    if (this.stepper && typeof this.stepper.selectedIndex !== 'undefined') {
      const selectedStepIndex = this.stepper.selectedIndex;
      const selectedStepTitle = this.stepTitles.toArray()[selectedStepIndex];
      if (selectedStepTitle) {
        setTimeout(() => {
          selectedStepTitle.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 300);
      }
    }
  }

  onNext(step: string) {
    if (this.dynamicComponentRef[step]?.instance.is_valid) {
      this.sharedFormService.updateFormValues(this.myForm.value);
      this.is_next_disabled = false;
      this.stepper.next();
    } else {
      this.is_next_disabled = true;
    }
  }

  navigateToFirstInvalidStep() {
    let stepIndex = 0;
    for (let stepControl of this.stepper._steps.toArray()) {
      if (stepControl.stepControl && !stepControl.stepControl.valid) {
        this.stepper.selectedIndex = stepIndex;
        break;
      }
      stepIndex++;
    }
  }

  navigateToStep(stepIndex: number) {
    this.stepper.selectedIndex = stepIndex;
  }

  findKeyInNestedObj(obj: { [x: string]: any; }, keyToFind: string, keyToReplace: string): any {
    let result = null;
    for (let key in obj) {
      if (key === keyToFind) {
        return obj[key];
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        result = this.findKeyInNestedObj(obj[key], keyToFind, keyToReplace);
        if (result) {
          obj[key] = keyToReplace;
          break;
        }
      }
    }
    return obj;
  }

  stopPolling() {
    this.destroyed_interval.next('');
    this.destroyed_interval.complete();
    this.pollingStarted = false;
  }
  ngOnDestroy() {
    window.onbeforeunload = null;
    this.stopPolling();
  }
  pollingTokenRefreshService() {
    if (!this.isSubmitting) {
      this.ngZone.runOutsideAngular(() => {
        interval(10000)
          .pipe(
            retry(3),
            takeUntil(this.destroyed_interval)
          )
          .subscribe(
            () => {
              this.intervalPutData();
            },
            (error) => {
              console.log('Error during polling:', error);
            }
          );
      });
    }
  }
  //end pollingCallService

  intervalPutData() {
    this.updatePrivacyAgreementInForm();
    const formCiphertext = localStorage.getItem('formCiphertext');
    const endpoint: string = `form/hmsa/${formCiphertext}`;
    let data = {
      "status": "in_progress",
      "form_data": this.myForm.value
    }
    this.blueFormService.put(endpoint, data, false).subscribe((response: any) => {
    }
      , error => {
        console.log('error', error);
      }) //end post
  } //end intervalPutData
  onSubmit() {
    this.stopPolling();
    if (this.myForm.valid) {
      this.isSubmitting = true;
      const formInfo = this.myForm.value;

      const formCiphertext = localStorage.getItem('formCiphertext');
      const endpoint = `form/hmsa/${formCiphertext}`;

      const data = {
        status: 'complete',
        hospital_slug: this.hospital_slug,
        form_data: formInfo,
      };

      this.blueFormService.put(endpoint, data, false).subscribe(
        (response: any) => {
          const isReactNativeWebView = localStorage.getItem('isReactNativeWebView') === 'true';
          const formsData = JSON.parse(localStorage.getItem('formsData') || '[]');
          const currentIndex = formsData.findIndex((form: any) => form.formCiphertext === formCiphertext);

          if (isReactNativeWebView && window.ReactNativeWebView) {
            setTimeout(() => {
              const message = {
                flag: 'form-complete',
                formData: this.myForm.value, // Add form data here
              };
              window.ReactNativeWebView.postMessage(JSON.stringify(message));
              this.isSubmitting = false;
            }, 3000); // 3 seconds delay
          } else if (currentIndex !== -1) {
            formsData[currentIndex].status = 'complete';
            localStorage.setItem('formsData', JSON.stringify(formsData));

            if (currentIndex + 1 < formsData.length) {
              this.isSubmitting = false;
              const nextForm = formsData[currentIndex + 1];
              this.reloadComponent(nextForm.form_id);
            } else {
              this.isSubmitting = false;
              this.router.navigate(['/done']);
            }
          } else {
            this.isSubmitting = false;
            this.router.navigate(['/done']);
          }
        },
        (error: any) => {
          this.currentStep = 'error';
          console.log('error', error);
          this.isSubmitting = false;
          this.pollingStarted = false;
        });
    } else {
      alert('Please complete the form before submitting.');
    }
  }


  /**
   * Reload the component with new form data.
   */
  private reloadComponent(formId: string): void {
    // Update local storage for the next form
    const formsData = JSON.parse(localStorage.getItem('formsData') || '[]');
    const nextForm = formsData.find((form: any) => form.form_id === formId);

    if (nextForm) {
      localStorage.setItem('form_id', nextForm.form_id);
      localStorage.setItem('formCiphertext', nextForm.formCiphertext);
      localStorage.setItem('hospital_slug', nextForm.hospital_slug);
      localStorage.setItem('hospital_id', nextForm.hospital_id.toString());
      localStorage.setItem('patient_id', nextForm.patient_id.toString());
    }

    // Force component reload
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['form', formId]);
    });
  }




  createForm(endpoint: string, data: any) {
    this.http.post(endpoint, data).subscribe(
      response => {
      },
      error => {
        console.error('Form creation error:', error);
        alert('Form submission failed. Please try again.');
      }
    );
  }

  createData() {
    // post will fail if the form is already created so we are safe here
    // all other inserts are puts, even on final submit
    this.hospital_slug = localStorage.getItem('hospital_slug') || '';
    this.hospital_id = Number(localStorage.getItem('hospital_id')) || 0;
    this.patient_id = Number(localStorage.getItem('patient_id')) || 0;
    this.form_id = Number(localStorage.getItem('form_id')) || 0;

    const formCiphertext = localStorage.getItem('formCiphertext');
    const endpoint: string = `form/hmsa/${formCiphertext}`;
    let data = {
      "status": "in_progress",
    }
    this.blueFormService.post(endpoint, data, false).subscribe((response: any) => {
    }
      , error => {
        console.log('error', error);
      }) //end post
  }  //end createData

  updateForm(endpoint: string, data: any) {
    this.http.put(endpoint, data).subscribe(
      response => {
        this.fetchUpdatedData(endpoint);  // Fetch and log the updated data from the server
      },
      error => {
        console.error('Form update error:', error);
        alert('Form update failed. Please try again.');
      }
    );
  }

  fetchUpdatedData(endpoint: string) {
    this.http.get(endpoint).subscribe(
      response => {
      },
      error => {
        console.error('Error fetching updated data:', error);
      }
    );
  }

  populateForm(data: any) {
    Object.keys(data).forEach(key => {
      if (this.myForm.controls[key]) {
        if (data[key] !== null && typeof data[key] === 'object' && !Array.isArray(data[key])) {
          this.myForm.controls[key].patchValue(data[key], { emitEvent: false });
        } else {
          this.myForm.controls[key].setValue(data[key], { emitEvent: false });
        }
      }
    });
  }
  isDynamicComponentRefValid(stepKey: string): boolean {
    return this.dynamicComponentRef[stepKey] && this.dynamicComponentRef[stepKey].instance && this.dynamicComponentRef[stepKey].instance.formGroup;
  }
} // End of class
