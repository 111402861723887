import { Component, Input, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Subject, combineLatest, takeUntil } from "rxjs";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { PrivacyModalComponent } from './privacy-modal/privacy-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CheckboxItemInterface } from "../../interfaces/interfaces";
import { formObjectByType } from "../../functions/functions";
import { SharedFormService } from '../../services/SharedFormService.service';
@Component({
  selector: 'app-privacy-and-agreements-step-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    TranslateModule,
    CustomCheckboxComponent
  ],
  templateUrl: './privacy-and-agreements-step-form.component.html',
  styleUrls: ['./privacy-and-agreements-step-form.component.css']
})
export class PrivacyAndAgreementsStepFormComponent implements OnDestroy, AfterViewInit {
  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';

  formValues: any = {};
  form_loaded = false;
  myForm!: FormGroup;
  checkboxItems: CheckboxItemInterface = {};
  accepted: any = [];
  declined: any = [];
  today!: Date;
  signedBy!: string;
  showCCM: boolean = false;
  private destroySubject = new Subject<void>();
  private backupCwpsCcmConsent: any = null;

  constructor(
    private _bottomSheet: MatBottomSheet,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private sharedFormService: SharedFormService,
    private cdr: ChangeDetectorRef
  ) {
    this.myForm = new FormGroup({});
  }

  ngAfterViewInit() {
    const currentLang = this.translateService.currentLang;
    const lang_form = `${currentLang}-${this.form_id}`;

    // Combine showCCM$ and getTranslation observables
    combineLatest([
      this.sharedFormService.showCCM$,
      this.translateService.getTranslation(lang_form)
    ])
      .pipe(takeUntil(this.destroySubject))
      .subscribe(([showCCMValue, translations]) => {
        this.showCCM = showCCMValue;

        const translationsObj = translations['healent'][this.step];
        const translationsObjSlug = translations[this.hospital_slug][this.step];
        let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step);
        this.myForm = formObject['myForm'];
        this.checkboxItems = formObject['checkboxItems'];
        if (!this.showCCM) {
          if (this.checkboxItems['cwpsCcmConsent']) {
            this.backupCwpsCcmConsent = this.checkboxItems['cwpsCcmConsent'];
          }
          delete this.checkboxItems['cwpsCcmConsent'];

          if (this.myForm.contains('cwpsCcmConsent')) {
            this.myForm.removeControl('cwpsCcmConsent');
          }
        } else {
          if (this.backupCwpsCcmConsent) {
            this.checkboxItems['cwpsCcmConsent'] = this.backupCwpsCcmConsent;
          }
          if (!this.myForm.contains('cwpsCcmConsent')) {
            this.myForm.addControl('cwpsCcmConsent', new FormControl(false));
          }
        }


        Object.keys(this.checkboxItems).forEach(key => {
          this.checkboxItems[key].showDecline = false;
        });

        // Sort the checkbox items
        this.checkboxItems = Object.entries(this.checkboxItems)
          .sort((a, b) => {
            const titleA = a[1]?.title || '';
            const titleB = b[1]?.title || '';
            return titleA.localeCompare(titleB);
          })
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {} as CheckboxItemInterface);

        // Patch values from backend if available
        if (this.patch_values) {
          const patchData = this.patch_values;

          // Ensure form controls are created before patching values
          Object.keys(patchData).forEach(key => {
            if (!this.myForm.contains(key)) {
              this.myForm.addControl(key, new FormControl(false));
            }
          });

          this.myForm.patchValue(patchData, { emitEvent: false });
        }

        // Set the form values and detect changes
        this.control?.setValue(this.myForm.getRawValue(), { emitEvent: true });
        this.formGroup = this.myForm;

        this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(val => {
          this.formValues = val;
          this.control?.setValue(this.myForm.getRawValue(), { emitEvent: true });
          this.formGroup = this.myForm;
        });

        this.form_loaded = true;

        // Trigger change detection
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  openPrivacyCheck(event: Event, key: string) {
    this.myForm.patchValue({
      [key]: false
    });
    const list = Object.keys(this.checkboxItems)
      .filter(k => this.checkboxItems[k].is_include && !this.myForm.get(k)?.value)
      .map(k => {
        const { is_include, ...rest } = this.checkboxItems[k];
        return { key: k, ...rest };
      });
    const dialogRef = this.dialog.open(PrivacyModalComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100vh',
      data: { privacyList: list, initialKey: key },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;

      this.accepted = result.accepted;
      this.declined = result.declined;

      // Update form values based on accepted and declined
      result.accepted.forEach((key: string) => {
        this.myForm.patchValue({ [key]: true });
        this.checkboxItems[key].showDecline = false;
        const control = this.myForm.get(key);
        control?.setValidators(null);
        control?.updateValueAndValidity();
      });

      result.declined.forEach((key: string) => {
        this.myForm.patchValue({ [key]: false });
        this.checkboxItems[key].showDecline = true;
        const control = this.myForm.get(key);
        control?.setValidators(null);
        control?.updateValueAndValidity();
      });
    });
  }
}
