import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class VerificationService {
    private apiURL: string;
    private apiURL1: string;
    private phoneNumber: string = '';
    private patient_dob: string = '';

    constructor(private http: HttpClient) {
        this.apiURL = `${environment.NAVIGATOR_API_URL}/api/v2/mobile/login-request-code`;
        this.apiURL1 = `${environment.NAVIGATOR_API_URL}/api/v2/form/otpverification`;
    }

    setPhoneNumber(phone: string) {
        this.phoneNumber = phone;
    }

    getPhoneNumber(): string {
        return this.phoneNumber;
    }

    setDOB(dob: string) {
        this.patient_dob = dob;
    }

    getDOB(): string {
        return this.patient_dob;
    }

    sendVerificationCode(payload: { userName: string, dateOfBirth: string }): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        return this.http.post(this.apiURL, payload, { headers: headers })
            .pipe(
                catchError(this.handleError)
            );
    }

    ConfirmVerificationCode(payload: { userName: string, dateOfBirth: string, loginCode: string }): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        return this.http.post(this.apiURL1, payload, { headers: headers })
            .pipe(
                catchError(this.handleErrors)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Client-side error:', error.error.message);
        } else {
            console.error(`Server-side error: ${error.status} - ${error.message}`);
        }
        return throwError('Failed to send verification code. Please try again later.');
    }

    private handleErrors(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('Client-side error:', error.error.message);
        } else {
            console.error(`Server-side error: ${error.status} - ${error.message}`);
        }
        return throwError('Failed to verify the Otp code. Please try again later.');
    }
}
