import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule
} from "@angular/forms";

import { Subject, takeUntil } from "rxjs";
import { NgIf } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { formObjectByType } from "../../functions/functions";
import { ActivatedRoute } from "@angular/router";
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';
import { SelectAsArrayComponent } from '../../reusable/select-as-array/select-as-array.component';

@Component({
  selector: 'app-gad7',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    CustomInputComponent,
    SelectAsArrayComponent
  ],
  templateUrl: './gad7.component.html',
  styleUrl: './gad7.component.css'
})
export class Gad7Component implements OnInit, OnDestroy {

  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';

  formValues: any = {};
  form_loaded = false;
  myForm!: FormGroup;
  checkboxItems: any = {}
  radioItems: any = {}
  inputItems: any = {}

  destroySubject = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }
  ngOnInit(): void {


    this.myForm = new FormGroup({});
    const currentLang = this.translateService.currentLang;
    const lang_form = `${currentLang}-${this.form_id}`;
    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {

      const translationsObj = translations['healent'][this.step];
      const translationsObjSlug = translations[this.hospital_slug][this.step];
      let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step)
      this.myForm = formObject['myForm'];
      this.radioItems = formObject['radioItems'];
      this.inputItems = formObject['inputItems'];

      if (this.patch_values) {
        this.myForm.patchValue(this.patch_values, { emitEvent: false });
      }
      this.control?.setValue(this.myForm.value, { emitEvent: true }); // set initial value
      this.formGroup = this.myForm;
      this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(val => {
        this.formValues = val;
        this.control?.setValue(this.myForm.value, { emitEvent: true });
        this.formGroup = this.myForm;
      });
      this.form_loaded = true;
    }); // end translateService.getTranslation

  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

} // end class
