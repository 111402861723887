import { Component } from '@angular/core';

@Component({
  selector: 'app-cwps-inform-consent',
  standalone: true,
  imports: [],
  templateUrl: './cwps-inform-consent.component.html',
  styleUrl: './cwps-inform-consent.component.css'
})
export class CwpsInformConsentComponent {

}
