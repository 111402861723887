export enum AppType {
  AKPS = 'akps',
  CWPS = 'cwps',
}

export enum AkpsFormType {
  INTRO = 'intro',
  BASIC_INFO = 'basic-info',
  CURRENT_MEDICATIONS = 'current-medications',
  TESTS_IMAGES = 'tests-images',
  PAIN_TREATMENT_HISTORY = 'pain-treatment-history',
  CURRENT_PAIN_STATUS = 'current-pain-status',
  ADDITIONAL_INFO = 'additional-info',
}

export let logos: any = {
  akps: '../../../assets/akps/logo.svg',
  cwps: '../../../assets/cwps/logo.svg',
}
