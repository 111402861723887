export const countries = [
    { code: 'US', name: 'United States', prefix: '+1', mask: '(000) 000-0000' },
    /*    { code: 'AF', name: 'Afghanistan', prefix: '+93', mask: '00 000 0000' },
       { code: 'AL', name: 'Albania', prefix: '+355', mask: '00 000 0000' },
       { code: 'DZ', name: 'Algeria', prefix: '+213', mask: '00 0000 000' },
       { code: 'AS', name: 'American Samoa', prefix: '+1-684', mask: '000 0000' },
       { code: 'AD', name: 'Andorra', prefix: '+376', mask: '000 000' },
       { code: 'AO', name: 'Angola', prefix: '+244', mask: '000 000 000' },
       { code: 'AI', name: 'Anguilla', prefix: '+1-264', mask: '000 0000' },
       { code: 'AQ', name: 'Antarctica', prefix: '+672', mask: '1 00 0000' },
       { code: 'AG', name: 'Antigua and Barbuda', prefix: '+1-268', mask: '000 0000' },
       { code: 'AR', name: 'Argentina', prefix: '+54', mask: '00 0000 0000' },
       { code: 'AM', name: 'Armenia', prefix: '+374', mask: '00 000 000' },
       { code: 'AW', name: 'Aruba', prefix: '+297', mask: '000 0000' },
       { code: 'AU', name: 'Australia', prefix: '+61', mask: '0 0000 0000' },
       { code: 'AT', name: 'Austria', prefix: '+43', mask: '000 0000' },
       { code: 'AZ', name: 'Azerbaijan', prefix: '+994', mask: '00 000 0000' },
       { code: 'BS', name: 'Bahamas', prefix: '+1-242', mask: '000 0000' },
       { code: 'BH', name: 'Bahrain', prefix: '+973', mask: '0000 0000' },
       { code: 'BD', name: 'Bangladesh', prefix: '+880', mask: '0000 000000' },
       { code: 'BB', name: 'Barbados', prefix: '+1-246', mask: '000 0000' },
       { code: 'BY', name: 'Belarus', prefix: '+375', mask: '00 000 00 00' },
       { code: 'BE', name: 'Belgium', prefix: '+32', mask: '000 000 000' },
       { code: 'BZ', name: 'Belize', prefix: '+501', mask: '000 0000' },
       { code: 'BJ', name: 'Benin', prefix: '+229', mask: '00 00 0000' },
       { code: 'BM', name: 'Bermuda', prefix: '+1-441', mask: '000 0000' },
       { code: 'BT', name: 'Bhutan', prefix: '+975', mask: '00 000 000' },
       { code: 'BO', name: 'Bolivia', prefix: '+591', mask: '0 000 0000' },
       { code: 'BA', name: 'Bosnia and Herzegovina', prefix: '+387', mask: '00 000 000' },
       { code: 'BW', name: 'Botswana', prefix: '+267', mask: '00 000 000' },
       { code: 'BR', name: 'Brazil', prefix: '+55', mask: '00 00000 0000' },
       { code: 'IO', name: 'British Indian Ocean Territory', prefix: '+246', mask: '000 0000' },
       { code: 'VG', name: 'British Virgin Islands', prefix: '+1-284', mask: '000 0000' },
       { code: 'BN', name: 'Brunei', prefix: '+673', mask: '000 0000' },
       { code: 'BG', name: 'Bulgaria', prefix: '+359', mask: '00 000 000' },
       { code: 'BF', name: 'Burkina Faso', prefix: '+226', mask: '00 00 0000' },
       { code: 'BI', name: 'Burundi', prefix: '+257', mask: '00 00 0000' },
       { code: 'KH', name: 'Cambodia', prefix: '+855', mask: '00 000 000' },
       { code: 'CM', name: 'Cameroon', prefix: '+237', mask: '0000 0000' },
       { code: 'CA', name: 'Canada', prefix: '+1', mask: '(000) 000-0000' },
       { code: 'CV', name: 'Cape Verde', prefix: '+238', mask: '000 0000' },
       { code: 'KY', name: 'Cayman Islands', prefix: '+1-345', mask: '000 0000' },
       { code: 'CF', name: 'Central African Republic', prefix: '+236', mask: '00 00 0000' },
       { code: 'TD', name: 'Chad', prefix: '+235', mask: '00 00 00 00' },
       { code: 'CL', name: 'Chile', prefix: '+56', mask: '0 0000 0000' },
       { code: 'CN', name: 'China', prefix: '+86', mask: '000 0000 0000' },
       { code: 'CX', name: 'Christmas Island', prefix: '+61', mask: '0 0000 0000' },
       { code: 'CC', name: 'Cocos Islands', prefix: '+61', mask: '0 0000 0000' },
       { code: 'CO', name: 'Colombia', prefix: '+57', mask: '000 000 0000' },
       { code: 'KM', name: 'Comoros', prefix: '+269', mask: '00 00000' },
       { code: 'CK', name: 'Cook Islands', prefix: '+682', mask: '00 000' },
       { code: 'CR', name: 'Costa Rica', prefix: '+506', mask: '0000 0000' },
       { code: 'HR', name: 'Croatia', prefix: '+385', mask: '00 000 0000' },
       { code: 'CU', name: 'Cuba', prefix: '+53', mask: '0 000 0000' },
       { code: 'CW', name: 'Curacao', prefix: '+599', mask: '000 0000' },
       { code: 'CY', name: 'Cyprus', prefix: '+357', mask: '00 000000' },
       { code: 'CZ', name: 'Czech Republic', prefix: '+420', mask: '000 000 000' },
       { code: 'CD', name: 'Democratic Republic of the Congo', prefix: '+243', mask: '000 000 0000' },
       { code: 'DK', name: 'Denmark', prefix: '+45', mask: '00 00 00 00' },
       { code: 'DJ', name: 'Djibouti', prefix: '+253', mask: '00 00 00 00' },
       { code: 'DM', name: 'Dominica', prefix: '+1-767', mask: '000 0000' },
       { code: 'DO', name: 'Dominican Republic', prefix: '+1-809', mask: '000 0000' },
       { code: 'DO', name: 'Dominican Republic', prefix: '+1-829', mask: '000 0000' },
       { code: 'DO', name: 'Dominican Republic', prefix: '+1-849', mask: '000 0000' },
       { code: 'TL', name: 'East Timor', prefix: '+670', mask: '000 0000' },
       { code: 'EC', name: 'Ecuador', prefix: '+593', mask: '00 000 0000' },
       { code: 'EG', name: 'Egypt', prefix: '+20', mask: '00 0000000' },
       { code: 'SV', name: 'El Salvador', prefix: '+503', mask: '00 00 0000' },
       { code: 'GQ', name: 'Equatorial Guinea', prefix: '+240', mask: '00 000 0000' },
       { code: 'ER', name: 'Eritrea', prefix: '+291', mask: '0 000 000' },
       { code: 'EE', name: 'Estonia', prefix: '+372', mask: '0000 0000' },
       { code: 'ET', name: 'Ethiopia', prefix: '+251', mask: '00 000 0000' },
       { code: 'FK', name: 'Falkland Islands', prefix: '+500', mask: '00000' },
       { code: 'FO', name: 'Faroe Islands', prefix: '+298', mask: '000 000' },
       { code: 'FJ', name: 'Fiji', prefix: '+679', mask: '000 0000' },
       { code: 'FI', name: 'Finland', prefix: '+358', mask: '00 000 0000' },
       { code: 'FR', name: 'France', prefix: '+33', mask: '0 00 00 00 00' },
       { code: 'PF', name: 'French Polynesia', prefix: '+689', mask: '00 00 00' },
       { code: 'GA', name: 'Gabon', prefix: '+241', mask: '0 00 00 00' },
       { code: 'GM', name: 'Gambia', prefix: '+220', mask: '000 0000' },
       { code: 'GE', name: 'Georgia', prefix: '+995', mask: '000 000000' },
       { code: 'DE', name: 'Germany', prefix: '+49', mask: '000 000000' },
       { code: 'GH', name: 'Ghana', prefix: '+233', mask: '00 000 0000' },
       { code: 'GI', name: 'Gibraltar', prefix: '+350', mask: '00000' },
       { code: 'GR', name: 'Greece', prefix: '+30', mask: '000 0000 000' },
       { code: 'GL', name: 'Greenland', prefix: '+299', mask: '00 00 00' },
       { code: 'GD', name: 'Grenada', prefix: '+1-473', mask: '000 0000' },
       { code: 'GU', name: 'Guam', prefix: '+1-671', mask: '000 0000' },
       { code: 'GT', name: 'Guatemala', prefix: '+502', mask: '0 000 0000' },
       { code: 'GN', name: 'Guinea', prefix: '+224', mask: '00 000 000' },
       { code: 'GW', name: 'Guinea-Bissau', prefix: '+245', mask: '0 0000000' },
       { code: 'GY', name: 'Guyana', prefix: '+592', mask: '000 0000' },
       { code: 'HT', name: 'Haiti', prefix: '+509', mask: '00 00 0000' },
       { code: 'HN', name: 'Honduras', prefix: '+504', mask: '0000 0000' },
       { code: 'HK', name: 'Hong Kong', prefix: '+852', mask: '0000 0000' },
       { code: 'HU', name: 'Hungary', prefix: '+36', mask: '00 000 0000' },
       { code: 'IS', name: 'Iceland', prefix: '+354', mask: '000 0000' },
       { code: 'IN', name: 'India', prefix: '+91', mask: '00000 00000' },
       { code: 'ID', name: 'Indonesia', prefix: '+62', mask: '000 0000 0000' },
       { code: 'IR', name: 'Iran', prefix: '+98', mask: '000 000 0000' },
       { code: 'IQ', name: 'Iraq', prefix: '+964', mask: '0 000 0000' },
       { code: 'IE', name: 'Ireland', prefix: '+353', mask: '000 000 000' },
       { code: 'IM', name: 'Isle of Man', prefix: '+44-1624', mask: '000000' },
       { code: 'IL', name: 'Israel', prefix: '+972', mask: '0 000 0000' },
       { code: 'IT', name: 'Italy', prefix: '+39', mask: '000 000 0000' },
       { code: 'CI', name: 'Ivory Coast', prefix: '+225', mask: '00 000 000' },
       { code: 'JM', name: 'Jamaica', prefix: '+1-876', mask: '000 0000' },
       { code: 'JP', name: 'Japan', prefix: '+81', mask: '0 0000 0000' },
       { code: 'JE', name: 'Jersey', prefix: '+44-1534', mask: '000000' },
       { code: 'JO', name: 'Jordan', prefix: '+962', mask: '0 0000 0000' },
       { code: 'KZ', name: 'Kazakhstan', prefix: '+7', mask: '000 000-00-00' },
       { code: 'KE', name: 'Kenya', prefix: '+254', mask: '000 000000' },
       { code: 'KI', name: 'Kiribati', prefix: '+686', mask: '00 000' },
       { code: 'KW', name: 'Kuwait', prefix: '+965', mask: '0000 0000' },
       { code: 'KG', name: 'Kyrgyzstan', prefix: '+996', mask: '000 000 000' },
       { code: 'LA', name: 'Laos', prefix: '+856', mask: '00 000 000' },
       { code: 'LV', name: 'Latvia', prefix: '+371', mask: '000 00000' },
       { code: 'LB', name: 'Lebanon', prefix: '+961', mask: '0 000 000' },
       { code: 'LS', name: 'Lesotho', prefix: '+266', mask: '0 0000000' },
       { code: 'LR', name: 'Liberia', prefix: '+231', mask: '00 000 000' },
       { code: 'LY', name: 'Libya', prefix: '+218', mask: '00 0000000' },
       { code: 'LI', name: 'Liechtenstein', prefix: '+423', mask: '000 0000' },
       { code: 'LT', name: 'Lithuania', prefix: '+370', mask: '000 00000' },
       { code: 'LU', name: 'Luxembourg', prefix: '+352', mask: '000 000 000' },
       { code: 'MO', name: 'Macau', prefix: '+853', mask: '0000 0000' },
       { code: 'MK', name: 'Macedonia', prefix: '+389', mask: '00 000 000' },
       { code: 'MG', name: 'Madagascar', prefix: '+261', mask: '00 00 000 00' },
       { code: 'MW', name: 'Malawi', prefix: '+265', mask: '0 0000 0000' },
       { code: 'MY', name: 'Malaysia', prefix: '+60', mask: '0 000 0000' },
       { code: 'MV', name: 'Maldives', prefix: '+960', mask: '000 0000' },
       { code: 'ML', name: 'Mali', prefix: '+223', mask: '00 00 00 00' },
       { code: 'MT', name: 'Malta', prefix: '+356', mask: '0000 0000' },
       { code: 'MH', name: 'Marshall Islands', prefix: '+692', mask: '000 0000' },
       { code: 'MR', name: 'Mauritania', prefix: '+222', mask: '00 00 0000' },
       { code: 'MU', name: 'Mauritius', prefix: '+230', mask: '000 0000' },
       { code: 'YT', name: 'Mayotte', prefix: '+262', mask: '000 000000' },
       { code: 'MX', name: 'Mexico', prefix: '+52', mask: '000 000 0000' },
       { code: 'FM', name: 'Micronesia', prefix: '+691', mask: '000 0000' },
       { code: 'MD', name: 'Moldova', prefix: '+373', mask: '0000 0000' },
       { code: 'MC', name: 'Monaco', prefix: '+377', mask: '000 000 000' },
       { code: 'MN', name: 'Mongolia', prefix: '+976', mask: '00 00 0000' },
       { code: 'ME', name: 'Montenegro', prefix: '+382', mask: '00 000 000' },
       { code: 'MS', name: 'Montserrat', prefix: '+1-664', mask: '000 0000' },
       { code: 'MA', name: 'Morocco', prefix: '+212', mask: '00 0000 000' },
       { code: 'MZ', name: 'Mozambique', prefix: '+258', mask: '00 000 0000' },
       { code: 'MM', name: 'Myanmar', prefix: '+95', mask: '00 000 000' },
       { code: 'NA', name: 'Namibia', prefix: '+264', mask: '00 000 0000' },
       { code: 'NR', name: 'Nauru', prefix: '+674', mask: '000 0000' },
       { code: 'NP', name: 'Nepal', prefix: '+977', mask: '00 000000' },
       { code: 'NL', name: 'Netherlands', prefix: '+31', mask: '00 000 0000' },
       { code: 'AN', name: 'Netherlands Antilles', prefix: '+599', mask: '000 0000' },
       { code: 'NC', name: 'New Caledonia', prefix: '+687', mask: '00 0000' },
       { code: 'NZ', name: 'New Zealand', prefix: '+64', mask: '0 000 0000' },
       { code: 'NI', name: 'Nicaragua', prefix: '+505', mask: '0000 0000' },
       { code: 'NE', name: 'Niger', prefix: '+227', mask: '00 00 0000' },
       { code: 'NG', name: 'Nigeria', prefix: '+234', mask: '000 000 0000' },
       { code: 'NU', name: 'Niue', prefix: '+683', mask: '0000' },
       { code: 'KP', name: 'North Korea', prefix: '+850', mask: '0 000 0000' },
       { code: 'MP', name: 'Northern Mariana Islands', prefix: '+1-670', mask: '000 0000' },
       { code: 'NO', name: 'Norway', prefix: '+47', mask: '00 00 00 00' },
       { code: 'OM', name: 'Oman', prefix: '+968', mask: '0000 0000' },
       { code: 'PK', name: 'Pakistan', prefix: '+92', mask: '000 0000000' },
       { code: 'PW', name: 'Palau', prefix: '+680', mask: '000 0000' },
       { code: 'PS', name: 'Palestine', prefix: '+970', mask: '00 000 0000' },
       { code: 'PA', name: 'Panama', prefix: '+507', mask: '000 0000' },
       { code: 'PG', name: 'Papua New Guinea', prefix: '+675', mask: '000 0000' },
       { code: 'PY', name: 'Paraguay', prefix: '+595', mask: '000 000000' },
       { code: 'PE', name: 'Peru', prefix: '+51', mask: '000 000 000' },
       { code: 'PH', name: 'Philippines', prefix: '+63', mask: '00 000 0000' },
       { code: 'PL', name: 'Poland', prefix: '+48', mask: '000 000 000' },
       { code: 'PT', name: 'Portugal', prefix: '+351', mask: '000 000 000' },
       { code: 'PR', name: 'Puerto Rico', prefix: '+1-787', mask: '000 0000' },
       { code: 'PR', name: 'Puerto Rico', prefix: '+1-939', mask: '000 0000' },
       { code: 'QA', name: 'Qatar', prefix: '+974', mask: '0000 0000' },
       { code: 'CG', name: 'Republic of the Congo', prefix: '+242', mask: '00 000 0000' },
       { code: 'RE', name: 'Reunion', prefix: '+262', mask: '000 000000' },
       { code: 'RO', name: 'Romania', prefix: '+40', mask: '000 000 0000' },
       { code: 'RU', name: 'Russia', prefix: '+7', mask: '000 000-00-00' },
       { code: 'RW', name: 'Rwanda', prefix: '+250', mask: '0000 0000' },
       { code: 'BL', name: 'Saint Barthelemy', prefix: '+590', mask: '000 000000' },
       { code: 'SH', name: 'Saint Helena', prefix: '+290', mask: '0000' },
       { code: 'KN', name: 'Saint Kitts and Nevis', prefix: '+1-869', mask: '000 0000' },
       { code: 'LC', name: 'Saint Lucia', prefix: '+1-758', mask: '000 0000' },
       { code: 'MF', name: 'Saint Martin', prefix: '+590', mask: '000 000000' },
       { code: 'PM', name: 'Saint Pierre and Miquelon', prefix: '+508', mask: '00 0000' },
       { code: 'VC', name: 'Saint Vincent and the Grenadines', prefix: '+1-784', mask: '000 0000' },
       { code: 'WS', name: 'Samoa', prefix: '+685', mask: '00 000' },
       { code: 'SM', name: 'San Marino', prefix: '+378', mask: '0000 000000' },
       { code: 'ST', name: 'Sao Tome and Principe', prefix: '+239', mask: '00 00000' },
       { code: 'SA', name: 'Saudi Arabia', prefix: '+966', mask: '0 000 0000' },
       { code: 'SN', name: 'Senegal', prefix: '+221', mask: '00 000 0000' },
       { code: 'RS', name: 'Serbia', prefix: '+381', mask: '00 000 0000' },
       { code: 'SC', name: 'Seychelles', prefix: '+248', mask: '0 000 000' },
       { code: 'SL', name: 'Sierra Leone', prefix: '+232', mask: '00 000000' },
       { code: 'SG', name: 'Singapore', prefix: '+65', mask: '0000 0000' },
       { code: 'SX', name: 'Sint Maarten', prefix: '+1-721', mask: '000 0000' },
       { code: 'SK', name: 'Slovakia', prefix: '+421', mask: '000 000 000' },
       { code: 'SI', name: 'Slovenia', prefix: '+386', mask: '00 000 000' },
       { code: 'SB', name: 'Solomon Islands', prefix: '+677', mask: '00000' },
       { code: 'SO', name: 'Somalia', prefix: '+252', mask: '00 000 000' },
       { code: 'ZA', name: 'South Africa', prefix: '+27', mask: '00 000 0000' },
       { code: 'KR', name: 'South Korea', prefix: '+82', mask: '0 000 0000' },
       { code: 'SS', name: 'South Sudan', prefix: '+211', mask: '00 000 0000' },
       { code: 'ES', name: 'Spain', prefix: '+34', mask: '000 000 000' },
       { code: 'LK', name: 'Sri Lanka', prefix: '+94', mask: '00 000 0000' },
       { code: 'SD', name: 'Sudan', prefix: '+249', mask: '00 000 0000' },
       { code: 'SR', name: 'Suriname', prefix: '+597', mask: '000 0000' },
       { code: 'SJ', name: 'Svalbard and Jan Mayen', prefix: '+47', mask: '00 00 00 00' },
       { code: 'SZ', name: 'Swaziland', prefix: '+268', mask: '00 00 0000' },
       { code: 'SE', name: 'Sweden', prefix: '+46', mask: '00 000 0000' },
       { code: 'CH', name: 'Switzerland', prefix: '+41', mask: '00 000 0000' },
       { code: 'SY', name: 'Syria', prefix: '+963', mask: '00 0000 000' },
       { code: 'TW', name: 'Taiwan', prefix: '+886', mask: '0 0000 0000' },
       { code: 'TJ', name: 'Tajikistan', prefix: '+992', mask: '00 000 0000' },
       { code: 'TZ', name: 'Tanzania', prefix: '+255', mask: '00 000 0000' },
       { code: 'TH', name: 'Thailand', prefix: '+66', mask: '0 000 0000' },
       { code: 'TG', name: 'Togo', prefix: '+228', mask: '00 000 000' },
       { code: 'TK', name: 'Tokelau', prefix: '+690', mask: '0000' },
       { code: 'TO', name: 'Tonga', prefix: '+676', mask: '00000' },
       { code: 'TT', name: 'Trinidad and Tobago', prefix: '+1-868', mask: '000 0000' },
       { code: 'TN', name: 'Tunisia', prefix: '+216', mask: '00 000 000' },
       { code: 'TR', name: 'Turkey', prefix: '+90', mask: '000 000 0000' },
       { code: 'TM', name: 'Turkmenistan', prefix: '+993', mask: '0 000000' },
       { code: 'TC', name: 'Turks and Caicos Islands', prefix: '+1-649', mask: '000 0000' },
       { code: 'TV', name: 'Tuvalu', prefix: '+688', mask: '000000' },
       { code: 'UG', name: 'Uganda', prefix: '+256', mask: '000 000000' },
       { code: 'UA', name: 'Ukraine', prefix: '+380', mask: '00 000 0000' },
       { code: 'AE', name: 'United Arab Emirates', prefix: '+971', mask: '0 000 0000' },
       { code: 'GB', name: 'United Kingdom', prefix: '+44', mask: '0000 000000' },
       { code: 'UY', name: 'Uruguay', prefix: '+598', mask: '0000 0000' },
       { code: 'UZ', name: 'Uzbekistan', prefix: '+998', mask: '00 000 0000' },
       { code: 'VU', name: 'Vanuatu', prefix: '+678', mask: '00000' },
       { code: 'VA', name: 'Vatican', prefix: '+39-06', mask: '000 0000' },
       { code: 'VE', name: 'Venezuela', prefix: '+58', mask: '000 000 0000' },
       { code: 'VN', name: 'Vietnam', prefix: '+84', mask: '0 000 0000' },
       { code: 'WF', name: 'Wallis and Futuna', prefix: '+681', mask: '00 0000' },
       { code: 'EH', name: 'Western Sahara', prefix: '+212', mask: '00 0000000' },
       { code: 'YE', name: 'Yemen', prefix: '+967', mask: '0 000 000' },
       { code: 'ZM', name: 'Zambia', prefix: '+260', mask: '00 000 0000' },
       { code: 'ZW', name: 'Zimbabwe', prefix: '+263', mask: '0 000000' } */
];
