import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';

interface CheckboxOptions {
  [key: string]: { label: string; questionid?: string };
}


@Component({
  selector: 'app-checkbox-group-as-array',
  standalone: true,
  imports: [ReactiveFormsModule, MatCheckboxModule, CommonModule],
  templateUrl: './checkbox-group-as-array.component.html',
  styleUrls: ['./checkbox-group-as-array.component.css']
})
export class CheckboxGroupAsArrayComponent implements OnInit {
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() options!: CheckboxOptions;  // Use the defined interface
  @Input() label!: string;
  @Input() icon!: string;
  @Input() subLabel!: string;
  @Input() subLabelClass!: string;
  @Input() required: boolean = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    const controls = Object.keys(this.options).reduce((acc, key) => {
      acc[key] = this.fb.control(false);
      return acc;
    }, {} as { [key: string]: FormControl });

    this.formGroup.addControl(this.controlName, this.fb.group(controls));
  }

  get group(): FormGroup {
    return this.formGroup.get(this.controlName) as FormGroup;
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
}
