import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-pain-slider',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    TranslateModule,
    FormsModule,
  ],
  templateUrl: './pain-slider.component.html',
  styleUrls: ['./pain-slider.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PainSliderComponent {
  @Input() formGroup!: FormGroup;
  @Input() title!: string;
  @Input() formName!: string;
  value: any = 5;

  formatLabel(value: number): string {
    return `${value}`;
  }
  getSliderThumbClass(): string {
    const sliderValue = this.value;
    return `value-${sliderValue}`;
  }

}
