<form [formGroup]="formGroup">
  <div (click)="focusInput($event)" [ngClass]="{ 'readonly-class': readonly }">
    <mat-form-field
      class="h-phone"
      [ngClass]="{
        labelActive: !isInputActive(),
        'mdc-text-field--invalid': isInvalid()
      }"
    >
      <mat-label>
        {{ title | translate }}
      </mat-label>
      <mat-icon matSuffix>
        <i [ngClass]="['input-icon', iconClass || 'healent-phone']"></i>
      </mat-icon>
      <div class="phoneInput" [ngClass]="{ hide: !isInputActive() }">
        <span class="country-prefix p-r-xs">+1</span>
        <input
          #phoneInput
          matInput
          [formControl]="localPhoneControl"
          ngxMask
          [mask]="selectedCountry?.mask || '(000) 000-0000'"
          [showMaskTyped]="false"
          [specialCharacters]="['+', ' ', '(', ')', '-', '.']"
          inputmode="numeric"
          (focus)="onInputFocus($event)"
          (blur)="onInputBlur($event)"
          class="pointer-all"
          placeholder="(___) ___-____"
          [readonly]="readonly"
          [required]="required"
        />
      </div>
    </mat-form-field>
  </div>
  <div
    [ngClass]="{
      hintContainer: true,
      valid: !isInvalid() && !isEmptyError()
    }"
  >
    <ng-container *ngIf="isInvalid(); else req">
      <span class="hintError">#Error</span>
      <span class="hintText">
        <ng-container *ngIf="isEmptyError(); else formatError">
          - This field cannot be empty</ng-container
        >
        <ng-template #formatError> - Invalid number</ng-template>
      </span>
    </ng-container>
    <ng-template #req>
      <span
        *ngIf="isRequired || required; else optionalField"
        class="hintRequired"
      >
        *Required
      </span>
      <ng-template #optionalField>
        <span class="hintOptional">+Optional</span>
      </ng-template>
    </ng-template>
  </div>
</form>
