<form [formGroup]="myForm" class="hide-reqirad">
  <h2 class="h2-section-title">
    Over the last 2 weeks how often have you been bothered by any of the
    following problems?
  </h2>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['littleinterest']?.is_include"
    controlName="littleinterest"
    [label]="radioItems['littleinterest']?.title"
    [options]="radioItems['littleinterest']?.options"
    optionsTitle="{{ radioItems['littleinterest']['options_title'] }}"
    titleNumber="{{ radioItems['littleinterest']['titleNumber'] }}"
    subTitle="{{ radioItems['littleinterest']['subTitle'] }}"
    [iconClass]="radioItems['littleinterest']?.icon"
    [separateTitle]="radioItems['littleinterest']?.separateTitle"
    [hint]="radioItems['littleinterest']?.hint"
    [questionId]="radioItems['littleinterest']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['feelingdown']?.is_include"
    controlName="feelingdown"
    [label]="radioItems['feelingdown']?.title"
    [options]="radioItems['feelingdown']?.options"
    [iconClass]="radioItems['feelingdown']?.icon"
    [separateTitle]="radioItems['feelingdown']?.separateTitle"
    optionsTitle="{{ radioItems['feelingdown']['options_title'] }}"
    titleNumber="{{ radioItems['feelingdown']['titleNumber'] }}"
    subTitle="{{ radioItems['feelingdown']['subTitle'] }}"
    [hint]="radioItems['feelingdown']?.hint"
    [questionId]="radioItems['feelingdown']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['troublefallingasleep']?.is_include"
    controlName="troublefallingasleep"
    [label]="radioItems['troublefallingasleep']?.title"
    [options]="radioItems['troublefallingasleep']?.options"
    [iconClass]="radioItems['troublefallingasleep']?.icon"
    [separateTitle]="radioItems['troublefallingasleep']?.separateTitle"
    optionsTitle="{{ radioItems['troublefallingasleep']['options_title'] }}"
    titleNumber="{{ radioItems['troublefallingasleep']['titleNumber'] }}"
    [subTitle]="radioItems['troublefallingasleep']?.subTitle"
    [hint]="radioItems['troublefallingasleep']?.hint"
    [questionId]="radioItems['troublefallingasleep']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['feelingtired']?.is_include"
    controlName="feelingtired"
    [label]="radioItems['feelingtired']?.title"
    [options]="radioItems['feelingtired']?.options"
    [iconClass]="radioItems['feelingtired']?.icon"
    [separateTitle]="radioItems['feelingtired']?.separateTitle"
    optionsTitle="{{ radioItems['feelingtired']['options_title'] }}"
    titleNumber="{{ radioItems['feelingtired']['titleNumber'] }}"
    [subTitle]="radioItems['feelingtired']?.subTitle"
    [hint]="radioItems['feelingtired']?.hint"
    [questionId]="radioItems['feelingtired']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['poorappetite']?.is_include"
    controlName="poorappetite"
    [label]="radioItems['poorappetite']?.title"
    [options]="radioItems['poorappetite']?.options"
    [iconClass]="radioItems['poorappetite']?.icon"
    [separateTitle]="radioItems['poorappetite']?.separateTitle"
    optionsTitle="{{ radioItems['poorappetite']['options_title'] }}"
    titleNumber="{{ radioItems['poorappetite']['titleNumber'] }}"
    [subTitle]="radioItems['poorappetite']?.subTitle"
    [hint]="radioItems['poorappetite']?.hint"
    [questionId]="radioItems['poorappetite']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['feelingbad']?.is_include"
    controlName="feelingbad"
    [label]="radioItems['feelingbad']?.title"
    [options]="radioItems['feelingbad']?.options"
    [iconClass]="radioItems['feelingbad']?.icon"
    [separateTitle]="radioItems['feelingbad']?.separateTitle"
    optionsTitle="{{ radioItems['feelingbad']['options_title'] }}"
    titleNumber="{{ radioItems['feelingbad']['titleNumber'] }}"
    [subTitle]="radioItems['feelingbad']?.subTitle"
    [hint]="radioItems['feelingbad']?.hint"
    [questionId]="radioItems['feelingbad']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['troubleconcentrating']?.is_include"
    controlName="troubleconcentrating"
    [label]="radioItems['troubleconcentrating']?.title"
    [options]="radioItems['troubleconcentrating']?.options"
    [iconClass]="radioItems['troubleconcentrating']?.icon"
    [separateTitle]="radioItems['troubleconcentrating']?.separateTitle"
    optionsTitle="{{ radioItems['troubleconcentrating']['options_title'] }}"
    titleNumber="{{ radioItems['troubleconcentrating']['titleNumber'] }}"
    [subTitle]="radioItems['troubleconcentrating']?.subTitle"
    [hint]="radioItems['troubleconcentrating']?.hint"
    [questionId]="radioItems['troubleconcentrating']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>

  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['movingorspeakingslowly']?.is_include"
    controlName="movingorspeakingslowly"
    [label]="radioItems['movingorspeakingslowly']?.title"
    [options]="radioItems['movingorspeakingslowly']?.options"
    [iconClass]="radioItems['movingorspeakingslowly']?.icon"
    [separateTitle]="radioItems['movingorspeakingslowly']?.separateTitle"
    optionsTitle="{{ radioItems['movingorspeakingslowly']['options_title'] }}"
    titleNumber="{{ radioItems['movingorspeakingslowly']['titleNumber'] }}"
    [subTitle]="radioItems['movingorspeakingslowly']?.subTitle"
    [hint]="radioItems['movingorspeakingslowly']?.hint"
    [questionId]="radioItems['movingorspeakingslowly']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['betterdead']?.is_include"
    controlName="betterdead"
    [label]="radioItems['betterdead']?.title"
    [options]="radioItems['betterdead']?.options"
    [iconClass]="radioItems['betterdead']?.icon"
    [separateTitle]="radioItems['betterdead']?.separateTitle"
    optionsTitle="{{ radioItems['betterdead']['options_title'] }}"
    titleNumber="{{ radioItems['betterdead']['titleNumber'] }}"
    [subTitle]="radioItems['betterdead']?.subTitle"
    [hint]="radioItems['betterdead']?.hint"
    [questionId]="radioItems['betterdead']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
  <app-select-as-array
    [formGroup]="myForm"
    *ngIf="radioItems['difficultydoingthings']?.is_include"
    controlName="difficultydoingthings"
    [label]="radioItems['difficultydoingthings']?.title"
    [options]="radioItems['difficultydoingthings']?.options"
    [iconClass]="radioItems['difficultydoingthings']?.icon"
    [separateTitle]="radioItems['difficultydoingthings']?.separateTitle"
    optionsTitle="{{ radioItems['difficultydoingthings']['options_title'] }}"
    titleNumber="{{ radioItems['difficultydoingthings']['titleNumber'] }}"
    [subTitle]="radioItems['difficultydoingthings']?.subTitle"
    [hint]="radioItems['difficultydoingthings']?.hint"
    [questionId]="radioItems['difficultydoingthings']?.questionId"
    class="m-t-48 d-block"
  >
  </app-select-as-array>
</form>
