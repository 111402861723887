<mat-form-field appearance="fill" [ngClass]="{ 'readonly-class': readonly }">
  <mat-label [ngClass]="{ 'front-red': control.invalid && control.touched }">
    {{ label }}
  </mat-label>
  <input id="{{ id }}"
    matInput
    [matDatepicker]="picker"
    [formControl]="control"
    [min]="minDate"
    [max]="maxDate"
    autocomplete="off"
    placeholder="MM/DD/YYYY"
    inputmode="numeric"
    [readonly]="readonly"
  />
  <div
    class="mat-mdc-form-field-icon-suffix"
    mat-icon-button
    matSuffix
    (click)="!readonly && picker.open()"
    tabindex="-1"
  >
    <mat-icon>
      <i class="input-icon healent-form-input-icon-2"></i>
    </mat-icon>
  </div>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<!-- Display required/optional hint -->
<div [ngClass]="{ hintContainer: true, valid: control.valid && control.value }">
  <div *ngIf="control.errors && control.touched">
    <span class="hintError">#Error</span>
    <span class="hintText"> - {{ getErrorMessage() }}</span>
  </div>
  <div *ngIf="!control.errors || !control.touched">
    <span *ngIf="isRequired(); else optionalField" class="hintRequired">
      *Required
    </span>
    <ng-template #optionalField>
      <span class="hintOptional">+Optional</span>
    </ng-template>
    <span class="hintText"> - MM/DD/YYYY</span>
  </div>
</div>
