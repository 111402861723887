<form [formGroup]="myForm">
  <ng-container *ngIf="form_loaded">
    <app-custom-radio-group
      [formGroup]="myForm"
      controlName="doYouHaveHealthInsurance"
      *ngIf="radioItems['doYouHaveHealthInsurance']?.is_include"
      label="{{ radioItems['doYouHaveHealthInsurance']['title'] }}"
      [options]="radioItems['doYouHaveHealthInsurance']['options']"
      class="flex-row-reverse m-b-l d-block"
    >
    </app-custom-radio-group>
    <div
      class="m-t-l"
      *ngIf="myForm.get('doYouHaveHealthInsurance')?.value === 'yes'"
    >
      <app-image-uploader
        [formGroup]="myForm"
        [uploadItems]="uploadItems"
        controlName="insuranceID"
        *ngIf="uploadItems['insuranceID']?.is_include"
        frontFormName="front"
        backFormName="back"
      ></app-image-uploader>
      <app-custom-input
        [formGroup]="myForm"
        controlName="primaryInsurance"
        *ngIf="inputItems['primaryInsurance']?.is_include"
        placeholder="{{ inputItems['primaryInsurance']['placeholder'] }}"
      ></app-custom-input>
      <p
        *ngIf="inputItems['primaryInsurance']?.is_include"
        class="hint-text p-l-m"
      >
        Placed on the front of your insurance card, usually labeled as ‘ID’ or
        ‘Policy Number’
      </p>
      <app-custom-date-picker
        [formGroup]="myForm"
        controlName="primaryInsuranceIssueDate"
        *ngIf="inputItems['primaryInsuranceIssueDate']?.is_include"
        label="{{ inputItems['primaryInsuranceIssueDate']['placeholder'] }}"
        [maxDate]="maxDate"
      >
      </app-custom-date-picker>
      <app-custom-date-picker
        [formGroup]="myForm"
        controlName="primaryInsuranceExpirationDate"
        *ngIf="inputItems['primaryInsuranceExpirationDate']?.is_include"
        label="{{
          inputItems['primaryInsuranceExpirationDate']['placeholder']
        }}"
        [minDate]="minDate"
      >
      </app-custom-date-picker>
      <app-insurance-package
        [formGroup]="myForm"
        formName="insurancePackage"
        *ngIf="inputItems['insurancePackage']?.is_include"
        title="{{ inputItems['insurancePackage']['placeholder'] }}"
        icon="{{ inputItems['insurancePackage']['icon'] }}"
      >
      </app-insurance-package>
      <app-custom-checkbox
        [formGroup]="myForm"
        controlName="is_cardholder"
        *ngIf="checkboxItems['is_cardholder']?.is_include"
        label="{{ checkboxItems['is_cardholder']['title'] }}"
      >
      </app-custom-checkbox>
      <div *ngIf="myForm.get('is_cardholder')?.value">
        <app-custom-input
          [formGroup]="myForm"
          controlName="cardholder"
          *ngIf="inputItems['cardholder']?.is_include"
          placeholder="{{ inputItems['cardholder']['placeholder'] }}"
          [maxlength]="20"
        ></app-custom-input>
        <app-custom-date-picker
          [formGroup]="myForm"
          controlName="dob"
          *ngIf="inputItems['dob']?.is_include"
          label="Date of Birth"
          [maxDate]="maxDate"
        >
        </app-custom-date-picker>
        <app-ssn
          [formGroup]="myForm"
          *ngIf="inputItems['ssn']?.is_include"
          title="{{ inputItems['ssn']['title'] }}"
          placeholder="{{ inputItems['ssn']['placeholder'] }}"
        ></app-ssn>
      </div>

      <app-custom-radio-group
        [formGroup]="myForm"
        controlName="doYouHaveSecondaryHealthInsurance"
        *ngIf="radioItems['doYouHaveSecondaryHealthInsurance']?.is_include"
        label="{{ radioItems['doYouHaveSecondaryHealthInsurance']['title'] }}"
        [options]="radioItems['doYouHaveSecondaryHealthInsurance']['options']"
        class="flex-row-reverse m-b-l m-t-l d-block"
      >
      </app-custom-radio-group>
      <div
        class="m-t-l"
        *ngIf="myForm.get('doYouHaveSecondaryHealthInsurance')?.value === 'yes'"
      >
        <app-image-uploader
          [formGroup]="myForm"
          [uploadItems]="uploadItems"
          controlName="secondaryInsuranceID"
          *ngIf="uploadItems['secondaryInsuranceID']?.is_include"
          frontFormName="frontSecondaryInsuranceCard"
          backFormName="backSecondaryInsuranceCard"
        ></app-image-uploader>
        <app-custom-input
          [formGroup]="myForm"
          controlName="secondaryInsurance"
          *ngIf="inputItems['secondaryInsurance']?.is_include"
          placeholder="{{ inputItems['secondaryInsurance']['placeholder'] }}"
        ></app-custom-input>
        <p
          *ngIf="inputItems['secondaryInsurance']?.is_include"
          class="hint-text p-l-m"
        >
          Placed on the front of your insurance card, usually labeled as ‘ID’ or
          ‘Policy Number’
        </p>
        <app-custom-date-picker
          [formGroup]="myForm"
          controlName="secondaryInsuranceIssueDate"
          *ngIf="inputItems['secondaryInsuranceIssueDate']?.is_include"
          label="{{ inputItems['secondaryInsuranceIssueDate']['placeholder'] }}"
          [maxDate]="maxDate"
        >
        </app-custom-date-picker>
        <app-custom-date-picker
          [formGroup]="myForm"
          controlName="secondaryInsuranceExpirationDate"
          *ngIf="inputItems['secondaryInsuranceExpirationDate']?.is_include"
          label="{{
            inputItems['secondaryInsuranceExpirationDate']['placeholder']
          }}"
          [minDate]="minDate"
        >
        </app-custom-date-picker>
        <app-insurance-package
          [formGroup]="myForm"
          formName="secondaryInsurancePackage"
          *ngIf="inputItems['secondaryInsurancePackage']?.is_include"
          title="{{ inputItems['secondaryInsurancePackage']['placeholder'] }}"
          icon="{{ inputItems['secondaryInsurancePackage']['icon'] }}"
        >
        </app-insurance-package>
        <app-custom-checkbox
          [formGroup]="myForm"
          controlName="secondary_is_cardholder"
          *ngIf="checkboxItems['secondary_is_cardholder']?.is_include"
          label="{{ checkboxItems['secondary_is_cardholder']['title'] }}"
        >
        </app-custom-checkbox>
        <div *ngIf="myForm.get('is_cardholder')?.value">
          <app-custom-input
            [formGroup]="myForm"
            controlName="secondaryCardholder"
            *ngIf="inputItems['secondaryCardholder']?.is_include"
            placeholder="{{ inputItems['secondaryCardholder']['placeholder'] }}"
            [maxlength]="20"
          ></app-custom-input>
          <app-custom-date-picker
            [formGroup]="myForm"
            controlName="secondaryDob"
            *ngIf="inputItems['secondaryDob']?.is_include"
            label="Date of Birth"
            [maxDate]="maxDate"
          >
          </app-custom-date-picker>
          <app-ssn
            [formGroup]="myForm"
            *ngIf="inputItems['secondarySsn']?.is_include"
            title="{{ inputItems['secondarySsn']['title'] }}"
            placeholder="{{ inputItems['secondarySsn']['placeholder'] }}"
          ></app-ssn>
        </div>
      </div>

      <app-custom-radio-group
        [formGroup]="myForm"
        controlName="isYourInsuranceAssociatedWithMedicaid"
        *ngIf="radioItems['isYourInsuranceAssociatedWithMedicaid']?.is_include"
        label="{{
          radioItems['isYourInsuranceAssociatedWithMedicaid']['title']
        }}"
        [options]="
          radioItems['isYourInsuranceAssociatedWithMedicaid']['options']
        "
        class="radios-vertical orderlast"
      >
      </app-custom-radio-group>
    </div>

    <app-custom-radio-group
      [formGroup]="myForm"
      controlName="isYourInsuranceAssociatedWithMedicaid"
      *ngIf="
        radioItems['isYourInsuranceAssociatedWithMedicaid']?.is_include &&
        !radioItems['doYouHaveHealthInsurance']?.is_include
      "
      label="{{ radioItems['isYourInsuranceAssociatedWithMedicaid']['title'] }}"
      [options]="radioItems['isYourInsuranceAssociatedWithMedicaid']['options']"
      class="radios-vertical orderlast flexColumn"
    >
    </app-custom-radio-group>
  </ng-container>
</form>
