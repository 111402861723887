import { Route } from '@angular/router';
import { BlueClientComponent } from "./repair/blue-client/blue-client.component";
import { DirectorComponent } from "./director/director.component";
import { VerificationComponent } from './repair/verification/verification.component';
import { DoneComponent } from './repair/reusable/done/done.component';
export const routes: Route[] = [
  //{ path: '', redirectTo: '/akps', pathMatch: 'full'},
  { path: 'director/:ciphertext', component: DirectorComponent },
  { path: 'verification/:form_id', component: VerificationComponent },
  { path: 'form/:form_id', component: BlueClientComponent },
  { path: 'done', component: DoneComponent },

];

