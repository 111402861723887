<p>
  Commonwealth Pain and Spine ("CP&S") is committed to providing safe and
  compliant patient care. In order for you to be prescribed controlled substance
  medication(s) for the treatment of chronic pain, you must agree to, and abide
  by, the information contained within this controlled substance agreement.
  <br />
  The purpose of the controlled substance agreement is to educate you about the
  medication(s) you will be taking for pain and to assure that you comply with
  CP&S\'s rules, and all applicable laws and regulations, while you are
  prescribed controlled substance medication(s).
</p>
