import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray, ReactiveFormsModule } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { CommonModule } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { formObjectByType } from "../../functions/functions";
import { TestAndImageComponent } from '../../reusable/test-and-image/test-and-image.component';
import { CustomCheckboxGroupComponent } from '../../reusable/custom-checkbox-group/custom-checkbox-group.component';
import { CustomCheckboxComponent } from '../../reusable/custom-checkbox/custom-checkbox.component';
import { CustomInputComponent } from '../../reusable/custom-input/custom-input.component';
import { CustomDatePickerComponent } from '../../reusable/custom-date-picker/custom-date-picker.component';
import { CustomRadioGroupComponent } from '../../reusable/custom-radio-group/custom-radio-group.component';
import { CustomTextareaComponent } from '../../reusable/custom-textarea/custom-textarea.component';
import { ChipSelectionComponent } from '../../reusable/chip-selection/chip-selection.component';
import { SelectAsArrayComponent } from '../../reusable/select-as-array/select-as-array.component';

@Component({
  selector: 'app-odi',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TestAndImageComponent,
    CustomCheckboxGroupComponent,
    CustomCheckboxComponent,
    CustomInputComponent,
    CustomDatePickerComponent,
    CustomRadioGroupComponent,
    CustomTextareaComponent,
    ChipSelectionComponent,
    SelectAsArrayComponent
  ],
  templateUrl: './odi.component.html',
  styleUrl: './odi.component.css'
})
export class OdiComponent implements OnInit, OnDestroy {
  @Input() control: FormControl | undefined;
  @Input() hospital_slug: string = '';
  @Input() form_id: number = 0;
  @Input() patch_values = {};
  @Input() formGroup: FormGroup | undefined;
  @Input() step: string = '';
  formValues: any = {};
  value: any;
  form_loaded = false;
  myForm!: FormGroup;
  checkboxItems: any = {};
  radioItems: any = {};
  inputItems: any = {};
  destroySubject = new Subject<void>();

  constructor(private translateService: TranslateService) {
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    this.myForm = new FormGroup({});
    const currentLang = this.translateService.currentLang;
    let lang_form = `${currentLang}-${this.form_id}`;

    this.translateService.getTranslation(lang_form).pipe(takeUntil(this.destroySubject)).subscribe(translations => {
      const translationsObj = translations['healent'][this.step];
      const translationsObjSlug = translations[this.hospital_slug][this.step];
      let formObject = formObjectByType(translationsObj, translationsObjSlug, this.myForm, this.step);

      this.myForm = formObject['myForm'];
      this.radioItems = formObject['radioItems'];
      this.inputItems = formObject['inputItems'];
      this.checkboxItems = formObject['checkboxItems'];

      if (this.patch_values) {
        this.myForm.patchValue(this.patch_values, { emitEvent: false });
      }

      this.control?.setValue(this.myForm.value, { emitEvent: true });
      this.formGroup = this.myForm;

      this.myForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(val => {
        this.formValues = val;
        this.control?.setValue(this.myForm.value, { emitEvent: true });
        this.formGroup = this.myForm;
      });

      this.onCheckboxChange();
      this.subscribeToRadioChanges();

      const backPainControl = this.myForm.get('doYouHaveBackPain');

      if (backPainControl) {
        this.toggleSelectValidators(backPainControl.value, 'painIntensity', 'personalCareWashingDressingEtc', 'lifting', 'walking', 'sitting', 'standing', 'sleeping', 'socialLife', 'traveling');
      }



      this.form_loaded = true;
    });
  }


  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onCheckboxChange() {
    // Handle any checkbox changes here
  }

  subscribeToRadioChanges() {
    const backPainControl = this.myForm.get('doYouHaveBackPain');

    if (backPainControl) {
      backPainControl.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(value => {
        this.toggleSelectValidators(value, 'painIntensity', 'personalCareWashingDressingEtc', 'lifting', 'walking', 'sitting', 'standing', 'sleeping', 'socialLife', 'traveling');
      });
    }

  }


  toggleSelectValidators(parentValue: string, ...selectControlNames: string[]) {
    selectControlNames.forEach(controlName => {
      const control = this.myForm.get(controlName);
      if (control) {
        if (parentValue === 'yes') {
          control.setValidators([Validators.required]);
        } else {
          control.clearValidators();
        }
        control.updateValueAndValidity();
      }
    });
  }
}
