import { FormControl, FormGroup, Validators } from '@angular/forms';

interface TranslationsObjSlugInterface {
  [key: string]: any;
}

export function myOverrideFunction(tag_key: string, key: string, translationsObjSlug: any, Items: any) {
  let value = {};

  if (translationsObjSlug != undefined && translationsObjSlug.hasOwnProperty(tag_key) && translationsObjSlug[tag_key].hasOwnProperty(key)) {
    Items[key] = translationsObjSlug[tag_key][key];
    value = Items[key];
  }

  let is_include = true;
  if (translationsObjSlug != undefined) {
    if (translationsObjSlug.hasOwnProperty(tag_key) && translationsObjSlug[tag_key].hasOwnProperty(key) && Object.keys(translationsObjSlug[tag_key]).length > 1) {
      Items[key] = translationsObjSlug[tag_key][key];
      value = Items[key];
      if (translationsObjSlug[tag_key][key].hasOwnProperty('is_include')) {
        is_include = translationsObjSlug[tag_key][key]['is_include'];
      }

    } else if (translationsObjSlug.hasOwnProperty(tag_key) && translationsObjSlug[tag_key].hasOwnProperty(key) && Object.keys(translationsObjSlug[tag_key]).length === 1) {
      if (translationsObjSlug[tag_key][key].hasOwnProperty('is_include')) {
        is_include = translationsObjSlug[tag_key][key]['is_include']; //WE NEED TO MAKE SURE IF WAS REQUIRED TEN NO LONGER REQUIRED
      }
      Items[key][tag_key] = is_include;
    }
  }

  return { value, is_include, Items }
} // end myFunction

export function formObjectByType(translationsObj: any, translationsObjSlug: TranslationsObjSlugInterface, myForm: FormGroup, step?: string) {
  /* RADIOS */
  let radioItems = null;
  if (translationsObj.hasOwnProperty('radios')) {
    radioItems = translationsObj['radios'];
    for (let [key, value] of Object.entries(radioItems as Object)) {
      const tag_key = "radios";
      let overrideObject = myOverrideFunction(tag_key, key, translationsObjSlug, radioItems);

      radioItems[key] = overrideObject['Items'][key];
      value = radioItems[key];
      if (overrideObject['is_include']) {
        const formGroupName = `${key}`;
        const control = new FormControl('');
        if (value.hasOwnProperty('required') && value['required'] === true) {
          control.setValidators([Validators.required]);
        }
        myForm.addControl(formGroupName, control);
      }
    } // end radios
  } // end radios

  /* INPUTS */
  let inputItems = null
  if (translationsObj.hasOwnProperty('inputs')) {
    inputItems = translationsObj['inputs'];
    for (let [key, value] of Object.entries(inputItems as Object)) {
      const formGroupName = `${key}`

      const tag_key = "inputs"
      let overrideObject = myOverrideFunction(tag_key, key, translationsObjSlug, inputItems)
      inputItems[key] = overrideObject['Items'][key]
      value = inputItems[key];
      if (overrideObject['is_include']) {
        myForm.addControl(formGroupName, new FormControl(''));
        if (value.hasOwnProperty('validators')) {
          if (value['validators'].includes('email')) {
            myForm.get(formGroupName)?.setValidators(Validators.email);
          }
        }
        if (value.hasOwnProperty('required') && value['required'] === true) {
          myForm.get(formGroupName)?.setValidators(Validators.required);
        }
        if (value.hasOwnProperty('is_prefilled_disabled') && value['is_prefilled_disabled'] === true) {
          myForm.get(formGroupName)?.disable();
        }
      } // end if is_include
    } // end inputs
  } // end inputs

  /* CHECKBOXES */
  let checkboxesItems = null
  if (translationsObj.hasOwnProperty('checkboxes')) {
    checkboxesItems = translationsObj['checkboxes'];
    for (let [key, value] of Object.entries(checkboxesItems as Object)) {
      const formGroupName = `${key}`
      const nestedFormGroup = new FormGroup({});
      const tag_key = "checkboxes"
      let overrideObject = myOverrideFunction(tag_key, key, translationsObjSlug, checkboxesItems)
      checkboxesItems[key] = overrideObject['Items'][key]
      value = checkboxesItems[key];
      if (overrideObject['is_include']) {
        for (let [k, v] of Object.entries(value['options'] as Object)) {
          nestedFormGroup.addControl(k, new FormControl(false));
        }
        myForm.addControl(formGroupName, nestedFormGroup);
      }
    } // end checkboxes
  }
  /* CHECKBOX */
  let checkboxItems = null;
  if (translationsObj.hasOwnProperty('checkbox')) {
    checkboxItems = translationsObj['checkbox'];
    for (let [key, value] of Object.entries(checkboxItems as Object)) {
      const formGroupName = `${key}`;
      const tag_key = "checkbox";
      let overrideObject = myOverrideFunction(tag_key, key, translationsObjSlug, checkboxItems);
      checkboxItems[key] = overrideObject['Items'][key];
      value = checkboxItems[key];
      if (overrideObject['is_include']) {
        const control = new FormControl(false);
        if (value.hasOwnProperty('required') && value['required'] === true) {
          control.setValidators([Validators.requiredTrue]);
        }
        myForm.addControl(formGroupName, control);
      }
    }
  }

  /* CHECKBOXES NESTED */
  let checkboxesNestedItems = null
  if (translationsObj.hasOwnProperty('checkboxesNested')) {
    checkboxesNestedItems = translationsObj['checkboxesNested'];
    for (let [key, value] of Object.entries(checkboxesNestedItems as Object)) {
      const formGroupName = `${key}`
      let nestedFormGroup = new FormGroup({});
      for (let [kk, vv] of Object.entries(value as Object)) {
        if (kk != "title" && kk != 'required') {
          const formNestedGroupName = `${kk}`
          const nestedDoubleFormGroup = new FormGroup({});
          const tag_key = "checkboxesNested"
          if (translationsObjSlug['checkboxesNested'][key][kk]) {
            translationsObj['checkboxesNested'][key][kk] = translationsObjSlug['checkboxesNested'][key][kk]
          }
          if (translationsObj['checkboxesNested'][key][kk] && translationsObj['checkboxesNested'][key][kk]['is_include'] === true) {
            for (let [kkk, vvv] of Object.entries(vv['options'] as Object)) {
              nestedDoubleFormGroup.addControl(kkk, new FormControl(false));
            }
            nestedFormGroup.addControl(kk, nestedDoubleFormGroup);
          }
        }
      }
      myForm.addControl(formGroupName, nestedFormGroup);
    } // end checkboxes
  }

  /* UPLOADS */
  let uploadItems = null;
  if (translationsObj.hasOwnProperty('uploads')) {
    uploadItems = translationsObj['uploads'];
    for (let [key, value] of Object.entries(uploadItems as Object)) {
      const formGroupName = `${key}`;
      const tag_key = "uploads";
      let overrideObject = myOverrideFunction(tag_key, key, translationsObjSlug, uploadItems);
      uploadItems[key] = overrideObject['Items'][key];
      value = uploadItems[key];
      if (overrideObject['is_include']) {
        myForm.addControl(formGroupName, new FormControl(''));
        if (value.hasOwnProperty('required') && value['required'] === true) {
          myForm.get(formGroupName)?.setValidators(Validators.required);
        }
      }
    } // end uploads
  }
  return { radioItems, inputItems, checkboxItems, checkboxesItems, checkboxesNestedItems, uploadItems, myForm }
} // end function
