<div class="m-t-m m-b-l" [formGroup]="group">
  <label>
    <i class="{{ icon }}"></i>
    <span class="font-500" [innerHTML]="label"></span>
    <span class="{{ subLabelClass }}">{{ subLabel }}</span>
  </label>

  <div *ngFor="let key of objectKeys(options)">
    <mat-checkbox [formControlName]="key">{{
      options[key].label
    }}</mat-checkbox>
  </div>

  <!-- Hint and Error Message Container -->
  <div
    [ngClass]="{
      hintContainer: true,
      valid: !group.hasError('atLeastOneChecked')
    }"
  >
    <!-- Required/Optional Hint -->
    <div>
      <span *ngIf="required; else optionalField" class="hintRequired">
        *Required
        <span class="hintText"> - Please check at least one option</span>
      </span>
      <ng-template #optionalField>
        <span class="hintOptional">+Optional</span>
      </ng-template>
    </div>
  </div>
</div>
