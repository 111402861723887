<h4>Education & Informed Consent</h4>
<p>
  Commonwealth Pain & Spine (“CP&S”) is committed to treating your pain in the
  most effective and safest manner possible using a variety of methods. As part
  of your treatment plan, your CP&S provider may, if appropriate, prescribe
  controlled substance medication to treat your pain. We provide our patients
  with the lowest effective dose of controlled substance medication in
  accordance with applicable laws, rules, regulations, standards of practice,
  and according to our highly trained provider’s medical decision-making. To be
  eligible to be prescribed controlled substance medication for the treatment of
  your chronic pain, you must follow your treatment plan, which includes
  understanding, and agreeing to the information contained within this Agreement
  You must understand the risks and benefits of using controlled substances or
  pain medications to treat your pain.
</p>
<br />
<p>
  In this Agreement, we refer to medications CP&S providers may prescribe as
  “controlled substances” or “pain medicine;” these terms refer to strong drugs
  or medications that are classified by the US Drug Enforcement Agency (“DEA”)
  into five (5) distinct categories or schedules. The schedule the DEA
  classifies a drug depends upon the drug’s acceptable medical use and the
  drug’s abuse or dependency potential. Schedule I drugs have a high potential
  for abuse and the potential to create severe psychological and/or physical
  dependence. As the drug schedule changes-- Schedule II, Schedule III, etc., so
  does the abuse potential-- Schedule V drugs represent the least potential for
  abuse.
</p>
<br />

<p>
  The goal of your medical treatment is to increase your daily functioning and
  improve the quality of your life. CP&S medical providers only prescribe
  controlled substances if needed to manage our patients’ moderate to severe
  pain and other associated symptoms. The success of your treatment depends on
  mutual trust and honesty between you and your CP&S provider.
</p>
<h4>PATIENT EDUCATION</h4>
<p>CP&S provides this patient education information to inform you about;</p>
<ol>
  <li>the potential side effects and risks of taking controlled substances;</li>
  <li>
    provide you with information on how to take your medication in a safe
    manner;
  </li>
  <li>what to avoid when you are taking controlled substances; and</li>
  <li>how to store and properly dispose of your medicine.</li>
</ol>
<p style="text-align: center; font-weight: bold; text-align-last: center">
  <span style="text-decoration: underline">SECTION 1.</span> Potential Side
  Effects & Risks of Taking Controlled Substance Medicines
</p>
<br />
<p>
  The long-term use of pain medicine, such as opioids, is controversial because
  of the uncertainty of benefits of the medicine and the risks associated with
  use of pain medicine. The extent of the risks is not clear, and CP&S cannot
  predict which risks or side effects you may have because of taking controlled
  substances. It is important to know that people respond differently to pain
  medicine depending on their health, the diseases they have, genetic factors,
  other medicines they are taking, and many other factors.
</p>
<h4>SIDE EFFECTS</h4>
<p>
  The use of controlled substance medicine has potential side effects,
  including, but not limited to:
</p>
<ul>
  <li>sleepiness, drowsiness</li>
  <li>constipation</li>
  <li>nausea</li>
  <li>itching</li>
  <li>vomiting</li>
  <li>dizziness</li>
  <li>accidental death</li>
  <li>allergic reaction</li>
  <li>slowing of breathing rate, decreased respirations</li>
  <li>physical dependence</li>
  <li>tolerance to medication effectiveness</li>
  <li>addiction to medication</li>
  <li>sedation, clouded thinking</li>
  <li>skin rash</li>
  <li>sexual dysfunction</li>
  <li>sleeping abnormalities</li>
  <li>sweating</li>
  <li>edema</li>
  <li>impaired cognitive ability, confusion, impaired judgment, memory loss</li>
  <li>dry mouth</li>
  <li>suppression of immune system</li>
  <li>
    If pregnant, or become pregnant, there is a risk of baby being born with
    birth defects and/or physically dependent on opioids.
  </li>
  <li>
    Reflexes and reaction time may slow down which may result in poor motor
    coordination causing danger to the patient, and to others, when operating
    machinery, driving, and/or caring for children or other persons in the
    patient’s care.
  </li>
</ul>

<h4>POTENTIAL RISKS</h4>
<p>
  <span style="text-decoration: underline; font-weight: bold"
    >Physical dependence</span
  >
  If you take a pain medicine for an extended period of time, your body becomes
  physically “dependent.” This means your body has gotten used to the medicine.
  Physical dependence is not the same as addiction. For example, a person can be
  dependent on insulin to treat diabetes, but it does not mean the person is
  addicted to the insulin. Physical dependence means that if your medicine use
  is severely decreased, or stopped, you may experience withdrawal syndrome.
  Withdraw symptoms may include in runny nose, yawning, large pupils, goose
  bumps, abdominal pain and cramping, diarrhea, irritability, body aches and a
  flu-like feeling.
</p>
<br />
<p>
  <span style="text-decoration: underline; font-weight: bold"
    >Tolerance to medication</span
  >
  Tolerance to pain medicine means that your body may get used to your medicine
  over time and you may not get the same amount of pain relief from your
  medicine as you did when you began taking it. If tolerance occurs, increasing
  doses of your pain medicine may not always help and may cause side effects.
  Tolerance or failure to respond well to pain medicine may cause your CP&S
  provider r to choose another form of treatment.
</p>
<br />
<h4>ADDITIONAL RISKS</h4>
<ul>
  <li>
    Taking more pain medicine than prescribed may cause you to stop breathing
    which could lead to death; this risk is greater for people taking other
    medications that make you drowsy or if you have respiratory problems, like
    COPD, smoking, emphysema, sleep apnea or asthma.
  </li>
  <li>
    Taking pain medicine while diagnosed with Hepatitis and/or HIV/AIDS may have
    risk of additional health issues.
  </li>
  <li>
    Taking pain medicine while diagnosed with cardiac illness may have risk of
    additional health issues.
  </li>
  <li>
    Taking pain medicine or other controlled substances may cause sleepiness,
    clouded thinking, decreased concentration, slower reflexes, or
    incoordination, all of which may create a danger to you, and others, when
    driving or operating machinery or if you are responsible for the care of
    another person. You need to avoid driving and doing dangerous work or
    activities until the initial effects of your pain medicine no longer create
    a danger.
  </li>
  <li>
    Controlled substance use has been associated with low testosterone levels.
    This may affect your mood, stamina, sexual desire, and physical/sexual
    performance.
  </li>
  <li>
    <span style="text-decoration: underline">Pregnancy:</span> There are risks
    to an unborn child if you become pregnant while taking pain medicine or
    other controlled substances. It is recommended that you use safe and
    effective birth control while taking pain medicine or other controlled
    substances to avoid side effects. If you plan to become pregnant or believe
    that you are pregnant while taking pain medicine or other controlled
    substances, immediately inform your medical provider caring for your
    pregnancy and your CP&S provider. CP&S generally recommends that patients do
    not use pain medicine or other controlled substances during pregnancy.
  </li>
</ul>

<h4>ADDICTION & OVERDOSE</h4>
<p style="text-decoration: underline; font-weight: bold">
  Risk Factors for becoming addicted to pain medicine
</p>
<ul>
  <li>You have a history of addiction;</li>
  <li>Your family has a history of addiction;</li>
  <li>You take medicines to treat mental health problems; and/or</li>
  <li>
    You are under the age of 65 (although anyone can abuse opioid medicines).
  </li>
</ul>
<br />
<p>
  <span style="text-decoration: underline; font-weight: bold">ADDICTION</span>
  is a chronic, brain disease that causes a compulsive use of a controlled
  substances, or other substances, despite harmful consequences. You can get
  addicted to opioids even though you take them exactly as prescribed. If you
  think you might be addicted, talk to your healthcare provider right away.
</p>
<br />
<p>
  Addiction is characterized by behavior that includes one or more of the
  following:
</p>
<ul>
  <li>Impaired control over drug use or compulsive use;</li>
  <li>Continued use despite harm;</li>
  <li>Cravings, and</li>
  <li>Drug use decreases one’s quality of life.</li>
</ul>
<h4>POTENTIAL FOR OVERDOSE</h4>
<p>
  The use of controlled substances creates a risk of overdose, including
  accidental overdose.
</p>
<ul>
  <li>
    Increasing the dose of your pain medicine or other controlled substances, or
    altering its form, may result in harmful effects including overdose.
  </li>
  <li>
    You may have a higher risk for overdose if you have a history of alcohol or
    substance abuse and/or history of opioid overdose or controlled substance
    sedation.
  </li>
  <li>
    You may have a higher risk for overdose if you take benzodiazepine or other
    sedatives with your pain medicine.
  </li>
  <li>
    You may have an increased risk for overdose if you have an underlying mental
    health problem that is untreated.
  </li>
  <li>
    If you are prescribed transdermal medication (medicine patches), external
    heat, fever, and exertion can increase the absorption of transdermal
    products which may potentially lead to adverse effects, reactions, and fatal
    overdose.
  </li>
  <li>You have a higher risk for overdose if you take any illegal drugs.</li>
</ul>

<h4 style="text-align: center">SIGNS OF OVERMEDICATION OR OVERDOSE</h4>
<p>
  The following list includes signs of overmedication and/or overdose.
  <span style="font-weight: bold"
    >This list is not inclusive and other emergency conditions can occur; it is
    better to be safe and seek medical attention.</span
  >
</p>
<p>
  Please call <b>911</b> for immediate emergency assistance or immediately go to
  the nearest emergency room if the patient exhibits any of these signs;
</p>
<ul>
  <li>
    Intoxicated behavior, such as confusion, slurred speech, and/or stumbling,
  </li>
  <li>Feeling dizzy or faint, pale, or clammy face,</li>
  <li>Acting very drowsy or groggy, cannot talk, slow or unusual heartbeat,</li>
  <li>
    Unusual snoring, gasping, or snorting during sleep, shortness of breath or
    light breathing,
  </li>
  <li>Difficulty waking up from sleep or difficulty staying awake.</li>
</ul>
<br />
<p style="text-align: center; font-weight: bold; text-align-last: center">
  <span style="text-decoration: underline">SECTION 2.</span> How Can I Take
  Controlled Substance Medicines Safely?
</p>
<ul>
  <li>
    Tell your healthcare provider about all the medicines you are taking,
    including vitamins, herbal supplements, and other over-the-counter
    medicines.
  </li>
  <li>
    Read the medication guide that comes with your prescription. Read the
    prescription label each time to confirm the dosage and do not take the
    medication in the dark or without proper reading glasses, if needed. Do not
    take medication after the expiration date.
  </li>
  <li>
    Take ALL controlled substances exactly as prescribed. Do not change the
    dose, frequency, or alter the form of the drugs themselves (such as opening
    capsules, cutting in half, dissolving, crushing, snorting, or chewing
    medications).
  </li>
  <li>
    Contact your CP&S provider if the medicine is not controlling your pain. Do
    not increase the dose on your own.
  </li>
  <li>
    Do not share or give your medicine to anyone else. Your CP&S provider
    selected this medication and the dose just for you. A dose that is okay for
    you could cause an overdose and death for someone else, and it is against
    the law.
  </li>
  <li>
    Your medicine can make you sleepy, dizzy, or lightheaded. Do not operate
    heavy machinery or a motor vehicle, work at unprotected heights or be
    responsible for another individual who is unable to care for himself/herself
    until you know how your opioid medicine affects you.
  </li>
  <li>
    Do not abruptly stop taking my medication. Abruptly stopping my medication
    may lead to adverse effects.
  </li>
</ul>
<br />
<p style="text-align: center; font-weight: bold; text-align-last: center">
  <span style="text-decoration: underline">SECTION 3.</span> What Should I Avoid
  Taking While I Am Taking Controlled Substance Medicines?
</p>

<p>
  <b>Alcohol</b> Do not drink any kind of alcohol while you are taking medicines
  because the combination of alcohol and pain medicine or other controlled
  substances may cause you to stop breathing and may lead to death.
</p>
<br />

<p>
  <b>Medications</b> You should avoid taking any of the following medicines
  while you are taking pain medicine or other controlled substances, unless your
  CP&S provider gives you clear permission, because it may cause you to stop
  breathing and may lead to death;
</p>
<br />
<p>Benzodiazepines (like Valium or Xanax)</p>
<p>Muscle relaxants (like Soma or Flexeril)</p>
<p>Sleep medicines (like Ambien or Lunesta)</p>
<p>Other prescription pain medicine or other controlled substances</p>
<p>
  Cold medications & other OTC medicine; if the medicine contains alcohol or
  makes you drowsy, read the label on the medicine to determine if the medicine
  may cause side effects when taken with your pain medicine or other controlled
  substances.
</p>
<br />

<p>
  <span style="font-weight: bold; text-decoration: underline"
    >Illegal Substances</span
  >
  Do not use any illegal substances/drugs, including but not limited to,
  cocaine, methamphetamines, heroin, and/or prescription drugs obtained through
  illicit means. Taking any illegal substances/drugs while you are taking pain
  medicines or other controlled substances may result in adverse effects,
  reactions, and fatal overdose.
</p>
<br />
<p style="text-align: center; font-weight: bold; text-align-last: center">
  <span style="text-decoration: underline">SECTION 4.</span> Storage of
  Medication & Proper Disposal
</p>
<p>
  Store your pain medicine or other controlled substances in a safe place where
  it cannot be reached by children or stolen by family or visitors in your home.
  Teenagers may try to experiment with pain medicines. Use a lockbox, or other
  secure location, to keep your medicine safe.
</p>
<br />
<p>
  Dispose of any unused medication after the expiration date of the prescription
  or if you no longer require the medication. Due to environmental, social and
  safety reasons, turn in any unused controlled substance medicine at a
  government approved drug take-back program instead of throwing the controlled
  substance away or flushing down the toilet. Prior to disposing of any
  medication, remove any personal identifying information from the prescription
  container and then properly dispose of the container. You can find a local
  place to dispose of your controlled substance medicine by following the link
  below;
</p>
<a
  href="https://apps.deadiversion.usdoj.gov/pubdispsearch/spring/main?execution=e1s1"
  target="_blank"
  >Controlled Substance Public Disposal Locations - Search Utility
  (usdoj.gov)</a
>
<br />
<br />

<h4 style="text-align: center; text-align-last: center">
  CONTROLLED SUBSTANCE AGREEMENT
</h4>
<p>
  Controlled substance medicines are useful to help treat your pain, but these
  medicines can be misused. This Agreement is required to make sure you are
  following the law and to prevent any misunderstanding about the treatment you
  receive. You must read, understand, agree, and sign this agreement to be
  treated with pain medicine or other controlled substances at Commonwealth Pain
  & Spine.
</p>
<ol>
  <li>
    To be prescribed pain medicine to improve my quality of life, I agree to
    help myself by following preventive and better health habits such as:
    exercising regularly, losing weight as directed by a physician,
    abstaining from the use of tobacco, alcohol, and illicit drugs, and
    participating in physical therapy as prescribed by my CP&S provider. 
  </li>
  <li>
    I agree to bring my medications in the original containers to every visit.
  </li>
  <li>
    I agree to submit to a blood, urine, or saliva drug test to make sure I am
    complying with my treatment plan. I agree to allow the tests to be observed
    by CP&S staff to ensure compliance.
  </li>
  <li>
    I agree to submit to a drug test without an appointment, and I will arrive
    at CP&S’s office within twenty-four (24) hours of the request with my
    prescription medications in its original container.
  </li>
  <li>
    I agree that the presence of an unauthorized substance in my system, or
    refusal to perform the drug test, is in direct violation of this Agreement
    and may result in the discontinuation of my medications or my discharge as a
    CP&S patient.
  </li>
  <li>
    I agree to submit to a pill count without an appointment and I will arrive
    at CP&S’s office within twenty-four (24) hours of the request with my
    prescription medications in its original container.
  </li>
  <li>
    I agree to tell my CP&S provider about ALL medications that I take,
    including over the counter medication and supplements. I agree that I must
    update my CP&S provider of ANY changes in my medications, including over the
    counter medication and supplements.
  </li>
  <li>
    I understand that taking other controlled substances while I am prescribed
    medications by my CP&S provider, including benzodiazepines, like Valium,
    Xanax, and Ativan, and sedatives, such as Soma or Fiorinal, may cause
    serious side effects, including death. I will not obtain or take
    benzodiazepine or sedative medications unless I receive permission from my
    CP&S provider.
  </li>
  <li>
    I agree to tell my CP&S provider about ALL medical conditions I currently
    have, or I am diagnosed with, by another medical provider while in the care
    of CP&S.
  </li>
  <li>
    I agree that I will use my medications ONLY as prescribed. I understand that
    any change to my prescriptions will require an office visit. I understand
    that increasing my dose without the close supervision of my provider could
    lead to severe sedation, respiratory depression, and death.
  </li>
  <li>
    I will contact CP&S at least 24 hours, but no more than 48 hours before, my
    medications run out to arrange for refills. No refills will be made during
    evenings or weekend.
  </li>
  <li>
    I will not use any illegal substances, including, but not limited to,
    cocaine, methamphetamines, heroine, while under the care of CP&S.
  </li>
  <li>
    I understand that lost medicines WILL NOT be replaced, and I will safeguard
    my medication from theft. I will report any stolen medication to the police
    department, and I will provide a copy of the police report to CP&S.
  </li>
  <li>
    I will properly dispose of any unused or expired pain medicine. I will not
    discard, flush, give away or lose control of my medications. 
  </li>
  <li>
    I will not share, sell, or trade my medications with anyone and understand
    that these actions are against the law.
  </li>
  <li>
    I agree to keep my medication in a lockbox or safe place and that I must
    keep my medications out of reach of all people, especially children, for
    their own safety. 
  </li>
  <li>
    I will not alter the form of my medication, nor will I take the medication
    in a way other than how it prescribed by my provider.  
  </li>
  <li>
    I will not request or accept controlled substance medication, including pain
    medications, controlled stimulants, or anti-anxiety medications, from any
    other medical provider or individual while I am receiving medication from my
    CP&S provider.
  </li>
  <li>
    In the event of an emergency, if I do obtain controlled substances from
    another provider, I understand I am required to disclose this information to
    CP&S within 48 hours of discharge from emergency services. I understand it
    is my responsibility to make sure CP&S is notified.
  </li>
  <li>
    I understand that decreasing or stopping my medication without the close
    supervision of my provider can lead to withdrawal symptoms or other harmful
    physical symptoms.
  </li>
  <li>
    I will inform my other health care providers, including emergency services,
    that I am prescribed controlled substances.
  </li>
  <li>
    I understand that I must have an updated phone number with CP&S. I agree to
    return any phone call from CP&S within twenty-four (24) business hours.  I
    will notify CP&S of any change in my name, address, or phone number.
  </li>
  <li>
    I understand the possibility that pain medicine may not provide complete
    pain relief and failure to respond well to pain medicine may cause my
    provider to choose another form of treatment.
  </li>
  <li>
    I will communicate fully and honestly with my CP&S provider to the best of
    my ability at all visits, including my pain level, functional activity, and
    any side effects of the medications.
  </li>
  <li>
    I will not drive or engage in any other activity that may be dangerous to me
    or someone else until the initial side effects of my pain medicine or other
    controlled substance no longer create a danger.
  </li>
  <li>
    I agree to allow my provider to contact any health care professional, family
    member, significant other, pharmacy, legal authority, or regulatory agency
    to obtain or provide information about my care, including investigating any
    misuse of my pain medication. I understand that any illegal activity may be
    reported to legal authorities, and I agree that the appropriate legal
    authorities may have full access to my records under the control of CP&S.
  </li>
  <li>
    I agree that my appointments may be scheduled with a CP&S Advanced Practice
    Registered Nurse or Physician Assistant.
  </li>
  <li>
    I agree to designate one pharmacy for CP&S to send my prescriptions. Once
    a prescription has been filled, I agree all questions regarding that
    prescription should be directed to the pharmacy. If I need to change my
    pharmacy, I will do so in writing and give CP&S notice of the change.
  </li>
  <li>
    I agree to call 911 or go to an emergency room if I experience any severe
    side effects or reactions from my medications, including my misuse, or I am
    experiencing severe withdraw symptoms.
  </li>
  <li>
    If I become pregnant, I will inform my CP&S provider immediately. I
    understand that if I become pregnant as a patient of CP&S, I have a duty to
    tell CP&S about my pregnancy and withholding that information is cause for
    termination as a patient of CP&S. If I choose to withhold that I am
    pregnant, I declare that I am informed and I understand the risks of taking
    controlled substances during pregnancy and if I do take a controlled
    substance during my pregnancy, I am doing so voluntarily.
  </li>
  <li>
    I understand that I should not drink alcohol while I am taking pain medicine
    or other controlled substances and the combination of pain medicine or other
    controlled substances, and alcohol may result in serious harm or death.  If
    I consume alcohol while taking pain medicine or other controlled substances
    prescribed to me, I do so despite the risk and I do not hold CP&S liable.
  </li>
  <li>
    I understand that inappropriate behavior toward CP&S staff, providers, or
    other patients will not be tolerated. I agree to be respectful to other
    patients I may encounter. I understand that I may not loiter in the parking
    lot of any CP&S location.  
  </li>
  <li>
    I agree to abide by CP&S’s no weapon policy while on CP&S premises. I
    understand that if I do not abide by this policy, I may be discharged as a
    CP&S patient.
  </li>
  <li>
    I agree to be evaluated by a mental health provider or addiction specialist,
    and seek psychiatric treatment, psychotherapy, and/or psychological
    treatment if directed by my CP&S provider. I understand that if I do not
    comply, I may be discharged as a CP&S patient.
  </li>
  <li>
    I understand that non-compliance with my treatment plan may result in my
    symptoms worsening or becoming life threatening. 
  </li>
  <li>
    I understand that I may be discharged as a CP&S patient for missing an
    appointment, cancelling/rescheduling appointments with less than 24-hour
    notice, or if I have multiple missed appointments.  
  </li>
  <li>
    I understand that if I am discharged as a patient for any reason, my CP&S
    provider may, or may not, prescribe a taper dosage of my medicine, depending
    on my individual circumstances, and I agree this action or inaction will be
    determined solely by my provider’s medical decision-making. If I am
    prescribed a taper dosage of my medicine, I will take the medication as
    instructed.
  </li>
</ol>

<p
  style="
    text-align: center;
    font-weight: bold;
    text-align-last: center;
    text-decoration: underline;
  "
>
  INFORMED CONSENT FOR CONTROLLED SUBSTANCE TREATMENT
</p>
<p>
  I agree to use controlled substance medications as part of my treatment plan
  for chronic pain. I understand that these medications can be helpful but have
  a high potential for misuse or other negative side effects, including death,
  and are therefore controlled by the local, state, and federal government.
  Because my physician/health care provider is prescribing such medication to
  help manage my pain, I agree to the follow the terms of this Agreement. I
  understand that there is a risk of psychological and/or physical dependence
  and addiction associated with chronic use of controlled substances. I
  understand that this Agreement is essential to the trust and confidence
  necessary in a provider/patient relationship and that my provider undertakes
  to treat me based on this Agreement. I understand that if I break this
  Agreement, my provider will stop prescribing these pain control medicines.
  I understand that if I violate any of the terms and conditions of this
  Agreement, I may be immediately discharged as a CP&S patient. If
  the violation involves obtaining controlled substance from another individual,
  or providing controlled substances to another individual, I may also be
  reported to my other healthcare providers, medical facilities, and law
  enforcement officials. 
</p>
<br />
<p>My CP&S provider has discussed the following topics with me:</p>
<ul>
  <li>Proper use of controlled substance, side effects, and risks</li>
  <li>
    Impact of driving work safety, and care for those under my control when
    taking controlled substances
  </li>
  <li>Use of controlled substances during pregnancy</li>
  <li>Potential for overdose and appropriate response to overdose</li>
  <li>Safe storage of controlled substances</li>
  <li>Proper disposal of controlled substances</li>
</ul>
<br />
<p>
  I agree that the goals of my pain management have been explained to me. Also,
  alternative treatments, outside of the use of pain medications, have been made
  available to me. I agree to proceed with pain management with controlled
  substances after a full explanation of the risks and benefits. I understand if
  I break this Agreement, it will result in a change in my treatment plan,
  including discontinuation of my medications or I may be discharged as a CP&S
  patient.
</p>
<br />
<p>
  <span style="font-weight: bold; text-decoration: underline"
    >Termination of Treatment at CP&S</span
  >
  I understand that my provider may stop treating me as a patient at his/her
  discretion. Moreover, I acknowledge that my provider may, in his/her sole
  discretion, stop treating me with controlled substances, refer me to an
  addiction specialist, and/or terminate my patient status if I break any
  portion of this Agreement or I am arrested for any unlawful conduct.
</p>
<br />

<p>
  <span style="font-weight: bold; text-decoration: underline"
    >Authorization to Share Protected Health Information and PDMP</span
  >
  I agree to waive my right to privacy and authorize CP&S to discuss my medical
  care and to disclose my use of controlled substance to any legal authority or
  regulatory agency who lawfully requests the same. I further authorize CP&S to
  cooperate with any city, state, or federal law enforcement agency (including
  the DEA), in the investigation about my care or actions. I agree that CP&S may
  discuss or contact any health care professional, family member, significant
  other, pharmacy, legal authority, or regulatory agency to obtain or provide
  information about my care. I authorize CP&S and my pharmacy to cooperate fully
  with any city, state, or federal law enforcement agency, including this
  state’s Medical Board and Board of Pharmacy, in the investigation of any
  misuse, sale, or other diversion of my pain medication. I authorize my
  provider to provide a copy of this Agreement to my pharmacy, primary care
  provider and local emergency room. I agree to waive any applicable privilege
  or right of privacy or confidentiality with respect to these authorizations. I
  agree to hold CP&S harmless from any liability or damages if any release of
  any of my information to any law enforcement agency, regulatory agency and/or
  legal authority was later found unlawful. I further authorize CP&S to perform
  searches for my information on any prescription drug monitoring program
  (“PDMP”) records as often as CP&S requests and that CP&S, with my express
  permission, may share my PDMP report findings with legal authorities, treating
  physicians, my pharmacy and any other person or organization that I have
  signed a release authorizing production of my PDMP reports and medical
  records.
</p>

<h4>MY SIGNATURE BELOW ACKNOWLEDGES THAT:</h4>
<p>
  I acknowledge that I have told my CP&S provider my complete and honest
  personal drug and alcohol history and that of my immediate family.
</p>
<p>
  I have read, or had read to me, understand, and agree to the statements in
  this document. I certify that no guarantee or assurance has been made as to
  the results that may be obtained by services received at CP&S. 
</p>
<p>
  The risks and potential benefits have been explained to me by my CP&S provider
  and I agree to all the above requirements of the Controlled Substances
  Agreement, with full understanding of the risks of being prescribed controlled
  substance medication.
</p>
<p>
  I had the opportunity to ask questions to my provider about this document and
  about taking pain medicine or other controlled substances. All my questions
  have been answered to my satisfaction.
</p>
<p>
  I agree to speak to my family, caregiver, or other persons that I live with,
  or assist with my care, about the risks of taking controlled substances,
  including the signs of overmedication, l risk of overdose, including
  accidental overdose and I will inform them of the steps to take in the event
  of a negative reaction to my medications, overmedication and/or overdose
</p>
<br />

<p>
  I hereby give my full, voluntary, and informed consent for treatment with
  controlled substances prescribed for me exclusively by my CP&S provider. I am
  executing this Controlled Substance Agreement voluntarily after having
  sufficient time to review it.
</p>
