<p>
  To best serve you and your medical needs, AK Pain and Spine Center has
  multiple ways for patients to contact us, during as well as outside of our
  regular clinic hours. They are:
</p>
<table class="table-info">
  <tr>
    <td><p class="nowrap">Telephone:</p></td>
    <td>
      <p class="lineBreak"><a href="tel:281.896.0013">281.896.0013</a></p>
    </td>
  </tr>
  <tr>
    <td><p class="nowrap">Email:</p></td>
    <td>
      <p class="lineBreak">
        <a href="mailto:info@akpainandspine.com">info&#64;akpainandspine.com</a>
      </p>
    </td>
  </tr>
  <tr>
    <td><p class="nowrap">Text:</p></td>
    <td>
      <p class="lineBreak">
        via the “Get a quick response via text” pop-up at
        <a href="http://akpainandspine.com">akpainandspine.com</a>
      </p>
    </td>
  </tr>
  <tr>
    <td><p class="nowrap">Fax:</p></td>
    <td>
      <p class="lineBreak">
        <a href="tel:713.527.2527">713.527.2527 </a>
      </p>
    </td>
  </tr>
</table>

<p style="text-align: center; text-align-last: auto">
  <strong style="text-align: center; text-align-last: auto"
    >NOTE: While our answering service is available after hours, if you are
    experiencing a medical emergency, you should call 911.
  </strong>
</p>
