import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
//import {HmsaFormInterface} from "./hmsa-form.interface";


@Injectable({ providedIn: 'root' })
export class BlueFormService {

  url: string = `${environment.API_URL}`

  constructor(private http: HttpClient) {
  }

  getFormData(endpoint: string, is_mocked: boolean = true): Observable<any> {

    // if(is_mocked){
    //   return of<HmsaFormInterface>({
    //     "version": 1,
    //     "form_data":{
    //       "gender": "male",
    //       "demographics":{
    //         "state": "hawaii"
    //       }
    //     }
    //   })
    // }



    const url = `${this.url}/${endpoint}`;

    return this.http.get<any>(url);
  } //end login

  post(endpoint: string, data: any, is_mocked: boolean = false) {

    const url = `${this.url}/${endpoint}`;

    return this.http.post(`${this.url}/${endpoint}`, data, { observe: 'response' });
  } //end post

  put(endpoint: string, data: any, is_mocked: boolean = false) {

    const url = `${this.url}/${endpoint}`;

    return this.http.put(`${this.url}/${endpoint}`, data, { observe: 'response' });
  } //end put

  patch(endpoint: string, data: any, is_mocked: boolean = false) {
    // if(is_mocked){
    //   return of<HmsaFormInterface | any>({
    //
    //   }
    //   )
    // } //end if
    const url = `${this.url}/${endpoint}`;

    return this.http.patch(`${this.url}/${endpoint}`, data, { observe: 'response' });
  } //end patch

  getPurl(endpoint: string) {


    const url = `${this.url}/${endpoint}`;

    return this.http.get<any>(url);
  } //end getPurl

  putPurl(purl: string, data: string) {


    return this.http.put<any>(purl, data);
  } //end getPurl


} //
