<div>
  <mat-form-field [formGroup]="formGroup" appearance="fill">
    <mat-label>{{ placeholder }}</mat-label>
    <input id="{{ id }}"
      matInput
      [type]="type"
      [formControl]="control"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [attr.min]="min"
      [attr.max]="max"
      [attr.maxlength]="maxlength"
      [required]="required"
    />
    <div matSuffix tabindex="-1" class="p-r-m">
      <mat-icon>
        <i
          [ngClass]="['input-icon', iconClass || 'healent-form-input-icon-6']"
        ></i>
      </mat-icon>
    </div>
  </mat-form-field>

  <!-- Hint and Error message container -->
  <div
    *ngIf="!readonly"
    [ngClass]="{ hintContainer: true, valid: control.valid && control.value }"
  >
    <!-- Display error message if control has error -->
    <div *ngIf="control.errors && control.touched">
      <span class="hintError">#Error</span>
      <span class="hintText"> - {{ getErrorMessage() }}</span>
    </div>

    <!-- Display required/optional hint if no error -->
    <div *ngIf="!control.errors || !control.touched">
      <span
        *ngIf="isFieldRequired() || required; else optionalField"
        class="hintRequired"
      >
        *Required
      </span>
      <ng-template #optionalField>
        <span class="hintOptional">+Optional</span>
      </ng-template>
      <span *ngIf="hint" class="hintText"> - {{ hint }}</span>
    </div>
  </div>
  <div *ngIf="readonly" class="hintContainer">
    <!-- Display error message if control has error -->
    <div>
      <span class="hintDisabled">!Disabled</span>
      <span class="hintText"> - This field is readonly</span>
    </div>
  </div>
</div>
